import React, { useContext, useState, useEffect } from 'react';

import {
    Tab,
    TabPane,
    ShipmentList
} from 'components';
import { useHistory } from 'react-router-dom';
import { HomeContext, ActiveShipmentsContext, TrackShipmentContext } from 'contexts';
import {
    // ACTIVE_SHIPMENT_PAGE_SIZE,
    ACTIVE_SHIPMENT_TABS,
    ACTIVE,
    ACTIVE_SHIPMENT_PAGE_SIZE,
    ARCHIVE_SHIPMENT_TABS,
    ALL,
    MYTASK,
    STAR,
    OTHER_ACTIVE,
    NEED_ATTENTION
} from 'actions';
import { useTranslation } from 'react-i18next';

export const ShipmentTabs = ({ setCurrentTab = () => {}, currentTab }) => {
    const [t] = useTranslation();
    const history = useHistory();
    const { isArchive, setShipment, seachParamActive,filterParamsShipment } = useContext(HomeContext);
    const { setTrackers, setSelectedTracker, setRoutes } = useContext(TrackShipmentContext);
    const { 
        doGetActiveShipments, 
        activeShipments,
        needActiveShipments,
        starActiveShipments,
        setNeedAttentionActiveShipments,
        setActiveShipments,
        totalPages,
        currentPage,
        doAddStarShipment,
        doRemoveStarShipment,
        currentPageStar,
        totalPagesStar,
        currentPageAttention,
        totalPagesAttention,
    } = useContext(ActiveShipmentsContext);
    const [tabs,setTabs] = useState(isArchive
        ? ARCHIVE_SHIPMENT_TABS
        : ACTIVE_SHIPMENT_TABS);
        
    /*eslint-disable */
    useEffect(() => {
        setNeedAttentionActiveShipments([]);
        setActiveShipments([]);
        doGetActiveShipments({
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: ACTIVE_SHIPMENT_TABS.ALL,
            status: ACTIVE,
            name: seachParamActive,
            tabType: NEED_ATTENTION
        });
        doGetActiveShipments({
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: ACTIVE_SHIPMENT_TABS.ALL,
            status: ACTIVE,
            name: seachParamActive,
            tabType: OTHER_ACTIVE
        });
        doGetActiveShipments({
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: ACTIVE_SHIPMENT_TABS.ALL,
            status: ACTIVE,
            name: seachParamActive,
            tabType: STAR
        });
    }, [seachParamActive])
    
    useEffect(() => {
        setTabs(isArchive
        ? ARCHIVE_SHIPMENT_TABS
        : ACTIVE_SHIPMENT_TABS);
    }, [isArchive])
    /*eslint-enable */

    const handleShowDetails = shipmentId => {
        if (shipmentId) {
            setTrackers([]);
            setSelectedTracker(null);
            setRoutes(null);
            history.push(`/${isArchive ? 'archive' : 'active-shipments'}/${shipmentId._id}`);
            setShipment(shipmentId);
        }
    }

    const handleTabChange = tab => {
        let tabOption = ALL;
        switch(tab) {
            case ALL:
                tabOption = ALL;
                break;
            case MYTASK:
                tabOption = "MYTASK";
                break;
            default:
                tabOption = ALL;
                break;
        }
        setCurrentTab(tabOption);

        doGetActiveShipments({
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: tabOption,
            status: ACTIVE,
            name: seachParamActive,
            tabType : NEED_ATTENTION
        });
        doGetActiveShipments({
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: tabOption,
            status: ACTIVE,
            name: seachParamActive,
            tabType: STAR
        });
        doGetActiveShipments({
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: tabOption,
            status: ACTIVE,
            name: seachParamActive,
            tabType: OTHER_ACTIVE
        });
    }

    const handlePageChange = (page,tabType) => {
        let params;
        if (filterParamsShipment) {
            filterParamsShipment.page = page;
            filterParamsShipment.tab = currentTab;
            filterParamsShipment.tabType = tabType;
            params = filterParamsShipment
        } else {
            params = {
                page: page,
                limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                tab: currentTab,
                status: ACTIVE,
                name: seachParamActive,
                tabType
            }
        }
        doGetActiveShipments(params);
    }

    const handleStarShipment = (shipment,tabType) => {
        if (shipment && shipment._id) {
            doAddStarShipment(shipment._id, currentTab, () => {
                doGetActiveShipments({
                    page: 0,
                    limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                    tab: currentTab,
                    status: ACTIVE,
                    name: seachParamActive,
                    tabType: STAR
                });
                doGetActiveShipments({
                    page: 0,
                    limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                    tab: currentTab,
                    status: ACTIVE,
                    name: seachParamActive,
                    tabType
                });
            })
        }
        
    }

    const handleRemoveStarShipment = (shipment) => {
        if (shipment && shipment._id) {
            doRemoveStarShipment(shipment._id, currentTab, () => {
                doGetActiveShipments({
                    page: 0,
                    limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                    tab: currentTab,
                    status: ACTIVE,
                    name: seachParamActive,
                    tabType: STAR
                });
                doGetActiveShipments({
                    page: 0,
                    limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                    tab: currentTab,
                    status: ACTIVE,
                    name: seachParamActive,
                    tabType: OTHER_ACTIVE
                });
                doGetActiveShipments({
                    page: 0,
                    limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                    tab: currentTab,
                    status: ACTIVE,
                    name: seachParamActive,
                    tabType: NEED_ATTENTION
                });
            })
        }
    }

    return (
        <div className="tr__shipment-tabs mtx2">
            <Tab initialTab={0} onTabClick={handleTabChange}>
                {tabs.map((tab, tabIndex) => (
                    <TabPane
                        key={tabIndex}
                        tabName={t(tab?.name).toLowerCase().replace('_', ' ')}
                        tabSlug={tab.id}
                    >
                        <>
                            {starActiveShipments && (
                                <ShipmentList
                                    className='mbx4'
                                    title={t("home.active-shipment.star-shipment")}
                                    shipments={starActiveShipments}
                                    onShowDetails={handleShowDetails}
                                    isArchive={isArchive}
                                    onStarShipment={handleRemoveStarShipment}
                                    isStar={true}
                                    currentTab={currentTab}
                                    totalPages={totalPagesStar}
                                    currentPage={currentPageStar}
                                    onPageChange={page => handlePageChange(page,STAR)}
                                    tabType={STAR}
                                />
                            )}
                            {needActiveShipments && (
                                <ShipmentList
                                    className='mbx4'
                                    title={t("home.active-shipment.need-attention")}
                                    shipments={needActiveShipments}
                                    onShowDetails={handleShowDetails}
                                    isArchive={isArchive}
                                    onStarShipment={shipment => handleStarShipment(shipment,NEED_ATTENTION)}
                                    currentTab={currentTab}
                                    totalPages={totalPagesAttention}
                                    currentPage={currentPageAttention}
                                    onPageChange={page => handlePageChange(page,NEED_ATTENTION)}
                                    tabType={NEED_ATTENTION}
                                />
                            )}
                            <ShipmentList
                                className='mbx4'
                                title={t("home.active-shipment.other-shipment")}
                                shipments={activeShipments}
                                onShowDetails={handleShowDetails}
                                isArchive={isArchive}
                                seachParamActive={seachParamActive}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={page => handlePageChange(page,OTHER_ACTIVE)}
                                onStarShipment={shipment => handleStarShipment(shipment,OTHER_ACTIVE)}
                                currentTab={currentTab}
                                tabType={OTHER_ACTIVE}
                            />
                        </>
                    </TabPane>
                ))}
            </Tab>
        </div>
    )
};
