import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import avatar from 'assets/images/avatar.png';
import {
    MultiSelect,
    ItemCard
} from 'components';
import {
    ActiveShipmentsContext,
    AuthContext,
    HomeContext,
} from 'contexts';
import {
    CONSIGNEE,
    EXPORT_CUSTOMS,
    EXPORT_LOGISTICS,
    FREIGHT,
    IMPORT_CUSTOMS,
    IMPORT_LOGISTICS,
    MARK_BOOKING_REQUEST,
    MARK_NEW,
    MARK_TRACK_SHIPMENT,
    MEMBER,
    SHIPPER,
    TEAM
} from 'actions';
import { GET_FILE, SHIPMENT_ADMIN } from 'actions/constants';
import { useTranslation } from 'react-i18next';

const MEMBER_U = MEMBER.toUpperCase();
const TEAM_U = TEAM.toUpperCase();

export const AddPartnersForm =   ({
    register = () => {},
    selectedValues = [],
    setSelectedValues = () => {},
    shipmentDetail
}) => {
    const [t] = useTranslation();
    const {
        markAsShipmentType,
        markAsShipmentTemplate,
        trackerTemplate
    } = useContext(HomeContext);
    const { userInfo } = useContext(AuthContext);
    const { partners, doGetPartners } = useContext(ActiveShipmentsContext);

    const [shipmentAdmin, setShipmentAdmin] = useState([]);
    const [shipper, setShipper] = useState([])
    const [consignee, setConsignee] = useState([]);
    const [exportCustom, setExportCustom] = useState([]);
    const [freightPartner, setFreightPartner] = useState([]);
    const [exportLogistics, setExportLogistics] = useState([]);
    const [importCustom, setImportCustom] = useState([]);
    const [importLogistics, setImportLogistics] = useState([]);
    
    const [selectedOptionShipmentAdmin, setSelectedOptionShipmentAdmin] = useState([]);
    const [selectedOptionShipper, setSelectedOptionShipper] = useState([]);
    const [selectedOptionConsignee, setSelectedOptionConsignee] = useState([]);
    const [selectedOptionExCustom, setSelectedOptionExCustom] = useState([]);
    const [selectedOptionFreight, setSelectedOptionFreight] = useState([]);
    const [selectedOptionExLogistic, setSelectedOptionExLogistic] = useState([]);
    const [selectedOptionImCustom, setSelectedOptionImCustom] = useState([]);
    const [selectedOptionImLogistic, setSelectedOptionImLogistic] = useState([]);
    const inviteByEmail = 'Invite by email';

    /*eslint-disable */
    useEffect(() => {
        doGetPartners();
    }, [])

    useEffect(() => {
        selectedValues.forEach( async value => {
            if (value.roleType === SHIPMENT_ADMIN && !shipmentAdmin.length) setShipmentAdmin(value.partners);
            if (value.roleType === SHIPPER && !shipper.length) setShipper(value.partners);
            if (value.roleType === CONSIGNEE && !consignee.length) setConsignee(value.partners);
            if (value.roleType === EXPORT_CUSTOMS && !exportCustom.length) setExportCustom(value.partners);
            if (value.roleType === FREIGHT && !freightPartner.length) setFreightPartner(value.partners);
            if (value.roleType === EXPORT_LOGISTICS && !exportLogistics.length) setExportLogistics(value.partners);
            if (value.roleType === IMPORT_CUSTOMS && !importCustom.length) setImportCustom(value.partners);
            if (value.roleType === IMPORT_LOGISTICS && !importLogistics.length) setImportLogistics(value.partners);
        });
    }, [selectedValues])

    useEffect(() => {
        if (shipmentDetail) {
            // Do binding previous partners
        }
        if (markAsShipmentType !== MARK_NEW) {
            switch(markAsShipmentType) {
                case MARK_TRACK_SHIPMENT:
                    if (trackerTemplate) {

                    }
                    break;
                case MARK_BOOKING_REQUEST:
                    if (markAsShipmentTemplate && !selectedValues.length) {
                        const { memberInfo, freight, creatorRole, base } = markAsShipmentTemplate;
                        let freights = [];
                        let creators = [];
                        let collaborators = [];

                        memberInfo.forEach(m => {
                            if (m.id === freight.freightId) freights.push({
                                ...m,
                                type: freight.freightType,
                                email: m.email,
                            });
                            else if (m.id === base.createdBy) creators.push({
                                ...m,
                                type: MEMBER_U,
                                email: m.email
                            });
                            else {
                                collaborators.push({
                                    ...m,
                                    type: MEMBER_U,
                                    email: m.email
                                })
                            };
                        });

                        setFreightPartner(old => [...old, ...mappingPartners(freights)]);
                        handleAddPartner(mappingPartners(freights), FREIGHT);
                        switch(creatorRole) {
                            case CONSIGNEE:
                                setConsignee(old => [...old, ...mappingPartners(creators), ...mappingPartners(collaborators)]);
                                handleAddPartner(mappingPartners([...creators, ...collaborators]), CONSIGNEE);
                                break;
                            case SHIPPER:
                                setShipper(old => [...old, ...mappingPartners(creators), ...mappingPartners(collaborators)]);
                                handleAddPartner(mappingPartners([...creators, ...collaborators]), SHIPPER);
                                break;
                        };

                    }
                    break;
                default:
                    break;
            }
        }
    }, [markAsShipmentType, markAsShipmentTemplate, trackerTemplate])

    /*eslint-enable */

    const mappingPartners = source => {
        return source.map(m => {
            return {
                id: m.id,
                icon: m.type === TEAM_U ? 'icon-users' : (m.avatar ? GET_FILE + m.avatar : avatar),
                title: m.username,
                description: m.type === TEAM_U ? m.organization.name : m.email,
                type: m.type,
            }
        })
    }

    const handleRemove = (item, roleType) => {
        selectedValues.map((e) => {
            if (e.roleType === roleType) {
                e.partners = e.partners.filter(o => o.id !== item.id);
            }
            return e;
        });
        setSelectedValues(selectedValues);
    }

    const handleAddPartner = (items, roleType) => {
        if (!items.length) return;
        doGetPartners();
        const partner = items.map(item => {
            return {
                ...item,
                id: item && item.description !== inviteByEmail && item.id,
                description: item.description,
                title: item.title,
                icon: item.icon,
                type: item.type ||  MEMBER.toUpperCase()
            }
        });
        if (selectedValues.length > 0) {
            let isExisted = false;
            selectedValues.map((e) => {
                if (e.roleType === roleType) {
                    e.partners = e.partners.concat(partner);
                    isExisted = true;
                }
                return e;
            });
            if (!isExisted) {
                let roleShipment  = { roleType: roleType, partners : partner };
                selectedValues = selectedValues.concat(roleShipment);
            }
            setSelectedValues(selectedValues);
        } else {
            let roleShipment  = { roleType: roleType, partners : partner };
            setSelectedValues(oldValue => [...oldValue, roleShipment]);
        }
    }

    const getMembersTeams = (index, value) => {
        doGetPartners({name : value.trim()});
    }

    const filteredOptions = (options, selectedOptions) => {
        if (!userInfo) return options;
        const afterSelectedOptions = options.filter(item => !selectedOptions.some(i => i.id === item._id) && userInfo._id !== item._id);
        // const afterSelectedValues = afterSelectedOptions.filter(item => !selectedValues.some(v => v.partners.some(sv => item._id === sv.id)));
        return afterSelectedOptions;
    }

    return (
        <div className="tr__add-partners-form">
            <div className="tr__add-partners-form--group mbx2">
            <MultiSelect
                    className="mbx4"
                    value={shipmentAdmin}
                    name="shipment-admin"
                    label={t("home.active-shipment.assign-shipment-admin")}
                    // addButton={true}
                    options={partners ? filteredOptions(partners.filter(colab => consignee.every(partner => colab._id !== partner.id )), selectedOptionShipmentAdmin).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item?.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    // addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(0, value)}
                    // searchLoading={memberLoading[0] || teamLoading[0]}
                    labelIcon='icon-admin'
                    placeholder={t("home.active-shipment.placehoder-search")}
                    register={register()}
                    onChange={items => {
                        handleAddPartner(items.map(item => {
                            return {
                                ...item,
                                temp: true
                            }
                        }), SHIPMENT_ADMIN);
                        setShipmentAdmin(oldValue => [...oldValue, ...items]);
                        setSelectedOptionShipmentAdmin(oldValue => [...oldValue, ...items]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, SHIPMENT_ADMIN);
                                        setShipmentAdmin(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionShipmentAdmin(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                    // isRemovable={!!member.temp}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    value={consignee}
                    name="consignee"
                    label={t("home.active-shipment.assign-consignee")}
                    // addButton={true}
                    options={partners ? filteredOptions(partners.filter(colab => consignee.every(partner => colab._id !== partner.id )), selectedOptionConsignee).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item?.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    // addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(0, value)}
                    // searchLoading={memberLoading[0] || teamLoading[0]}
                    labelIcon='icon-box'
                    placeholder={t("home.active-shipment.placehoder-search")}
                    register={register()}
                    onChange={items => {
                        handleAddPartner(items.map(item => {
                            return {
                                ...item,
                                temp: true
                            }
                        }), CONSIGNEE);
                        setConsignee(oldValue => [...oldValue, ...items]);
                        setSelectedOptionConsignee(oldValue => [...oldValue, ...items]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, CONSIGNEE);
                                        setConsignee(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionConsignee(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                    // isRemovable={!!member.temp}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    value={shipper}
                    name="shipper"
                    label={t("home.active-shipment.assign-shipper")}
                    // addButton={true}
                    options={partners ? filteredOptions(partners.filter(colab => shipper.every(partner => colab._id !== partner.id )), selectedOptionShipper).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item?.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    // addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(1, value)}
                    // searchLoading={memberLoading[1] || teamLoading[1]}
                    labelIcon='icon-shipper'
                    placeholder={t("home.active-shipment.placehoder-search")}
                    register={register()}
                    onChange={items => {
                        handleAddPartner(items.map(item => {
                            return {
                                ...item,
                                temp: true
                            }
                        }), SHIPPER);
                        setShipper(oldValue => [...oldValue, ...items.map(i => {
                            return {
                                ...i,
                                temp: true
                            }
                        })]);
                        setSelectedOptionShipper(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item,
                                temp: true
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, SHIPPER);
                                        setShipper(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionShipper(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                    // isRemovable={!!member.temp}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={partners ? filteredOptions(partners.filter(colab => exportCustom.every(partner => colab._id !== partner.id )), selectedOptionExCustom).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item?.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    value={exportCustom}
                    name="exportCustom"
                    label={t("home.active-shipment.assign-export-customs-broker")}
                    // addButton={true}
                    // addButtonLabel="Add"
                    onInputChange={value => getMembersTeams(2, value)}
                    // searchLoading={memberLoading[2] || teamLoading[2]}
                    labelIcon='icon-export'
                    placeholder={t("home.active-shipment.placehoder-search")}
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items.map(item => {
                            return {
                                ...item,
                                temp: true
                            }
                        }), EXPORT_CUSTOMS);
                        setExportCustom(oldValue => [...oldValue, ...items.map(i => {
                            return {
                                ...i,
                                temp: true
                            }
                        })]);
                        setSelectedOptionExCustom(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item,
                                temp: true
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, EXPORT_CUSTOMS );
                                        setExportCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionExCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                    // isRemovable={!!member.temp}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={partners ? filteredOptions(partners.filter(colab => freightPartner.every(partner => colab._id !== partner.id )), selectedOptionFreight).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item?.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    value={freightPartner}
                    // addButton={true}
                    // addButtonLabel="Add"
                    name="freightPartner"
                    label={t("home.active-shipment.assign-freight-partner")}
                    onInputChange={value => getMembersTeams(3, value)}
                    // searchLoading={memberLoading[3] || teamLoading[3]}
                    labelIcon='icon-truck'
                    placeholder={t("home.active-shipment.placehoder-search")}
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items.map(item => {
                            return {
                                ...item,
                                temp: true
                            }
                        }), FREIGHT);
                        setFreightPartner(oldValue => [...oldValue, ...items.map(i => {
                            return {
                                ...i,
                                temp: true
                            }
                        })]);
                        setSelectedOptionFreight(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item,
                                temp: true
                            };
                        })]);
                    }}
                    onRemove={item => handleRemove(item)}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, FREIGHT);
                                        setFreightPartner(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionFreight(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                    // isRemovable={!!member.temp}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={partners ? filteredOptions(partners.filter(colab => exportLogistics.every(partner => colab._id !== partner.id )), selectedOptionExLogistic).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item?.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    value={exportLogistics}
                    // addButton={true}
                    // addButtonLabel="Add"
                    name="exportLogistic"
                    label={t("home.active-shipment.assign-export-logistics")}
                    onInputChange={value => getMembersTeams(4, value)}
                    // searchLoading={memberLoading[4] || teamLoading[4]}
                    labelIcon='icon-box-export'
                    placeholder={t("home.active-shipment.placehoder-search")}
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items.map(item => {
                            return {
                                ...item,
                                temp: true
                            }
                        }), EXPORT_LOGISTICS);
                        setExportLogistics(oldValue => [...oldValue, ...items.map(i => {
                            return {
                                ...i,
                                temp: true
                            }
                        })]);
                        setSelectedOptionExLogistic(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item,
                                temp: true
                            };
                        })]);
                    }}
                    renderList={mems => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {mems.length > 0 && mems.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, EXPORT_LOGISTICS);
                                        setExportLogistics(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionExLogistic(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                    // isRemovable={!!member.temp}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={partners ? filteredOptions(partners.filter(colab => importCustom.every(partner => colab._id !== partner.id )), selectedOptionImCustom).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item?.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    value={importCustom}
                    // addButton={true}
                    // addButtonLabel="Add"
                    name="importCustom"
                    onInputChange={value => getMembersTeams(5, value)}
                    // searchLoading={memberLoading[5] || teamLoading[5]}
                    label={t("home.active-shipment.assign-import-customs")}
                    labelIcon='icon-import'
                    placeholder={t("home.active-shipment.placehoder-search")}
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items.map(item => {
                            return {
                                ...item,
                                temp: true
                            }
                        }), IMPORT_CUSTOMS);
                        setImportCustom(oldValue => [...oldValue, ...items.map(i => {
                            return {
                                ...i,
                                temp: true
                            }
                        })]);
                        setSelectedOptionImCustom(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item,
                                temp: true
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, IMPORT_CUSTOMS);
                                        setImportCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionImCustom(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                    // isRemovable={!!member.temp}
                                />
                            ))}
                        </div>
                    )}
                />
                <MultiSelect
                    className="mbx4"
                    options={partners ? filteredOptions(partners.filter(colab => importLogistics.every(partner => colab._id !== partner.id )), selectedOptionImLogistic).map(item => {
                        return {
                            email: item.email,
                            id: item._id || new Date().getTime().toString(),
                            icon: item._id ? (item.type === MEMBER_U ? (item.avatar ? GET_FILE + item.avatar : avatar) : 'icon-users') : 'icon-envelop',
                            title: item._id ? item?.name : item.email,
                            description: item._id ? (item.type === MEMBER_U ? item.email : item.organization.name) : inviteByEmail,
                            type: item.type
                        }
                    }) : []}
                    value={importLogistics}
                    // addButton={true}
                    // addButtonLabel="Add"
                    name="importLogistic"
                    label={t("home.active-shipment.assign-import-logistics")}
                    onInputChange={value => getMembersTeams(6, value)}
                    // searchLoading={memberLoading[6] || teamLoading[6]}
                    labelIcon='icon-box-import'
                    placeholder={t("home.active-shipment.placehoder-search")}
                    register={register()}
                    onChange={(items) => {
                        handleAddPartner(items.map(item => {
                            return {
                                ...item,
                                temp: true
                            }
                        }), IMPORT_LOGISTICS);
                        setImportLogistics(oldValue => [...oldValue, ...items.map(i => {
                            return {
                                ...i,
                                temp: true
                            }
                        })]);
                        setSelectedOptionImLogistic(oldValue => [...oldValue, ...items.map((item) => {
                            return {
                                ...item,
                                temp: true
                            };
                        })]);
                    }}
                    renderList={members => (
                        <div className="tr__partners d-flex flex-wrap mtx1">
                            {members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={item => {
                                        handleRemove(item, IMPORT_LOGISTICS);
                                        setImportLogistics(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                        setSelectedOptionImLogistic(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
                                    }}
                                    cardOnly={true}
                                    // isRemovable={!!member.temp}
                                />
                            ))}
                        </div>
                    )}
                />
            </div>
        </div>
    )
};

AddPartnersForm.propTypes = {
    register: PropTypes.func,
    selectedValues: PropTypes.array,
    setSelectedValues: PropTypes.func,
    shipmentDetail: PropTypes.object
}
