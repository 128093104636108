import React from 'react';
import { ActivitiesHeader } from '../ActivitiesHeader';

import './index.scss';
import { PropTypes } from 'prop-types';
import moment from 'moment';

export const LogItem = ({ logs = [] }) => {
    return logs && (
        <div className="tr__activities-log-item">
            <ActivitiesHeader
                className="pbx2"
            />
            {logs.map((log, logIndex) => (
                <div className="table--body d-flex justify-content-between" key={logIndex} >
                    <div className="table--body__column name">
                        {log.username}
                    </div>
                    <div className="table--body__column email">
                        {log.email}
                    </div>
                    <div className="table--body__column activity">
                        {log.description}
                    </div>
                    <div className="table--body__column time">
                        {moment(log.dateTime).format('DD MMM YYYY | hh:mm A')}
                    </div>
                </div>
            ))}
        </div>
	)
};

LogItem.propTypes = {
    log: PropTypes.array
};