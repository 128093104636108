import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { useTranslation } from 'react-i18next';

export const DocumentHeader = ({ className = '', isDocuments = false }) => {
    const [t] = useTranslation();
    return (
        <div className={`tr__document-header f-medium d-flex align-items-center justify-content-between table-header ${className} ${isDocuments ? 'document' : ''}`}>
            {!isDocuments && (
                <>
                    <div className="column name">
                        <p>{t("home.active-shipment.details.tabs.document-tab.task")}</p>
                    </div>
                    <div className="column partner">
                        <p>{t("home.active-shipment.details.tabs.document-tab.partner")}</p>
                    </div>
                    <div className="column uploader">
                        <p>{t("home.active-shipment.details.tabs.document-tab.uploaded")}</p>
                    </div>
                    <div className="column assignee">
                        <p>{isDocuments ? <>&nbsp;</> : t("home.active-shipment.details.tabs.document-tab.assignee")}</p>
                    </div>
                    <div className="column btns">&nbsp;</div>
                 </>
            )}
            {isDocuments && (
                <>
                    <div className="column name">
                        <p>{t("home.active-shipment.details.tabs.document-tab.document")}</p>
                    </div>
                    <div className="column uploader">
                        <p>{t("home.active-shipment.details.tabs.document-tab.uploaded")}</p>
                    </div>
                    <div className="column type">
                        <p>{t("home.active-shipment.details.tabs.document-tab.document-type")}</p>
                    </div>
                    <div className="column tradeTrust">
                        <p>{t("home.active-shipment.details.tabs.document-tab.status")}</p>
                    </div>
                    <div className="column btns">&nbsp;</div>
                </> )
            }
        </div>
    )
};

DocumentHeader.propTypes = {
    className: PropTypes.string
};
