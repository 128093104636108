import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import {
    Modal,
} from 'components';
import { IssueContext, ShipmentInfoContext } from 'contexts';
import { useLocation } from 'react-router-dom';
import { CLOSED } from 'actions';
import { useTranslation } from 'react-i18next';
export const ConfirmStatusIssue = ({
    open = false,
    onCancel,
    onSubmit = () => {},
    title='',
    message = '',
}) => {
    const [t] = useTranslation();
    const { statusIssue, doChangeCloseStatus, doChangeOpenStatus } = useContext(IssueContext);
    const { doGetShipmentInfo } = useContext(ShipmentInfoContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 3];
    
    
    const handleSubmit = () => {
        const payload = {
            status: statusIssue.status
        }
        if (statusIssue.status === CLOSED) {
            doChangeCloseStatus(shipmentId, statusIssue.id, payload, () => {
                doGetShipmentInfo(shipmentId);
                onCancel();
            })
        } else {
            doChangeOpenStatus(shipmentId, statusIssue.id, payload, () => {
                doGetShipmentInfo(shipmentId);
                onCancel();
            })
        }
    }
    return (
        <Modal
            open={open}
            className="tr__issue-confirmation"
            onCancel={onCancel}
            title={t("home.active-shipment.details.tabs.issues-tab.confirmation-change-status")}
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel={t("home.active-shipment.details.tabs.issues-tab.Yes")}
            onSubmitClick={handleSubmit}
            cancelButton={true}
            cancelButtonLabel={t("home.active-shipment.details.tabs.issues-tab.No")}
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center change-status-issue">
                <h6 className="h6 mtbx2 f-medium">{t("home.active-shipment.details.tabs.issues-tab.message-issue")}</h6>
            </div>
        </Modal>
    )
}
ConfirmStatusIssue.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string
};
