import './index.scss';
import { Dropdown } from "components";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ActiveShipmentsContext } from "contexts";
import { ACTIVE, DOCUMENT_PAGE_SIZE } from "actions";
export const ShipmentInformation = ({shipmentKeys, register, handleSearchShipmentKey, onChangeInputValue, filterCountByIndex, count, onChangeInput}) => {
    const { t } = useTranslation();
    const { doGetValueByShipmentInf } = useContext(ActiveShipmentsContext);

    return (
        <>
            {count.map((data, index) => {
                return (
                    <div key={index} className="tr__shipment-filter--group last">
                        {count.length > 1 ? <div className={'tr__shipment-filter--group-remove-icon'} onClick={() => filterCountByIndex (index)}>
                            -
                        </div> : ''}
                        <Dropdown
                            className="select mbx4"
                            icon="icon-chevron-down"
                            iconPosition="right"
                            mode="input"
                            name="shipmentKey"
                            label={t("home.active-shipment.filter-panel.shipmentKey.label")}
                            options={shipmentKeys && shipmentKeys.length > 0 ? shipmentKeys.map(item => {
                                return {
                                    key: item.fieldName,
                                    value: item.label,
                                };
                            }) : []}
                            placeholder={t("home.active-shipment.filter-panel.shipmentKey.placeholder")}
                            dropdownPosition="center"
                            refs={register}
                            onInputChange={handleSearchShipmentKey}
                            onChange={(value) => {
                                data.information = value;
                                doGetValueByShipmentInf({
                                    page:0,
                                    limit:DOCUMENT_PAGE_SIZE,
                                    status:ACTIVE,
                                    projectField:value.key
                                },(callback) => onChangeInput(callback.items, index));
                            }}
                            value={data.information}
                        />
                        <Dropdown
                            icon="icon-chevron-down"
                            iconPosition="right"
                            className="select mbx4"
                            name="shipmentValue"
                            mode="input"
                            dropdownPosition="center"
                            options={data.items && data.items.length > 0 ? data.items.map(item => {
                                return {
                                    key: item,
                                    value: item,
                                };
                            }) : []}
                            label={t("home.active-shipment.filter-panel.shipmentValue.label")}
                            onInputChange={(value) => onChangeInputValue(value, index)}
                            onChange={(value) => {
                                data.value = value.value
                            }}
                            value={data.value}
                            defaultValue={data.value ? data.value : ''}
                            placeholder={t("home.active-shipment.filter-panel.shipmentValue.placeholder")}
                        />
                    </div>
                )
            })}
        </>
    )
}
