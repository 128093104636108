import React from 'react';
import PropTypes from 'prop-types';

import {
    Modal,
    AssignMembers
} from 'components';

export const AssignMembersModal = ({ open = false, onCancel }) => {
    return (
        <Modal
            open={open}
            className="tr__assign-members"
            onCancel={onCancel}
            title="Create new issue"
            isBackDropClickable={false}
        >
            <AssignMembers title="Assign new members" />
        </Modal>
    )
};

AssignMembersModal.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func
}