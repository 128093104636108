import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import './index.scss';
import {
    Modal,
    TeamDetails,
    InviteMembers
} from 'components';
import {
    ActiveShipmentsContext,
    HomeContext,
    MemberContext,
    ModalContext,
    OrganizationContext,
    TeamContext
} from 'contexts';
import { ORGANIZATIONS_LIST_URL, TEAM } from 'actions';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AddTeam = ({ open = false, onCancel }) => {
	const [t] = useTranslation();
    const location = useLocation();
    const { setNotificationMessage } = useContext(HomeContext);
    const { setAddTeam } = useContext(ModalContext);
    const { doCreateTeam } = useContext(TeamContext);
    const { partners, doGetPartners } = useContext(ActiveShipmentsContext);
    const { doGetOrganization, doGetOrganizations, setOrganizations } = useContext(OrganizationContext);
    const { allMembers, setAllMembers } = useContext(MemberContext);

    const { handleSubmit, register, errors, trigger, formState, reset } = useForm();
    const [mems, setMems] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [memberLoading] = useState(false);
    const [tempAllMembers, setTempAllMembers] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        if (open) doGetPartners();
    }, [open])

    useEffect(() => {
        if (!tempAllMembers.length) {
            setTempAllMembers(allMembers);
        }
    }, [allMembers])

    useEffect(() => {
        if (tempAllMembers.length > 0) setAllMembers(tempAllMembers);
    }, [mems])
    /*eslint-enable */

    const isOrgTeam = () => location.pathname.indexOf(ORGANIZATIONS_LIST_URL) > -1;

    const doAddTeam = async (data) => {
        const {
            teamName,
            teamDescription,
            teamOrg
        } = data;
        
        const payload = {
            name: teamName,
            description: teamDescription,
            orgId: teamOrg,
            isOrgTeam: isOrgTeam()
        };
        
        if (mems.length > 0) {
            // payload.members = mems.map(member => {
            //     return {
            //         memberId: member.id,
            //         email: member.id ? member.description : member.title,
            //         teamRoles: [
            //             {
            //                 roleName: data[`teamRole${member.id}`]
            //             }
            //         ]
            //     }
            // });
            payload.members = mems.map(mem => {
                const newMem = {
                    email: mem.id ? mem.description : mem.title,
                }
                if (!mem.isInviteEmail) {
                    newMem['memberId'] = mem.id
                }
                newMem.teamRoles =  [
                        {
                            roleName: data[`teamRole${mem.id}`]
                        }
                    ]
                
                return newMem
            })
        }
        // if (!mems.length) return;

        await doCreateTeam(payload, () => {
            reset();
            setMems([]);
            doGetOrganization(teamOrg);
            setNotificationMessage(`
                <p><strong>${payload.name}</strong> team has been added successfully!</p>
            `);
            doGetOrganization(teamOrg);
        });
        setAddTeam(false);
    }

    const onHideAddTeamModal = () => {
        reset();
        onCancel();
        setOrganizations([]);
        setMems([]);
    }

    const getOrganizations = value => {
        if (isOrgTeam()) return;
        if (formState.submitCount > 0) trigger('teamOrg');
        setSearchLoading(true);

        doGetOrganizations({
            name: value,
            isSearching: true
        }, () => {
            setSearchLoading(false);
        }, false, true);
	};

    const getMembers = value => {
        doGetPartners({name : value.trim()});
        // setMemberLoading(true);

        // doGetMembers({
        //     term: value,
        //     page: 0,
        //     limit: MEMBER_PAGE_SIZE,
        //     isSearching: true,
        //     sort: 'name_asc',
        //     listing: true
        // }, () => {
        //     setMemberLoading(false);
        // }, false);
    };

    const handleAddItem = () => {
        if (tempAllMembers.length > 0) setAllMembers(tempAllMembers);
    }

    return (
        <Modal
            open={open}
            className="tr__add-team"
            onCancel={onHideAddTeamModal}
            title={t("team.addNewTeam")}
            submitButton
            submitButtonLabel={t("organization.add")}
            footerLabel={t("team.titleFooterTeam")}
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(doAddTeam)}>
                    {children}
                </form>
            )}
        >
            <TeamDetails 
                title="Team Details"
                isEditable={true}
                register={register}
                errors={errors}
                onInputChange={getOrganizations}
                searchLoading={searchLoading}
                formState={formState}
            />
            <InviteMembers 
                title={t("team.inviteMembers")} 
                isMember={false}
                register={register}
                members={partners.filter(member => member.type !== TEAM)}
                errors={errors}
                selectedMembers={mems}
                onAddItem={handleAddItem}
                setSelectedMembers={setMems}
                onInputChange={getMembers}
                searchLoading={memberLoading}
                isOrgTeam={isOrgTeam()}
                isNew={true}
                formState={formState}
            />
        </Modal>
    )
};

AddTeam.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func
};