import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import moment from 'moment';
import {
    Tab,
    TabPane,
    Button,
    Table,
    MembersAvatar,
    IssueDetails
} from 'components';
import { HomeContext, IssueContext, ModalContext } from 'contexts';
import { ALL, CLOSED, OPEN, COMMENT_PAGE_SIZE, ISSUES_URL, ISSUE_DETAILS_URL, ISSUE_PAGE_SIZE, ISSUE_URL_ARCHIVE, ISSUE_URL_ARCHIVE_DETAILS, PERMISSIONS_LIST } from 'actions';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Issues = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const { setAddIssue } = useContext(ModalContext);
    const { 
        issues,
        totalPages, 
        currentPage, 
        doGetIssues,
        setCurrentPage,
        setIssue,
        doGetTasksIssue,
        doGetComments,
        setIssues,
    } = useContext(IssueContext);
    const { isArchive, permission } = useContext(HomeContext);
    const [tabs] = useState([{name:ALL,tabName:t('home.active-shipment.details.tabs.issues-tab.all')},{name:OPEN,tabName:t('home.active-shipment.details.tabs.issues-tab.open')},{name:CLOSED,tabName:t('home.active-shipment.details.tabs.issues-tab.closed')}]);
    const [status, setStatus] = useState(ALL);
    const { shipmentId } = useParams();
    /*eslint-disable*/
    useEffect(() => {
        setIssues([]);
        doGetIssues(shipmentId, {
            page : 0,
            status: status,
            limit : ISSUE_PAGE_SIZE
        }, () => {
            setIssue(null);
        })
    },[status])
    /*eslint-enable*/

    const columns = [
		{
			dataIndex: 'unread,status,title,description',
			render: (unread , status, title, description) => (
                <div className={`issue-title d-flex ${status !== CLOSED ? 'active' : ''}`}>
                    <i className={`${status === CLOSED ? 'icon-check' : 'icon-warning'} mrx1`} />
                    <div className="issue-title--info">
                    
                        <h6 className="h6 mbx1">{title} {unread}</h6>
                        <p>{description}</p>
                    </div>
                </div>
            ),
            done: (unread, status, title, description) => unread ? 'done' : false,
        },
        {
			dataIndex: 'partners',
			render: partners => <MembersAvatar members={partners} skip={5} />
        },
        {
			dataIndex: 'base,status,statusUpdatedBy,statusUpdatedDate',
			render: (base, status, statusUpdatedBy, statusUpdatedDate) => (
                <div className="issue-date">
                    {/* <h6 className="h6 mbx1">{status !== CLOSED ? moment(base.createdDate).format('DD MMM YYYY | hh:mm A') :  moment(base.updatedDate).format('DD MMM YYYY | hh:mm A')}</h6> */}
                    <h6 className="h6 mbx1">{statusUpdatedDate ? moment(statusUpdatedDate).format('DD MMM YYYY | hh:mm A') : moment(base.createdDate).format('DD MMM YYYY | hh:mm A')}</h6>
                    <p className={`${status !== CLOSED ? t("home.active-shipment.details.tabs.issues-tab.open") : ''}`}>{status === CLOSED ? t("home.active-shipment.details.tabs.issues-tab.closed"): (statusUpdatedBy && statusUpdatedDate ? t("home.active-shipment.details.tabs.issues-tab.re-opened-on") : t("home.active-shipment.details.tabs.issues-tab.created-on")) }</p>
                </div>
            )
		}
    ];
    
    const showIssueDetails = issue => {
        if (issue) {
            doGetComments(shipmentId, issue._id, {
                page: 0,
                limit: COMMENT_PAGE_SIZE
            }, () => {
                isArchive ? history.push(`${ISSUE_URL_ARCHIVE.replace(':shipmentId', shipmentId)}/${issue._id}`) : history.push(`${ISSUES_URL.replace(':shipmentId', shipmentId)}/${issue._id}`);
            });
		}
    }

    const handleAddIssue = () => {
        setAddIssue(true);
        doGetTasksIssue(shipmentId);
    }

    const loadMoreIssues = () => {
        doGetIssues(shipmentId, {
            page: currentPage + 1,
            status: status,
            limit: ISSUE_PAGE_SIZE
        })
    }

    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }
    return  (
        <Switch>
            <Route path={isArchive ? ISSUE_URL_ARCHIVE_DETAILS : ISSUE_DETAILS_URL} exact>
                <IssueDetails />
            </Route>
            <Route path={ isArchive ? ISSUE_URL_ARCHIVE : ISSUES_URL} exact>
                <div className='tr__shipment-issues'>
                    <div className="tr__shipment-issues--header d-flex align-items-center justify-content-end">
                        {!isArchive && checkPermission(PERMISSIONS_LIST.ISSUE_CREATE) && (
                            <Button
                                onClick={handleAddIssue}
                            >
                                {t("home.active-shipment.details.tabs.issues-tab.create-new-issue")}
                            </Button>
                        )}
                    </div>
                    <Tab
                        initialTab={0}
                        onTabClick={tab => {
                            setStatus(tab.toUpperCase());
                            setCurrentPage(0);
                        }}
                    >
                        {tabs.map((tab, tabIndex) => (
                            <TabPane
                                key={tabIndex}
                                tabName={tab.tabName}
                                tabSlug={tab.name}
                            >
                                <Table
                                    className="border"
                                    dataSource={issues}
                                    columns={columns}
                                    onRowClick={showIssueDetails}
                                />
                                {totalPages > currentPage + 1 && (
                                    <div className="load-more-link mtx2 text-center">
                                        <p className="tr__link" onClick={loadMoreIssues}>
                                        Load more Issues...</p>
                                    </div>
                                )}
                            </TabPane>
                        ))}
                    </Tab>
                </div>
            </Route>
        </Switch>
    );
};