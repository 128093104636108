import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import "./index.scss";
import {DetailsTableHeader, ShipmentItem, TableFooter} from "components";
import {LOG_PAGE_SIZE, ORGANIZATIONS_LIST_URL} from "actions";
import {OrganizationContext} from "contexts";
import { useTranslation } from "react-i18next";

export const Shipments = ({backUrl = ORGANIZATIONS_LIST_URL}) => {
    const [t] = useTranslation();
    const {organization, doGetShipments, totalPageShipments, currentPageShipments, shipments } = useContext(OrganizationContext);

    /*eslint-disable */
    useEffect(() => {
        doGetShipments(organization._id, {
            page: 0,
            limit: LOG_PAGE_SIZE
        }, () => {}, true)
    }, [organization]);
    /*eslint-enable */

    const handlePageChange = (page) => {
        doGetShipments(organization._id, {
            page: page,
            limit: LOG_PAGE_SIZE
        }, () => {}, true)
    }

    return (
        <div className="tr__activities-log">
            <div className="wrapper">
                <DetailsTableHeader
                    title={t("organization.shipments.title")}
                    buttonAdd={false}
                    actionButton={false}
                    backUrl={backUrl}
                />
                <div className="page-box">
                    <ShipmentItem backUrl={backUrl} shipments={shipments}/>
                </div>
            </div>
            {totalPageShipments > 1 && (
                <TableFooter
                    currentPage={currentPageShipments}
                    totalPages={totalPageShipments}
                    setCurrentPage={handlePageChange}
                />
            )}
        </div>
    );
};
Shipments.prototype = {
    backUrl: PropTypes.func,
};
