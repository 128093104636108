import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './index.scss';
import {
    Button,
    ShippingDetails,
    AddressDetails,
    EditBookingDetails,
    EditAISTracking,
    EditInvoice,
    EditCutOff,
    EditContactDetails,
    EditContainers,
    EditTranshipmentInfo,
    EditSectionInfo
} from 'components';
import { ShipmentInfoContext, HomeContext, ModalContext, DocumentsContext, ActiveShipmentsContext } from 'contexts';
import { trimArrayItems } from 'utils';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { AIR } from 'actions';


export const EditShipmentInfo = ({ goBack, shipmentInfo }) => {
    const [t] = useTranslation();
    const { setFileId, isArchive } = useContext(HomeContext);
    const { setViewExtractedInfo } = useContext(ModalContext);
    const { doUpdateShipmentInfo } = useContext(ShipmentInfoContext);
    const { setDocumentPermission } = useContext(DocumentsContext);
    const { shipmentDetails, airLines } = useContext(ActiveShipmentsContext);
    const { handleSubmit, register, errors, getValues, watch, setError, clearErrors } = useForm();
    const [aisTracking, setAisTracking] = useState([0]);
    const {shipmentId} = useParams();
    const [containersTracking, setContainersTracking] = useState([0]);
    const [transhipments, setTranshipments] = useState([]);
    const [prePolLocation, setPrePolLocation] = useState();
    const [postPodLocation, setPostPodLocation] = useState();
    const [prePolAir, setPrePolAir] = useState();
    const [postPodAir, setPostPodAir] = useState();
    const [departureLocation, setDepartureLocation] = useState({});
    const [arrivalLocation, setArrivalLocation] = useState({});

    /*eslint-disable */
    useEffect(() => {
        if (shipmentInfo) {
            if(shipmentInfo.containerTracking &&  shipmentInfo.containerTracking.containers && shipmentInfo.containerTracking.containers.length > 0){
                setContainersTracking([...Array(shipmentInfo.containerTracking.containers.length).keys()]);
            }
            if(shipmentInfo.transhipmentInfo  && shipmentInfo.transhipmentInfo.length > 0){
                setTranshipments([...Array(shipmentInfo.transhipmentInfo.length).keys()]);
            }
            if(shipmentInfo.aisTracking  && shipmentInfo.aisTracking.length > 0){
                setAisTracking([...Array(shipmentInfo.aisTracking.length).keys()]);
            }
        }
    }, [shipmentInfo])
    /*eslint-enable */
    
    const markAsActiveShipment = (data) => {
        if(!shipmentInfo) return;
        const params = {};
        const { 
            bookingConfirmation,
            masterBillOfLading,
            houseBill,
            invoiceNumber,
            shipperAddress,
            consigneeDeliveryAdress,
            carrierName,
            scacCode,
            prePol,
            postPod,
            bookingDate,
            shipper,
            eta,
            etd,
            measurement,
            containerYard,
            commodityDescription,
            grossWeightUnit,
            grossWeightValue,
            serviceType,
            hsCode,
            numberOfContainers,
            contactShipperAdd,
            consignee,
            consigneeAddress,
            notifyParty,
            notifyPartyAddress,
            serviceContractNumber,
            shipperContact,
            shipperFax,
            consigneeContact,
            consigneeFax,
            notifyContact,
            notifyFax,
            vgmSubmissionTimeline,
            siSubmission,
            cutOffDate,
            cutOffTime,
            sections,
            houseAirBill,
            masterAirwayBill0,
            airline,
            vesselName0,
            destAddress,
            originAdress
        } = data;
        let prepol = null;
        const selectedAilineName = airLines.find(airLine => airLine.code === airline);
        if (shipmentInfo.addresses && prePolLocation) {
            prepol = {
                location: prePolLocation,
                date: shipmentInfo.addresses.prepol && shipmentInfo.addresses.prepol.date,
                actual: shipmentInfo.addresses.prepol ? shipmentInfo.addresses.prepol.actual : false
            }
        }

        if(shipmentInfo?.addresses && shipmentInfo?.addresses?.prepol && prePol === shipmentInfo?.addresses?.prepol?.location?.locode){
            prepol = {
              ...shipmentInfo.addresses.prepol
            }
            delete prepol?._id;
            delete prepol?.location?._id;
        }

        let postpod = null;
        if (shipmentInfo.addresses && postPodLocation) {
            postpod = {
                location: postPodLocation,
                date: shipmentInfo.addresses.postpod && shipmentInfo.addresses.postpod.date ,
                actual: shipmentInfo.addresses.postpod ? shipmentInfo.addresses.postpod.actual : false
            }
        }

        if(shipmentInfo?.addresses && shipmentInfo?.addresses?.postpod && postPod === shipmentInfo?.addresses?.postpod?.location?.locode){
            postpod = {
              ...shipmentInfo.addresses.postpod
            }
            delete postpod?._id;
            delete postpod?.location?._id;
        }

        const confirmationNo = bookingConfirmation?.trim() ? trimArrayItems(bookingConfirmation?.split(',')) : null;
        const masterBoL = masterBillOfLading?.trim() ? trimArrayItems(masterBillOfLading?.split(',')) : null;
        const houseBoL = houseBill?.trim() ? trimArrayItems(houseBill?.split(',')) : null;
        const invoices = invoiceNumber?.trim() ? trimArrayItems(invoiceNumber?.split(',')) : null;
        const houseAirwayBill = houseAirBill?.trim() ? trimArrayItems(houseAirBill?.split(',')) : null
        const aisTrackings = aisTracking.map(v => {
            const name = data[`vesselName${v}`];
            const imo = data[`vesselImo${v}`];
            const voyage = data[`vesselVoyage${v}`];
            let departure = departureLocation[`vesselDeparture${v}`];
            let arrival = arrivalLocation[`vesselArrival${v}`];
            if(!departure && shipmentInfo.aisTracking && shipmentInfo.aisTracking[v] && shipmentInfo.aisTracking[v].departure){
                departure = shipmentInfo.aisTracking[v].departure;
                delete departure._id;
            }
            if(!arrival && shipmentInfo.aisTracking && shipmentInfo.aisTracking[v] && shipmentInfo.aisTracking[v].arrival){
                arrival = shipmentInfo.aisTracking[v].arrival;
                delete arrival._id;
            }
            const newParams = {};

            if (name) newParams.name = name;
            if (imo) newParams.imo = imo;
            if (voyage) newParams.voyage = voyage;
            if (departure) newParams.departure = departure;
            if (arrival) newParams.arrival = arrival;
            return Object.keys(newParams).length === 5 ? newParams : null;
        }).filter(v => v);

        if (aisTrackings && aisTrackings.length > 0) params.aisTracking = aisTrackings;

        if (shipmentInfo.shippingDetails.shipmentMode === AIR) {
            params.airAddresses = {
                pol: shipmentInfo.addresses.pol,
                pod: shipmentInfo.addresses.pol,
                prepol: prePolAir,
                postpod: postPodAir
            }

            if (shipmentInfo?.addresses && shipmentInfo?.addresses?.postpod && destAddress === shipmentInfo?.addresses?.postpod?.city){
                params.airAddresses.postpod = {
                    ...shipmentInfo.addresses.postpod
                } 
            }

            if (shipmentInfo?.addresses && shipmentInfo?.addresses?.prepol && originAdress === shipmentInfo?.addresses?.prepol?.city){
                params.airAddresses.prepol = {
                    ...shipmentInfo.addresses.prepol
                } 
            }

        }

        // let postpod = null;
        // if (shipmentInfo.addresses && postPodLocation) {
        //     postpod = {
        //         location: postPodLocation,
        //         date: shipmentInfo.addresses.postpod && shipmentInfo.addresses.postpod.date ,
        //         actual: shipmentInfo.addresses.postpod ? shipmentInfo.addresses.postpod.actual : false
        //     }
        // }

        // if(shipmentInfo?.addresses && shipmentInfo?.addresses?.postpod && postPod === shipmentInfo?.addresses?.postpod?.location?.locode){
        //     postpod = {
        //       ...shipmentInfo.addresses.postpod
        //     }
        //     delete postpod?._id;
        //     delete postpod?.location?._id;
        // }

        const selectedAirVessels = {
            originAirport: shipmentInfo?.addresses?.pol,
            destinationAirport: shipmentInfo?.addresses?.pod,
            airline: selectedAilineName,
        }

        if (vesselName0) {
            selectedAirVessels.name = vesselName0;
        }

        if (masterAirwayBill0) {
            selectedAirVessels.masterAirwayBill = masterAirwayBill0
        }

        if (houseAirwayBill && houseAirwayBill.length > 0) {
            selectedAirVessels.houseAirwayBill = houseAirwayBill
        }

        if (shipmentInfo.shippingDetails.shipmentMode === AIR) {
            params.airVessels = [selectedAirVessels];
        }
        
        if (shipmentInfo.shippingDetails.shipmentMode === AIR) {
            if (shipperAddress) params.airAddresses = {
                ...params.airAddresses,
                shipperPickupAdd: shipperAddress
            }
            if (consigneeDeliveryAdress) params.airAddresses = {
                ...params.airAddresses,
                consigneeDeliveryAdd: consigneeDeliveryAdress
            }

        } else {
            if (shipperAddress) params.addresses = {
                ...params.addresses,
                shipperPickupAdd: shipperAddress
            }
    
            if (consigneeDeliveryAdress) params.addresses = {
                ...params.addresses,
                consigneeDeliveryAdd: consigneeDeliveryAdress
            }
    
    
            if (prepol) params.addresses = {
                ...params.addresses,
                prepol
            };
    
            if (postpod) params.addresses =  {
                ...params.addresses,
                postpod
            };;
        }
       

        if (carrierName) {
            params.bookingDetails = {
                ...params.bookingDetails,
                carrierName
            };
        }

        if (scacCode) {
            params.bookingDetails = {
                ...params.bookingDetails,
                scacCode
            };
        }

        if (confirmationNo) params.bookingDetails = {
            ...params.bookingDetails,
            confirmationNo
        };

        if (masterBoL && masterBoL.length > 0) params.bookingDetails = {
            ...params.bookingDetails,
            masterBoL
        };

        if (houseBoL && houseBoL.length > 0) params.bookingDetails = {
            ...params.bookingDetails,
            houseBoL
        };

        if (masterBoL && masterBoL.length > 0) params.bookingDetails = {
            ...params.bookingDetails,
            masterBoL
        };

        if (invoices && invoices.length > 0) params.invoices = invoices;

        if (bookingDate)  params.shippingDetails = {
            ...params.shippingDetails,
            bookingDate: new Date(bookingDate).getTime()
        };

        if (shipper)  params.shippingDetails = {
            ...params.shippingDetails,
                shipper
        };

        if (eta)  params.shippingDetails = {
            ...params.shippingDetails,
            eta: new Date(eta).getTime().toString()
        };

        if (etd)  params.shippingDetails = {
            ...params.shippingDetails,
            etd: new Date(etd).getTime().toString()
        };

        const containers = containersTracking && containersTracking.length > 0 && containersTracking.map(v => {
            const containerNumber = data[`containerNumber${v}`];
            const carrierSealNumber = data[`carrierSealNumber${v}`];
            const containerType = data[`containerType${v}`];
            const weightUnit = data[`weightUnit${v}`];
            const weightValue = data[`weightValue${v}`];
            const volumeUnit = data[`volumeUnit${v}`];
            const volumeValue = data[`volumeValue${v}`];
            const newParams = {};

            if (containerNumber) newParams.containerNumber = containerNumber;
            if (carrierSealNumber) newParams.carrierSealNumber = carrierSealNumber;
            if (containerType) newParams.containerType = containerType;
            if (weightValue && weightUnit) newParams.weight = {
                weightUnit: weightUnit,
                value: weightValue
            };
            if (volumeValue && volumeUnit) newParams.volume = {
                unit: volumeUnit,
                value: volumeValue
            };
            return Object.keys(newParams).length > 0 ? newParams : null;
        }).filter(v => v);

        params.containerTracking = {};

        if (measurement)  params.containerTracking = {
            ...params.containerTracking,
            measurement
        };

        if (containerYard)  params.containerTracking = {
            ...params.containerTracking,
            containerYard
        };
        
        if (commodityDescription)  params.containerTracking = {
            ...params.containerTracking,
            commodityDescription
        };

        if (serviceType)  params.containerTracking = {
            ...params.containerTracking,
            serviceType
        };

        if (hsCode)  params.containerTracking = {
            ...params.containerTracking,
            hsCode
        };

        if (numberOfContainers)  params.containerTracking = {
            ...params.containerTracking,
            numberOfContainers
        };

        if (grossWeightUnit && grossWeightValue)  params.containerTracking.grossWeight = {
            ...params.containerTracking.grossWeight,
            weightUnit: grossWeightUnit,
            value: grossWeightValue 
        };

        if (containers && containers.length > 0) params.containerTracking.containers = containers;

        params.contactDetails = {};

        if (contactShipperAdd)  params.contactDetails = {
            ...params.contactDetails,
            shipperAddress: contactShipperAdd
        };

        if (consignee)  params.contactDetails = {
            ...params.contactDetails,
            consignee
        };

        if (consigneeAddress)  params.contactDetails = {
            ...params.contactDetails,
            consigneeAddress
        };

        if (notifyParty)  params.contactDetails = {
            ...params.contactDetails,
            notifyParty
        };

        if (notifyPartyAddress)  params.contactDetails = {
            ...params.contactDetails,
            notifyPartyAddress
        };

        if (serviceContractNumber)  params.contactDetails = {
            ...params.contactDetails,
            serviceContractNumber
        };

        if (shipperContact)  params.contactDetails = {
            ...params.contactDetails, 
            shipperContact 
        };

        if (shipperFax)  params.contactDetails = {
            ...params.contactDetails, 
            shipperFax 
        };
        
        if (consigneeContact)  params.contactDetails = {
            ...params.contactDetails, 
            consigneeContact 
        };

        if (consigneeFax)  params.contactDetails = {
            ...params.contactDetails, 
            consigneeFax 
        };

        if (notifyContact)  params.contactDetails = {
            ...params.contactDetails, 
            notifyContact 
        };

        if (notifyFax)  params.contactDetails = {
            ...params.contactDetails, 
            notifyFax 
        };

        if (siSubmission)  params.cutOff = {
            ...params.cutOff, 
            siSubmission 
        };

        if (vgmSubmissionTimeline)  params.cutOff = {
            ...params.cutOff, 
            vgmSubmissionTimeline 
        };

        if (cutOffDate)  params.cutOff = {
            ...params.cutOff, 
            date: new Date(cutOffDate).getTime().toString()
        };

        if (cutOffTime)  params.cutOff = {
            ...params.cutOff, 
            time: cutOffTime 
        };
        
        const transhipmentInfo = transhipments.map(v => {
            const tsPort = data[`tsPort${v}`];
            const tsNextPort = data[`tsNextPort${v}`];
            const tsVessel = data[`tsVessel${v}`];
            const tsVesselIMO = data[`tsVesselIMO${v}`];
            const tsETD = data[`tsETD${v}`];
            const tsETA = data[`tsETA${v}`];
            const newParams = {};

            if (tsPort) newParams.tsPort = tsPort;
            if (tsNextPort) newParams.tsNextPort = tsNextPort;
            if (tsVessel) newParams.tsVessel = tsVessel;
            if (tsVesselIMO) newParams.tsVesselIMO = tsVesselIMO;
            if (tsETD) newParams.tsETD = tsETD;
            if (tsETA) newParams.tsETA = tsETA;
            return Object.keys(newParams).length > 0 ? newParams : null;
        }).filter(v => v);

        if(transhipmentInfo && transhipmentInfo.length > 0) {
            params.transhipmentInfos = transhipmentInfo;
        }
        if ((shipmentInfo.document && shipmentInfo.document._id) || _.isEmpty(shipmentInfo.document)) {
            delete params.document;
        } else {
            params.document = shipmentInfo.document;
        }
        if(sections){
            params.addedInfoSections = sections;
        }
        doUpdateShipmentInfo(shipmentId,params);
        goBack();
    }
    
    const handleViewExtracted = () => {
        if (shipmentInfo && !_.isEmpty(shipmentInfo.document)) {
            setFileId(shipmentInfo.document.awsId);
            setViewExtractedInfo(true);
            setDocumentPermission(true);
        }
    }

    return (
        <div
            className="tr__edit-shipment-info"
        >
            <div className="tr__edit-shipment-info--title d-flex align-items-center col-12">
                <p className="tr__link tag mrx2" onClick={goBack}>
                    <i className="icon-chevron-left" />
                    <span>{t("home.active-shipment.details.tabs.shipment-tab.back")}</span>
                </p>
                <h2 className="h2 f-medium">{t("home.active-shipment.details.tabs.shipment-tab.edit-shipment-information")}</h2>
            </div>
            <form onSubmit={handleSubmit(markAsActiveShipment)}>
                <div className="tr__edit-shipment-info--content">
                    <div className="tr__verify-details-form--note d-flex flex-column align-items-center justify-content-center">
                        <Button
                            className="outline f-medium mtx2"
                            type="secondary"
                            onClick={handleViewExtracted}
                            // disabled={isArchive}
                        >
                            {t("home.active-shipment.view-all-extracted")}
                        </Button>
                    </div>
                    <div className="row">
                        {shipmentInfo?.shippingDetails?.shipmentMode !== AIR && <EditBookingDetails
                            bookingDetails={shipmentInfo.bookingDetails}
                            shippingDetails={shipmentInfo.shippingDetails}
                            register={register}
                            watch={watch}
                            getValues={getValues}
                            errors={errors}
                            editShipmentInfo
                            isArchive={isArchive}
                        /> }
                       
                        <ShippingDetails 
                            shippingDetails={shipmentInfo.shippingDetails}
                            register={register} 
                            watch={watch}
                            editShipmentInfo
                            isArchive={isArchive}/>
                        <EditCutOff 
                            cutOff={shipmentInfo.cutOff}
                            register={register} watch={watch}
                            isArchive={isArchive}
                            editShipmentInfo
                            isEditShipmentInfo={true}
                            shippingDetails={shipmentInfo.shippingDetails}
                        />
                        <EditInvoice 
                            shipmentDetails={shipmentInfo}
                            isArchive={isArchive}
                            register={register}
                            watch={watch} 
                            editShipmentInfo 
                            isEditShipmentInfo={true}
                            shippingDetails={shipmentInfo.shippingDetails}
                             />
                    </div> 
                    <div className="row">
                        <div className="col-8">
                            <EditAISTracking 
                                register={register}
                                vessels={aisTracking}
                                setVessels={setAisTracking}
                                errors={errors}
                                getValues={getValues}
                                trackings={shipmentInfo?.aisTracking}
                                airTracking={shipmentDetails?.aisTracking}
                                watch={watch}
                                editShipmentInfo
                                setError={setError}
                                clearErrors={clearErrors}
                                setDepartureLocation={setDepartureLocation}
                                departureLocation={departureLocation}
                                setArrivalLocation={setArrivalLocation}
                                arrivalLocation={arrivalLocation}
                                isArchive={isArchive}
                                setTranshipments={setTranshipments}
                                shippingDetails={shipmentInfo.shippingDetails}
                            />
                             <EditTranshipmentInfo 
                                register={register} 
                                transhipmentInfo={shipmentInfo.transhipmentInfo} 
                                watch={watch}
                                editShipmentInfo 
                                isArchive={isArchive}
                                transhipments={transhipments} 
                                setTranshipments={setTranshipments}
                            />
                            <EditContainers
                                containerTracking={shipmentInfo.containerTracking}
                                containers={containersTracking}
                                setContainers={setContainersTracking}
                                register={register}
                                watch={watch}
                                errors={errors}
                                editShipmentInfo
                                isArchive={isArchive}
                            />
                             <EditContactDetails  
                                register={register}
                                errors={errors}
                                contactDetails={shipmentInfo.contactDetail}
                                watch={watch}
                                isArchive={isArchive}
                                editShipmentInfo
                                isEditShipmentInfo={true}
                            />
                        </div>
                        <div className="col-4">
                            <AddressDetails 
                                routes={shipmentInfo.routes || shipmentInfo.addresses} 
                                errors={errors}
                                getValues={getValues}
                                register={register} 
                                editShipmentInfo
                                setPrePolLocation={setPrePolLocation}
                                setPostPodLocation={setPostPodLocation}
                                setPrePolAir={setPrePolAir}
                                setPostPodAir={setPostPodAir}
                                isArchive={isArchive}
                                shippingDetails={shipmentInfo.shippingDetails}
                            />
                        </div>
                    </div>
                    <EditSectionInfo register={register} sections={shipmentInfo.addedInfoSections} errors={errors} />
                </div>
                <div className="tr__edit-shipment-info--footer d-flex align-items-center justify-content-between">
                <p className="f-medium">{t("home.active-shipment.EditShipmentInfo.description-footer")}</p>
                <div className="d-flex align-items-center">
                    <p
                        className="tr__link secondary mrx2"
                        onClick={goBack}
                    >{t("home.active-shipment.EditShipmentInfo.Cancel")}</p>
                    <Button
                         isSubmitBtn={true}
                         disabled={isArchive}
                    >
                        {t("home.active-shipment.EditShipmentInfo.Save-Changes")}
                    </Button>
                </div>
            </div>
            </form>
        </div>
    )
};

EditShipmentInfo.propTypes = {
    goBack: PropTypes.func
};
