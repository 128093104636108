import React from 'react';
import PropTypes from 'prop-types';

import {
} from 'components';
import { EditShipmentInfoInput } from 'components';
import { useTranslation } from 'react-i18next';

export const EditContactDetails = ({
    register = () => {},
    contactDetails,
    watch = () => {},
    editShipmentInfo,
    isArchive = false,
    isEditShipmentInfo = false
}) => {
    const [t] = useTranslation();
    return (
        <div className="tr__verify-details-form--group mbx4">
            <div className="tr__verify-details-form--group--content">
                <div className="tr__verify-details-form--group--content-frame">
                    <h3 className="h3 f-medium tr__info-block--label mtx2 mlx2 d-flex align-items-center"> 
                        {t("home.active-shipment.details.tabs.shipment-tab.contact-details")}
                    </h3>
                    <div className="tr__verify-details-form--group__input">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="contactShipperAdd"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.shipper-address")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={contactDetails && contactDetails.shipperAddress ? contactDetails.shipperAddress : ''}
                                    originalvalue={contactDetails && contactDetails.shipperAddress ? contactDetails.shipperAddress : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={watch('contactShipperAdd',contactDetails && contactDetails.shipperAddress ? contactDetails.shipperAddress : '')}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="consignee"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.consignee")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={contactDetails && contactDetails.consignee ? contactDetails.consignee : ''}
                                    originalvalue={contactDetails && contactDetails.consignee ? contactDetails.consignee : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={watch('consignee',contactDetails && contactDetails.consignee ? contactDetails.consignee : '')}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="consigneeAddress"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.consignee-address")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={contactDetails && contactDetails.consigneeAddress ? contactDetails.consigneeAddress : ''}
                                    originalvalue={contactDetails && contactDetails.consigneeAddress ? contactDetails.consigneeAddress : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={watch('consigneeAddress',contactDetails && contactDetails.consigneeAddress ? contactDetails.consigneeAddress : '')}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="notifyParty"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.notify-party")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={contactDetails && contactDetails.notifyParty ? contactDetails.notifyParty : ''}
                                    originalvalue={contactDetails && contactDetails.notifyParty ? contactDetails.notifyParty : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={watch('notifyParty',contactDetails && contactDetails.notifyParty ? contactDetails.notifyParty : '')}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="notifyPartyAddress"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.notify-party-address")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={contactDetails && contactDetails.notifyPartyAddress ? contactDetails.notifyPartyAddress : ''}
                                    originalvalue={contactDetails && contactDetails.notifyPartyAddress ? contactDetails.notifyPartyAddress : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={watch('notifyPartyAddress',contactDetails && contactDetails.notifyPartyAddress ? contactDetails.notifyPartyAddress : '')}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="serviceContractNumber"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.service-contract-number")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={contactDetails && contactDetails.serviceContractNumber ? contactDetails.serviceContractNumber : ''}
                                    originalvalue={contactDetails && contactDetails.serviceContractNumber ? contactDetails.serviceContractNumber : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={watch('serviceContractNumber',contactDetails && contactDetails.serviceContractNumber ? contactDetails.serviceContractNumber : '')}
                                />
                            </div> 
                            <div className="mtx4">
                            {t("home.active-shipment.details.tabs.shipment-tab.contact-information")}
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="shipperContact"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.shipper-contact")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={
                                        contactDetails && contactDetails.shipperContact 
                                        ? contactDetails.shipperContact 
                                        : ''
                                    }
                                    originalvalue={
                                        contactDetails && contactDetails.shipperContact 
                                        ? contactDetails.shipperContact 
                                        : ''
                                    }
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={
                                        watch('shipperContact',
                                            contactDetails && contactDetails.shipperContact 
                                            ? contactDetails.shipperContact 
                                            : ''
                                        )
                                    }
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="shipperFax"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.shipper-fax")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={
                                        contactDetails && contactDetails.shipperFax 
                                        ? contactDetails.shipperFax 
                                        : ''
                                    }
                                    originalvalue={
                                        contactDetails && contactDetails.shipperFax 
                                        ? contactDetails.shipperFax 
                                        : ''
                                    }
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={
                                        watch('shipperFax',
                                            contactDetails && contactDetails.shipperFax 
                                            ? contactDetails.shipperFax 
                                            : ''
                                        )
                                    }
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="consigneeContact"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.consignee-contact")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={
                                        contactDetails && contactDetails.consigneeContact 
                                        ? contactDetails.consigneeContact 
                                        : ''
                                    }
                                    originalvalue={
                                        contactDetails && contactDetails.consigneeContact 
                                        ? contactDetails.consigneeContact 
                                        : ''
                                    }
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={
                                        watch('consigneeContact',
                                            contactDetails && contactDetails.consigneeContact 
                                            ? contactDetails.consigneeContact 
                                            : ''
                                        )
                                    }
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="consigneeFax"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.consignee-fax")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={
                                        contactDetails && contactDetails.consigneeFax 
                                        ? contactDetails.consigneeFax 
                                        : ''
                                    }
                                    originalvalue={
                                        contactDetails && contactDetails.consigneeFax 
                                        ? contactDetails.consigneeFax 
                                        : ''
                                    }
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={
                                        watch('consigneeFax',
                                            contactDetails && contactDetails.consigneeFax 
                                            ? contactDetails.consigneeFax 
                                            : ''
                                        )
                                    }
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="notifyContact"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.notify-contact")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={
                                        contactDetails && contactDetails.notifyContact 
                                        ? contactDetails.notifyContact 
                                        : ''
                                    }
                                    originalvalue={
                                        contactDetails && contactDetails.notifyContact 
                                        ? contactDetails.notifyContact 
                                        : ''
                                    }
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={
                                        watch('notifyContact',
                                            contactDetails && contactDetails.notifyContact 
                                            ? contactDetails.notifyContact 
                                            : ''
                                        )
                                    }
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="notifyFax"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.notify-fax")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    viewOnly={isArchive}
                                    defaultValue={
                                        contactDetails && contactDetails.notifyFax 
                                        ? contactDetails.notifyFax 
                                        : ''
                                    }
                                    originalvalue={
                                        contactDetails && contactDetails.notifyFax 
                                        ? contactDetails.notifyFax 
                                        : ''
                                    }
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={
                                        watch('notifyFax',
                                            contactDetails && contactDetails.notifyFax 
                                            ? contactDetails.notifyFax 
                                            : ''
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

EditContactDetails.propTypes = {
    register: PropTypes.func,
    contactDetails: PropTypes.any,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool,
};
