import React, { createContext, useContext, useEffect, useState } from 'react';

import {
    getTeams,
    getTeam,
    getOrgTeams,
    createTeam,
    updateTeam,
    removeTeam,
    OPERATION_FAILED_MESSAGE,
    TEAM_PAGE_SIZE,
    exportCSVTeam,
    // getUploadedSignedFileUrl
} from 'actions';
import { GET_FILE } from 'actions/constants';
import { HomeContext, AuthContext } from 'contexts';
import { OrganizationContext } from './OrganizationContext';

export const TeamContext = createContext();

export const TeamContextProvider = ({ children }) => {
    const { setLoading, setNotificationMessage, setSearchMessage, setNotificationType } = useContext(HomeContext);
    const { loggedInUser, userInfo, setUserOrg, doGetUserInfo } = useContext(AuthContext);
    const { setUserOrgRole } = useContext(OrganizationContext);
    const [teams, setTeams] = useState([]);
    const [team, setTeam] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalTeams, setTotalTeams] = useState(0);
    const [userTeamRole, setUserTeamRole] = useState('');
    // const [logsTeam, setLogsTeam] = useState([]);
    // const [currentPageLog, setCurrentPageLog] = useState(0);
    // const [totalPagesLog, setTotalPagesLog] = useState(1);

    /*eslint-disable */
    useEffect(() => {
        if (userInfo && team && team.organization) {
            const memOrg = userInfo.orgTeams ? userInfo.orgTeams.find(org => org.orgId === team.organization._id) : null;
            const memTeam = memOrg ? memOrg.teams.find(t => t.teamId === team._id) : null;

            setUserOrg(memOrg ? memOrg : null);

            setUserOrgRole(memOrg ? memOrg.roleName : '');
            setUserTeamRole(memTeam ? memTeam.roleName : '')
        } else setUserTeamRole('');
    }, [userInfo, team])

    // Clear all state after logout
    // useEffect(() => {
    //     if (!loggedInUser) {
    //         setTeams([]);
    //         setCurrentPage(0);
    //         setTotalPages(1);
    //         setTotalTeams(0);
    //         setTeam(null);
    //     }
    // }, [loggedInUser])
    /*eslint-enable */

    const handleException = error => {
        const { data } = (error.response || {});
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetTeams = async (params, callback, errorCallBack, hasLoading = true) => {
        setTeam(null);

        if (teams.length === 0 || currentPage !== params.page || params.isSearching) {
            try {
                if (hasLoading) setLoading(true);
                delete params.isSearching;

                const response = await getTeams(params, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    for(let team of data.items) if (team.organization && team.organization.logo) {
                        // let signedLogo = await getUploadedSignedFileUrl(team.organization.logo, loggedInUser);
                        // team.organization.logo = signedLogo.data.ret.downloadSignedURI.itemURI;

                        team.organization.logo = GET_FILE + team.organization.logo;
                    }
                    setTeams(data.items || []);
                    setTotalPages(data.totalPage);
                    setCurrentPage(data.currentPage);
                    if (callback) callback();
                    if (!data.items.length) {
                        if (errorCallBack) errorCallBack();
                    }
                    if (params.name) setSearchMessage('No search result');
                    else {
                        setTotalTeams(data.totalItems);
                        setSearchMessage('');
                    }
                }

                if (hasLoading) setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetOrgTeams = async (params, callback, hasLoading = true) => {
        setTeam(null);

        if (teams.length === 0 || currentPage !== params.page || params.isSearching) {
            try {
                if (hasLoading) setLoading(true);
                delete params.isSearching;

                const response = await getOrgTeams(params, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    setTeams(data.items || []);
                    setTotalPages(data.totalPage);
                    setCurrentPage(data.currentPage);
                    if (callback) callback();
                    if (params.name) setSearchMessage('No search result');
                    else {
                        setTotalTeams(data.totalItems);
                        setSearchMessage('');
                    }
                }

                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doCreateTeam = async (payload, callback) => {
        try {
            setLoading(true);
            const { isOrgTeam } = payload;
            delete payload.isOrgTeam;

            const response = await createTeam(payload, loggedInUser);
            const { status } = response;

            if (status === 200) {
                const params = {
                    page: currentPage,
                    limit: TEAM_PAGE_SIZE,
                    isSearching: true
                };

                if (isOrgTeam) {
                    doGetOrgTeams({
                        ...params,
                        orgId: payload.orgId
                    });
                } else {
                    doGetTeams(params);
                }
                doGetUserInfo();

                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doGetTeam = async (teamId, callback, isSearching = false) => {
        if (teamId) {
            // if (teams && teams.length > 0 && !isSearching) {
            //     setTeam(teams.find(o => o._id === teamId));
            // } else {
                try {
                    setLoading(true);

                    const response = await getTeam(teamId, loggedInUser);
                    const { data, status } = response;

                    if (status === 200) {
                        setTeam(data);

                        if (callback) callback();
                    }

                    setLoading(false);
                } catch (error) {
                    handleException(error);
                }
            // }
        }
    }

    const doUpdateTeam = async (payload, callback) => {
        try {
            setLoading(true);

            const response = await updateTeam(payload, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setTeam(oldTeam => {
                    return {
                        ...oldTeam,
                        ...data
                    }
                });
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doDeleteTeam = async (teamId, callback) => {
        if (teamId) {
            try {
                setLoading(true);

                const response = await removeTeam(teamId, loggedInUser);
                const { status } = response;

                if (status === 200) {
                    const params = {
                        page: currentPage,
                        limit: TEAM_PAGE_SIZE,
                        isSearching: true
                    };
                    doGetTeams(params);
                if (callback) callback();
            }

                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doExportCSV = async (teamId, callback) => {
        if (teamId) {
            try {
               setLoading(true);
               
               const response = await exportCSVTeam(teamId);
               const { data, status } = response;

               if (status === 200) {
                   if (callback) callback(data);
               }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    return(
        <TeamContext.Provider
            value={{
                currentPage,
                teams,
                team,
                totalPages,
                totalTeams,
                userTeamRole,
                setCurrentPage,
                setTeam,
                setTeams,
                doGetTeams,
                doGetOrgTeams,
                doCreateTeam,
                doGetTeam,
                doUpdateTeam,
                doDeleteTeam,
                doExportCSV
            }}
        >
            { children }
        </TeamContext.Provider>
    );
};
