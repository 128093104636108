import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Button,
    Statuses,
    Collapse,
    CardDocumentItem,
    CardEventItem
} from 'components';
import { AuthContext, HomeContext, ModalContext } from 'contexts';
import { SHIPMENT_STATUS } from 'actions';
import { PERMISSIONS_LIST } from 'actions/constants';
import { useTranslation } from 'react-i18next';

export const ShipmentTaskItem = ({ tasks, currentTab }) => {
    const { t } = useTranslation();
    const { userInfo } = useContext(AuthContext);
    const {
        setAddTask,
        setEditSection,
        setIsEditSection,
    } = useContext(ModalContext);
    const {
        isArchive,
        setSectionId,
        setSectionName,
        setSelectTask,
        setCurrentTabManagement,
        setIsTemplate,
        permission,
        setPermissionEditTask,
    } = useContext(HomeContext);

    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }

    const getStatuses = selectedTask => {
        const statuses = [
            {
                id: SHIPMENT_STATUS.INPROGRESS.toLowerCase(),
                num: 0
            },
            {
                id: SHIPMENT_STATUS.PENDING.toLowerCase(),
                num: 0
            },
            {
                id: SHIPMENT_STATUS.STUCK.toLowerCase(),
                num: 0
            },
            {
                id: SHIPMENT_STATUS.DONE.toLowerCase(),
                num: 0
            }
        ];

        selectedTask.tasks.documents.forEach(d => {
            switch (d.status.taskStatus) {
                case SHIPMENT_STATUS.INPROGRESS:
                    statuses[0].num += 1;
                    break;
                case SHIPMENT_STATUS.PENDING:
                    statuses[1].num += 1;
                    break;
                case SHIPMENT_STATUS.STUCK:
                    statuses[2].num += 1;
                    break;
                case SHIPMENT_STATUS.DONE:
                    statuses[3].num += 1;
                    break;
                default:
            }
        })

        selectedTask.tasks.events.forEach(d => {
            switch (d.status.taskStatus) {
                case SHIPMENT_STATUS.INPROGRESS:
                    statuses[0].num += 1;
                    break;
                case SHIPMENT_STATUS.PENDING:
                    statuses[1].num += 1;
                    break;
                case SHIPMENT_STATUS.STUCK:
                    statuses[2].num += 1;
                    break;
                case SHIPMENT_STATUS.DONE:
                    statuses[3].num += 1;
                    break;
                default:
            }
        });
        return statuses;
    }

    return (tasks.length > 0 && userInfo && userInfo._id) ? tasks.map((section, sectionIndex) => {
        return (
            <div key={sectionIndex}>
                <Collapse
                    name={`${section.name}-${sectionIndex}`}
                    className="tr__shipment-task-item mtx3"
                    title={section.name}
                    initialOpen={true}
                    renderHeader={() => (
                        <>
                            <span>{section.name}</span>
                            <div className="toggle-btn d-flex align-items-center">
                                {
                                    section.tasks.events.concat(section.tasks.documents).length > 0 && (
                                        <Statuses
                                            className="bar mlx2"
                                            statuses={getStatuses(section)}
                                            showLabel={true}
                                            unit="number"
                                        />
                                    )
                                }
                                <i className="icon-chevron-down" />
                            </div>
                        </>
                    )}
                >
                    <div className='tr__shipment-task-item--table'>
                        <div className="tr__shipment-task-item--body">
                            {section.tasks.events && section.tasks.events.length > 0 && (
                                <>
                                    <div className='table--header'>
                                        <h4 className="h4 f-medium">{t("home.active-shipment.shipmenttaskitem.Events")}</h4>
                                    </div>
                                    <CardEventItem task={section.tasks.events} currentTab={currentTab} section={section}/>
                                </>
                            )}

                            {section.tasks.documents && section.tasks.documents.length > 0 && (
                                <>
                                    <div className='table--header'>
                                        <h4 className="h4 f-medium">{t("home.active-shipment.shipmenttaskitem.Documents")}</h4>
                                    </div>
                                    <CardDocumentItem task={section.tasks.documents} currentTab={currentTab} section={section}/>
                                </>
                            )}
                        </div>
                    </div>
                   
                        <div className="tr__shipment-task-item-btns d-flex align-items-center mtx2">
                                     {!isArchive && checkPermission(PERMISSIONS_LIST.TASK_CREATE) && (
                                        <Button
                                        className="outline icon mrx2"
                                        icon="icon-plus"
                                        type="primary"
                                        onClick={() => {
                                            setAddTask(true);
                                            setSectionId(section.id);
                                            setSectionName(section.name);
                                            setSelectTask(section);
                                            setCurrentTabManagement(currentTab);
                                            setIsTemplate(false);
                                            setPermissionEditTask(checkPermission(PERMISSIONS_LIST.TASK_CREATE))
                                        }}
                                    // disabled={!collaborators.some(collaborator => (collaborator._id && collaborator._id === userInfo._id))}
                                    >
                                        {t("home.active-shipment.details.tabs.management-tab.add-task")}
                                    </Button>
                                    )}
                                    {!isArchive && checkPermission(PERMISSIONS_LIST.SECTION_UPDATE) && (
                                        <Button
                                            className="outline icon"
                                            icon="icon-pencil"
                                            type="secondary"
                                            onClick={() => {
                                                setEditSection(true);
                                                setIsEditSection(true);
                                                setSectionId(section.id);
                                                setSectionName(section.name);
                                                setCurrentTabManagement(currentTab);
                                                setIsTemplate(false);
                                            }}
                                        // disabled={!collaborators.some(collaborator => (collaborator._id && collaborator._id === userInfo._id))}
                                        >
                                            {t("home.active-shipment.details.tabs.management-tab.edit-section")}
                                        </Button>
                                    )}
                        </div>
                </Collapse>

            </div>
        )
    }) : '';
};

ShipmentTaskItem.propTypes = {
    initialTasks: PropTypes.array,
    tasks: PropTypes.array,
    collaborators: PropTypes.array
};
