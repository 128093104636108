import React, {useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Tab,
    TabPane,
    RequestList
} from 'components';
import { BOOKING_REQUESTS_PAGE_SIZE, CREATED, EDIT_REQUEST_URL, RECEIVED } from 'actions';
import { BookingRequestContext, HomeContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";

export const RequestTabs = ({
    searchParams,
    setSearchParams = () => {},
    setSelectedTab = () => {}
}) => {
    const { bookingRequestTab, setBookingRequestTab } = useContext(HomeContext);
    const { doGetBookingRequests } = useContext(BookingRequestContext);
    const history = useHistory();

    const { t } = useTranslation();

    const [tabsName] = useState([{name:CREATED,tabName:t('home.BookingRequest.table.tabsName.created')},{name:RECEIVED,tabName:t('home.BookingRequest.table.tabsName.received')}])

    /*eslint-disable */
    useEffect(() => {
        setBookingRequestTab(CREATED)
        doGetBookingRequests({
            page: 0,
            limit: BOOKING_REQUESTS_PAGE_SIZE,
            tab: bookingRequestTab,
            isSearching: true
        });
    }, [])
    /*eslint-enable */

    const handleTabChange = tab => {
        let tabOption;
        switch (tab) {
            case CREATED:
                tabOption = CREATED;
                break;
            case RECEIVED:
                tabOption = RECEIVED
                break;
            default:
                tabOption = CREATED;
                break;
        }
        setSelectedTab(tabOption);

        doGetBookingRequests({
            page: 0,
            limit: BOOKING_REQUESTS_PAGE_SIZE,
            tab: tab.toUpperCase(),
            isSearching: true
        });
    }

    const handeNavigateEditReference = data => {
        const { _id } = data;
        if (_id) history.push(EDIT_REQUEST_URL.replace(':brId', _id));
    };

    return (
        <div className="tr__request-tabs mtx2">
            <Tab initialTab={bookingRequestTab === CREATED ? 0 : 1} onTabClick={handleTabChange}>
                {tabsName.map((tab, tabIndex) => (
                    <TabPane
                        key={tabIndex}
                        tabName={tab.tabName}
                        tabSlug={tab.name}
                    >
                        <RequestList
                            typeRequest={tab.name}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            handeNavigateEditReference={handeNavigateEditReference}
                        />
                    </TabPane>
                ))}
            </Tab>
        </div>
    )
};

RequestTabs.propTypes = {
    setSelectedTab: PropTypes.func,
    searchParams: PropTypes.any,
    setSearchParams: PropTypes.func
};
