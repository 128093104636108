import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

import {
    Modal,
} from 'components';
import { AuthContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const NoResultSearch = ({ open = false, onCancel, title = 'No search result', ...rest }) => {
    const [t] = useTranslation();
    const { userInfo } = useContext(AuthContext);

    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title={title}
            btnClasses="flex-column justify-content-center"
            footerLabel={userInfo && userInfo.orgTeams && userInfo.orgTeams.length > 0 ? '' : 'Only available for Organization member'}
            onCancelClick={onCancel}
            isBackDropClickable={false}
            submitButtonDisabled={userInfo && userInfo.orgTeams && !userInfo.orgTeams.length}
            {...rest}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">{t("trackshipment.no-search-result")}</h6>
            </div>
        </Modal>
    )
};

NoResultSearch.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    title: PropTypes.string
};
