import React from 'react';
import PropTypes from 'prop-types';

import {
    Collapse,
    Input
} from 'components';
import { getErrorMessage } from 'utils';

export const EditSectionInfo = ({ register, sections, isArchive = false, errors }) => {

    const handleError = (section,item,field) => {
        return errors.sections && errors.sections[section] && errors.sections[section].items && errors.sections[section].items[item] && errors.sections[section].items[item][field] 
               ? errors.sections[section].items[item][field] : null;
    }

    return sections && sections.length > 0 ? sections.map((section, index) => {
        const field = `sections[${index}]`;
        return  <div className="tr__verify-details-form--group mbx4" key={index}>
                    <Collapse title={section && section.sectionName ? section.sectionName : ''} className="details" initialOpen={true}>
                        <div className="tr__verify-details-form--group--content">
                            <input hidden ref={register()} name={`${field}.sectionName`} value={section && section.sectionName ? section.sectionName : ''}/>
                            <div className="tr__verify-details-form--group--content-frame">
                                <div className="tr__verify-details-form--group__input">
                                    <div className="row">
                                        {section.items && section.items.length > 0 && section.items.map((item, i) => {
                                                const items = `${field}.items[${i}]`;
                                                return item.key ? (
                                                    <div className={ section.items.length > 1 ? "col-6 col-sm-6" : "col-12 col-sm-12"} key={items}>
                                                        <input 
                                                            hidden 
                                                            ref={register()} 
                                                            name={`${items}.key`}
                                                            value={item.key}
                                                        />
                                                        <Input
                                                            className="mtx2"
                                                            name={`${items}.value`}
                                                            refs={register({required: true})}
                                                            viewOnly={isArchive}
                                                            label={item.key.trim()}
                                                            defaultValue={item.value}
                                                            error={handleError(index,i,'value')}
                                                            errorMessage={getErrorMessage(handleError(index,i,'value'))}
                                                        />
                                                    </div>
                                                ) : ''
                                            }) 
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
        }
    ) : ''
};

EditSectionInfo.propTypes = {
    register: PropTypes.func,
    section: PropTypes.object,
    isArchive: PropTypes.bool
};
