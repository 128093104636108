import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const ShipmentHeader = ({className = ''}) => {
    const [t] = useTranslation();
    return (
        <div
            className={`tr__activities-header f-medium d-flex align-items-center justify-content-between ${className}`}>
            <div className="column tr__activities-header__name">
                <p>{t("organization.shipments.ShipmentHeader.header__name")}</p>
                {/* <i className="icon-filter" /> */}
            </div>
            <div className="column tr__activities-header__email">
                <p>{t("organization.shipments.ShipmentHeader.header__email")}</p>
                {/* <i className="icon-filter" /> */}
            </div>
            <div className="column tr__activities-header__activity">
                <p>{t("organization.shipments.ShipmentHeader.header__activity")}</p>
                {/* <i className="icon-filter" /> */}
            </div>
        </div>
    )
};
ShipmentHeader.prototype = {
    className: PropTypes.string
}
