import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import {
  Modal,
} from 'components';
import { AuthContext, CollaboratorsContext, HomeContext, ShipmentInfoContext, TemplateContext } from 'contexts';
import { ACTIVE_SHIPMENTS_URL } from 'actions';
import { useHistory, useLocation } from 'react-router-dom';
export const ConfirmRemoveCollaborator = ({
  open = false,
  onCancel,
  onSubmit = () => { },
  title = '',
  message = '',
}) => {
  const history = useHistory();
  const {userInfo} = useContext(AuthContext);
  const { collaboratorId, isTemplate } = useContext(HomeContext);
  const { collaborators, doRemoveCollaborator, setCollaborators } = useContext(CollaboratorsContext);
  const { collaboratorsTemp, setCollaboratorsTemp, doRemoveCollaboratorTemplate, templatesDetails, doGetTemplatesDetails } = useContext(TemplateContext);
  const location = useLocation();
  const pathnameArr = location.pathname.split('/');
  const shipmentId = pathnameArr[pathnameArr.length - 1];
  const { doGetShipmentInfo } = useContext(ShipmentInfoContext);

  const handleSubmit = () => {
    const payload = {
      id: collaboratorId,
    }
    const payloadTemplate = {
      collaboratorId: collaboratorId,
    }
    if (isTemplate) {
      console.log(templatesDetails.id)
      doRemoveCollaboratorTemplate(templatesDetails.id, payloadTemplate, () => {
        const filterCollaborators = collaboratorsTemp.filter(collaborator => collaborator._id !== collaboratorId);
        setCollaboratorsTemp(filterCollaborators);
        doGetTemplatesDetails(templatesDetails.id);
        onCancel();
      })
      // doGetShipmentInfo(shipmentId);
    } else {
      doRemoveCollaborator(shipmentId, payload, () => {
        if(collaboratorId === userInfo._id){
          history.push(ACTIVE_SHIPMENTS_URL);
        } else {
          const filterCollaborators = collaborators.filter(collaborator => collaborator._id !== collaboratorId);
          doGetShipmentInfo(shipmentId);
          setCollaborators(filterCollaborators);
          onCancel();
        }
      })
    }
    
  }
  return (
    <Modal
      open={open}
      className="tr__collaborator-confirmation"
      onCancel={onCancel}
      title={title}
      btnClasses="justify-content-center"
      submitButton
      submitButtonLabel="Yes"
      onSubmitClick={handleSubmit}
      cancelButton={true}
      cancelButtonLabel="No"
      cancelButtonClassNames="outline secondary"
      onCancelClick={onCancel}
      isBackDropClickable={false}
    >
      <div className="text-center remove-collaborator">
        <h6 className="h6 mtbx2 f-medium">{message}</h6>
      </div>
    </Modal>
  )
}
ConfirmRemoveCollaborator.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string
};
