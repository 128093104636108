import React, { createContext, useContext, useEffect, useState } from 'react';

import {
    getConfig,
    getNotifications,
    disablePopupFirstTime,
    maskAsRead,
    deleteNotification,
    disableNotification,
    OPERATION_FAILED_MESSAGE
} from 'actions';

import { getToken } from 'utils';
import {HomeContext} from 'contexts';
import { AuthContext } from './AuthContext';

export const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
    const { setLoading, setNotificationMessage, setNotificationType } = useContext(HomeContext);
    const { loggedInUser } = useContext(AuthContext);
    const [token, setToken] = useState(null);
    const [config, setConfig] = useState(null);
    const [rawNotifications, setRawNotifications] = useState(null);


    /*eslint-disable */
    useEffect(() => {
        if (!token)  setToken(getToken());
    },[token])
    /*eslint-enable */

    const handleException = error => {
        const {data} = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetConfig = async () => {
        try {
            const response = await getConfig(loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setConfig(data);
            }
        } catch (error) { }
    }

    const doGetNotifications = async (callback, errorCallBack) => {
        try {
            const response = await getNotifications(loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                if (callback)
                    callback(data);
            }
        } catch (error) {
            if (errorCallBack)
                errorCallBack();
        }
    }

    const doDisablePopupFirstTime = async (id, callback) => {
        try {
            const response = await disablePopupFirstTime(id, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                callback(data);
            }
        } catch (error) {
            return callback(false);
        }
    }

    const doMaskAsRead = async (ids, callback) => {
        try {
            const response = await maskAsRead(loggedInUser, ids);
            const { data, status } = response;
            return callback(status === 200 && data.success);
        } catch { }
        return callback(false);
    }

    const doDeleteNotification = async (id, callback) => {
        try {
            const response = await deleteNotification(loggedInUser, id);
            const { data, status } = response;

            return callback(status === 200 && data.success);
        } catch { }
        return callback(false);
    }

    const doDisableNotification = async (id, callback) => {
        try {
            const response = await disableNotification(id, loggedInUser);
            const { status } = response;
            if (status === 200) {
                if (callback) callback();
            }
        } catch (error) {
            handleException(error)
        }
    }
    return (
        <NotificationContext.Provider
            value={{
                config,
                rawNotifications,
                doGetConfig,
                doGetNotifications,
                doMaskAsRead,
                doDeleteNotification,
                doDisableNotification,
                setRawNotifications,
                doDisablePopupFirstTime
            }}
        >
            { children}
        </NotificationContext.Provider>
    );
};