import React from 'react';

import './index.scss';
import { ShipmentTaskHeader, ShipmentTaskItem } from 'components';
import { shipmentTasksFCL, shipmentTasksLCL } from 'utils';

export const ShipmentTasks = ({ type, tasks, collaborators, currentTab }) => {
    return (
        <div className="tr__shipment-task">
            <ShipmentTaskHeader className="mbx1" />
            <div className="tr__shipment-task-list">
                <ShipmentTaskItem 
                    initialTasks={type === 'FCL' ? shipmentTasksFCL : shipmentTasksLCL} 
                    tasks={tasks}
                    collaborators={collaborators}
                    currentTab={currentTab}
                />
            </div>
        </div>
    )
};
