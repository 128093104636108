import React, { useContext } from 'react';

import {
    Modal,
} from 'components';
import { HomeContext, ManagementContext, ModalContext, TemplateContext } from 'contexts';
import { useLocation } from 'react-router-dom';
import { ALL, MYTASK } from 'actions';
import { useTranslation } from 'react-i18next';

export const DeleteTask = ({ open = false, onCancel }) => {
    const { t } = useTranslation();
    const { eventTask, setNotificationMessage, setIsEditTask, currentTabManagement, isTemplate, setIsTemplate } = useContext(HomeContext);
    const { setDeleteTask } = useContext(ModalContext);
    const { doDeleteTask, doGetTaskManagements } = useContext(ManagementContext);
    const { doDeleteTaskTemplate, doGetTemplatesDetails, templatesDetails } = useContext(TemplateContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    
    const handleDeleteTask = () => {
        if(isTemplate === true) {
            doDeleteTaskTemplate(eventTask.id, () => {
                setIsTemplate(false);
                onCancel();
                doGetTemplatesDetails(templatesDetails && templatesDetails.id);
                setNotificationMessage(
                    t("home.delete-task.notification-message-delete")
                );
            })
        } else {
            doDeleteTask(shipmentId, eventTask.id, () => {
                setDeleteTask(false);
                setIsEditTask(false);
                if(currentTabManagement === ALL) {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        limit: 6,
                        tab: ALL
                    });
                } else {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        limit: 6,
                        tab: MYTASK
                    })
                }
                setNotificationMessage(
                    t("home.delete-task.notification-message-delete")
            );
            })
        }
          
    }
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title={t("home.delete-task.title")}
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel={t("home.delete-task.yes")}
            onSubmitClick={handleDeleteTask}
            cancelButton={true}
            cancelButtonLabel={t("home.delete-task.no")}
            cancelButtonType=""
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">{t("home.delete-task.content")}</h6>
            </div>
        </Modal>
    )
};