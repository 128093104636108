import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { useTranslation } from 'react-i18next';

export const ActivitiesHeader = ({ className = '' }) => {
	const [t] = useTranslation();
    return (
        <div className={`tr__activities-header f-medium d-flex align-items-center justify-content-between ${className}`}>
            <div className="column tr__activities-header__name">
                <p>{t("member.memberSetting.name")}</p>
                {/* <i className="icon-filter" /> */}
            </div>
            <div className="column tr__activities-header__email">
                <p>Email</p>
                {/* <i className="icon-filter" /> */}
            </div>
            <div className="column tr__activities-header__activity">
                <p>{t("activities-log.activities")}</p>
                {/* <i className="icon-filter" /> */}
            </div>
            <div className="column tr__activities-header__date">
                <p>{t("activities-log.date-time")} </p>
                {/* <i className="icon-filter" /> */}
            </div>
        </div>
    )
};

ActivitiesHeader.propTypes = {
    className: PropTypes.string
};