import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import { useTranslation } from 'react-i18next';
import { AIR } from 'actions';

export const Invoice = ({ invoices = [], shippingDetails = {} }) => {
    const [t] = useTranslation();
    return <InfoBlock
        className={`tr__details-box ${shippingDetails?.shipmentMode === AIR ? 'col-sm-4' : 'col-sm-3'}`}
        title={t("home.active-shipment.details.tabs.shipment-tab.invoice")}
    >
         <InfoRow>
            <div className="col-12">
                <div className='row'>
                    <InfoCell
                        className="col-12"
                        label={t("home.active-shipment.details.tabs.shipment-tab.invoice-number")}
                        value={invoices ? invoices.join(', ') : ''}
                    />
                </div>
            </div>
        </InfoRow>
    </InfoBlock>
};

Invoice.propTypes = {
    invoices: PropTypes.array
};
