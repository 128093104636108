import React, { useContext } from 'react'
import moment from 'moment';
import { AIR } from 'actions';
import { HomeContext } from 'contexts';
export const ShipmentStep = ({ step = [], titleAirPosts  }) => {
    const {typeOfShipment} = useContext(HomeContext);
    return (
        <div className={`step d-flex ${step.status}`}>
            <div className='step--icon'>
                <i className={step.icon} />
            </div>
            <div className="step--info">
                <h6 className={`h6 mtx05 mbx3 f-medium ${step.status !== 'done' ? 'disabled' : ''}`}>{step.title} {typeOfShipment?.key === AIR && titleAirPosts} </h6>
                <div className="step--info__times mbx2">
                    {step.items && step.items.length > 0 && step.items.map((item, itemIndex) => (
                        <div key={itemIndex}>
                            {item.vesselName && (
                                <p className={`f-medium mtbx1 ${step.status !== 'done' ? 'disabled' : ''}`}>{`${item.vesselName.name} (${item.vesselName.imo})`}</p>
                            )}
                            {item.items && item.items.length > 0 && item.items.map((event, eventIndex) => (
                                <div key={`${eventIndex}`}>
                                    <div className="d-flex align-center justify-content-between" >
                                        <h6 className="h6 f-medium">{!event.actual ? "Est." : ""} {event.description.split(' ').slice(0, 2).join(' ')} </h6>
                                        <h6 className={`h6 f-medium ${!event.actual ? 'disabled' : ''}`}>{event.date ? moment(event.date).format('DD MMM YYYY | hh:mm A') : ''}</h6>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}