import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Modal,
    Textarea,
    Input,
} from 'components';
import { getErrorMessage, isNotSpace } from 'utils';
import { useForm } from 'react-hook-form';
import { HomeContext, IssueContext, ModalContext, ShipmentInfoContext } from 'contexts';
import { useHistory, useLocation } from 'react-router-dom';
import { ISSUES_URL } from 'actions';
import { MultiSelect } from 'components/common';
import { useTranslation } from 'react-i18next';

export const IssueModal = ({ open = false, onCancel, isEdit = false, setSearchParams = () => {}, searchParams }) => {
    const [t] = useTranslation();
    const history = useHistory();
    const { handleSubmit, register, errors, reset, formState } = useForm({
        mode: "onChange"
    });
    const { doCreateIssues, issue, doUpdateIssue, taskIssue } = useContext(IssueContext);
    const { setNotificationMessage } = useContext(HomeContext);
    const { setAddIssue, setEditIssue } = useContext(ModalContext);
    const { doGetShipmentInfo } = useContext(ShipmentInfoContext);
    const [issuesSearched, setIssuesSearched] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentIdCreate = pathnameArr[pathnameArr.length - 2];
    const shipmentIdUpdate = pathnameArr[pathnameArr.length - 3];
    const [inputValue, setInputValue] = useState(false);
 
   /*eslint-disable*/
    useEffect(() => {
        setIssuesSearched(taskIssue);
    }, [taskIssue])
     /*eslint-enable*/

    const doAddIssue = (data) => {
        const {
            title,
            description, 
        } = data

        const payload = {
            title: title,
            description: description,
            linkedTasks: selectedValue
        }

        doCreateIssues(shipmentIdCreate, payload, (data) => {
            reset();
            setAddIssue(false);
            setNotificationMessage(`
                <p> Issue has been added successfully!</p>
            `);
            setInputValue(false);
            doGetShipmentInfo(shipmentIdCreate);
            // setSelectedValue([]);
            history.push(`${ISSUES_URL.replace(':shipmentId', shipmentIdCreate)}/${data._id}`);
        });
    }

    const EditIssue = (data) => {
        const {
            title,
            description, 
        } = data

        const payload = {
            title: title,
            description: description,
            linkedTasks: selectedValue
        }
        doUpdateIssue(shipmentIdUpdate, issue._id, payload, () => {
            reset();
            // setSelectedValue([]);
            setAddIssue(false);
            setInputValue(false);
            setEditIssue(false);
            setNotificationMessage(t("home.active-shipment.details.tabs.issues-tab.message-successfully"));
        });
    }

    const onHideEditIssueModal = () => {
        reset();
        onCancel();
        setSelectedValue([]);
        setInputValue(false);
        setEditIssue(false);
    }
    const handleSearchTasksIssue = value => {
        const filteredArray = taskIssue.filter(tasks => tasks.taskName.includes(value));
        setIssuesSearched(filteredArray);
        value === '' ? setInputValue(false) : setInputValue(true)
    };
    const handleChange = item => {
        setSelectedValue(item.map(e => e.id));
        item && setInputValue(false);
    }

    return (
        <Modal
            open={open}
            className="tr__issue-modal"
            onCancel={onHideEditIssueModal}
            title={isEdit ? t("home.active-shipment.details.tabs.issues-tab.edit-issue") : t("home.active-shipment.details.tabs.issues-tab.created-issue")}
            submitButton
            submitButtonLabel={isEdit ? t("home.active-shipment.details.tabs.issues-tab.save-changes") : t("home.active-shipment.details.tabs.issues-tab.create")}
            onSubmitClick={isEdit ? handleSubmit(EditIssue) : handleSubmit(doAddIssue)}
            submitButtonDisabled = {!formState.isValid || inputValue}
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit()}>
                    {children}
                </form>
            )}
        >
            <div className="tr__issue-modal--form">
                <Input
                    label={t("home.active-shipment.details.tabs.issues-tab.title")}
                    required
                    className="mbx3"
                    name="title"
                    refs={register({ required: true, validate: isNotSpace})}
                    errorMessage={getErrorMessage(errors.title, "Title", "This field cannot be blank")}
                    maxLength={50}
                    defaultValue={issue ? issue.title : ''}
                    placeholder={t("home.active-shipment.details.tabs.issues-tab.enter-title")}
                    error={!!errors.title}
                />
                <Textarea
                    label={t("home.active-shipment.details.tabs.issues-tab.description")}
                    className="mbx3"
                    name="description"
                    refs={register()}
                    maxLength={200}
                    defaultValue={issue ? issue.description : ''}
                    placeholder={t("home.active-shipment.details.tabs.issues-tab.enter-description")}
                    // error={!!errors.description}
                    // errorMessage={getErrorMessage(errors.description, "Description", "Description must not exceed 200 characters")}
                />
                <MultiSelect
                    className="mbx2"
                    options={issuesSearched && issuesSearched.length > 0 ? issuesSearched.map(item => {
                        return {
                            id: item._id,
                            description: item.taskName,
                        }
                    }) : []}
                    name="linkedTask"
                    value={isEdit && issue && issue.linkedTasks ? issue.linkedTasks : []}
                    multiple={true}
                    isSelectable={true}
                    label={t("home.active-shipment.details.tabs.issues-tab.linked-task")}
                    placeholder={issuesSearched.length === 0 ? t("home.active-shipment.details.tabs.issues-tab.no-tasks-found") : t("home.active-shipment.details.tabs.issues-tab.choose-a-task")}
                    onChange={handleChange}
                    onInputChange={handleSearchTasksIssue}
                    errorMessage='Please choose at least 1 link task'
                    register={register}
                    // errors={errors}
                    // formState={formState}
                />
            </div>
        </Modal>
    )
};

IssueModal.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    isEdit: PropTypes.bool,
}