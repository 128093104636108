import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import { useTranslation } from 'react-i18next';
import { AIR } from 'actions';

export const TabAddress = ( { addresses= {}, shippingDetails = {} } ) => {
    const [t] = useTranslation();

    const getLocationValue = (data) => {
        const { name, country, locode} = {...data.location};
        return name + (country ? `, ${country}` : '') + (locode ? ` (${locode})` : '');
    }

    return <InfoBlock
        className="tr__details-box"
        title={t("home.active-shipment.details.tabs.shipment-tab.address")}
    >
         <InfoRow >
            {shippingDetails?.shipmentMode !== AIR && <>
                <div className="col-12">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.pre-pol")}
                        value={addresses.prepol ? getLocationValue(addresses.prepol) : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.pol")}
                        value={addresses.pol ? getLocationValue(addresses.pol) : ''}
                    />
                </div>
            </div>
            <div className="col-12 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.pod")}
                        value={addresses.pod ? getLocationValue(addresses.pod) : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.post-pod")}
                        value={addresses.postpod ? getLocationValue(addresses.postpod) : ''}
                    />
                </div>
            </div>
        </>}
            
            {shippingDetails?.shipmentMode === AIR &&  <div className="col-12 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={"Origin address"}
                        value={addresses?.prepol ? `${addresses?.prepol?.city}, ${addresses?.prepol?.country} ${addresses?.prepol?.countryCode}` : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={"Destination address"}
                        value={addresses?.postpod ? `${addresses?.postpod?.city}, ${addresses?.postpod?.country} ${addresses?.postpod?.countryCode}` : ''}
                    />
                </div>
            </div>}
           
            <div className="col-12 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.shipper-pickup")}
                        value={addresses.shipperPickupAdd ? addresses.shipperPickupAdd : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.consignee-delivery")}
                        value={addresses.consigneeDeliveryAdd ? addresses.consigneeDeliveryAdd : ''}
                    />
                </div>
            </div>
        </InfoRow>
        
    </InfoBlock>
};

TabAddress.propTypes = {
    addresses: PropTypes.object
}