import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.scss';
import { BookingRequestContext, HomeContext, ModalContext } from 'contexts';
import {
    TabPane,
    Tab,
    InfoCell,
    Collaborators
} from 'components';

import {
    CommentTab,
    ActivityLogTab
} from 'components';

import {
    ACTIVITIES_LOG,
    COMMENTS,
    MARKED,
    MARK_AS_ACTIVE_SHIPMENT
} from 'actions';
import { useLocation } from 'react-router-dom';
import { Collapse } from 'components/common';
import {useTranslation} from "react-i18next";

export const Reference = ({
    errors = [],
    register = () => {},
}) => {
    const { bookingRequest } = useContext(BookingRequestContext);
    const { setAddBRCollaborators } = useContext(ModalContext);
    const { doGetBookingRequestLogs, activityLogs } = useContext(BookingRequestContext);
    const { chooseLinkedVessel } = useContext(HomeContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const bookingId = pathnameArr[pathnameArr.length - 1];
    // const chooseLinkedVessel = location.state;
    const { setCollabsBk } = useContext(ModalContext);
    const { t } = useTranslation();

    /*eslint-disable*/
    useEffect(() => {
        doGetBookingRequestLogs(bookingId, () => {});
    }, [])
    /*eslint-enable*/

    const from = bookingRequest?.linkedVesselSchedule?.legs[0].start;
    const to = bookingRequest?.linkedVesselSchedule?.legs[bookingRequest.linkedVesselSchedule.legs.length - 1].end;
    const transits = bookingRequest?.linkedVesselSchedule?.legs?.slice(0, bookingRequest.linkedVesselSchedule.legs.length - 1);

    const fromBookingVessel = chooseLinkedVessel?.legs[0].start;
    const toBookingVesel = chooseLinkedVessel?.legs[chooseLinkedVessel.legs.length - 1].end;
    const transitsBookingVessel = chooseLinkedVessel?.legs?.slice(0, chooseLinkedVessel.legs.length - 1);

    return bookingRequest ? (
        <div className="tr_reference">
            <div className={`tr_reference--header d-flex align-items-center justify-content-between ${bookingRequest.status.toLowerCase()}`}>
                <Collaborators
                    members={bookingRequest ? bookingRequest.memberInfo : []}
                    skip={4}
                    onAdd={() => setAddBRCollaborators(true)}
                    onAvatarsClick={() => setCollabsBk(true)}
                />
                <div className="tr__booking-request--status__badge">
                    <span className={`${bookingRequest.status.toLowerCase()}`}>{bookingRequest.status === MARK_AS_ACTIVE_SHIPMENT ? MARKED.toLowerCase() : bookingRequest.status.toLowerCase()}</span>
                </div>
            </div>
            <div className="tr_reference--form">
                <h2 className="h2 f-medium">{t('home.BookingRequest.addNewRequest.detail.title')}</h2>
                <h3 className="h3 f-medium">{t('home.BookingRequest.addNewRequest.detail.typeOfShipment')}: <span>{bookingRequest.type}</span></h3>
                <div className="tr_reference--form__details">
                    <div className="row">
                        {bookingRequest.type === 'FCL' ? bookingRequest.containers && bookingRequest.containers.length > 0 && bookingRequest.containers.map((c, cIndex) => {
                            return (
                                <React.Fragment key={cIndex}>
                                    <div className="col-12 col-sm-6">
                                        <div className="reference--info mtx2">
                                            <label>{t('home.BookingRequest.addNewRequest.detail.labelNumberOfContainers')}</label>
                                            <span>{c.numberOfContainer}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className="reference--info mtx2">
                                            <label>{t('home.BookingRequest.addNewRequest.detail.labelTypeOfContainer')}</label>
                                            <span>{c.typeOfContainer}</span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        }) : (
                            <>
                                <div className="col-12 col-sm-6">
                                    <div className="reference--info mtx2">
                                        <label>{t('home.BookingRequest.addNewRequest.detail.labelNumberOfPackage')}</label>
                                        <span>{bookingRequest.numberOfPackage}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="reference--info mtx2">
                                        <label>{t('home.BookingRequest.addNewRequest.detail.labelNumberOfPallet')}</label>
                                        <span>{bookingRequest.numberOfPallet}</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <div className="reference--info mtx2">
                                        <label>{t('home.BookingRequest.addNewRequest.detail.labelTotalOfVolume')}</label>
                                        <span>{bookingRequest.totalOfVolume}</span>
                                    </div>
                                </div>
                            </>
                        )}
                        {bookingRequest.totalWeight && (
                            <div className="col-12 col-sm-6">
                                <div className="reference--info mtx2">
                                    <label>{t('home.BookingRequest.addNewRequest.detail.labelTotalWeight')}</label>
                                    <div className="d-flex"><span>{bookingRequest.totalWeight.value}</span> <p className="text-capitalize mlx1">{bookingRequest.totalWeight.weightUnit.toLowerCase()}</p></div>
                                </div>
                            </div>
                        )}
                        {bookingRequest.temperature && (
                            <div className="col-12 col-sm-6">
                                <div className="reference--info mtx2">
                                    <label>{t('home.BookingRequest.addNewRequest.detail.labelTemperature')}</label>
                                    <div className="d-flex"><span>{bookingRequest.temperature.value} </span><p className="text-capitalize mlx1">{bookingRequest.temperature.unitOfMeasure === 'CENTIGRADE' ? 'Celsius' : bookingRequest.temperature.unitOfMeasure.toLowerCase()}</p></div>
                                </div>
                            </div>
                        )}
                        <div className="col-12 full-size">
                            <div className="reference--info mtx2">
                                <label>{t('home.BookingRequest.addNewRequest.detail.labelRemark')}</label>
                                <span>{bookingRequest.remark}</span>
                            </div>
                        </div>
                        {bookingRequest.loadingPort && (
                            <div className="col-12 col-sm-6">
                                <div className="reference--info mtx2">
                                    <label>{t('home.BookingRequest.addNewRequest.detail.labelLoadingPort')}</label>
                                    <span
                                        title={`${bookingRequest.loadingPort.city}, ${bookingRequest.loadingPort.country} (${bookingRequest.loadingPort.locode})`}
                                    >
                                        {`${bookingRequest.loadingPort.city}, ${bookingRequest.loadingPort.country} (${bookingRequest.loadingPort.locode})`}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="col-12 col-sm-6">
                            <div className="reference--info mtx2">
                                <label>{t('home.BookingRequest.addNewRequest.detail.labelPlaceOfReceipt')}</label>
                                <span title={bookingRequest.placeOfReceipt}>{bookingRequest.placeOfReceipt}</span>
                            </div>
                        </div>
                        <div className="col-12 full-size">
                            <div className="reference--info mtx2">
                                <label>{t('home.BookingRequest.addNewRequest.detail.labelDateOfReceipt')}</label>
                                <span>{bookingRequest?.dateOfReceipt?.fromDate !== bookingRequest?.dateOfReceipt?.toDate ? `${moment(bookingRequest?.dateOfReceipt?.fromDate).format('DD MMM YYYY')} - ` : ''}{moment(bookingRequest?.dateOfReceipt?.toDate).format('DD MMM YYYY')}</span>
                            </div>
                        </div>
                        {bookingRequest.dischargePort && (
                            <div className="col-12 col-sm-6">
                                <div className="reference--info mtx2">
                                    <label>{t('home.BookingRequest.addNewRequest.detail.labelDischargePort')}</label>
                                    <span
                                        title={`${bookingRequest.dischargePort.city}, ${bookingRequest.dischargePort.country} (${bookingRequest.dischargePort.locode})`}
                                    >
                                        {`${bookingRequest.dischargePort.city}, ${bookingRequest.dischargePort.country} (${bookingRequest.dischargePort.locode})`}
                                    </span>
                                </div>
                            </div>
                        )}
                        <div className="col-12 col-sm-6">
                            <div className="reference--info mtx2">
                                <label>{t('home.BookingRequest.addNewRequest.detail.labelPlaceOfDelivery')}</label>
                                <span title={bookingRequest.placeOfDelivery}>{bookingRequest.placeOfDelivery}</span>
                            </div>
                        </div>
                        {bookingRequest.type === 'FCL' ? (
                            <>
                                <div className="col-12 full-size">
                                    <div className="reference--info mtx2">
                                        <label>{t('home.BookingRequest.addNewRequest.detail.labelEmptyP/U_Address')}</label>
                                        <span>{bookingRequest.emptyAddress}</span>
                                    </div>
                                </div>
                                <div className="col-12 full-size">
                                    <div className="reference--info mtx2">
                                        <label>{t('home.BookingRequest.addNewRequest.detail.labelDropFullAddress')}</label>
                                        <span>{bookingRequest.dropAddress}</span>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="col-12 half-size">
                                    <div className="reference--info mtx2">
                                        <label>{t('home.BookingRequest.addNewRequest.detail.labelExportContainer')}</label>
                                        <span>{bookingRequest.exportAddress}</span>
                                    </div>
                                </div>
                                <div className="col-12 half-size">
                                    <div className="reference--info mtx2">
                                        <label>{t('home.BookingRequest.addNewRequest.detail.labelImportContainer')}</label>
                                        <span>{bookingRequest.importAddress}</span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <h2 className="h2 f-medium">{t('home.BookingRequest.addNewRequest.detail.reference')}</h2>
                <div className="booking-number f-medium d-flex align-items-center">
                    <p>{t('home.BookingRequest.addNewRequest.detail.BookingNumber')}</p>
                    <span>{bookingRequest.bookingRequestNumber || ''}</span>
                </div>

                {bookingRequest?.linkedVesselSchedule && <>
                    <h2 className="h2 f-medium mtx3 mbx3">{t('home.BookingRequest.addNewRequest.detail.LinkVesselScheduleDetails')}</h2>
                    <div className="tr__vessel--search__info">
                        <div className="d-flex justify-content-between border-column">
                            <div className="px2">
                                <div className="port__brand">
                                    <img src={chooseLinkedVessel ? chooseLinkedVessel?.carrier?.logoURL : bookingRequest?.linkedVesselSchedule?.carrier?.logoURL} alt="Logo" />
                                    <span>{chooseLinkedVessel ? chooseLinkedVessel?.carrier?.name : bookingRequest?.linkedVesselSchedule?.carrier?.name}</span>
                                </div>
                            </div>
                            <div className="right__port">
                                <div className="block-column right__port__date d-flex flex-column align-items-center justify-content-center">
                                    <h4 className="right__port__date__number f-medium mbx1">
                                        {chooseLinkedVessel ? chooseLinkedVessel?.duration : bookingRequest?.linkedVesselSchedule?.duration} day{chooseLinkedVessel ? (chooseLinkedVessel?.duration > 1 ? 's' : '') : bookingRequest?.linkedVesselSchedule?.duration > 1 ? 's' : ''}
                                    </h4>
                                    {(bookingRequest?.linkedVesselSchedule?.legs.length > 1 && (
                                        <p className="f-medium">{bookingRequest?.linkedVesselSchedule?.legs.length - 1} T/S</p>
                                    )) || (chooseLinkedVessel?.legs.length > 1 && (
                                        <p className="f-medium">{chooseLinkedVessel && chooseLinkedVessel.legs && chooseLinkedVessel.legs.length > 0 &&  chooseLinkedVessel.legs.length -1 } T/S</p>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="port__info d-flex justify-content-center">
                            <div className="port__title text-right">
                                <h4 className="port__title__code f-medium h6 mbx1">
                                    {chooseLinkedVessel ? fromBookingVessel?.port?.name : from?.port?.name} ({chooseLinkedVessel ? fromBookingVessel?.port?.code : from?.port?.code})
                                    </h4>
                                <p className="f-medium">
                                    {moment(chooseLinkedVessel ? fromBookingVessel?.port?.date : from?.date).format('DD MMM YYYY')}
                                </p>
                            </div>
                            <div className="port__transhipping text-center">
                                <span className='port__transhipping__dashed-line'></span>
                                <div className="port__transhipping__label d-flex align-items-center">
                                    <div className="port__transhipping__item">
                                        {chooseLinkedVessel ? transitsBookingVessel.length > 0 && transits.map((leg, legIndex) => (
                                            <React.Fragment >
                                                <i className="icon-chevron-right" />
                                                <p className="f-medium" >
                                                    {leg.end.port.code}
                                                </p>
                                                {legIndex === transits.length - 1 && (
                                                    <i className="icon-chevron-right" />
                                                )}
                                            </React.Fragment>
                                        )) : transits.length > 0 && transits.map((leg, legIndex) => (
                                            <React.Fragment >
                                                <i className="icon-chevron-right" />
                                                <p className="f-medium" >
                                                    {leg.end.port.code}
                                                </p>
                                                {legIndex === transits.length - 1 && (
                                                    <i className="icon-chevron-right" />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                {bookingRequest?.linkedVesselSchedule?.legs.length > 1 && (
                                    <p className="sub-title mtx2">Transhipped</p>
                                )}
                            </div>
                            <div className="port__title">
                                <h4 className="port__title__code f-medium h4 mbx1">
                                    {chooseLinkedVessel ? toBookingVesel?.port?.name : to.port.name} ({chooseLinkedVessel ? toBookingVesel?.port?.code : to.port.code})
                                    </h4>
                                <p className="f-medium">
                                    {moment(chooseLinkedVessel ? toBookingVesel?.date : to?.date).format('DD MMM YYYY')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="tr__vessel--search__footer">
                        <Collapse collapseTitle="vessels" className="blue-bg">
                            <div className="content__view">
                                {chooseLinkedVessel ? chooseLinkedVessel?.legs.map((dataRoute, routeIndex) => (
                                    <div className="content__view__row" key={routeIndex}>
                                        <div className="d-flex title">
                                            <InfoCell title={t('home.BookingRequest.reference.vessel')} label={dataRoute.vessel.name} className="a" />
                                            <InfoCell title={t('home.BookingRequest.reference.voyage')} label={dataRoute.voyage} />
                                            <InfoCell title={t('home.BookingRequest.reference.service')} label={dataRoute.route.name} />
                                        </div>
                                        <div className="d-flex title">
                                            <InfoCell title={t('home.BookingRequest.reference.departure')} label={`${dataRoute.start.port.name} (${dataRoute.start.port.code})`} >
                                                <div className="date">{moment(dataRoute.start.date).format('DD MMM YYYY')} </div>
                                            </InfoCell>
                                            <InfoCell title={t('home.BookingRequest.reference.arrival')} label={`${dataRoute.end.port.name} (${dataRoute.end.port.code})`}>
                                                <div className="date">{moment(dataRoute.end.date).format('DD MMM YYYY')} </div>
                                            </InfoCell>
                                        </div>
                                    </div>
                                )) : bookingRequest?.linkedVesselSchedule?.legs.map((dataRoute, routeIndex) => (
                                    <div className="content__view__row" key={routeIndex}>
                                        <div className="d-flex title">
                                            <InfoCell title={t('home.BookingRequest.reference.vessel')} label={dataRoute.vessel.name} className="a" />
                                            <InfoCell title={t('home.BookingRequest.reference.voyage')} label={dataRoute.voyage} />
                                            <InfoCell title={t('home.BookingRequest.reference.service')} label={dataRoute.route.name} />
                                        </div>
                                        <div className="d-flex title">
                                            <InfoCell title={t('home.BookingRequest.reference.departure')} label={`${dataRoute.start.port.name} (${dataRoute.start.port.code})`} >
                                                <div className="date">{moment(dataRoute.start.date).format('DD MMM YYYY')} </div>
                                            </InfoCell>
                                            <InfoCell title={t('home.BookingRequest.reference.arrival')} label={`${dataRoute.end.port.name} (${dataRoute.end.port.code})`}>
                                                <div className="date">{moment(dataRoute.end.date).format('DD MMM YYYY')} </div>
                                            </InfoCell>
                                        </div>
                                        {/* <div className="date">{moment(dataRoute.start.date).format('DD MMM YYYY')} </div> */}
                                        {/* </div> */}
                                        {/* <div className="content__view__row__column"> */}
                                        {/* </div> */}
                                    </div>
                                ))}
                            </div>
                        </Collapse>
                    </div>
                </>}


                <div className="reference-tab mtx5">
                    <Tab initialTab={0} onTabClick={tab => console.log(tab)}>
                        <TabPane tabName={t('home.BookingRequest.tabComment.tabNameComments')} tabSlug={COMMENTS}>
                            <CommentTab
                                register={register}
                                errors={errors}
                            />
                        </TabPane>
                        <TabPane tabName={t('home.BookingRequest.tabComment.tabNameActivesLog')} tabSlug={ACTIVITIES_LOG}>
                            <ActivityLogTab
                                activityLogs={activityLogs}
                            />
                        </TabPane>
                    </Tab>
                </div>
            </div>
        </div>
    ) : '';
};

Reference.propTypes = {
    errors: PropTypes.any,
    register: PropTypes.func
};

