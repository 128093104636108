import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { Dropdown, Checkbox, Input } from 'components';
import { daysOptions } from 'utils';

export const DaysInput = ({ label, register = () => {}, errors = [], dayAjust, setDayAjust, isEditTask, eventTask, isAddTaskFta }) => {
    const [dayMode, setDayMode] = useState(daysOptions[0]);
    const [toggleDays, setToggleDays] = useState(false);
    const defaultAjustDay = (isEditTask && eventTask && eventTask.dueDate && eventTask.dueDate.eventBasedDate) ? eventTask.dueDate.eventBasedDate.adjustDays : !isEditTask && isAddTaskFta ? 3 : 1;

    const handleToggle = () => {
        const newToggleValue = !toggleDays;
        setToggleDays(newToggleValue);

        // if (newToggleValue) {
        //     setDayAjust(1);
        // } else {
        //     setDayAjust('');
        // }
    }

    /*eslint-disable*/
    useEffect(() => {
        setDayAjust(defaultAjustDay);
    }, [eventTask])
     /*eslint-enable*/
     

    return (
        <div className="tr__days-input">
            <div className="tr__days-input--header">
                {label && (
                    <label className="text-label">{label}</label>
                )}
                <Checkbox
                    name="toggleAdjustDays"
                    type="checkbox"
                    checked={toggleDays}
                    onChange={handleToggle}
                    className="toggle"
                />
            </div>
            <div className={`tr__days-input--field d-flex ${toggleDays ? '' : 'disabled'}`}>
                <Input
                    type="number"
                    name="adjustDay"
                    placeholder="Add days"
                    value={dayAjust}
                    onNumberChange={num => setDayAjust(num)}
                />
                <Dropdown
                    className="select"
                    mode="select"
                    name="eventType"
                    value={dayMode}
                    onChange={mode => setDayMode(mode)}
                    placeholder="e.g. days or hours"
                    dropdownPosition="center"
                    refs={register({ required: true })}
                />
            </div>
        </div>
    )
};

DaysInput.propTypes = {
    label: PropTypes.string
};