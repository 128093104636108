import React from 'react'
import './index.scss';
import { Table } from 'components';
import moment from 'moment';
import avatar from 'assets/images/avatar.png';
import { GET_FILE } from 'actions';
import {useTranslation} from "react-i18next";

export const ActivityLogTab = ({ activityLogs }) => {
    const { t } = useTranslation();

    const columns = [
        {
			dataIndex: 'fieldName',
			render: (fieldName) => (
                <h6 className="h6" title={fieldName}>{fieldName}</h6>
            )
        },
        {
			dataIndex: 'oldValue,fieldName',
			render: (oldValue,fieldName) => (
                <h6 className="h6" title={oldValue}>{fieldName.indexOf("Date") > -1 ? moment(parseInt(oldValue.split("-")[0])).format('DD MMM YYYY') + " - " + moment(parseInt(oldValue.split("-")[1])).format('DD MMM YYYY')  : oldValue }</h6>
            )
        },
        {
			dataIndex: 'newValue,fieldName',
			render: (newValue,fieldName) => (
                <h6 className="h6" title={newValue}>{fieldName.indexOf("Date") > -1 ?  moment(parseInt(newValue.split("-")[0])).format('DD MMM YYYY') + " - " + moment(parseInt(newValue.split("-")[1])).format('DD MMM YYYY') : newValue}</h6>
            )
        },
    ]
    return (
        <div className="activities-log">
            <div className="" >
                {activityLogs && activityLogs.length > 0 ? activityLogs.map((item, index) => (
                    <div className="activities-log frame">
                        <div className="activities-log--item d-flex align-items-center" key={index}>
                            <div className="activities-log--item__avatar mrx2">
                                <img src={item.avatar ? GET_FILE + item.avatar : avatar} alt={item.avatar} />
                            </div>
                            <span className="activities-log--item__name" title={item.username}>{item.username} &nbsp; </span>
                            <span className="activities-log--item__content" title={item.description}>{item.description} &nbsp; </span>
                            <span className="activities-log--item__time"> {moment(item.dateTime).format('DD MMM YYYY | hh:mm A')}</span>
                        </div>
                        <Table
                            className="activities-log--table border"
                            dataSource={item.activity.actionType==="EDIT"? item.activity.editedFields : []}
                            columns={columns}
                        >
                            {item.activity.actionType==="EDIT" ? <div className="activities-log--table-header flex align-items-center justify-content-between table-header">
                                <div className="column field">
                                    <p>{t('home.BookingRequest.activityLogTab.field')}</p>
                                </div>
                                <div className="column original-value">
                                    <p>{t('home.BookingRequest.activityLogTab.originalValue')}</p>
                                </div>
                                <div className="column new-value">
                                    <p>{t('home.BookingRequest.activityLogTab.newValue')}</p>
                                </div>
                            </div> : ""}
                        </Table>
                    </div>
                )) : (
                    <p> {t('home.BookingRequest.activityLogTab.noActivity')}</p>
                )}
            </div>
        </div>
    )
}
