import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss'
import {
    Collapse,
    Dropdown,
    Input
} from 'components';
import { ftaOptions, getErrorMessage, currencyCodes, isValidNetPrice } from 'utils';
import { FtaAdvisorContext, ExtractedContext, HomeContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { DEBOUNCE_TIME, PERMISSIONS_LABEL_LIST } from 'actions';
import { debounce } from 'lodash';
import { Tooltip } from 'components/common';
import { useTranslation } from 'react-i18next';

export const DocumentTable = ({
    className = '',
    register = () => {},
    errors = [],
    invoice,
    getValues = () => {},
    setValue = () => {},
    ftaAdvisor,
    watch,
    files,
    clearErrors = () => {},
}) => {
    const [t] = useTranslation();
    const [docs, setDocs] = useState([]);
    const [isEditInvoiceNum, setIsEditInvoiceNum] = useState(false);
    const [hsCodeLoading, setHsCodeLoading] = useState([]);
    const { extractedFtaData } = useContext(ExtractedContext);
    const { permission } = useContext(HomeContext);
    const { doGetHsCodes } = useContext(FtaAdvisorContext);
    const { shipmentId } = useParams();
    const [currencyOptions, setCurrencyOptions] = useState(currencyCodes);

    const validPirce = value => {
        const addCommas = price => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const removeNonNumeric = price => price.toString().replace(/[^0-9.]/g, "");
        const price = value.split('.');
        price[0] = addCommas(removeNonNumeric(price[0]));
        return price.join('.');
    }

    /*eslint-disable */
    useEffect(()=>{
        if ( extractedFtaData && extractedFtaData.length > 0 && files[invoice]){
            const data = extractedFtaData.find(item => item.id === files[invoice].id);
            const items = [{
                    key: 0,
                    dataType: data && !data.HsCode && data.Description ? true : false,
                    hsCode: data &&  data.HsCode,
                    netPrice: data &&  data.NetPrice,
                    currency: data &&  data.Currency,
                    description: data &&  data.Description && !data.HsCode ? data.Description : '',
                    hsCodeOptions: []
                }];
            setDocs(items);
            setHsCodeLoading(Array(items.length).fill(false));
            setTimeout(() => {
                clearErrors(`invoices[${invoice}]`);
            },500)
        }else {
            setDocs([{ key:0, dataType: false, hsCodeOptions: [] }]);
            setHsCodeLoading([false]);
        }
    },[extractedFtaData,ftaAdvisor])
    /*eslint-enable */

    const handleAddDoc = () => {
        const item = {
            key: docs.reduce((max,doc) => doc.key >= max.key ? doc : max).key + 1,
            dataType: false,
            hsCodeOptions: []
        }
        setDocs(oldDocs => [...oldDocs, item]);
        setHsCodeLoading(old => [...old,false]);
    }

    const handleRemoveDoc = doc => {
        setDocs(oldDocs => [...oldDocs.filter(d => d.key !== doc)]);
        setHsCodeLoading(hsCodeLoading.splice(1));
    }

    const handleError = (doc,field) => {
       return errors.invoices && errors.invoices[invoice] && errors.invoices[invoice].items && errors.invoices[invoice].items[doc] && errors.invoices[invoice].items[doc][field]
              ? errors.invoices[invoice].items[doc][field] : null;
    }

    const handleGetHsCode = debounce(async (val,index,docIndex) => {
        if(val) {
            setHsCodeLoading(old => [...old.map((item, itemIndex) => itemIndex === index)]);
            const params = {
                origin: ftaAdvisor.ftaOrigin,
                destination: ftaAdvisor.ftaDestination,
                goodsType: val
            };
            await doGetHsCodes(shipmentId, params, (values) => {
                setHsCodeLoading(Array(hsCodeLoading.length).fill(false));
                let data = [...docs];
                data[docIndex].hsCodeOptions = values;
                setDocs(data);
            }, () => {
                setHsCodeLoading(Array(hsCodeLoading.length).fill(false));
            });
        }
    }, DEBOUNCE_TIME)

    const getOption = async (dataType,name,index,docIndex) =>{
        let data = [...docs];
        const val = getValues(name);
        if(dataType && val &&  data[docIndex].hsCodeOptions.length === 0){
            handleGetHsCode(val,index,docIndex);
        }
    }

    const getMaxlength = name => {
        const value = getValues(name);
        if(value){
            const arr = value.split('.');
            return value.length === 26 && arr.length === 2 && arr[1].length === 0 ? 27 : 26;
        }
        return 26;
    }
    const checkDeleteInvoices = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }

    return (
        <div className={`tr__document-table ${className}`}>
            <Collapse
                className="details"
                initialOpen={true}
                renderHeader={() => (
                    <>
                        <div className="d-flex align-items-center justify-content-between">
                            <span>{t("home.active-shipment.details.tabs.fta-tab.invoice-number")}</span>
                            {!isEditInvoiceNum &&
                                <>
                                    <span className="mlx1">{getValues(`invoices[${invoice}].invoiceNumber`)}</span>
                                    <i className="icon-pencil mlx1" onClick={e => {
                                        e.stopPropagation();
                                        setIsEditInvoiceNum(!isEditInvoiceNum);
                                    }} />
                                </>
                            }
                            <div className={`invoiceNum d-flex mlx1 align-items-center ${isEditInvoiceNum ? '' : 'hidden'}`}>
                                <Input
                                    name={`invoices[${invoice}].invoiceNumber`}
                                    refs={register()}
                                    maxLength={50}
                                    placeholder={t("home.task.task-name.placeholder")}
                                    onChange={e => e.stopPropagation()}
                                    onClick={e => e.stopPropagation()}
                                    defaultValue={ftaAdvisor.invoice && ftaAdvisor.invoice[invoice] && ftaAdvisor.invoice[invoice]?.invoiceNumber
                                        ? ftaAdvisor.invoice[invoice]?.invoiceNumber : ''}
                                    onBlur={e => {
                                        e.stopPropagation();
                                        setIsEditInvoiceNum(!isEditInvoiceNum);
                                    }}
                                />
                            </div>
                        </div>
                        <i className="icon-chevron-down" />
                    </>
                )}
            >
                <div className="tr__document-table--header table-row d-flex align-items-center table-header justify-content-between">
                    <div className="column type">
                        <p>{t("home.active-shipment.details.tabs.fta-tab.data-type")}</p>
                    </div>
                    <div className="column description">
                        <p>{t("home.active-shipment.details.tabs.fta-tab.description")} <span className="required-star">*</span></p>
                    </div>
                    <div className="column code">
                        <p>{t("home.active-shipment.details.tabs.fta-tab.hs-code")} <span className="required-star">*</span></p>
                    </div>
                    <div className="column price">
                        <p>{t("home.active-shipment.details.tabs.fta-tab.total-net-price")}</p>
                    </div>
                    <div className="column currency">
                        <p>{t("home.active-shipment.details.tabs.fta-tab.currency")}</p>
                    </div>
                </div>
                <div className="tr__document-table--content mtx1">
                    {docs.map((doc,i) => {
                        const items = `invoices[${invoice}].items[${doc.key}]`;
                        return (
                            <div className="table-row d-flex align-items-center table-body justify-content-between" key={doc.key}>
                                <div className="column type d-flex align-items-center">
                                    {docs.length > 1 && checkDeleteInvoices(PERMISSIONS_LABEL_LIST.FTA_DELETE_INVOICE) && (
                                        <i className="icon-times mrx2" onClick={() => handleRemoveDoc(doc.key)} />
                                        )}
                                    <Dropdown
                                        mode="select"
                                        icon="icon-chevron-down"
                                        iconPosition="right"
                                        options={ftaOptions}
                                        dropdownPosition="center"
                                        placeholder="Choose type"
                                        onChange={item => {
                                            const dat = [...docs.map(d => {
                                                if(d.key === doc.key){
                                                    d.dataType = item.key === ftaOptions[1].key;
                                                    d.hsCode = "";
                                                    return d
                                                }else{
                                                    return d
                                                }
                                            })];
                                            setValue(`${items}.dataType`,item.key);
                                            setValue(`${items}.description`,'');
                                            setValue(`${items}.hsCode`,'');
                                            setDocs(dat);
                                        }}
                                        value={doc.dataType ? ftaOptions[1] : ftaOptions[0]}
                                    />
                                </div>
                                <div className="column description">
                                    <Input
                                        name={`${items}.description`}
                                        placeholder={t("home.active-shipment.details.tabs.fta-tab.column-description.placeholder")}
                                        maxLength={50}
                                        refs={register()}
                                        onChange={e => handleGetHsCode(e.target.value,i,doc.key)}
                                        disabled={!doc.dataType }
                                        defaultValue={doc.description ? doc.description : ''}
                                    />
                                </div>
                                <div className="column hscode" onClick={() => getOption(doc.dataType,`${items}.description`,i,doc.key)}>
                                    {!doc.dataType ?
                                        <Input
                                            name={`${items}.hsCode`}
                                            refs={register({required: true})}
                                            error={handleError(doc.key,'hsCode')}
                                            errorMessage={getErrorMessage(handleError(doc.key,'hsCode'), "hsCode")}
                                            defaultValue={doc.hsCode ? doc.hsCode : ''}
                                            placeholder={t("home.task.task-name.placeholder")}
                                        /> :
                                        <Dropdown
                                            mode="select"
                                            icon="icon-chevron-down"
                                            iconPosition="right"
                                            options={doc.hsCodeOptions && doc.hsCodeOptions.length > 0 ? doc.hsCodeOptions.map(option => {
                                                return {
                                                    key: option.hsCode,
                                                    value: option.hsCode,
                                                    description:  option.description
                                                }
                                            }) : []}
                                            dropdownPosition="center"
                                            placeholder={t("home.active-shipment.details.tabs.fta-tab.column-hscode.placeholder")}
                                            searchLoading={hsCodeLoading[i]}
                                            name={`${items}.hsCode`}
                                            refs={register({required: true})}
                                            error={handleError(doc.key,'hsCode')}
                                            errorMessage={getErrorMessage(handleError(doc.key,'hsCode'), "hsCode")}
                                            defaultValue={doc.hsCode ? doc.hsCode : ''}
                                            renderLabel={selectedValue => (
                                                <>
                                                    {selectedValue ? (selectedValue.description.length > 50 ?
                                                            <Tooltip
                                                                renderContent={() => (
                                                                    <div className="tool-desc">{selectedValue.description}</div>
                                                                )}
                                                            >
                                                                    <span className="selected">{selectedValue.value} - {selectedValue.description.substring(0, 50)}...</span>
                                                            </Tooltip> : <span className="selected">{selectedValue.value} - {selectedValue.description}</span>
                                                        ) : ''
                                                    }
                                                </>
                                            )}
                                            render={(options, handleChange, selectedValue) => (
                                                <>
                                                    <div>
                                                        {options.map((option, optionIndex) => (
                                                            <div class="tr__dropdown__item "
                                                                onClick={() => {
                                                                    handleChange(option)
                                                                }}
                                                                key={optionIndex}
                                                            >
                                                                {option.description.length > 50 ?
                                                                    <h6 class="h6" title={option.description}>{option.value} - {option.description.substring(0, 47)}...</h6>
                                                                    : <h6 class="h6">{option.value} - {option.description}</h6>
                                                                }
                                                            </div>
                                                        ))}
                                                    </div>
                                               </>
                                           )}
                                        />
                                    }
                                </div>
                                <div className="column price">
                                    <Input
                                        name={`${items}.netPrice`}
                                        placeholder="$$$$$$$"
                                        maxLength={getMaxlength(`${items}.netPrice`)}
                                        refs={register({required: false, validate: isValidNetPrice })}
                                        error={handleError(doc.key,'netPrice')}
                                        errorMessage={getErrorMessage(handleError(doc.key,'netPrice'), "netPrice", "Please input number only")}
                                        defaultValue={ doc.netPrice ? doc.netPrice : '100,000'}
                                        onChange={e => setValue(`${items}.netPrice`,validPirce(e.target.value))}
                                    />
                                </div>
                                <div className="column currency">
                                    <Dropdown
                                        name={`${items}.currency`}
                                        mode="input"
                                        icon="icon-chevron-down"
                                        iconPosition="right"
                                        dropdownPosition="center"
                                        placeholder={t("home.active-shipment.details.tabs.fta-tab.column-currency.placeholder")}
                                        options={currencyOptions.map(option => {
                                            return {
                                                key: option,
                                                value: option,
                                            }
                                        })}
                                        onInputChange={val => setCurrencyOptions(val ? currencyCodes.filter(code => code.toUpperCase().includes(val.toUpperCase())) : currencyCodes)}
                                        refs={register({required: watch(`${items}.netPrice`)})}
                                        error={handleError(doc.key,'currency')}
                                        errorMessage={getErrorMessage(handleError(doc.key,'currency'), "Currency",)}
                                        defaultValue={ doc.currency ?  doc.currency : 'USD'}
                                    />
                                </div>
                            </div>
                        )}
                    )}
                </div>
                {docs.length < 10 &&
                    <div className="tr__document-table--footer">
                        <p className="tr__link" onClick={handleAddDoc}>
                            <i className="icon-plus" />
                            <span>{t("home.active-shipment.details.tabs.fta-tab.add-new")}</span>
                        </p>
                    </div>
                }
            </Collapse>
        </div>
    )
};

DocumentTable.propTypes = {
    className: PropTypes.string
};
