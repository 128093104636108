import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Tooltip } from '../Tooltip';

export const InputTags = ({
    defaultValue,
    isTooltip,
    className,
    name,
    placeholder = 'Please enter the value...',
    refs,
    disabled = false,
    maxLength = 200,
    label,
    error,
    errorMessage,
    onChange,
    viewOnly = false,
}) => {
  const [tags, setTags] = useState(defaultValue ? defaultValue : []);
  const [value, setValue] = useState();
  const [showTooltip, setShowTooltip] = useState(false);
  
  /*eslint-disable */
  useEffect(() => {
    if(value) setValue('');
    if(onChange) onChange(tags);
    if(defaultValue){
      setShowTooltip(tags.toString() !== defaultValue.toString());
    }
  },[tags])
  /*eslint-enable */

  const removeTag = (i) => {
    const newTags = [ ...tags ];
    setTimeout(() => {
        newTags.splice(i, 1);
        setTags(newTags);
    }, 300);
  };

  const inputKeyDown = (e) => {
    let val = e.target.value;
    if ((e.key === 'Enter' || e.key === ',') && val.trim()) {
      setTags([...tags, val.trim()]);
      e.preventDefault();
    }else if (e.key === 'Backspace' && !val) {
      removeTag(tags.length - 1);
    }else if (e.key === ',' && !val.trim()) {
      e.preventDefault();
    }
  };

  return (
    <div className={`tr__input-tags ${className}`}>
      <label htmlFor={name}>
        {label}
        {showTooltip && isTooltip && 
          <Tooltip
              renderContent={() => (
                  <div>Old Value: {defaultValue.join(', ')}</div>
              )}
          >
              <i className="icon-warning mlx1"></i>
          </Tooltip>
        
        }
      </label>
      <div className="tr__input-tags--wrapper">
        {tags.length > 0 && tags.map((tag, i) => (
          <div className="tr__input-tags--values mrx1" key={i}>
            <h6 className="h6">{tag}</h6>
            <i className="icon-times" onClick={() => removeTag(i)} />
          </div>
        ))}
        <div className="tr__input-tags__input">
            {viewOnly ? (
                    <p className="label">{defaultValue}</p>
                ) :
              (
                <>
                  <input 
                    type="text" 
                    placeholder={placeholder}
                    onKeyDown={inputKeyDown} 
                    value={value} 
                    onChange={e => setValue(e.target.value)}
                    disabled={disabled}
                    maxLength={maxLength}
                    onBlur={e => {
                     setValue('');
                    }}
                  />
                  <input hidden name={name} id={name} ref={refs} value={tags.toString()}/>
                </>
              )
          }
        </div>
      </div>
      <p className={`error ${error && errorMessage && 'active'}`}>{errorMessage}</p>
    </div>
  );
}

InputTags.propTypes = {
    defaultValue: PropTypes.array
};