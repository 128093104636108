import React, {useContext} from "react";
import './index.scss';
import {HomeContext, TemplateContext} from 'contexts';
import {useForm} from "react-hook-form";
import {Input, Modal, Textarea} from 'components';
import {getErrorMessage} from 'utils';
import {useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AddTemplate = ({open = false, onCancel}) => {
    const [t] = useTranslation();
    const {setNotificationMessage} = useContext(HomeContext);
    const {handleSubmit, register, errors} = useForm();
    const { doAddTemplate }  = useContext(TemplateContext);
    const history = useHistory();

    const handleEditTemplate = (data) => {
        const payload = {
            name: data.name.trim(),
            desc: data.description.trim()
        }
        doAddTemplate(payload, (templateId) => {
            setNotificationMessage(`
               <p>Template has been add successfully!</p>
            `);
            onCancel();
            history.push(`/organizations/templates/${templateId}`);
        })
    }

    return (
        <Modal
            open={open}
            className="tr__edit-template"
            onCancel={onCancel}
            title={t("template.my-template.create-template")}
            btnClasses="justify-content-end"
            submitButton
            submitButtonLabel={t("template.my-template.create")}
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(handleEditTemplate)}>
                    {children}
                </form>
            )}
        >
            <div className="tr__edit-template__modal--form">
                <Input
                    label={t("template.my-template.titles")}
                    required
                    className="mbx3"
                    name="name"
                    refs={register({
                        required: true,
                        validate: val => !val.trim() ? 'This field cannot be blank' : undefined
                    })}
                    errorMessage={getErrorMessage(errors.name, "Name", "This field cannot be blank")}
                    maxLength={200}
                    placeholder={t("template.my-template.enter-name")}
                    error={!!errors.name}
                />
                <Textarea
                    label={t("template.my-template.description")}
                    className="mbx3"
                    name="description"
                    refs={register()}
                    maxLength={500}
                    placeholder={t("template.my-template.enter-description")}
                    error={!!errors.description}
                    errorMessage={getErrorMessage(errors.description, "Description", "Description must not exceed 500 characters")}
                />
            </div>
        </Modal>
    )
}
