import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import { OrganizationContext, ModalContext, HomeContext, NotificationContext } from 'contexts';
import {
    Modal,
} from 'components';
export const PopUps = ({
    open = false,
    onCancel,
}) => {
    const { notificationInvited } = useContext(OrganizationContext)
    const { setCountNotification, setPopUps } = useContext(ModalContext);
    const { setNotificationId } = useContext(HomeContext);
    const { setCouponMessage } = useContext(HomeContext);
    const { doGetNotifications, doDisablePopupFirstTime } = useContext(NotificationContext);
    const [arrayNotiPopup, setArrayNotiPopup] = useState([]);

    /*eslint-disable */
    useEffect(()=>{
        if(notificationInvited.length > 0) {
            setArrayNotiPopup(notificationInvited);
        }
    },[notificationInvited])
    /*eslint-enable */

    const notificationDetail = async (events, invited) => {
        setCountNotification(true);
        setCouponMessage(invited.message);
        setNotificationId(invited.id);
        await doDisablePopupFirstTime(invited.id, () => {});
        doGetNotifications(data => {
			const arr = data.filter(x => x.url === "POPUP" && x.firstSeenPopup === false);
			if(arr.length > 0){
				setArrayNotiPopup(arr);
				setPopUps(true);
			} else {
                setPopUps(false);
            }
		})
    }

    const cancelNotification = () => {
        for (let index = 0; index < notificationInvited.length; index++) {
			doDisablePopupFirstTime(notificationInvited[index].id, () => {});
		}
        setPopUps(false);
    }

    return (
        <Modal
            open={open}
            className="tr__link-issue__modal"
            onCancel={cancelNotification}
            title="Notification Invited"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="d-flex flex-column link-issue__box">
                {arrayNotiPopup && arrayNotiPopup.length > 0 && arrayNotiPopup.map((invited, invitedIndex) => (
                    <div onClick={(events) => notificationDetail(events,invited)} key={invitedIndex} className="d-flex align-items-center mtx1 link-issue__box-item">
                        <p className="mlx2">{invited.message}</p>
                    </div>
                ))}
            </div>
        </Modal>
    )
}
