import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import moment from 'moment';
import {
    Collaborators,
    Button,
    Table,
    DocumentHeader,
    UploadDocuments,
    Tooltip
} from 'components';
import { CollaboratorsContext, DocumentsContext, HomeContext, ManagementContext, ModalContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { CONSIGNEE, DEFAULT, DOCUMENT_PAGE_SIZE, EXPORT_CUSTOMS, EXPORT_LOGISTICS, FREIGHT, GET_FILE, IMPORT_CUSTOMS, IMPORT_LOGISTICS, PERMISSIONS_LIST, SHIPMENT_ADMIN } from 'actions';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const Documents = () => {
    const [t] = useTranslation();
    const { register, errors } = useForm();
    const {
        isArchive,
        setTaskName,
        setTaskId,
        setTaskRoles,
        setIsTemplate,
        setIsDocument,
        permission,
        setTypeDocumentTask
    } = useContext(HomeContext);
    const {
        documents,
        doGetDocuments,
        setDocumentsOfTask,
        setDocuments,
        doUploadDocumentsTask,
        doGetTradeTrust,
        permissionTask,
        permissionDefaultTask,
        setFunctionsTask
    } = useContext(DocumentsContext);
    const { setViewAllDocuments, setAssignCollaborators } = useContext(ModalContext);
    const { doGetTaskPartners } = useContext(ManagementContext);
    const { doGetCollaborators } = useContext(CollaboratorsContext);
    const [limit] = useState(3);
    const { shipmentId } = useParams();

    /*eslint-disable */
    useEffect(() => {
        setDocuments([]);
        doGetDocuments(shipmentId, {
            page: 0,
            limit: DOCUMENT_PAGE_SIZE
        }, () => {
        })
    }, [])
    /*eslint-enable */

    const checkPermission = (taskId, action, type) => {
        if (type === DEFAULT) {
            return permission?.shipmentFunctions.includes(action) || permissionDefaultTask.find(task => task.id === taskId)?.functions.includes(action);
        } else {
            return permission?.shipmentFunctions.includes(action) || permissionTask.find(task => task.id === taskId)?.functions.includes(action);
        }
    }

    const navigateViewDocuments = (documents, taskId, type, partners) => {
        // if (partners.some(partner => partner?.collaboratorId === userInfo?._id) || type === DEFAULT) {
        //     setDocumentPermission(true)
        // } else {
        //     setDocumentPermission(false)
        // }

        if (permission?.shipmentFunctions.includes(PERMISSIONS_LIST.DOCUMENT_BLOCKCHAIN)) {
            doGetTradeTrust(taskId, documents.filter(doc => doc.awsId).map(doc => doc.awsId), true, data => {
                // map response data to documents
                documents.forEach(doc => {
                    doc.tradeTrust = data.find(e => e.id === doc.awsId);
                });
    
               
            });
        }
        setFunctionsTask(((type === DEFAULT ? permissionDefaultTask : permissionTask).filter(task => task.id === taskId).length > 0) 
        ?(permission?.shipmentFunctions).concat((type === DEFAULT ? permissionDefaultTask : permissionTask).find(task => task.id === taskId)?.functions)
        : permission?.shipmentFunctions)
        
        setTypeDocumentTask(type);
        setDocumentsOfTask(documents);
        setViewAllDocuments(true);
        setTaskId(taskId);
    }

    const handleAssignTask = (taskId, taskName, parnters, roles) => {
        setIsDocument(true);
        setIsTemplate(false);
        doGetTaskPartners(taskId, () => {
            setAssignCollaborators(true);
        });
        // setAssignCollaborators(true);
        setTaskId(taskId);
        setTaskName(taskName);
        setTaskRoles(roles);

        doGetCollaborators(shipmentId, {
            page: 0,
            limit: 9999,
            role: roles.map(role => role.role)
        });

    }

    const handleDownloadDocs = (docs) => {
        const newDocs = [...docs];
        newDocs.forEach(doc => {
            setTimeout(() => {
                window.open(GET_FILE + doc.awsId);
            }, 1000)
        })

    }
    const checkRolesPartner = (role) => {
        let rolePartner = "";
        switch (role) {
            case EXPORT_CUSTOMS:
                rolePartner = "home.role.export-customs"
                break;
            case IMPORT_CUSTOMS:
                rolePartner = "home.role.import-customs"
                    break;
            case FREIGHT:
                rolePartner = "home.role.freight"
                break;
            case IMPORT_LOGISTICS:
                rolePartner = "home.role.import-logistics"
                break;
            case EXPORT_LOGISTICS:
                rolePartner = "home.role.export-logistics"
                break;
            case CONSIGNEE:
                rolePartner = "home.role.consignee"
                break;
            case SHIPMENT_ADMIN:
                rolePartner = "home.role.shipment-admin"
                break;
            default:
                rolePartner = "home.role.shipper"
                break;
        }
        return rolePartner;
    }
    const columns = [
        {
            dataIndex: '_id,taskName,documents,partners,type',
            render: (_id, taskName, documents, partners, type) => (
                <div className="tr__doc-title d-flex">
                    {type === DEFAULT && <i className="icon-star mrx2" />}
                    <div className="w-100">
                        <div className="mbx1 d-flex align-items-center justify-content-between">
                            <h4 className="has-tooltip" title={taskName}>{taskName}</h4>
                            <span>{documents.length} doc{documents.length > 1 ? 's' : ''}</span>
                        </div>
                        {/* <p className={`tr__link ${(partners.some(partner => partner?.collaboratorId === userInfo?._id) || type === DEFAULT) ? '' : 'disabled'}`} onClick={() => navigateViewDocuments(documents, _id)}>View all documents</p> */}
                        {/* {checkPermission(_id, (type === DEFAULT ? PERMISSIONS_LIST.DOCUMENT : ))} */}
                        <p className={`tr__link ${documents.length > 0 ? '' : 'disabled'}`} onClick={() => navigateViewDocuments(documents, _id, type, partners)}>{t("home.active-shipment.details.tabs.document-tab.view-all-documents")}</p>
                    </div>
                </div>
            )
        },
        {
            dataIndex: 'roles',
            render: (roles) => (
                <div className="column partner w-100 px2">
                    <div className="partners d-flex justify-content-between text-capitalize">
                        {roles.length > 0 && (
                            <>
                                {roles && roles.length > 0 && (
                                    <>
                                        {roles.slice(0, limit).map((role, roleIndex) => (
                                            <Tooltip
                                                // content={role.replace('_', ' ').toLowerCase()}
                                                content={role && role.role 
                                                    ? t(checkRolesPartner(role.role))
                                                    : role}
                                                key={roleIndex}
                                            >
                                                {role && role.role && role.role === EXPORT_CUSTOMS ? <i className={`icon-export`} /> : ''}
                                                {role && role.role && role.role === IMPORT_CUSTOMS ? <i className={`icon-import`} /> : ''}
                                                {role && role.role && role.role === FREIGHT ? <i className={`icon-truck`} /> : ''}
                                                {role && role.role && role.role === IMPORT_LOGISTICS ? <i className={`icon-box-import`} /> : ''}
                                                {role && role.role && role.role === EXPORT_LOGISTICS ? <i className={`icon-box-export`} /> : ''}
                                                {role && role.role && role.role === CONSIGNEE ? <i className={`icon-box`} /> : ''}
                                                {role && role.role && role.role === SHIPMENT_ADMIN ? <i className={`icon-admin`} /> : ''}
                                                <i className={`icon-${role && role.role ? role.role.toLowerCase()  : ''}`} />
                                            </Tooltip>
                                        ))}

                                        {limit < roles.length && (
                                            <Tooltip
                                                renderContent={() => (
                                                    <div className="tags d-flex flex-column">
                                                        {roles.slice(limit, roles.length).map((partner, partnerIndex) => (
                                                            <span key={partnerIndex}>{partner && partner.role && partner.role.replace('_', ' ').toLowerCase()}</span>
                                                        ))}
                                                    </div>
                                                )}
                                            >
                                                <span className="f-medium">+{roles.length - limit}</span>
                                            </Tooltip>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )
        },
        {
            dataIndex: 'documents',
            render: (documents) => (
                <div className="tr__doc-date">
                    {documents.length > 0 && (
                        <>
                            <p className="mbx1">{documents[documents.length - 1]?.uploadedBy?.name}</p>
                            <p className="date">{moment(documents[documents.length - 1]?.uploadedTime).format('DD MMM YYYY | hh:mm A')}</p>
                        </>
                    )
                    }
                </div>
            )
        },
        {
            dataIndex: '_id,partners,roles,taskName,type',
            render: (_id, partners, roles, taskName, type) => (
                <>
                    {type !== DEFAULT ? (
                        <Collaborators
                            members={partners}
                            skip={2}
                            label={t("template.my-template.detail.assign")}
                            onAdd={() => handleAssignTask(_id, taskName, partners, roles)}
                            isDocument={true}
                            noneButton={!checkPermission(_id, PERMISSIONS_LIST.TASK_ASSIGN_PARTNERS, type)}
                        />
                    ) : (<div></div>)
                    }
                </>
            )

        },
        {
            dataIndex: 'documents,partners,type,_id',
            render: (documents, partners, type, _id) => (
                <> 
                    {!isArchive && checkPermission(_id, (type === DEFAULT ? PERMISSIONS_LIST.DOCUMENT_DOWNLOAD_DEFAULT : PERMISSIONS_LIST.DOCUMENT_DOWNLOAD_TASK_DOCUMENT), type) && documents.length > 0 && (
                        <Button
                            type="secondary"
                            className="icon outline"
                            icon="icon-download"
                            onClick={() => handleDownloadDocs(documents)}
                            // disabled={( && !isArchive ? ((partners.some(partner => partner?.collaboratorId === userInfo?._id) || type === DEFAULT) ? false : true) : true)}
                        >{t("home.active-shipment.details.tabs.document-tab.download")}</Button>
                    )
                        
                    }
                    
                </>
            )
        }
    ];

    const handleUploadDocuments = (files) => {
        doUploadDocumentsTask()
    }

    return (
        <div className="tr__shipment-documents d-flex">
            <div className="tr__shipment-documents--table">
                <div className="tr__shipment-documents--list">
                    <DocumentHeader />
                    <Table
                        className="documents"
                        dataSource={documents}
                        columns={columns}
                    >
                    </Table>
                    
                </div>
                {/* {totalPages > currentPage + 1 && (
                        <div className="load-more-link mtx2 text-center">
                            <p className="tr__link" onClick={loadMoreDocuments}>
                                Load more documents...</p>
                        </div>
                    )} */}
            </div>
            
            {!isArchive && (
                <div className="tr__shipment-documents--uploader">
                    <UploadDocuments
                        title={t("home.active-shipment.details.tabs.document-tab.upload-documents")}
                        name="shipmentDocs"
                        mode="doc"
                        documents={permission?.shipmentFunctions.includes(PERMISSIONS_LIST.DOCUMENT_UPLOAD_DEFAULT && PERMISSIONS_LIST.DOCUMENT_UPLOAD_TASK_DOCUMENT) 
                            ? documents 
                            : documents.filter(document => (document.type === DEFAULT || permissionTask.some(task => task.id === document._id)))}
                        onFilesUpload={handleUploadDocuments}
                        errors={!!errors}
                        register={register}
                    />
                </div>
            )}
        </div>
    )
};
