import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Input,
    EditShipmentInfoDatepicker,
    EditShipmentInfoInput
} from 'components';
import moment from 'moment';
import { HomeContext } from 'contexts';
import { MARK_TRACK_SHIPMENT, SEA } from 'actions';
import { useTranslation } from 'react-i18next';

export const ShippingDetails = ({ 
    shippingDetails,
    register = () => {},
    watch = () => {},
    editShipmentInfo,
    isArchive = false,
    bookingRequest = {},
    tabTypeShipment,
    isLinkedShipment = false
}) => {
    const [t] = useTranslation();
    const [date,setDate] = useState('');
    const [etaDate,setEtaDate] = useState('');
    const [etdDate,setEtdDate] = useState('');
    const { markAsShipmentType } = useContext(HomeContext);
    

    useEffect(()=>{
        const from = bookingRequest && bookingRequest.linkedVesselSchedule && bookingRequest.linkedVesselSchedule.legs.length > 0 && bookingRequest.linkedVesselSchedule.legs[0].start;
        const to = bookingRequest && bookingRequest.linkedVesselSchedule && bookingRequest.linkedVesselSchedule.legs.length > 0 && bookingRequest.linkedVesselSchedule.legs[bookingRequest.linkedVesselSchedule.legs.length - 1].end;
        const bookingDate = shippingDetails && shippingDetails.bookingDate ? moment(+shippingDetails.bookingDate).format('DD MMM YYYY') : null;
        const eta = shippingDetails && shippingDetails.eta ? moment(+shippingDetails.eta).format('DD MMM YYYY') : null || (bookingRequest && from ? from?.date : null);
        const etd = shippingDetails && shippingDetails.etd ? moment(+shippingDetails.etd).format('DD MMM YYYY') : null || (bookingRequest && to ? to?.date : null);
        setEtaDate(eta);
        setEtdDate(etd);
        setDate(bookingDate);
    },[shippingDetails, bookingRequest])

    const getValueByKey = (key) => {
        return shippingDetails ? shippingDetails[key] : ''
    }

    const isMarkTrackShipment = () => markAsShipmentType === MARK_TRACK_SHIPMENT;
   
    return (
        <div className={`tr__verify-details-form--group mbx4  col-12 ${shippingDetails?.shipmentMode === SEA || tabTypeShipment === SEA || isLinkedShipment ? 'col-sm-3' : 'col-sm-4' }`}>
            <div className="tr__verify-details-form--group--content">
                <h3 className="h3 f-medium tr__info-block--label mtx2 mlx2 d-flex align-items-center"> 
                    {t("home.active-shipment.details.tabs.shipment-tab.shipping-details")}
                </h3>
                <div className="tr__verify-details-form--group--content-frame">
                    <div className="tr__verify-details-form--group__input">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <Input
                                    className="mtx2"
                                    name="shipmentMode"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.shipment-mode")}
                                    defaultValue={getValueByKey('shipmentMode')}
                                    viewOnly={true}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <Input
                                    className="mtx2"
                                    name="shipmentType"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.shipment-type")}
                                    defaultValue={getValueByKey('shipmentType')}
                                    viewOnly={true}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoDatepicker
                                    className="mtx2"
                                    name="bookingDate"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.booking-date")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.choose-a-date")}
                                    refs={register()}
                                    disabled={isArchive}
                                    value={date}
                                    onDatesChange={(date) => {setDate(date)}}
                                    originalvalue={shippingDetails && shippingDetails.bookingDate ? moment(shippingDetails.bookingDate).format('DD MMM YYYY') : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={moment(date).format('DD MMM YYYY') }
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="shipper"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.shipper")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    refs={register()}
                                    disabled={isArchive}
                                    defaultValue={getValueByKey('shipper')}
                                    originalvalue={getValueByKey('shipper')}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={watch('shipper',getValueByKey('shipper'))}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoDatepicker
                                    className="mtx2"
                                    name="eta"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.eta")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.choose-a-date")}
                                    refs={register()}
                                    value={etaDate}
                                    disabled={isArchive || isMarkTrackShipment()}
                                    onDatesChange={(date) => {setEtaDate(date)}}
                                    originalvalue={shippingDetails && shippingDetails.eta ? moment(+shippingDetails.eta).format('DD MMM YYYY') : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={moment(etaDate).format('DD MMM YYYY')}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoDatepicker
                                    className="mtx2"
                                    name="etd"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.etd")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.choose-a-date")}
                                    refs={register()}
                                    value={etdDate}
                                    disabled={isArchive || isMarkTrackShipment()}
                                    onDatesChange={(date) => {setEtdDate(date)}}
                                    originalvalue={shippingDetails && shippingDetails.etd ? moment(+shippingDetails.etd).format('DD MMM YYYY') : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={moment(etdDate).format('DD MMM YYYY')}
                                />
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

ShippingDetails.propTypes = {
    shippingDetails: PropTypes.object,
    editShipmentInfo: PropTypes.bool,
    register: PropTypes.func,
    watch: PropTypes.func
};
