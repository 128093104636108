import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export const InfoBlock = ({ className = '', title, children, highColumn = false }) => {
    return (
        <div className={`tr__info-block col-12 ${className}`}>
            <div className={`tr__info-block--content ${highColumn ? 'high-column' : ''} `}>
                {title && (
                    <h3 className="h3 f-medium tr__info-block--label mtx2 mlx2 d-flex align-items-center">
                        <span>{title}</span>
                    </h3>
                )}
                <div className="row">
                    {children}
                </div>
            </div>
        </div>
    )
};

InfoBlock.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string
}