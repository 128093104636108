import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import { ManagementContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import { ARCHIVE_URL, CANCELED } from 'actions';
import { useTranslation } from 'react-i18next';

export const ConfirmStatusShipment = ({
    open = false,
    onCancel,
    ...rest
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { statusShipment, doChangeStatusShipmentComplete, doChangeStatusShipmentCancel } = useContext(ManagementContext);
    const handleSubmit = () => {
        const payload = {
            status: statusShipment.status
        }
        if (statusShipment.status === CANCELED) {
            doChangeStatusShipmentCancel(statusShipment.shipmentId, payload, () => {
                history.push(ARCHIVE_URL);
            })
        } else {
            doChangeStatusShipmentComplete(statusShipment.shipmentId, payload, () => {
                history.push(ARCHIVE_URL);
            })
        }
       
        onCancel();
    }

    console.log(statusShipment, 'statusShipment');

    return (
        <Modal
            open={open}
            className="tr__delete-confirmation"
            onCancel={onCancel}
            title={statusShipment && statusShipment.status === CANCELED ? t("home.status-shipment.title-cancel") : t("home.status-shipment.title-complete")}
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel={t("home.status-shipment.yes")}
            onSubmitClick={handleSubmit}
            cancelButton={true}
            cancelButtonLabel={t("home.status-shipment.no")}
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
            {...rest}
        >
            <div className="text-center delete-message">
                <h6 className="h6 mtbx2 f-medium">
                    {statusShipment && statusShipment.status === CANCELED 
                        ? t("home.status-shipment.notification-cancelled") 
                        : t("home.status-shipment.notification-completed") 
                    }
                </h6>
            </div>
        </Modal>
    )
};

ConfirmStatusShipment.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
};