import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Modal,
    Dropdown,
    Datepicker,
    Button,
    MultiSelect
} from 'components';
import {
    shipmentTypes
} from 'utils';
import {
    BOOKING_REQUESTS_PAGE_SIZE,
    DESTINATION,
    EMPTY,
    LOCODE_PAGE_SIZE,
    MEMBER,
    ORIGIN,
    RECEIVED,
    TEAM
} from 'actions';
import { MemberContext, TeamContext, TrackShipmentContext } from 'contexts';
import {useTranslation} from "react-i18next";

export const RequestFilter = ({
    open = false,
    onCancel,
    searchParams,
    setSearchParams = () => {},
    onFilterApplied = () => {},
    onClearFilters = () => {},
    selectedTab
}) => {
    const { origins, destinations, doGetLocodes } = useContext(TrackShipmentContext);
    const { members, doGetMembers } = useContext(MemberContext);
    const { teams, doGetTeams } = useContext(TeamContext);

    const { t } = useTranslation();

    const [originLoading, setOriginLoading] = useState(false);
    const [destinationLoading, setDestinationLoading] = useState(false);
    const [dropdownList, setDropdownList] = useState([]);
    const [receiptDate, setReceiptDate] = useState();
    const [createdDate, setCreatedDate] = useState();
    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    const [type, setType] = useState();
    const [requester, setRequester] = useState([]);
    const [partner, setPartner] = useState([]);
    const [searchingText, setSearchingText] = useState();
    const [isPersonSearching, setIsPersonSearching] = useState(false);

    // const timeSpan = 86400000;

    /*eslint-disable */
    useEffect(() => {
        doGetLocodes({ limit: LOCODE_PAGE_SIZE }, null, false);
        doGetMembers({
            isSearching: true
        }, null, null, false);
        doGetTeams({
            isSearching: true
        }, null, null, false)
    }, [])
    
    useEffect(() => {
        if (members && teams) {
            setDropdownList([...members.map(mem => {
                return {
                    ...mem,
                    type: MEMBER
                }
            }), ...teams.map(team => {
                return {
                    ...team,
                    type: TEAM
                }
            })].sort((a, b) => a.name?.localeCompare(b.name)));
        }
    }, [members, teams]);

    useEffect(() => {
        if (selectedTab) {
            setIsPersonSearching(false);
            clearFilters(false);
        }
    }, [selectedTab])
    /*eslint-enable */

    const onSearchOrigin = value => {
        handlePortChange(ORIGIN, null);
        setOriginLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    };
  
    const onSearchDestination = value => {
        handlePortChange(DESTINATION, null);
        setDestinationLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    };

    const handlePortChange = (type, value) => {
        let newParams = null;
        switch(type) {
            case ORIGIN:
                if (value) {
                    newParams = {
                        ...searchParams,
                        loadingPort: value.key
                    }
                } else {
                    newParams = {
                        ...searchParams
                    };
                    delete newParams.loadingPort;
                    setSearchParams(newParams);
                }
                setFrom(value);
                break;
            case DESTINATION:
                if (value) {
                    newParams = {
                        ...searchParams,
                        dischargePort: value.key
                    }
                } else {
                    newParams = {
                        ...searchParams
                    };
                    delete newParams.dischargePort;
                    setSearchParams(newParams);
                }
                setTo(value);
                break;
            default:
                break;
        }

        setSearchParams(newParams);
    };

    const handleReceiptDatesChange = dates => {
        if (!dates) {
            const newParams = {...searchParams};
            delete newParams.dateOfReceiptFrom;
            delete newParams.dateOfReceiptTo;

            setSearchParams(newParams);
            return;
        }
        const [from, to] = dates;

        if (from && to) {
            const fromTime = new Date(from).getTime() - (new Date().getTimezoneOffset() * 60000);
            const toTime = new Date(to).getTime() - (new Date().getTimezoneOffset() * 60000);

            setReceiptDate(from);
            setSearchParams(old => {
                return {
                    ...old,
                    dateOfReceiptFrom: fromTime,
                    dateOfReceiptTo: toTime,
                }
            })
        } else {
            const newParams = {...searchParams};
            delete newParams.dateOfReceiptFrom;
            delete newParams.dateOfReceiptTo;

            setReceiptDate(null);
            setSearchParams(newParams);
        }
    };
    
    const handleCreatedDatesChange = dates => {
        if (!dates) {
            const newParams = {...searchParams};
            delete newParams.createdDateFrom;
            delete newParams.createdDateTo;

            setSearchParams(newParams);
            return;
        }
        const [from, to] = dates;
        if (from && to) {
            const fromTime = new Date(from).getTime() - (new Date().getTimezoneOffset() * 60000);
            const toTime = new Date(to).getTime() - (new Date().getTimezoneOffset() * 60000);

            setCreatedDate(from);
            setSearchParams(old => {
                return {
                    ...old,
                    createdDateFrom: fromTime,
                    createdDateTo: toTime,
                }
            })
        } else {
            const newParams = {...searchParams};
            delete newParams.createdDateFrom;
            delete newParams.createdDateTo;

            setCreatedDate(null);
            setSearchParams(newParams);
        }
    };

    const handleShipmentTypeChange = type => {
        setType(type);
        setSearchParams(old => {
            return {
                ...old,
                type: type.key
            }
        })
    };

    const handleSearchPartners = value => {
        setSearchingText(value);
        if (!value) setIsPersonSearching(false);
        doGetMembers({
            term: value,
            isSearching: true
        }, null, false);

        doGetTeams({
            term: value,
            isSearching: true
        }, null, false);
    };

    const handleRequesterChange = members => {
        setRequester(members);
        const newParams = {...searchParams};
        if (!members.length) {
            delete newParams.requesters;
            setSearchingText('');
            setIsPersonSearching(false);
        }
        else newParams.requesters = members[0].id
        setSearchParams(newParams);
    }

    const handlePartnerChange = members => {
        setPartner(members);
        const newParams = {...searchParams};
        if (!members.length) delete newParams.freightPartners
        else newParams.freightPartners = members[0].id
        setSearchParams(newParams);
    }

    const clearFilters = (isNewSearch = true) => {
        const newParams = {
            page: 0,
            limit: BOOKING_REQUESTS_PAGE_SIZE,
            isSearching: true
        };

        if (searchParams.query) {
            newParams.query = searchParams.query;
        }

        setFrom(null);
        setTo(null);
        setType(null);
        setCreatedDate(null);
        setReceiptDate(null);
        setPartner([]);
        setRequester([]);
        setSearchParams(newParams);

        if (isNewSearch) onClearFilters(newParams);
    }

    return (
        <Modal
            open={open}
            className="tr__request-filter"
            onCancel={onCancel}
            title={t('home.BookingRequest.header.filter')}
            type="panel"
        >
            <div className="tr__request-filter--group route mbx3 pbx3">
                <span className="route-icon">
                    <i className="icon-location" />
                    <i className="icon-location" />
                </span>
                <Dropdown
                    className="select"
                    icon="icon-chevron-down"
                    iconPosition="right"
                    mode="input"
                    name="origin"
                    label={t('home.BookingRequest.model.vesselRoute')}
                    horizontalLabel={t('home.BookingRequest.model.origin')}
                    searchLoading={originLoading}
                    onInputChange={onSearchOrigin}
                    value={from}
                    options={origins && origins.length > 0 ? origins.map(locode => {
                        return {
                            key: locode.locode,
                            value: `${locode.city}, ${locode.country} (${locode.locode})`,
                            item: locode
                        }
                    }) : []}
                    onChange={value => handlePortChange(ORIGIN, value)}
                    placeholder={t('home.BookingRequest.model.placeholderVR')}
                    dropdownPosition="center"
                />
                <Dropdown
                    className="select"
                    icon="icon-chevron-down"
                    iconPosition="right"
                    mode="input"
                    name="destination"
                    horizontalLabel={t('home.BookingRequest.model.destination')}
                    searchLoading={destinationLoading}
                    onInputChange={onSearchDestination}
                    value={to}
                    options={destinations && destinations.length > 0 ? destinations.map(locode => {
                        return {
                            key: locode.locode,
                            value: `${locode.city}, ${locode.country} (${locode.locode})`,
                            item: locode
                        }
                    }) : []}
                    onChange={value => handlePortChange(DESTINATION, value)}
                    placeholder={t('home.BookingRequest.model.placeholderVR')}
                    dropdownPosition="center"
                />
            </div>
            
            <div className="tr__request-filter--group mbx5">
                <Datepicker
                    name="dateOfReceipt"
                    className="mbx3"
                    label={t('home.BookingRequest.model.dateOfReceipt')}
                    placeholder="DD MMM YYYY"
                    start={searchParams && searchParams.dateOfReceiptFrom ? new Date(searchParams.dateOfReceiptFrom) : null}
                    end={searchParams && searchParams.dateOfReceiptFrom ? new Date(searchParams.dateOfReceiptTo) : null}
                    defaultValue={receiptDate ? receiptDate : EMPTY}
                    onDatesChange={handleReceiptDatesChange}
                    minDate={null}
                    range={true}
                />

                <Datepicker
                    name="createdDate"
                    className="mbx3"
                    label={t('home.BookingRequest.model.createdDate')}
                    placeholder="DD MMM YYYY"
                    start={searchParams && searchParams.createdDateFrom ? new Date(searchParams.createdDateFrom) : null}
                    end={searchParams && searchParams.createdDateTo ? new Date(searchParams.createdDateTo) : null}
                    defaultValue={createdDate ? createdDate : EMPTY}
                    onDatesChange={handleCreatedDatesChange}
                    minDate={null}
                    range={true}
                />

                <Dropdown
                    className="select mbx3"
                    icon="icon-chevron-down"
                    iconPosition="right"
                    mode="select"
                    name="shipmentType"
                    label={t('home.BookingRequest.model.type')}
                    value={type}
                    options={shipmentTypes.map(type => {
                        const { id, name} = type;
                        return {
                            key: id,
                            value: name
                        }
                    })}
                    onChange={handleShipmentTypeChange}
                    placeholder={t('home.BookingRequest.model.placeholderType')}
                    dropdownPosition="center"
                />
                {selectedTab === RECEIVED ? (
                    <MultiSelect
                        className="mbx4"
                        options={dropdownList.map(item => {
                            return {
                                id: item._id,
                                description: item.type === MEMBER ? item.name : (item.organization ? item.organization.name : ''),
                                type: item.type
                            }
                        })}
                        name="requester"
                        label={t('home.BookingRequest.model.requester')}
                        value={requester}
                        isSelectable={true}
                        onInputChange={handleSearchPartners}
                        errors={{ requester: isPersonSearching}}
                        errorMessage={isPersonSearching ? '0' : ''}
                        placeholder={t('home.BookingRequest.model.placeholderRequester')}
                        onChange={handleRequesterChange}
                        formState={{ submitCount: 2 }}
                        single={true}
                    />
                ) : (
                    <MultiSelect
                        className="mbx4"
                        options={dropdownList.map(item => {
                            return {
                                id: item._id,
                                description: item.type === MEMBER ? item.name : (item.organization ? item.organization.name : ''),
                                type: item.type
                            }
                        })}
                        name="partners"
                        label={t('home.BookingRequest.model.freightPartner')}
                        value={partner}
                        isSelectable={true}
                        onInputChange={handleSearchPartners}
                        errors={{ partners: isPersonSearching}}
                        errorMessage={isPersonSearching ? '0' : ''}
                        placeholder={t('home.BookingRequest.model.placeholderFP')}
                        onChange={handlePartnerChange}
                        formState={{ submitCount: 2 }}
                        single={true}
                    />
                )}
            </div>
           
            <div className="tr__request-filter--btns d-flex align-items-center mtx5">
                <Button
                    className="icon outline"
                    icon="icon-reload"
                    type="secondary"
                    onClick={clearFilters}
                >
                    {t('home.BookingRequest.model.btnClear')}
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        if (!searchingText || (searchingText && (partner.length > 0 || requester.length > 0))) {
                            setSearchingText(null);
                            setIsPersonSearching(false);
                            if (onFilterApplied) onFilterApplied();
                        } else {
                            if (searchingText) setIsPersonSearching(true);
                        }
                    }}
                >
                    {t('home.BookingRequest.model.btnApply')}
                </Button>
            </div>
        </Modal>
    )
};

RequestFilter.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func,
    onFilterApplied: PropTypes.func,
    onClearFilters: PropTypes.func
};
