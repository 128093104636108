import React from 'react';

import './index.scss';
import { SearchVesselForm, MapBox } from 'components';

export const VesselScheduleMap = () => {
    const vesselScheduleMapId = 'vessel-schedule-map';

    return (
        <div className="tr__vessel-schedule-map">
            <SearchVesselForm />
            <MapBox
                id={vesselScheduleMapId}
            />
            {/* <Map
                id={vesselScheduleMapId}
                pos1={[1.583758, 103.360898]}
                pos2={[6.064526, 100.338914]}
                pos3={[17.66686420888392, 83.23774434375001]}
                pos4={[14.616259, 79.910743]}
            /> */}
        </div>
    )
};