import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
    LANGUAGE
} from 'actions';

import enTranslation from 'locales/en/translation.json';
import viTranslation from 'locales/vi/translation.json';

const selectedLanguage = localStorage.getItem(LANGUAGE);

const resources = {
    en: {
        translation: enTranslation
    },
    vi: {
        translation: viTranslation
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: selectedLanguage || 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },
        react: {
            wait: true,
        }
    });

export default i18n;