import React from 'react';

import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import PropTypes from 'prop-types';

export const SectionInfo = ({ sections = [] }) => {

    return sections && sections.length > 0 ? sections.map((section, index) =>  
        <InfoBlock
            className="tr__details-box"
            title={section && section.sectionName ? section.sectionName : ''}
            key={index}
        >
            <InfoRow >
                {section.items && section.items.length > 0 && section.items.map((item, i) => 
                        <InfoCell
                            className="col-12 col-section"
                            label={item.key ? item.key : ''}
                            value={item.value ? item.value : ''}
                            key={i}
                        />
                    )
                }
    
            </InfoRow>
        </InfoBlock>
    ) : ''
};

SectionInfo.propTypes = {
    sections: PropTypes.array
};
