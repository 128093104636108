import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { CheckboxGroup } from 'components';
import { getErrorMessage, taskRoles, accessPermissions } from 'utils';

export const AddRole = ({ title, note, register = () => {}, roles, setRoles, errors = [], eventTask, isEditTask}) => {
    /*eslint-disable*/
    useEffect(() => {
        if (isEditTask && eventTask && eventTask.roles && eventTask.roles.length > 0) {
            let selectedTaskRoles = [];
            taskRoles.forEach(r => eventTask.roles.forEach(role => {
                if(typeof role === 'object' && role.role.toLowerCase().replace('_' , '-') === r.id){
                    selectedTaskRoles.push({...r, permission: accessPermissions.find(per => per.key === role.permission)})
                }
            }));
            setRoles(selectedTaskRoles.map(role => {
                return {
                    ...role,
                    disabled: true
                }
            }) );
        } else {
            setRoles([]);
        }
    }, [eventTask])
    /*eslint-enable*/

    const handleChangeRoles = roles => {
        setRoles(roles.map(role => role.permission ? role : {...role, permission: accessPermissions[2]}));
    }

    return(
        <div className="tr__add-role">
            {title && (
                <h4 className="h4 f-medium">{title}<span className="required-star">*</span></h4>
            )}
            <CheckboxGroup
                name="taskRoles"
                type="checkbox"
                horizontal
                items={taskRoles}
                values={roles}
                refs={register({ required: true })}
                error={!!errors.typeOfTask}
                errorMessage={getErrorMessage(errors.taskRoles, "task Roles")}
                onChange={roles => handleChangeRoles(roles)}
                buttonMode={true}
            />
        </div>
    )
};

AddRole.propTypes = {
    title: PropTypes.string,
    note: PropTypes.string
}