import React, { useContext } from 'react';
import './index.scss';
import {
    Modal,
    AssigneeList,
} from 'components';
import {
    BookingRequestContext
} from 'contexts';
import avatar from 'assets/images/default-avatar.png';
import {useTranslation} from "react-i18next";


export const CollaboratorsBk = ({ open = false, onCancel }) => {
    const { bookingRequest } = useContext(BookingRequestContext);

    const { t } = useTranslation();

    const onHideAddMemberModal = () => {
        onCancel();
    }

    return (
        <Modal
            open={open}
            className="tr__bk--collaborators"
            onCancel={onHideAddMemberModal}
            title={t('home.BookingRequest.collaboratorsBk.title')}
            footerLabel=""
            isBackDropClickable={false}
        >
             <AssigneeList
                // title="Individuals"
                assignees={bookingRequest?.memberInfo.map(mem => {
                    return {
                        id: mem.id,
                        avatar: mem.avatar ? mem.avatar : avatar,
                        name: mem.username,
                        email: mem.email
                    }
                })}
                characters={25}
            />
        </Modal>
    )
};
