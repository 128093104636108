import React, { useEffect, useState } from 'react';

import './index.scss';
import { Input, Checkbox } from 'components';
import { useTranslation } from 'react-i18next';

export const Reminder = ({ register = () => {}, isEditTask, eventTask, setReminders, reminders }) => {
    const { t } = useTranslation();
    const [toggle, setToggle] = useState(true);
    // const [reminders, setReminders] = useState([{
    //     key: 0,
    //     daysBeforeDueDate: 0,
    //     remindNotification: true,
    //     remindMail: true,
    // }]);

    /*eslint-disable*/
    useEffect(() => {
        // setDayAjust(defaultAjustDay);
        if(isEditTask && eventTask && eventTask.remindSchedule && eventTask.remindSchedule.length > 0){
            const reminderData =  eventTask.remindSchedule.map((item, index) => {
                return {
                    key: index,
                    ...item
                }
            });
            setToggle(eventTask && eventTask.reminderEnable)
            setReminders(reminderData);
        }
    }, [eventTask])
     /*eslint-enable*/
    
    const removeReminder = reminder => {
        setReminders(old => [...old.filter(o => o.key !== reminder.key)])
    }

    const addMoreReminder = () => {
        const newReminder = {
            key: reminders.reduce((max,reminder) => reminder.key >= max.key ? reminder : max).key + 1,
            daysBeforeDueDate: 0,
            remindNotification: true,
            remindMail: true,
        }
        setReminders(old => [...old,newReminder]);
    }

    const hanldeChangeReminder = (reminder, value, key) => {
        if (key === 'daysBeforeDueDate') {  
            reminder[key] = value < 0 ? 0 : value;
        } else {
            reminder[key] = value;
        }
        setReminders(old => [...old.map(o => o.key === reminder.key ? reminder : o)]);
    }

    return (
        <div className="tr__reminder">
            <h4 className="h4 f-medium">{t("home.active-shipment.Reminder.title")}</h4>
            <div className="tr__reminder--header mbx1">
                <Checkbox
                    name="reminderEnable"
                    type="checkbox"
                    checked={toggle}
                    onChange={() => setToggle(!toggle)}
                    className="toggle"
                    refs={register()}
                />
            </div>
            <div className={`row ${!toggle ? 'disabled' : ''}`}>
                {reminders.length > 0 && reminders.map(reminder => {
                    const reminderItems = `reminders[${reminder.key}]`;
                    return (
                        <div className="d-flex align-items-center justify-content-between mtx1" key={reminder.key}>
                            <div className="align-items-center d-flex">
                                { reminders.length > 1  && <i className="icon-times mrx2" onClick={() => removeReminder(reminder)} /> }
                                <Input
                                    type="number"
                                    placeholder="Add days"
                                    value={reminder.daysBeforeDueDate}
                                    onNumberChange={num => hanldeChangeReminder(reminder, num, 'daysBeforeDueDate')}
                                />
                                <input hidden name={`${reminderItems}.daysBeforeDueDate`} ref={register()} value={reminder.daysBeforeDueDate} />
                                <span className="mlx2">{t("home.active-shipment.Reminder.daysBeforeDueDate")}</span>
                            </div>
                            <Checkbox
                                name={`${reminderItems}.remindNotification`}
                                type="checkbox"
                                label={t("home.active-shipment.Reminder.remindNotification.label")}
                                refs={register()}
                                checked={reminder.remindNotification}
                                onChange={() => hanldeChangeReminder(reminder, !reminder.remindNotification, "remindNotification")}
                            />
                            <Checkbox
                                name={`${reminderItems}.remindMail`}
                                type="checkbox"
                                label="Email"
                                refs={register()}
                                checked={reminder.remindMail}
                                onChange={() => hanldeChangeReminder(reminder, !reminder.remindMail, "remindMail")}
                            />
                        </div>
                    )})
                }
                <div className="mtx2">
                    <p className="tr__link" onClick={addMoreReminder}>
                        <i className="icon-plus"></i>
                        <span>{t("home.active-shipment.Reminder.add_reminder")}</span>
                    </p>
                </div>
            </div>
        </div>
    )
};
