import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

import {
    EditShipmentInfoInput,
} from 'components';
import { useTranslation } from 'react-i18next';

export const EditTranshipmentInfo = ({ 
    register = () => {},  
    transhipmentInfo, 
    watch = () => {}, 
    editShipmentInfo, 
    transhipments, 
    setTranshipments,
    isArchive = false 
}) => {
    const [t] = useTranslation();
    const handleRemoveCard = (transhipment) => {
        setTranshipments(old => [...old.filter(o => o !== transhipment)])
    }
    return (
        <>
          {transhipments && transhipments.length > 0 && (<div className="tr__verify-details-form--group mbx4">
                <div className="tr__verify-details-form--group--content">
                    <h3 className="h3 f-medium tr__info-block--label mtx2 mlx2 d-flex align-items-center"> 
                        {t("home.active-shipment.details.tabs.shipment-tab.transhipment-information")}
                    </h3>
                    <div className="tr__verify-details-form--group--content-frame">
                        <div className="tr__verify-details-form--group__input">
                            {transhipments.map(transhipment => (
                                <React.Fragment key={transhipment}>
                                    <div className="mtx2 frame">
                                        <div className="tr__link remove-card" onClick={() => handleRemoveCard(transhipment)}>-</div>
                                        <div className="row card">
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`tsPort${transhipment}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.ts-port")}
                                                    placeholder="BN823592"
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    maxLength={50}
                                                    defaultValue={
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsPort
                                                        ? transhipmentInfo[transhipment].tsPort 
                                                        : ''
                                                    }
                                                    originalvalue={
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsPort
                                                        ? transhipmentInfo[transhipment].tsPort 
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`tsPort${transhipment}`,
                                                            transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsPort
                                                            ? transhipmentInfo[transhipment].tsPort 
                                                            : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`tsNextPort${transhipment}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.ts-next-port")}
                                                    placeholder="BN823592"
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    defaultValue={
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsNextPort
                                                        ? transhipmentInfo[transhipment].tsNextPort 
                                                        : ''
                                                    }
                                                    originalvalue={transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsNextPort
                                                        ? transhipmentInfo[transhipment].tsNextPort 
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`tsNextPort${transhipment}`,
                                                            transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsNextPort
                                                            ? transhipmentInfo[transhipment].tsNextPort 
                                                            : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`tsVessel${transhipment}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.ts-vessel")}
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    defaultValue={
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVessel
                                                        ? transhipmentInfo[transhipment].tsVessel 
                                                        : ''
                                                    }
                                                    originalvalue={transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVessel
                                                        ? transhipmentInfo[transhipment].tsVessel 
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`tsVessel${transhipment}`,
                                                            transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVessel
                                                            ? transhipmentInfo[transhipment].tsVessel 
                                                            : ''
                                                        )
                                                }   
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`tsVesselIMO${transhipment}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.ts-vessel-imo")}
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    defaultValue={
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVesselIMO 
                                                        ? transhipmentInfo[transhipment].tsVesselIMO
                                                        : ''
                                                    }
                                                    originalvalue={
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVesselIMO 
                                                        ? transhipmentInfo[transhipment].tsVesselIMO
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`tsVesselIMO${transhipment}`,
                                                            transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsVesselIMO 
                                                            ? transhipmentInfo[transhipment].tsVesselIMO
                                                            : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`tsETD${transhipment}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.ts-etd")}
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    defaultValue={
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETD 
                                                        ? transhipmentInfo[transhipment].tsETD 
                                                        : ''
                                                    }
                                                    originalvalue={
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETD 
                                                        ? transhipmentInfo[transhipment].tsETD
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`tsETD${transhipment}`,
                                                            transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETD 
                                                            ? transhipmentInfo[transhipment].tsETD : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`tsETA${transhipment}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.ts-eta")}
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    defaultValue={
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETA 
                                                        ? transhipmentInfo[transhipment].tsETA
                                                        : ''
                                                    }
                                                    originalvalue={
                                                        transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETA 
                                                        ? transhipmentInfo[transhipment].tsETA
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`tsETA${transhipment}`,
                                                            transhipmentInfo && transhipmentInfo[transhipment] && transhipmentInfo[transhipment].tsETA 
                                                            ? transhipmentInfo[transhipment].tsETA 
                                                            : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
        </div>)}
        </>
    )
};

EditTranshipmentInfo.propTypes = {
    register: PropTypes.func,
    transhipmentInfo: PropTypes.array,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool,
    transhipments: PropTypes.array, 
    setTranshipments: PropTypes.func,
};
