import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { Dropdown } from 'components';
import { accessPermissions, getErrorMessage } from 'utils';
import { AuthContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const AccessPermission = ({ title, roles, setRoles = () => {}, register = () => {}, errors = [], isEditTask, eventTask}) => {
    const { t } = useTranslation();
    const {userInfo} = useContext(AuthContext)
    const handleChangePermission = (id, value) => {
        setRoles(old => [...old.map(role => role.id === id ? {...role, permission: value} : role)]);
    }

    return(
        <div className="tr__access-permission">
            {title && (
                <h4 className="h4 f-medium">{title}</h4>
            )}
            <div className="list">
                <div className="tr__role d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center title" title={isEditTask && eventTask.creator ? eventTask.creator.name : userInfo.name}>
                        <i className="user mrx2" />
                        <span>{isEditTask && eventTask.creator ? eventTask.creator.name : userInfo.name}</span>
                    </span>
                    <Dropdown 
                        className="select"
                        mode="select"
                        defaultValue={t("home.active-shipment.AccessPermission.Manager")}
                        disabled={true}
                    />
                </div>
                {roles && roles.length > 0 ? roles.map(role => {
                        return (
                            <div className="tr__role d-flex align-items-center justify-content-between" key={role.id}>
                                <span className="d-flex align-items-center">
                                    <i className={`${role.icon} mrx2`} />
                                    <span>{t(role.name)}</span>
                                </span>
                                <Dropdown 
                                    className="select"
                                    icon="icon-chevron-down"
                                    iconPosition="right"
                                    mode="select"
                                    name={role.id}
                                    placeholder="Select permission"
                                    options={accessPermissions.map(type => {
                                        return {
                                            key: type.key,
                                            value: t(type.value)
                                        }
                                    })}
                                    refs={register({required: true})}
                                    error={errors}
                                    errorMessage={getErrorMessage(errors[role.id])}
                                    onChange={value => handleChangePermission(role.id, value)}
                                    defaultValue={t(role.permission.value)}
                                />
                            </div>
                        )
                    }) : ''
                }
            </div>
        </div>
    )
};

AccessPermission.propTypes = {
    title: PropTypes.string,
}