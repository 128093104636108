import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { Input, Dropdown,DeleteBlock } from 'components';
import { getErrorMessage } from 'utils';
import { ModalContext, OrganizationContext, TeamContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import { MEMBER } from 'actions';

export const TeamDetails = ({
    isModalForm = true,
    children,
    isEditable = false,
    onInputChange = () => {},
    searchLoading = false,
    register = () => {},
    errors = [],
    reset = () => {},
    isNew = true,
    formState
}) => {
    const [t] = useTranslation();
    const { team } = useContext(TeamContext);
    const { setDeleteTeam } = useContext(ModalContext);
    const { organization, organizations } = useContext(OrganizationContext);

    const [isEdit, setIsEdit] = useState(isEditable);

    /*eslint-disable */
    useEffect(() => {
        onInputChange();
    }, [])

    // No need because user can't change team organization
    /* useEffect(() => {
        if (isEdit) {
            doGetOrganizations({
                page: 0,
                limit: ORGANIZATION_PAGE_SIZE
            });
        }
    }, [isEdit]) */

    useEffect(() => {
        setIsEdit(isEditable);
    }, [isEditable])
    /*eslint-enable */

    return (
        <>
        <div className={`tr__team-details ${isEdit ? '' : 'view-only'}`}>
            <div className="tr__team-details__main-content">
                {!isModalForm &&  <div className="h2 f-medium titleCard d-flex align-items-center">
                    {children}
                </div> }
               
                <div className={`tr__team-details--card row`}>
                    <div className={`${isModalForm ? 'col-sm-6' : ''}`}>
                        <Input
                            required
                            label={t("team.teamName")}
                            name="teamName"
                            placeholder={t("team.placeholderName")}
                            viewOnly={!isEdit}
                            refs={register({ required: true })}
                            defaultValue={team && team.name}
                            error={!!errors.teamName}
                            errorMessage={getErrorMessage(errors.teamName, "Team name")}
                        />
                    </div>
                    <div className={`${isModalForm ? 'col-sm-6' : ''}`}>
                        {(isEdit || isNew) ? (
                            <Dropdown
                                icon="icon-chevron-down"
                                iconPosition="right"
                                mode='select'
                                viewOnly={!isEdit}
                                name="teamOrg"
                                // className="mbx3"
                                label={t("team.organization")}
                                placeholder={isEdit ? t("team.placeholderOrg") : ''}
                                dropdownPosition="center"
                                refs={register({ required: true })}
                                error={!!errors.teamOrg}
                                errorMessage={getErrorMessage(errors.teamOrg, "Organization")}
                                disabled={(!team && !!organization) || (isEdit && !isModalForm)}
                                defaultValue={team ? {
                                    key: team.organization ? team.organization._id : (organization ? organization._id : ''),
                                    value: team.organization ? team.organization.name : (organization ? organization.name : '')
                                } : (organization ? {
                                    key: organization._id,
                                    value: organization.name
                                } : {
                                    key: organizations[0] ? organizations[0]._id : '',
                                    value: organizations[0] ? organizations[0].name : ''
                                })}
                                onInputChange={onInputChange}
                                searchLoading={searchLoading}
                                options={organizations.filter(x => x.currentUser.roleName !== MEMBER.toUpperCase()).map(org => {
                                    return {
                                        key: org._id,
                                        value: org.name
                                    }
                                })}
                            />
                    ) : (
                             <Input
                                required
                                label={t("team.organization")}
                                name="teamOrg"
                                refs={register({ required: true })}
                                defaultValue={team ? {
                                            key: team.organization ? team.organization._id : (organization ? organization._id : ''),
                                            value: team.organization ? team.organization.name : (organization ? organization.name : '')
                                        } : (organization ? {
                                            key: organization._id,
                                            value: organization.name
                                        } : {
                                            key: organizations[0] ? organizations[0]._id : '',
                                            value: organizations[0] ? organizations[0].name : ''
                                        })}
                                        error={!!errors.teamOrg}
                                        errorMessage={getErrorMessage(errors.teamOrg, "Organization")}
                            /> 
                    )}
                    </div>
                    <div className="col-12">
                        <Input
                            required
                            label={t("team.description")}
                            placeholder={t("team.placeholderDesc")}
                            className="mbx3"
                            name="teamDescription"
                            viewOnly={!isEdit}
                            refs={register({ required: true })}
                            defaultValue={team && team.description}
                            error={!!errors.teamDescription}
                            errorMessage={getErrorMessage(errors.teamDescription, "Team description")}
                        />
                    </div>
                </div>
            </div>
            
            {!isModalForm && (
                <DeleteBlock
                    isEdit={isEdit}
                    buttonRightLabel={t("organization.edit")}
                    buttonLeftLabel={!team?.showButtonDelete ? "" : t("team.delete")}
                    onLeftBtnClick={() => setDeleteTeam(true)}
                    onCancel={() => reset()}
                    className="outline icon"
                    icon="icon-pencil"
                    typeBtn="secondary"
                    onRightBtnClick={() => setIsEdit(true)}
                    setIsEdit={edit => setIsEdit(edit)}
                />
            )}
        </div>
    </>
    )
};

TeamDetails.propTypes = {
    isModalForm: PropTypes.bool,
    isEditable: PropTypes.bool,
    onSubmit: PropTypes.func,
    register: PropTypes.any,
    clearErrors: PropTypes.any,
    errors: PropTypes.any,
    onInputChange: PropTypes.func,
    searchLoading: PropTypes.bool,
    reset: PropTypes.func,
    isNew: PropTypes.bool,
    formState: PropTypes.any
};
