import React from 'react';
import PropTypes from 'prop-types';

import {
    EditShipmentInfoInput,
} from 'components';
import { getErrorMessage } from 'utils';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/common';

export const EditContainers = ({ register, containerTracking, watch, editShipmentInfo, containers, setContainers, errors,  isArchive=false, bookingRequest= {} }) => {
    const [t] = useTranslation();
    const handleRemoveCard = (container) => {
        setContainers(old => [...old.filter(o => o !== container)])
    }
    return (
        <div className="tr__verify-details-form--group mbx4">
            <div className="tr__verify-details-form--group--content">
                <h3 className="h3 f-medium tr__info-block--label mtx2 mlx2 d-flex align-items-center"> 
                    {t("home.active-shipment.details.tabs.shipment-tab.container-tracking")}
                </h3>
                <div className="tr__verify-details-form--group--content-frame">
                    <div className="tr__verify-details-form--group__input">
                        <div className="mtx4">{t("home.active-shipment.details.tabs.shipment-tab.containers")}</div>
                            {containers.map(container => (
                                <React.Fragment key={container}>
                                    <div className="mtx2 frame">
                                        {container > 0 && <div className="tr__link remove-card" onClick={() => handleRemoveCard(container)}>-</div>}
                                        <div className="row card">
                                            <div className="col-12 col-sm-12">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`containerNumber${container}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.container-number")}
                                                    placeholder="BN823592"
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    maxLength={50}
                                                    defaultValue={
                                                        containerTracking && containerTracking.containers[container] 
                                                        ? containerTracking.containers[container].containerNumber 
                                                        : ""
                                                    }
                                                    originalvalue={
                                                        containerTracking && containerTracking.containers[container] 
                                                        ? containerTracking.containers[container].containerNumber 
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`containerNumber${container}`,
                                                            containerTracking && containerTracking.containers[container] 
                                                            ? containerTracking.containers[container].containerNumber 
                                                            : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`carrierSealNumber${container}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.carrier-seal-number")}
                                                    placeholder="BN823592"
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    defaultValue={
                                                        containerTracking && containerTracking.containers[container] 
                                                        ? containerTracking.containers[container].carrierSealNumber 
                                                        : ''
                                                    }
                                                    originalvalue={containerTracking && containerTracking.containers[container] 
                                                        ? containerTracking.containers[container].carrierSealNumber 
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`carrierSealNumber${container}`,
                                                            containerTracking && containerTracking.containers[container] 
                                                            ? containerTracking.containers[container].carrierSealNumber 
                                                            : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`containerType${container}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.container-type")}
                                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    defaultValue={
                                                        containerTracking && containerTracking.containers[container] 
                                                        ? containerTracking.containers[container].containerType 
                                                        : (bookingRequest && bookingRequest.containers && bookingRequest?.containers.length > 0 ? bookingRequest?.containers.map(container => container.typeOfContainer) : "")
                                                    }
                                                    originalvalue={containerTracking && containerTracking.containers[container] 
                                                        ? containerTracking.containers[container].containerType 
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`containerType${container}`,
                                                            containerTracking && containerTracking.containers[container] 
                                                            ? containerTracking.containers[container].containerType 
                                                            : ''
                                                        )
                                                }   
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`weightUnit${container}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.weight-unit")}
                                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    defaultValue={
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                        ? containerTracking.containers[container].weight.weightUnit 
                                                        : ""
                                                    }
                                                    originalvalue={
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                        ? containerTracking.containers[container].weight.weightUnit 
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`weightUnit${container}`,
                                                            containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                            ? containerTracking.containers[container].weight.weightUnit 
                                                            : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`weightValue${container}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.weight-value")}
                                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                    refs={register({ pattern: /^([0-9]*)(\.?[0-9]+)$/ })}
                                                    viewOnly={isArchive}
                                                    error={!!errors[`weightValue${container}`]}
                                                    errorMessage={getErrorMessage(errors[`weightValue${container}`], "Weight Value")}
                                                    defaultValue={
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                        ? containerTracking.containers[container].weight.value 
                                                        : ""
                                                    }
                                                    originalvalue={
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                        ? containerTracking.containers[container].weight.value 
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`weightValue${container}`,
                                                            containerTracking && containerTracking.containers[container] && containerTracking.containers[container].weight 
                                                            ? containerTracking.containers[container].weight.value : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`volumeUnit${container}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.volume-unit")}
                                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                    refs={register()}
                                                    viewOnly={isArchive}
                                                    defaultValue={
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].volume 
                                                        ? containerTracking.containers[container].volume.unit 
                                                        : ''
                                                    }
                                                    originalvalue={
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].volume 
                                                        ? containerTracking.containers[container].volume.unit 
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`volumeUnit${container}`,
                                                            containerTracking && containerTracking.containers[container] && containerTracking.containers[container].volume 
                                                            ? containerTracking.containers[container].volume.unit 
                                                            : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <EditShipmentInfoInput
                                                    className="mtx2"
                                                    name={`volumeValue${container}`}
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.volume-value")}
                                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                    refs={register({ pattern: /^[0-9]+$/ })}
                                                    viewOnly={isArchive}
                                                    error={!!errors[`volumeValue${container}`]}
                                                    errorMessage={getErrorMessage(errors[`volumeValue${container}`], "Volume Value")}
                                                    defaultValue={
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].volume 
                                                        ? containerTracking.containers[container].volume.value 
                                                        : (bookingRequest ? bookingRequest?.totalOfVolume : "")
                                                    }
                                                    originalvalue={
                                                        containerTracking && containerTracking.containers[container] && containerTracking.containers[container].volume 
                                                        ? containerTracking.containers[container].volume.value 
                                                        : ''
                                                    }
                                                    tooltipOldlvalue={editShipmentInfo}
                                                    newValue={
                                                        watch(`volumeValue${container}`,
                                                            containerTracking && containerTracking.containers[container] && containerTracking.containers[container].volume 
                                                            ? containerTracking.containers[container].volume.value 
                                                            : ''
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))}
                            {!isArchive && <div className="h4 f-medium add-container mtx4 mbx4 align-items-center">
                                <Button
                                onClick={() => setContainers(old => [...old, old.length])}
                                className="outline"
                                >
                                <i className="icon-plus mrx1"/>
                                <span>{t("home.active-shipment.details.tabs.shipment-tab.add-new-container")}</span>
                                </Button>

                        </div>}
                        <div className="">
                            <h3 className="h3 f-medium tr__info-block--label mtx2 mlx2 d-flex align-items-center"> 
                                {t("home.active-shipment.details.tabs.shipment-tab.container-summary")}
                            </h3>
                            <div className="tr__verify-details-form--group--content-frame">
                                <div className="mtx2 frame">
                                    <div className="row card">
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="measurement"
                                                label={t("home.active-shipment.details.tabs.shipment-tab.measurement")}
                                                placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                refs={register({ pattern: /^([0-9]*)(\.?[0-9]+)$/ })}
                                                error={!!errors.measurement}
                                                errorMessage={getErrorMessage(errors.measurement, "Measurement")}
                                                defaultValue={containerTracking && containerTracking.measurement ? containerTracking.measurement : ''}
                                                originalvalue={containerTracking && containerTracking.measurement ? containerTracking.measurement : ''}
                                                viewOnly={isArchive}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={watch('measurement',containerTracking && containerTracking.measurement ? containerTracking.measurement : '')}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="containerYard"
                                                label={t("home.active-shipment.details.tabs.shipment-tab.container-yard")}
                                                placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                refs={register()}
                                                viewOnly={isArchive}
                                                defaultValue={containerTracking && containerTracking.containerYard ? containerTracking.containerYard : ''}
                                                originalvalue={containerTracking && containerTracking.containerYard ? containerTracking.containerYard : ''}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={watch('containerYard',containerTracking && containerTracking.containerYard ? containerTracking.containerYard : '')}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="commodityDescription"
                                                label={t("home.active-shipment.details.tabs.shipment-tab.commodity-description")}
                                                placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                refs={register()}
                                                viewOnly={isArchive}
                                                defaultValue={containerTracking && containerTracking.commodityDescription ? containerTracking.commodityDescription : ''}
                                                originalvalue={containerTracking && containerTracking.commodityDescription ? containerTracking.commodityDescription : ''}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={watch('commodityDescription',
                                                containerTracking && containerTracking.commodityDescription ? containerTracking.commodityDescription : '')}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="serviceType"
                                                label={t("home.active-shipment.details.tabs.shipment-tab.service-type")}
                                                placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                refs={register()}
                                                viewOnly={isArchive}
                                                defaultValue={containerTracking && containerTracking.serviceType ? containerTracking.serviceType : ''}
                                                originalvalue={containerTracking && containerTracking.serviceType ? containerTracking.serviceType : ''}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={watch('serviceType',containerTracking && containerTracking.serviceType ? containerTracking.serviceType : '')}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="grossWeightUnit"
                                                label={t("home.active-shipment.details.tabs.shipment-tab.gross-weight-unit")}
                                                placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                refs={register()}
                                                viewOnly={isArchive}
                                                defaultValue={containerTracking && containerTracking.grossWeight ? containerTracking.grossWeight.weightUnit : (bookingRequest ? bookingRequest.totalWeight && bookingRequest.totalWeight.weightUnit : '')}
                                                originalvalue={containerTracking && containerTracking.grossWeight ? containerTracking.grossWeight.weightUnit : ''}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch('grossWeightUnit',
                                                        containerTracking && containerTracking.grossWeight 
                                                        ? containerTracking.grossWeight.weightUnit 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="grossWeightValue"
                                                label={t("home.active-shipment.details.tabs.shipment-tab.gross-weight-value")}
                                                placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                refs={register({ pattern: /^([0-9]*)(\.?[0-9]+)$/ })}
                                                viewOnly={isArchive}
                                                error={!!errors.grossWeightValue}
                                                errorMessage={getErrorMessage(errors.grossWeightValue, "Gross Weight Value")}
                                                defaultValue={containerTracking && containerTracking.grossWeight ? containerTracking.grossWeight.value : (bookingRequest ? bookingRequest.totalWeight && bookingRequest.totalWeight.value : '')}
                                                originalvalue={containerTracking && containerTracking.grossWeight ? containerTracking.grossWeight.value : ''}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch('grossWeightValue',
                                                        containerTracking && containerTracking.grossWeight 
                                                        ? containerTracking.grossWeight.value 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="hsCode"
                                                label={t("home.active-shipment.details.tabs.shipment-tab.hs-code")}
                                                placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                refs={register()}
                                                viewOnly={isArchive}
                                                defaultValue={containerTracking && containerTracking.hsCode ? containerTracking.hsCode : ''}
                                                originalvalue={containerTracking && containerTracking.hsCode ? containerTracking.hsCode : ''}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={watch('hsCode',containerTracking && containerTracking.hsCode ? containerTracking.hsCode : '')}
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <EditShipmentInfoInput
                                                className="mtx2"
                                                name="numberOfContainers"
                                                label={t("home.active-shipment.details.tabs.shipment-tab.number-of-containers")}
                                                placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                                refs={register()}
                                                viewOnly={isArchive}
                                                error={!!errors.numberOfContainers}
                                                errorMessage={getErrorMessage(errors.numberOfContainers, "Number of containers")}
                                                defaultValue={containerTracking && containerTracking.numberOfContainers ? containerTracking.numberOfContainers : (bookingRequest && bookingRequest.containers && bookingRequest?.containers.length > 0 ? bookingRequest?.containers.map(container => container.numberOfContainer) : "")}
                                                originalvalue={containerTracking && containerTracking.numberOfContainers ? containerTracking.numberOfContainers : ''}
                                                tooltipOldlvalue={editShipmentInfo}
                                                newValue={
                                                    watch('numberOfContainers',
                                                        containerTracking && containerTracking.numberOfContainers 
                                                        ? containerTracking.numberOfContainers 
                                                        : ''
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

EditContainers.propTypes = {
    register: PropTypes.func,
    containerTracking: PropTypes.object,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool,
    containers: PropTypes.array, 
    setContainers: PropTypes.func,
    errors: PropTypes.any,
    bookingRequest: PropTypes.object
};
