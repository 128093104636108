import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import { useTranslation } from 'react-i18next';
import { AIR } from 'actions';

export const AISTracking = ({ aisTracking = [], shippingDetails = {}, aisAirTracking = [] }) => {
    const [t] = useTranslation();
    const [aisTrackings,setAisTrackings] = useState([0]);

    /*eslint-disable */
    useEffect(() => {
        if (aisTracking && aisTracking.length > 0) setAisTrackings(aisTracking)
        else if (aisAirTracking && aisAirTracking.length > 0) {
            setAisTrackings(aisAirTracking);
        }
    }, [aisTracking, aisAirTracking])
    /*eslint-enable */
    
    const getLocationValue = location => {
        if (location) {
            const { name, country, locode } = location;
            return name + (country ? `, ${country}` : '') + (locode ? ` (${locode})` : '');
        }
        return '';
    }

    return <InfoBlock
        className="tr__details-box"
        title={t("home.active-shipment.details.tabs.shipment-tab.ship-tracking")}
    >
        <InfoRow>
            {aisTrackings.length > 0 ? aisTrackings.map((vessel, vesselIndex) => (
                <div className={`${vesselIndex > 0 ? 'mtx3' : ''}`} key={vesselIndex}>
                    <div className="col-12">
                        <div className='row'>
                            {shippingDetails?.shipmentMode === AIR && <InfoCell
                                className="col-sm-6"
                                label={"Airline name"}
                                value={vessel?.airline?.name}
                            />}
                            <InfoCell
                                className="col-sm-6"
                                label={t("home.active-shipment.details.tabs.shipment-tab.vessel-name")}
                                value={vessel.name}
                            />
                            {shippingDetails?.shipmentMode !== AIR && <InfoCell
                                className="col-sm-6"
                                label={t("home.active-shipment.details.tabs.shipment-tab.imo")}
                                value={vessel.imo ? `${vessel.imo}` : ''}
                            />}
                        </div>
                    </div>
                    {shippingDetails?.shipmentMode !== AIR && <>
                        <div className="col-12 mtx2">
                            <div className='row'>
                                <InfoCell
                                    className="col-sm-6"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.voyage")}
                                    value={vessel.voyage ? `${vessel.voyage}` : ''}
                                />
                                <InfoCell
                                    className="col-sm-6"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.departure")}
                                    value={getLocationValue(vessel.departure)}
                                />
                            </div>
                        </div>
                        <div className="col-12 mtx2">
                            <div className='row'>
                                <InfoCell
                                    className="col-sm-6"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.arrival")}
                                    value={getLocationValue(vessel.arrival)}
                                />
                            </div>
                        </div>
                    </>}
                    {shippingDetails?.shipmentMode === AIR && <>
                        <div className="col-12 mtx2">
                            <div className='row'>
                                <InfoCell
                                    className="col-sm-6"
                                    label={"Origin Airports"}
                                    value={vessel?.originAirport ? `${vessel?.originAirport?.city}, ${vessel?.originAirport?.country} (${vessel?.originAirport?.countryCode}) ` : ''}
                                />
                                <InfoCell
                                    className="col-sm-6"
                                    label={"Destination Airports"}
                                    value={vessel?.destinationAirport ? `${vessel?.destinationAirport?.airportName}, ${vessel?.destinationAirport?.country} (${vessel?.destinationAirport?.countryCode}) ` : ''}
                                />
                            </div>
                        </div>
                        <div className="col-12 mtx2">
                            <div className='row'>
                                <InfoCell
                                    className="col-sm-6"
                                    label={"Master airway bill"}
                                    value={vessel?.masterAirwayBill ? `${vessel?.masterAirwayBill}` : ''}
                                />
                                <InfoCell
                                    className="col-sm-6"
                                    label={"House airway bill"}
                                    value={vessel?.houseAirwayBill ? vessel?.houseAirwayBill.join(', ') : ''}
                                />
                            </div>
                        </div>
                    </>}
                   
                </div>
            )) : ''}
        </InfoRow>
    </InfoBlock> 
};

AISTracking.propTypes = {
    aisTracking: PropTypes.array
};