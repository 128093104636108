import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';

import './index.scss';
import {
    AddressDetails,
    EditAISTracking,
    EditBookingDetails,
    EditContainers,
    EditInvoice,
    ShippingDetails,
    EditCutOff,
    EditContactDetails,
    EditTranshipmentInfo,
    EditSectionInfo
} from 'components';
import { HomeContext, ModalContext, ExtractedContext, DocumentsContext } from 'contexts';
import { MARK_TRACK_SHIPMENT, SEA } from 'actions';
import { isEmpty } from 'lodash';
import { convertStringtoDDMMYYY } from 'utils';
import { useTranslation } from 'react-i18next';

export const VerifyDetailsForm = ({
    register = () => { },
    errors = [],
    getValues = () => { },
    shipmentDetail,
    vessels = [],
    setVessels = () => { },
    watch = () => { },
    setShipmentDetail = () => { },
    containers = [],
    setContainers = () => { },
    transhipmentInfos = [],
    setTranshipmentInfos = () => { },
    setError= () => { },
    clearErrors=() => {},
    setDepartureLocation=() =>{},
    setArrivalLocation=() =>{},
    departureLocation,
    arrivalLocation,
    bookingRequest = {},
    selectedShipmentType = '',
    documents = [],
    tabTypeShipment,
    typeOfShipment,
    isLinkedShipment = false
}) => {
    const [t] = useTranslation();
    const { markAsShipmentType, trackerTemplate, setFileId } = useContext(HomeContext);
    const { setViewExtractedInfo, viewExtractedInfo } = useContext(ModalContext);
    const { ocrData, setOcrData } = useContext(ExtractedContext);
    const { setDocumentPermission } = useContext(DocumentsContext);

    /*eslint-disable */
    useEffect(() => {
        if (!viewExtractedInfo && !isEmpty(ocrData)) {
            // TODO: pre-fill data from ocrData - need to trigger all input after mapping ocrData to shipmentDetail

            if(ocrData.Shipper){
                shipmentDetail.shippingDetails = {
                    ...shipmentDetail.shippingDetails,
                    shipper: ocrData.Shipper
                }
            }
            if(ocrData.ETD){
                shipmentDetail.shippingDetails = {
                    ...shipmentDetail.shippingDetails,
                    etd: new Date(convertStringtoDDMMYYY(ocrData.ETD)).getTime()
                }
            }
            if(ocrData.ETA){
                shipmentDetail.shippingDetails = {
                    ...shipmentDetail.shippingDetails,
                    eta: new Date(convertStringtoDDMMYYY(ocrData.ETA)).getTime()
                }
            }
            if(ocrData.BookingDate){
                shipmentDetail.shippingDetails = {
                    ...shipmentDetail.shippingDetails,
                    bookingDate: new Date(convertStringtoDDMMYYY(ocrData.BookingDate)).getTime()
                }
            }
            if(ocrData.CarrierName){
                shipmentDetail.bookingDetail = {
                    ...shipmentDetail.bookingDetail,
                    carrierName: ocrData.CarrierName,
                    scacCode: ''
                }
            }

            if(ocrData.Time){
                shipmentDetail.cutOff = {
                    ...shipmentDetail.cutOff,
                    time: ocrData.Time
                }
            }
            if(ocrData.SISubmission){
                shipmentDetail.cutOff = {
                    ...shipmentDetail.cutOff,
                    siSubmission: ocrData.SISubmission
                }
            }
            if(ocrData.Date){
                shipmentDetail.cutOff = {
                    ...shipmentDetail.cutOff,
                    date: new Date(convertStringtoDDMMYYY(ocrData.Date)).getTime()
                }
            }
            if(ocrData.VGMSubmissionTimeline){
                shipmentDetail.cutOff = {
                    ...shipmentDetail.cutOff,
                    vgmSubmissionTimeline: ocrData.VGMSubmissionTimeline
                }
            }

            if(ocrData.ShipperAddress){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    shipperAddress: ocrData.ShipperAddress
                }
            }
            if(ocrData.ShipperContact){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    shipperContact: ocrData.ShipperContact
                }
            }
            if(ocrData.ShipperFax){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    shipperFax: ocrData.ShipperFax
                }
            }
            if(ocrData.Consignee){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    consignee: ocrData.Consignee
                }
            }
            if(ocrData.ConsigneeAddress){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    consigneeAddress: ocrData.ConsigneeAddress
                }
            }
            if(ocrData.ConsigneeContact){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    consigneeContact: ocrData.ConsigneeContact
                }
            }
            if(ocrData.ConsigneeFax){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    consigneeFax: ocrData.ConsigneeFax
                }
            }
            if(ocrData.NotifyParty){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    notifyParty: ocrData.NotifyParty
                }
            }
            if(ocrData.NotifyPartyAddress){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    notifyPartyAddress: ocrData.NotifyPartyAddress
                }
            }
            if(ocrData.NotifyContact){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    notifyContact: ocrData.NotifyContact
                }
            }
            if(ocrData.NotifyFax){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    notifyFax: ocrData.NotifyFax
                }
            }
            if(ocrData.ServiceContractNumber){
                shipmentDetail.contactDetail = {
                    ...shipmentDetail.contactDetail,
                    serviceContractNumber: ocrData.ServiceContractNumber
                }
            }

            let tranInfo = {};
            if(ocrData.TSPort){
                tranInfo = {
                    ...tranInfo,
                    tsPort: ocrData.TSPort
                }
            }
            if(ocrData.TSNextPort){
                tranInfo = {
                    ...tranInfo,
                    tsNextPort: ocrData.TSNextPort
                }
            }
            if(ocrData.TSVessel){
                tranInfo = {
                    ...tranInfo,
                    tsVessel: ocrData.TSVessel
                }
            }
            if(ocrData.TSVesselIMO){
                tranInfo = {
                    ...tranInfo,
                    tsVesselIMO: ocrData.TSVesselIMO
                }
            }
            if(ocrData.TSETD){
                tranInfo = {
                    ...tranInfo,
                    tsETD: ocrData.TSETD
                }
            }
            if(ocrData.TSETA){
                tranInfo = {
                    ...tranInfo,
                    tsETA: ocrData.TSETA
                }
            }
            if(!isEmpty(tranInfo)){
                shipmentDetail.transhipmentInfo = [tranInfo];
            }

            if(ocrData.Measurement){
                shipmentDetail.containerTracking = {
                    ...shipmentDetail.containerTracking,
                    measurement: ocrData.Measurement
                }
            }
            if(shipmentDetail.ContainerYard ){
                data.containerTracking = {
                    ...shipmentDetail.containerTracking,
                    containerYard: ocrData.ContainerYard
                }
            }
            if(ocrData.CommodityDescription){
                shipmentDetail.containerTracking = {
                    ...shipmentDetail.containerTracking,
                    commodityDescription: ocrData.CommodityDescription
                }
            }
            if(ocrData.NumberOfContainers){
                shipmentDetail.containerTracking = {
                    ...shipmentDetail.containerTracking,
                    numberOfContainers: ocrData.NumberOfContainers
                }
            }
            if(ocrData.ServiceType){
                shipmentDetail.containerTracking = {
                    ...shipmentDetail.containerTracking,
                    serviceType: ocrData.ServiceType
                }
            }
            if(ocrData.HSCode){
                shipmentDetail.containerTracking = {
                    ...shipmentDetail.containerTracking,
                    hsCode: ocrData.HSCode
                }
            }
            if(ocrData.GrossWeightUnit){
                shipmentDetail.containerTracking = {
                    ...shipmentDetail.containerTracking,
                    grossWeight:{
                        ...shipmentDetail.containerTracking.grossWeight,
                        weightUnit: ocrData.GrossWeightUnit
                    } 
                }
            }
            if(ocrData.GrossWeightValue){
                shipmentDetail.containerTracking = {
                    ...shipmentDetail.containerTracking,
                    grossWeight:{
                        ...shipmentDetail.containerTracking.grossWeight,
                        value: ocrData.GrossWeightValue
                    } 
                }
            }

            let tracking = {};
            if(ocrData.VesselName){
                tracking = {
                    ...tracking,
                    name: ocrData.VesselName
                }
            } 
            if(ocrData.VesselName){
                tracking = {
                    ...tracking,
                    imo: ocrData.IMO
                }
            } 
            if(ocrData.VesselName){
                tracking = {
                    ...tracking,
                    voyage: ocrData.Voyage
                }
            }
            if(ocrData.Arrival){
                tracking = {
                    ...tracking,
                    arrival: {name : ocrData.Arrival}
                }
            } 
            if(ocrData.Departure){
                tracking = {
                    ...tracking,
                    departure: {name : ocrData.Departure}
                }
            }
            if(!isEmpty(tracking)){
                shipmentDetail.vessels = [tracking];
            }

            if(ocrData.InvoiceNumber){
                shipmentDetail.invoices = ocrData.InvoiceNumber;
            }

            let container = {};
            if(ocrData.ContainerNumber){
                container = {
                    ...container,
                    containerNumber: ocrData.ContainerNumber
                }
            }
            if(ocrData.ContainerType){
                container = {
                    ...container,
                    containerType: ocrData.ContainerType
                }
            } 
            if(ocrData.CarrierSealNumber){
                container = {
                    ...container,
                    carrierSealNumber: ocrData.CarrierSealNumber
                }
            }
            if(ocrData.WeightUnit){
                container = {
                    ...container,
                    weight:{
                        ...container.weight,
                        weightUnit: ocrData.WeightUnit
                    } 
                }
            }
            if(ocrData.WeightValue){
                container = {
                    ...container,
                    weight:{
                        ...container.weight,
                        value: ocrData.WeightValue
                    } 
                }
            }
            if(ocrData.VolumeUnit){
                container = {
                    ...container,
                    volume:{
                        ...container.volume,
                        unit: ocrData.VolumeUnit
                    } 
                }
            }
            if(ocrData.VolumeValue){
                container = {
                    ...container,
                    volume:{
                        ...container.volume,
                        value: ocrData.VolumeValue
                    } 
                }
            }
            shipmentDetail.containerTracking.containers = [];
            if(!isEmpty(container)){
                shipmentDetail.containerTracking = {
                    ...shipmentDetail.containerTracking,
                    containers: [container]
                };
            }

            shipmentDetail.bookingDetail = {
                ...shipmentDetail.bookingDetail,
                ...{
                    confirmationNo: [],
                    masterBoL: [],
                    houseBoL: []
                }
            };

            if (ocrData.BookingNumber) {
                switch (shipmentDetail.documents[0].typeOfDocument) {
                    case 'BC':
                        shipmentDetail.bookingDetail.confirmationNo = [ocrData.BookingNumber];
                        break;
                    case 'MBL':
                        shipmentDetail.bookingDetail.masterBoL = [ocrData.BookingNumber];
                        break;
                    case 'HBL':
                        shipmentDetail.bookingDetail.houseBoL = [ocrData.BookingNumber];
                        break;

                    default:
                        break;
                }
            }
            shipmentDetail.addedInfoSections = ocrData.newSections && ocrData.newSections.length > 0 ? ocrData.newSections : [];
            setOcrData({});
        }
    }, [ocrData, viewExtractedInfo])
    
    useEffect(() =>{
        if(markAsShipmentType === MARK_TRACK_SHIPMENT){
            const { pol, pod } = trackerTemplate;
            let data = {...shipmentDetail};
            data.shippingDetails = {
                ...data.shippingDetails,
                eta: new Date(pod.date).getTime(),
                etd: new Date(pol.date).getTime()
            }
            setShipmentDetail(data);
        }
    },[markAsShipmentType])

    useEffect(() =>{
        if(bookingRequest) {
            let vessel = []
            let shipmentData = {...shipmentDetail};
            bookingRequest?.linkedVesselSchedule?.legs.map(dt => {
                vessel.push({
                    name: dt?.vessel?.name,
                    voyage: dt?.voyage, 
                    departure: {
                        name: dt?.start?.port?.name,
                        country: dt?.start?.port?.name,
                        locode: dt?.start?.port?.code 
                    },
                    arrival: {
                        name: dt?.end?.port?.name,
                        country: dt?.end?.port?.name,
                        locode: dt?.end?.port?.code 
                    } })
            })
            shipmentData.vessels = vessel;
            setShipmentDetail(shipmentData);
            if(vessel.length > 0)
                setVessels([...Array(vessel.length).keys()])
        }
    },[])
    /*eslint-enable */

    const handleViewExtracted = () => {
        if (shipmentDetail && shipmentDetail.documents && shipmentDetail.documents.length) {
            setFileId(shipmentDetail.documents[0].awsId);
            setViewExtractedInfo(true);
            setDocumentPermission(true);
        }
    }

    return (
        <div className="tr__verify-details-form mbx2">
            <div className="tr__verify-details-form--note d-flex flex-column align-items-center justify-content-center">
                <Button
                    disabled={documents && documents.length > 0 ? false : true}
                    className="outline f-medium mtx2"
                    type="secondary"
                    onClick={handleViewExtracted}
                >
                   {t("home.active-shipment.view-all-extracted")}
                </Button>
            </div>
            <div className="row">
                {(tabTypeShipment === SEA || typeOfShipment?.key === SEA || isLinkedShipment ) && <EditBookingDetails
                    register={register}
                    bookingDetails={shipmentDetail ? shipmentDetail.bookingDetail : null}
                    editShipmentInfo={markAsShipmentType === MARK_TRACK_SHIPMENT && !!trackerTemplate}
                    watch={watch}
                    bookingRequest={bookingRequest}
                    shippingDetails={shipmentDetail ? shipmentDetail.shippingDetails : null}
                />}
                <ShippingDetails
                    register={register}
                    shippingDetails={shipmentDetail ? shipmentDetail.shippingDetails : null}
                    bookingRequest={bookingRequest}
                    tabTypeShipment={tabTypeShipment}
                    typeOfShipment={typeOfShipment}
                    isLinkedShipment={isLinkedShipment}
                />
              
                <EditCutOff cutOff={shipmentDetail && shipmentDetail.cutOff ? shipmentDetail.cutOff : {}} register={register} selectedShipmentType={selectedShipmentType}  tabTypeShipment={tabTypeShipment}  isLinkedShipment={isLinkedShipment}/>
                <EditInvoice
                    register={register}
                    errors={errors}
                    tabTypeShipment={tabTypeShipment}
                    isLinkedShipment={isLinkedShipment}
                />
            </div>
            <div className="row">
                <div className="col-8">
                    <EditAISTracking
                    register={register}
                    vessels={vessels}
                    setVessels={setVessels}
                    errors={errors}
                    getValues={getValues}
                    trackings={shipmentDetail && shipmentDetail.vessels ?shipmentDetail.vessels : []}
                    editShipmentInfo={markAsShipmentType === MARK_TRACK_SHIPMENT && !!trackerTemplate}
                    watch={watch}
                    setError={setError}
                    clearErrors={clearErrors}
                    setDepartureLocation={setDepartureLocation}
                    departureLocation={departureLocation}
                    setArrivalLocation={setArrivalLocation}
                    arrivalLocation={arrivalLocation}
                    bookingRequest={bookingRequest}
                    setTranshipments={setTranshipmentInfos}
                    tabTypeShipment={tabTypeShipment}
                    typeOfShipment={typeOfShipment}
                    routes={shipmentDetail ? shipmentDetail.airRoutes : null}
                    />
                    <EditTranshipmentInfo
                        register={register}
                        transhipments={transhipmentInfos}
                        transhipmentInfo={shipmentDetail && shipmentDetail.transhipmentInfo ? shipmentDetail.transhipmentInfo : []}
                        setTranshipments={setTranshipmentInfos}
                    />
                    <EditContainers
                        register={register}
                        shipmentDetail={shipmentDetail}
                        containerTracking={shipmentDetail && shipmentDetail.containerTracking ? shipmentDetail.containerTracking : null}
                        editShipmentInfo={markAsShipmentType === MARK_TRACK_SHIPMENT && !!trackerTemplate}
                        containers={containers}
                        setContainers={setContainers}
                        watch={watch}
                        errors={errors}
                        bookingRequest={bookingRequest}
                    />
                    <EditContactDetails
                        contactDetails={shipmentDetail && shipmentDetail.contactDetail ? shipmentDetail.contactDetail : {}}
                        register={register}
                    />
                </div>
                <div className="col-4">
                    <AddressDetails
                        register={register}
                        routes={shipmentDetail ? shipmentDetail.routes : null}
                        editShipmentInfo={markAsShipmentType === MARK_TRACK_SHIPMENT && !!trackerTemplate}
                        setShipmentDetail={setShipmentDetail}
                        tabTypeShipment={tabTypeShipment}
                        typeOfShipment={typeOfShipment}
                        isLinkedShipment={isLinkedShipment}
                    />
                </div>
            </div> 
            <EditSectionInfo register={register} sections={shipmentDetail && shipmentDetail.addedInfoSections} errors={errors} />
        </div>
    )
};

VerifyDetailsForm.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.any,
    getValues: PropTypes.func,
    vessels: PropTypes.array,
    setVessels: PropTypes.func,
    shipmentDetail: PropTypes.object,
    watch: PropTypes.func,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
    documents: PropTypes.array,
};