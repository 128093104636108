import React, { useContext } from 'react';
import './index.scss';
import {
    Modal,
    Input
} from 'components';
import { HomeContext, ManagementContext, ModalContext, TemplateContext } from 'contexts';
import { PERMISSIONS_LIST } from 'actions';
import { useForm } from 'react-hook-form';
import { getErrorMessage } from 'utils';
import { useLocation } from 'react-router-dom';
import { ALL, MYTASK } from 'actions';
import { useTranslation } from 'react-i18next';

export const EditSection = ({ open = false, onCancel, isEdit = false }) => {
    const { t } = useTranslation();
    const {
        setNotificationMessage,
        sectionName,
        sectionId,
        currentTabManagement,
        setCurrentTabManagement,
        isTemplate,
        setIsTemplate,
        sectionOrder,
    } = useContext(HomeContext);
    const { setIsEditSection, setEditSection } = useContext(ModalContext);
    const { permission } = useContext(HomeContext);
    const { doCreateSection, doUpdateSection, doRomoveSection, doGetTaskManagements } = useContext(ManagementContext);
    const { doEditSection, templatesDetails, doGetTemplatesDetails, doDeleteSection } = useContext(TemplateContext);

    const { handleSubmit, register, reset, errors } = useForm();
    const handleCloseModal = () => {
        onCancel();
        setIsEditSection(false);
    }
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];

    const handleEditSection = (data) => {
        const { sectionName } = data;
        const payload = {
            sectionName: sectionName.trim()
        }
        const payloadTemplate = {
            name: sectionName.trim(),
            order: sectionOrder
        }
        if (isTemplate === true) {
            doEditSection(sectionId, payloadTemplate, () => {
                onCancel();
                setIsEditSection(false);
                setIsTemplate(false);
                doGetTemplatesDetails(templatesDetails && templatesDetails.id);
                setNotificationMessage(
                    t("home.edit-section.notification-message-edit")
                )
            })
        } else {
            doUpdateSection(sectionId, payload, () => {
                setEditSection(false);
                setIsEditSection(false);
                if (currentTabManagement === ALL) {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: ALL
                    });
                } else {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: MYTASK
                    })
                }

                setNotificationMessage(
                    t("home.edit-section.notification-message-update")
            );
            });
        }

    }

    const doAddSection = (data) => {
        const { sectionName } = data;
        const payload = {
            sectionType: 'USER_SECTION',
            sectionName: sectionName.trim()
        }
        doCreateSection(shipmentId, payload, () => {
            reset();
            setEditSection(false);
            setIsEditSection(false);
            setCurrentTabManagement(ALL);
            setNotificationMessage(
                t("home.edit-section.notification-message-add")
        );
        });
    }

    const handleDeleteSection = () => {
        if (isTemplate === true) {
            doDeleteSection(sectionId, () => {
                onCancel();
                setIsEditSection(false);
                setIsTemplate(false);
                doGetTemplatesDetails(templatesDetails && templatesDetails.id);
                setNotificationMessage(
                    t("home.edit-section.notification-message-delete")
                )
            })
        } else {
            doRomoveSection(shipmentId, sectionId, () => {
                setEditSection(false);
                setIsEditSection(false);
                if (currentTabManagement === ALL) {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: ALL
                    });
                } else {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: MYTASK
                    })
                }
                setNotificationMessage(
                    t("home.edit-section.notification-message-romove")
                );
            });
        }
        


    }
    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={handleCloseModal}
            title={isEdit ? `${t("home.active-shipment.details.tabs.management-tab.edit-section")}`: `${t("home.active-shipment.details.tabs.management-tab.add-section")}`}
            btnClasses="justify-content-between"
            submitButton={true}
            onSubmitClick={isEdit ? handleSubmit(handleEditSection) : handleSubmit(doAddSection)}
            submitButtonLabel={isEdit ? `${t("home.section.save")}` : `${t("home.section.add")}`}
            cancelButton={checkPermission(PERMISSIONS_LIST.SECTION_DELETE)}
            onCancelClick={isEdit ? handleSubmit(handleDeleteSection) : () => { }}
            cancelButtonLabel={t("home.section.delete")}
            cancelButtonType="primary"
            cancelButtonClassNames="outline"
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit()}>
                    {children}
                </form>
            )}
        >
            <h4 className="h4 f-medium mbx2">{t("home.section.message")}</h4>
            <div className="tr__section-name">
                <Input
                    label={t("home.section.label")}
                    required
                    className="mbx3"
                    name="sectionName"
                    refs={register({ required: true, validate: val => !val.trim() ? 'This field cannot be blank' : undefined})}
                    defaultValue={isEdit ? sectionName : ''}
                    error={!!errors.sectionName}
                    errorMessage={getErrorMessage(errors.sectionName, "section name", 'This field cannot be blank')}
                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                />
            </div>
        </Modal>
    )
};
