import React, { createContext, useContext, useState } from 'react'
import { 
    getFtaDetails,
    getHsCodes,
 } from 'actions';
import { AuthContext } from './AuthContext';
import { HomeContext } from './HomeContext';

export const FtaAdvisorContext = createContext();

export const FtaAdvisorContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const [ftaDetails, setFtaDetails] = useState([]);
    const [documentFta, setDocumentFta] = useState([]);
    const { setLoading } = useContext(HomeContext);

    const doGetHsCodes = async (shipmentId, params, callback, errorCallback) => {
        if(shipmentId){
            try {
                const response = await getHsCodes(shipmentId, params, loggedInUser);
                const { data, status } = response;

                if(status === 200) {
                    // setHsCodes(data || []);

                    if(callback) callback(data);
                    // return hsCodes;
                }
            } catch (error) {
                // setHsCodes(hsCodes);
                if (errorCallback) errorCallback();
            }
            // return hsCodes;
        }
    }

    const doGetFtaAdvisorResult = async (shipmentId, params, payload, callback, errorCallback) => {
        if(shipmentId){
            setLoading(true);
            try {
                const response = await getFtaDetails(shipmentId, params, payload, loggedInUser);
                const { data, status } = response;

                if(status === 200) {
                    setFtaDetails(data);
                    if(callback) callback();
                }
            } catch (error) {
                setFtaDetails([]);
                if (errorCallback) errorCallback();
            }
            setLoading(false);
        }
    }
    
    return (
        <FtaAdvisorContext.Provider
            value={{
           
                ftaDetails,
                documentFta, 
                setDocumentFta,
                setFtaDetails,
                doGetHsCodes,
                doGetFtaAdvisorResult
            }}
        >
            { children}
        </FtaAdvisorContext.Provider>
    )
}
