import React, { useContext, useState } from 'react';
import moment from 'moment';

import './index.scss';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
    Table,
    TableFooter,
    TableHeader,
    Input,
    BookingTaskHeader,
    Tooltip,
    MembersAvatar
} from 'components';
import { ADD_NEW_REQUEST_URL, BOOKING_REQUESTS_PAGE_SIZE, CREATOR, MARKED, MARK_AS_ACTIVE_SHIPMENT } from 'actions';
import { BookingRequestContext, HomeContext } from 'contexts';
import {useTranslation} from "react-i18next";


export const RequestList = ({
    typeRequest,
    searchParams,
    setSearchParams = () => {},
    handeNavigateEditReference = () => {}
}) => {
    const history = useHistory();
    const { searchMessage, setBrType } = useContext(HomeContext);
    const {
        currentPage,
        totalPages,
        bookingRequests,
        doGetBookingRequests
    } = useContext(BookingRequestContext);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState();

    const { t } = useTranslation();

    const columns = [
        {
			dataIndex: 'referenceNumber,note,linkedVesselSchedule',
			render: (referenceNumber, note, linkedVesselSchedule) => (
                <div className="reference-no d-flex align-items-center justify-content-between">
                    <h6 className="h6 f-medium mrx1">{referenceNumber}</h6>

                    {linkedVesselSchedule && <Tooltip
                        content="Linked Vessel Schedule"
                    >
                        <i className="tr__link-task-pin-booking icon-chain mrx1"/>
                    </Tooltip>}

                    {note && (
                        <Tooltip
                            content={note}
                        >
                            <i className="icon-notes" />
                        </Tooltip>
                    )}
                </div>

            )
        },
        {
			dataIndex: 'bookingRequestNumber',
			render: bookingRequestNumber => (
                <div className="booking-no">
                    <h6 className="h6 f-medium" title={bookingRequestNumber || ''}>{bookingRequestNumber || ''}</h6>
                </div>

            )
        },
        {
			dataIndex: 'loadingPort',
			render: loadingPort => (
                <h6 className="h6">{`${loadingPort.city}, ${loadingPort.country} (${loadingPort.locode})`}</h6>
            )
        },
        {
			dataIndex: 'dischargePort',
			render: dischargePort => (
                <h6 className="h6">{`${dischargePort.city}, ${dischargePort.country} (${dischargePort.locode})`}</h6>
            )
        },
        {
			dataIndex: 'dateOfReceipt',
			render: dateOfReceipt => {
                const isValidDates = dateOfReceipt && dateOfReceipt.fromDate && dateOfReceipt.toDate;
                const fromDateStr = moment(dateOfReceipt?.fromDate).format('DD MMM YYYY');
                const toDateStr = moment(dateOfReceipt?.toDate).format('DD MMM YYYY')
                return (
                    <h6 className="h6">{isValidDates ? `${fromDateStr}${fromDateStr !== toDateStr ? ` - ${toDateStr}` : ''}` : ''}</h6>
                )
            }
        },
        {
			dataIndex: 'members',
            render: members => <MembersAvatar
                className="small"
                members={members}
                skip={4}
            />
        },
        {
			dataIndex: 'base',
			render: base => (
                <h6 className="h6">{moment(base.createdDate).format('DD MMM YYYY')}</h6>
            )
        },
        {
			dataIndex: 'status',
			render: status => (
                <div className={`booking-status ${(status || '').toLowerCase()}`}>
                    <p>{status === MARK_AS_ACTIVE_SHIPMENT ? MARKED.toLowerCase() : status.toLowerCase()}</p>
                </div>
            )
        }
    ];

    const handlePageChange = page => {
        const params = {
            page,
            limit: BOOKING_REQUESTS_PAGE_SIZE,
            tab: typeRequest,
            isSearching: true
        };

        doGetBookingRequests(params);
    }

    const handleAddRequest = () => {
        setBrType(CREATOR);
        history.push({
            pathname: ADD_NEW_REQUEST_URL,
            state: false
        });
    }

    const onInput = () => {
        const newParams = {
            // ...searchParams,
            ...searchTerm,
            tab: typeRequest,
            limit: BOOKING_REQUESTS_PAGE_SIZE,
            page: 0,
            isSearching: true
        };
        if (searchTerm) newParams.query = searchTerm.trim();

        setSearchParams(newParams);
        setSearchLoading(true);

        doGetBookingRequests(newParams, () => {
            setSearchLoading(false);
        }, false);
    };

    const handleStatusSorting = () => {

    }

    return (
        <div className="tr__request-list">
              <div className="wrapper">
                <div className="main-content">
                    <TableHeader
                        onAddButtonClick={handleAddRequest}
                        labelButton={t('home.BookingRequest.tableHeader.button')}
                        actionSearch = {false}
                    >
                        <Input
                            icon="icon-search"
                            iconPosition="right"
                            name="bookingRequestSearch"
                            onIconClick={() => onInput()}
                            placeholder={t('home.BookingRequest.tableHeader.placeholderSearch')}
                            loading={searchLoading}
                            onKeyPress={e => {
                                if (e.which === 13) onInput();
                            }}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </TableHeader>

                    <BookingTaskHeader
                        className="mtx2"
                        typeRequest={typeRequest}
                        onStatusSorting={handleStatusSorting}
                    />

                    <Table
                        className="border"
                        dataSource={bookingRequests}
                        columns={columns}
                        emptyMessage={searchMessage ? searchMessage : t('home.BookingRequest.message-booking')}
                        onRowClick={handeNavigateEditReference}
                    />
                </div>
                <div className="tr__members-footer d-flex align-items-center justify-content-between">
                    <p className="note">{t('home.BookingRequest.footer')}</p>
                    {totalPages > 1 && (
                        <TableFooter
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={handlePageChange}
                            isBookingRequest={true}
                        />
                    )}
                </div>
            </div>
        </div>
    )
};

RequestList.propTypes = {
    className: PropTypes.string,
    searchParams: PropTypes.object,
    setSearchParams: PropTypes.func
};
