import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import './index.scss';
import {
    RadioGroup,
    Dropdown,
    Datepicker,
    FileUploader,
    Checkbox,
    Button,
    CheckboxGroup
} from 'components';
import {
    roles,
    shipmentTypes,
    reuploadDocument,
    getErrorMessage,
    linksCheckbox,
    airTypes,
    reuploadAirDocument,
    seaShipmentTypes
} from 'utils';
import { ActiveShipmentsContext, AuthContext, HomeContext, ModalContext, OrganizationContext, TemplateContext, TrackShipmentContext } from 'contexts';
import { 
    DESTINATION,
    LOCODE_PAGE_SIZE,
    MARK_BOOKING_REQUEST,
    MARK_NEW,
    MARK_TRACK_SHIPMENT,
    MINE,
    ORGANIZATION_PAGE_SIZE,
    ORIGIN,
    TEMPLATE_PAGE_SIZE,
    MAX_FILE_SHIPMENT_DETAIL_DOCS,
    MAX_SIZE_SHIPMENT_DETAIL_DOC,
    FILE_TYPE_SHIPMENT_DETAIL_DOCS,
    FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS,
    ALL,
    ACTIVE,
    ORGANIZATIONS_LIST_URL,
    AIR,
    SEA
} from 'actions';
import { ShipmentDocument } from 'components/pages/ActiveShipmentsDetails';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export const AddShipmentDetailsForm = ({
    register = () => {},
    errors,
    getValues = () => {},
    shipmentDetail,
    documents = [],
    setDocuments = () => {},
    isLinkedShipment = false,
    bookingRequest = {},
    setDocumentChecked,
    documentChecked,
    documentList,
    setSelectedShipmentType,
    selectedShipmentType,
    selectedRoles, 
    setSelectedRoles,
    selectedLink,
    setSelectedLink,
    setSelectedOrg,
    selectedOrg,
    shipmentDetails, 
    tabTypeShipment = '',
    typeOfShipment,
    setSelectedAirType,
    selectedAirType
}) => {
    const history = useHistory();
    const [t] = useTranslation();
    const { userInfo } = useContext(AuthContext);
    const { markAsShipmentType, markAsShipmentTemplate, trackerTemplate, setIdTemplate, idTemplate} = useContext(HomeContext);
    const { organizations, doGetOrganizations } = useContext(OrganizationContext);
    const { origins, setOrigins, destinations, setDestinations, firstLocode, doGetLocodes } = useContext(TrackShipmentContext);
    const { templates, doGetTemplates, doGetTemplatesDetails } = useContext(TemplateContext);
    const { doGetAirPort, originAirPorts, destinationAirPorts } = useContext(ActiveShipmentsContext);
    const { setPreviewTempalates } = useContext(ModalContext);

    const [selectedLinkedRoles, setSelectedLinkedRoles] = useState((!isEmpty(shipmentDetails?.linkedShipment?.consigneeLinkedShipment) && roles[1]) || (!isEmpty(shipmentDetails?.linkedShipment?.shipperLinkedShipment) && roles[2]) || roles[1]);
    const [templateChooser, setTemplateChooser] = useState(false);
    const [roleOptions, setRoleOptions] = useState((!isEmpty(shipmentDetails?.linkedShipment?.consigneeLinkedShipment) && roles.slice(1, 2)) || (!isEmpty(shipmentDetails?.linkedShipment?.shipperLinkedShipment) && roles.slice(2, 3)) || (isLinkedShipment && roles.slice(1, 3)) || roles.slice(0, 3));
    const [selectedDocument, setSelectedDocument] = useState(reuploadDocument[0]);
    // const [selectedAriDoc, setSelectedDoc] = useState(reuploadAirDocument[0]);
    const [orgLoading, setOrgLoading] = useState(false);
    const [originLoading, setOriginLoading] = useState(false);
    const [destinationLoading, setDestinationLoading] = useState(false);
    const [templateLoading, setTemplateLoading] = useState(false);
    const [departureDate, setDepartureDate] = useState();
    const [arrivalDate, setArrivalDate] = useState();
    const [selectedOrigin, setSelectedOrigin] = useState();
    const [selectedOriginAirPort, setSelectedOriginAirPort] = useState();
    const [selectedDestAirPort, setSelectedDestAirPort] = useState();
    const [selectedDestination, setSelectedDestination] = useState();
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [chooseTemplate, setChooseTemplate] =  useState();

    /*eslint-disable */
     useEffect(() => {
        doGetOrganizations({ page: 0, isSearching: true, status: ACTIVE },(data) => {
            if(data.length === 1){
                setSelectedOrg({key:data[0]._id, value:data[0].name});
            }
        });
        doGetLocodes({ limit: LOCODE_PAGE_SIZE });
        doGetTemplates({
            tab: MINE,
            limit: 9999,
            page: ALL,
        });

        doGetAirPort({
            limit: 9999,
            page: 0
        });

        if (firstLocode && firstLocode.length > 0) {
            setOrigins(firstLocode);
            setDestinations(firstLocode);
        }
    }, [])

    useEffect(() => {
        if (templates && templates.length > 0 && shipmentDetail && shipmentDetail.templateId) {
            setSelectedTemplate(templates.find(t => t._id === shipmentDetail.templateId));
        }
    }, [templates])

    useEffect(() => {
        if (shipmentDetail) {
            const { creatorRole, airRoutes,  routes, departureDate, arrivalDate, shippingDetails, orgId, documentType } = shipmentDetail;
            const sOrg = organizations.find(o => o._id === orgId);
            const currentRoleOption = roleOptions.find(r => r.id === creatorRole);
            const allRoleOption = creatorRole ? roles.find(r => r.id === creatorRole) : null;

            if (!currentRoleOption && allRoleOption) setRoleOptions(roles);
            setTemplateChooser(!!shipmentDetail.templateId);
            setSelectedRoles(selectedRoles);
            setSelectedOrigin((typeOfShipment?.key === SEA || tabTypeShipment === SEA) ? {
                key: routes?.pol?.location?.locode,
                value: `${routes?.pol?.location?.name}, ${routes?.pol?.location?.country} (${routes?.pol?.location?.locode})`,
                item: routes?.pol?.location
            } : {});
            setSelectedOriginAirPort((tabTypeShipment === AIR) ? {
                key: airRoutes?.pol?.city,
                value: `${airRoutes?.pol?.city}, ${airRoutes?.pol?.country} (${airRoutes?.pol?.countryCode})`,
                item: airRoutes?.pol
            } : {})
            setSelectedDestination((typeOfShipment?.key === SEA || tabTypeShipment === SEA) ? {
                key: routes?.pod?.location?.locode,
                value: `${routes?.pod?.location?.name}, ${routes?.pod?.location?.country} (${routes?.pod?.location?.locode})`,
                item: routes?.pod?.location
            }: {});
            setSelectedDestAirPort((tabTypeShipment === AIR) ? {
                key: airRoutes?.pod?.city,
                value: `${airRoutes?.pod?.city}, ${airRoutes?.pol?.country} (${airRoutes?.pod?.countryCode})`,
                item: airRoutes?.pod
            } : {})
            setDepartureDate(new Date(departureDate));
            setArrivalDate(new Date(arrivalDate));
            if (shippingDetails) setSelectedShipmentType(shipmentTypes.find(t => t.id === shippingDetails.shipmentType));
            setSelectedOrg(sOrg ? {
                key: sOrg._id,
                value: sOrg.name
            } : null);
            if (documentType) setSelectedDocument(reuploadDocument.find(d => d.id === documentType));
        }

        if (markAsShipmentType !== MARK_NEW) {
            switch(markAsShipmentType) {
                case MARK_TRACK_SHIPMENT:
                    if (trackerTemplate) {
                        const { pol, pod, prePol, postPod } = trackerTemplate;

                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: prePol.location.locode,
                            isSearching: true
                        });
                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: postPod.location.locode,
                            isSearching: true
                        }, null, null, DESTINATION);
                        setSelectedOrigin({
                            key: prePol.location.locode,
                            value: `${prePol.location.name}, ${prePol.location.country} (${prePol.location.locode})`,
                            item: prePol.location
                        });
                        setSelectedOriginAirPort({
                            key: prePol.location.locode,
                            value: `${prePol.location.name}, ${prePol.location.country} (${prePol.location.locode})`,
                            item: prePol.location
                        })
                        setSelectedDestination({
                            key: postPod.location.locode,
                            value: `${postPod.location.name}, ${postPod.location.country} (${postPod.location.locode})`,
                            item: postPod.location
                        });
                        setSelectedDestAirPort((tabTypeShipment === AIR ) ? {
                            key: airRoutes?.pod?.city,
                            value: `${airRoutes?.pod?.city}, ${airRoutes?.pol?.country} (${airRoutes?.pod?.countryCode})`,
                            item: airRoutes?.pod
                        } : {})
                        setDepartureDate(new Date(pol.date));
                        setArrivalDate(new Date(pod.date));
                    }
                    break;
                case MARK_BOOKING_REQUEST:
                    if (markAsShipmentTemplate) {
                        const { creatorRole, loadingPort, dischargePort, type } = markAsShipmentTemplate;

                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: loadingPort.locode,
                            isSearching: true
                        });
                        doGetLocodes({
                            limit: LOCODE_PAGE_SIZE,
                            cop: dischargePort.locode,
                            isSearching: true
                        }, null, null, DESTINATION);
                        setSelectedRoles(old => [...old, Object.assign(roles.find(r => r.id === creatorRole), {disabled: true}) ]);
                        setSelectedOrigin({
                            key: loadingPort.locode,
                            value: `${loadingPort.city}, ${loadingPort.country} (${loadingPort.locode})`,
                            item: loadingPort
                        });
                        // setSelectedAirPort((tabTypeShipment === AIR ) ? {
                        //     key: airRoutes?.pol?.countryCode,
                        //     value: `${airRoutes?.pol?.city}, ${airRoutes?.pol?.country} (${airRoutes?.pol?.countryCode})`,
                        //     item: airRoutes?.pol
                        // } : {})
                        setSelectedDestination({
                            key: dischargePort.locode,
                            value: `${dischargePort.city}, ${dischargePort.country} (${dischargePort.locode})`,
                            item: dischargePort
                        });
                        // setSelectedAirLine((tabTypeShipment === AIR ) ? {
                        //     key: airRoutes?.pod?.code,
                        //     value: `${airRoutes?.pod?.name}, ${airRoutes?.pod?.location} (${airRoutes?.pod?.code})`,
                        //     item: airRoutes?.pod
                        // }: {})
                        setSelectedShipmentType(shipmentTypes.find(t => t.id === type));

                        if (bookingRequest) {
                            // const { departureDate, arrivalDate } = shipmentDetails;
                            setDepartureDate(bookingRequest && from ? new Date(from?.date) : "");
                            setArrivalDate(bookingRequest && to ? new Date(to?.date)  : "");
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }, [markAsShipmentType, markAsShipmentTemplate, trackerTemplate, shipmentDetail])

    useEffect(() => {
        if (shipmentDetail && organizations && organizations.length > 0) {
            if (shipmentDetail.orgId) {
                const sOrg = organizations.find(o => o._id === shipmentDetail.orgId);
                if (sOrg) {
                    setSelectedOrg({
                        key: sOrg._id,
                        value: sOrg.name
                    })
                }
            }
        }
    }, [shipmentDetail, organizations])

    useEffect(() => {
        setSelectedDocument((tabTypeShipment === AIR ) ? reuploadAirDocument[0] : reuploadDocument[0])
     },[tabTypeShipment])
    /*eslint-enable */

    const from = bookingRequest && bookingRequest.linkedVesselSchedule && bookingRequest.linkedVesselSchedule.legs.length > 0 && bookingRequest.linkedVesselSchedule.legs[0].start;
    const to = bookingRequest && bookingRequest.linkedVesselSchedule && bookingRequest.linkedVesselSchedule.legs.length > 0 && bookingRequest.linkedVesselSchedule.legs[bookingRequest.linkedVesselSchedule.legs.length - 1].end;

    const handleFileUpload = uploadFiles => {
        let fileList = [];
        for (var i = 0; i < uploadFiles.length; i++) {
          if (!uploadFiles[i].name) return
          fileList.push(uploadFiles[i])
        }
        setDocuments(oldFiles => [...oldFiles, ...fileList])
    }

    const handleChooseOption = (item) => {
        setIdTemplate(item.key);
        setChooseTemplate(item);
        // doGetTemplatesDetails(item.key);
    }

    const handleFileRemove = file => {
        if (!file) return;
        setDocuments(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }

    const handleShowAllRoles = () => {
        setRoleOptions(roles);
    }

    const onSearchOrganization = (value) => {
        setOrgLoading(true);
        doGetOrganizations({
			page: 0,
			limit: ORGANIZATION_PAGE_SIZE,
            name: value.trim(),
			isSearching: true,
            status: ACTIVE
		}, () => {
			setOrgLoading(false);
		}, false);
    }

    const onSearchOriginLocode = value => {
        setOriginLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    }

    const onSearchOriginAirport = value => {
        setOriginLoading(true);
        doGetAirPort({
            limit: LOCODE_PAGE_SIZE,
            search: value.trim(),
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    }

    const onSearchDestAirport = value => {
        setDestinationLoading(true);
        doGetAirPort({
            limit: LOCODE_PAGE_SIZE,
            search: value.trim(),
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    }

    const onSearchDestLocode = (value) => {
        setDestinationLoading(true);

        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    }

    const onSearchTemplate = (value) => {
        setTemplateLoading(true);

        doGetTemplates({
            limit: TEMPLATE_PAGE_SIZE,
            name: value.trim(),
            tab: MINE
        }, () => {
            setTemplateLoading(false);
        }, false);
    }

    const isMarkNewShipment = () => markAsShipmentType === MARK_NEW;
    const isMarkTrackShipment = () => markAsShipmentType === MARK_TRACK_SHIPMENT;
    const isMarkBRShipment = () => markAsShipmentType === MARK_BOOKING_REQUEST;
    const redirectToOrg = () => history.push(ORGANIZATIONS_LIST_URL);
    return (
        <div className="tr__add-shipment-details-form">
            <div className="tr__add-shipment-details-form--group width-form mbx4">
                <Dropdown
                    className="select big-label"
                    icon="icon-chevron-down"
                    iconPosition="right"
                    mode="input"
                    name="organization"
                    label={t("home.active-shipment.choose-your-organization")}
                    required
                    defaultValue={selectedOrg}
                    options={organizations && organizations.length > 0 ? organizations.map(org => {
                        return {
                            key: org._id,
                            value: org.name
                        }
                    }) : []}
                    placeholder={t("home.active-shipment.select-organization")}
                    dropdownPosition="center"
                    onInputChange={onSearchOrganization}
                    searchLoading={orgLoading}
                    // refs={register({ required: !markAsShipmentTemplate })}
                    refs={register({ required: true })}
                    error={!!errors.organization}
                    errorMessage={getErrorMessage(errors.organization, "Organization")}
                />
                {userInfo && userInfo.orgTeams && userInfo.orgTeams.length === 0 && (
                    <p className="text-error mtx3 orgClick" onClick={redirectToOrg}>{t("home.active-shipment.message-hyperlink")}</p>
                )}
            </div>
            <div className="tr__add-shipment-details-form--group mbx4">
                <div className="template-chooser">
                    <div className="template-chooser--header d-flex align-items-center justify-content-between">
                        <h4 className="h4 f-medium">
                            <span className="text-template">{t("home.active-shipment.choose-template")}</span>
                        </h4>
                        {templates && templates.length > 0 && (
                            <Checkbox
                                name="templateChooser"
                                type="checkbox"
                                checked={templateChooser}
                                className="toggle right"
                                onChange={() => setTemplateChooser(!templateChooser)}
                                disabled={!(templates && templates.length > 0)}
                            />
                        )
                        //  :
                        //  (
                        //     <small className="f-regular">There is no template available</small>
                        // )
                        }
                    </div>
                    {templateChooser && (
                        <div className="template-chooser--content mtx2 d-flex align-items-center justify-content-between">
                            <Dropdown
                                className="select mrx4"
                                icon="icon-chevron-down"
                                iconPosition="right"
                                name="template"
                                options={templates && templates.length > 0 ? templates.map(temp => {
                                    return {
                                        key: temp._id,
                                        value: temp.name,
                                    }
                                }) : []}
                                defaultValue={selectedTemplate ? {
                                    key: selectedTemplate._id,
                                    value: selectedTemplate.name
                                } : chooseTemplate}
                                mode="input"
                                placeholder={t("home.active-shipment.placeholder-template")}
                                dropdownPosition="top full"
                                onInputChange={onSearchTemplate}
                                onChange={(item) => {handleChooseOption(item)}}
                                error={!!errors.template}
                                errorMessage={getErrorMessage(errors.template, "Template")}
                                refs={register({ required: true })}
                                searchLoading={templateLoading}
                            />
                            <Button
                                className="small"
                                disabled={!idTemplate}
                                onClick={() => {
                                    setPreviewTempalates(true);
                                    doGetTemplatesDetails(idTemplate);
                                }}
                            >
                                {t("home.active-shipment.preview")}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className="tr__add-shipment-details-form--group mbx4">
                {!isLinkedShipment &&  <CheckboxGroup
                    label={t("home.active-shipment.role-shipment")}
                    className="big-label"
                    name="shipmentRole"
                    type="checkbox"
                    horizontal
                    items={roleOptions}
                    values={selectedRoles}
                    refs={register({ required: true })}
                    error={!!errors.shipmentRole}
                    errorMessage={getErrorMessage(errors.taskRoles, "task Roles")}
                    onChange={roles => setSelectedRoles(roles)}
                    disabled={isMarkBRShipment()}
                    buttonMode={true}
                />}
               
                {isLinkedShipment && <RadioGroup
                    label={t("home.active-shipment.role-shipment")}
                    name="shipmentRole"
                    className="big-label"
                    refs={register()}
                    horizontal
                    items={roleOptions}
                    value={selectedLinkedRoles}
                    onChange={roles => setSelectedLinkedRoles(roles)}
                >
                    <label className={`tr__form-radio`}>
                        <span className="button-disable">
                            <i className="icon-admin" />
                            <span>{t("home.role.shipment-admin")}</span>
                        </span>
                    </label>
                </RadioGroup>}

               {!isLinkedShipment && roleOptions.length < roles.length && (
                    <p
                        className="tr__link"
                        onClick={handleShowAllRoles}
                    >
                        {t("home.active-shipment.select-other-role")}
                    </p>
                )}


            </div>
            
            {isLinkedShipment ? '' : <div className="tr__add-shipment-details-form--group mbx4">
                <RadioGroup
                    label={t("home.active-shipment.select-type-of-shipment")}
                    name="shipmentType"
                    className="big-label"
                    horizontal
                    items={(tabTypeShipment === SEA || typeOfShipment?.key === SEA) ? seaShipmentTypes : airTypes}
                    value={selectedShipmentType}
                    disabled={isMarkBRShipment()}
                    onChange={isMarkBRShipment() ? null : type => setSelectedShipmentType(type)}
                    refs={register({ required: true })}
                    error={!!errors.shipmentType}
                    errorMessage={getErrorMessage(errors.shipmentType, "Shipment Type")}
                />
            </div>}
                    
            {isLinkedShipment ? '' : (typeOfShipment?.key === SEA || tabTypeShipment === SEA) && <div className="tr__add-shipment-details-form--group row mbx4">
                <div className="col-6">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="originPort"
                        label={t("home.active-shipment.origin-port")}
                        required
                        comparable={true}
                        placeholder={t("home.active-shipment.port-code")}
                        disabled={!isMarkNewShipment()}
                        dropdownPosition="center"
                        error={!!errors.originPort}
                        defaultValue={selectedOrigin}
                        errorMessage={getErrorMessage(errors.originPort, "Origin", "Origin port and Destination port can't be the same")}
                        refs={register({ required: true, validate: value => value !== getValues('destinationPort') })}
                        options={origins && origins.length > 0 ? origins.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        onInputChange={onSearchOriginLocode}
                        searchLoading={originLoading}
                    />
                </div>
                <div className="col-6">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="destinationPort"
                        label={t("home.active-shipment.destination-port")}
                        required
                        comparable={true}
                        disabled={!isMarkNewShipment()}
                        placeholder={t("home.active-shipment.port-code")}
                        dropdownPosition="center"
                        error={!!errors.destinationPort}
                        defaultValue={selectedDestination}
                        errorMessage={getErrorMessage(errors.destinationPort, "Destination Port", "Origin port and Destination port can't be the same")}
                        options={destinations && destinations.length > 0 ? destinations.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        refs={register({ required: true, validate: value => value !== getValues('originPort') })}
                        onInputChange={onSearchDestLocode}
                        searchLoading={destinationLoading}
                    />
                </div>
            </div>}

            {isLinkedShipment ? '' : (tabTypeShipment === AIR) &&  <div className="tr__add-shipment-details-form--group row mbx4">
                <div className="col-6">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="originPort"
                        label={'Origin Airports'}
                        required
                        comparable={true}
                        placeholder={t("home.active-shipment.port-code")}
                        disabled={!isMarkNewShipment()}
                        dropdownPosition="center"
                        error={!!errors.originPort}
                        defaultValue={selectedOriginAirPort}
                        errorMessage={getErrorMessage(errors.originPort, "Origin Airports", "Origin Airports and Destination Airports can't be the same")}
                        refs={register({ required: true, validate: value => value !== getValues('destinationPort') })}
                        options={originAirPorts && originAirPorts.length > 0 ? originAirPorts.map(originPort => {
                            return {
                                key: originPort.city,
                                value: `${originPort.city}, ${originPort.country} (${originPort.countryCode})`,
                                item: originPort,
                            }
                        }) : []}
                        onInputChange={onSearchOriginAirport}
                        searchLoading={originLoading}
                    />
                </div>
                <div className="col-6">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="destinationPort"
                        label={'Destination Airports'}
                        required
                        comparable={true}
                        disabled={!isMarkNewShipment()}
                        placeholder={t("home.active-shipment.port-code")}
                        dropdownPosition="center"
                        error={!!errors.destinationPort}
                        defaultValue={selectedDestAirPort}
                        errorMessage={getErrorMessage(errors.destinationPort, "Destination Port", "Origin Airports and Destination Airports can't be the same")}
                        options={destinationAirPorts && destinationAirPorts.length > 0 ? destinationAirPorts.map(destAirPort => {
                            return {
                                key: destAirPort.city,
                                value: `${destAirPort.city}, ${destAirPort.country} (${destAirPort.countryCode})`,
                                item: destAirPort
                            }
                        }) : []}
                        refs={register({ required: true, validate: value => value !== getValues('originPort') })}
                        onInputChange={onSearchDestAirport}
                        searchLoading={destinationLoading}
                    />
                </div>
            </div>}
            {isLinkedShipment ? "" :   <div className="tr__add-shipment-details-form--group row mbx4">
                <div className="col-6">
                    <Datepicker
                        name="vesselDepartureDate"
                        label={t("home.active-shipment.vessel-departure-date")}
                        required
                        placeholder={t("home.active-shipment.add-departure-date")}
                        disabled={isMarkTrackShipment() || from}
                        start={departureDate}
                        error={!!errors.vesselDepartureDate}
                        errorMessage={getErrorMessage(errors.vesselDepartureDate, "Vessel Departure Date")}
                        refs={register({ required: true })}
                        minDate={null}
                        // onDatesChange={dates => setMinArrivalDate(dates)}
                    />
                </div>
                <div className="col-6">
                    <Datepicker
                        name="vesselArrivalDate"
                        label={t("home.active-shipment.vessel-arrival-date")}
                        required
                        placeholder={t("home.active-shipment.add-arrival-date")}
                        disabled={isMarkTrackShipment() || to}
                        start={arrivalDate}
                        error={!!errors.vesselArrivalDate}
                        errorMessage={getErrorMessage(errors.vesselArrivalDate, "Vessel Arrival Date")}
                        refs={register({ required: true })}
                        minDate={null}
                        // onDatesChange={dates => setMaxDepartureDate(dates)}
                    />
                </div>
            </div>}
          
            {/*** Upload document ***/}

            {isLinkedShipment ? <div className="mbx4">  
            <div className="col-12 mtx4">
                    <RadioGroup
                        label={t("home.active-shipment.filter-panel.shipmentKey.label")}
                        className="large-label"
                        name="vesselType"
                        buttonMode={false}
                        type="default"
                        horizontal
                        items={linksCheckbox}
                        value={selectedLink}
                        onChange={e => setSelectedLink(e)}
                        description={t("home.active-shipment.filter-panel.shipmentKey.description")}
                    />
                </div>
                {selectedLink.id === 'yes' && (
                    <div className="col-12 mtx2 tr__add-link__list-file">
                        <ShipmentDocument
                            icon="icon-file"
                            values={documentChecked}
                            items={documentList}
                            onChange={(docs => setDocumentChecked(docs))}
                        />
                    </div>
            )} </div> : ""}

            {isLinkedShipment ? '' : <div className="tr__add-shipment-details-form--group">
                    <RadioGroup
                        name="documentType"
                        buttonMode={false}
                        label={t("home.active-shipment.upload-document")}
                        className='big-label'
                        type="default"
                        horizontal
                        items={(tabTypeShipment === AIR ) ? reuploadAirDocument : reuploadDocument}
                        value={selectedDocument}
                        error={!!errors.documentType}
                        errorMessage={getErrorMessage(errors.documentType, "Document Type")}
                        refs={register({ required: true })}
                        onChange={doc => setSelectedDocument(doc)}
                    />
                </div>
            }

            {/* {isLinkedShipment ? '' : (tabTypeShipment === AIR ) && <div className="tr__add-shipment-details-form--group">
                    <RadioGroup
                        name="airDocumentType"
                        buttonMode={false}
                        label={t("home.active-shipment.upload-document")}
                        className='big-label'
                        type="default"
                        horizontal
                        items={reuploadAirDocument}
                        value={selectedAriDoc}
                        error={!!errors.airDocumentType}
                        errorMessage={getErrorMessage(errors.airDocumentType, "Document Type")}
                        refs={register({ required: true })}
                        onChange={doc => setSelectedDoc(doc)}
                    />
                </div>
            } */}
                <div className="tr__add-shipment-details-form--group mbx4">
                    <FileUploader
                        name="shipmentDetailsDocs"
                        className="big-label"
                        handleDrop={handleFileUpload}
                        handleRemove={handleFileRemove}
                        uploadedFiles={documents}
                        mode="list"
                        multiple={false}
                        error={!!errors.shipmentDetailsDocs}
                        errorMessage={getErrorMessage(errors.shipmentDetailsDocs, "ShipmentDetails Docs Type")}
                        refs={register}
                        outputFormat='base64'
                        limitNumberUploadFile={MAX_FILE_SHIPMENT_DETAIL_DOCS}
                        limitSizePerFile={MAX_SIZE_SHIPMENT_DETAIL_DOC}
                        fileTypeApproved={['image', 'pdf']}
                        uploadInner={true}
                        uploadFileType={FILE_TYPE_SHIPMENT_DETAIL_DOCS}
                        uploadFileSubType={FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS}
                        ocrRequired={true}
                    />
                </div>
            
            {/*** !!! Upload document ***/}
        </div>
    )
}
AddShipmentDetailsForm.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.object,
    getValues: PropTypes.func,
    shipmentDetail: PropTypes.object,
    documents: PropTypes.array,
    setDocuments: PropTypes.func,
    bookingRequest: PropTypes.object
};
