/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './index.scss'
import { Button, Collapse, Spinner, Tooltip } from 'components';
import { HomeContext, ModalContext, VesselScheduleContext } from 'contexts';
import { copyToClipboard } from 'utils';
import { useHistory } from 'react-router-dom';
import { ADD_NEW_REQUEST_URL } from 'actions';
import { useTranslation } from 'react-i18next';

export const ResultCard = () => {
    const { setNotificationMessage, linkedVessel, setChooseLinkedVessel } = useContext(HomeContext);
    const { totalItems, vesselSchedule } = useContext(VesselScheduleContext);
    const { setSelectLinkedVessel } = useContext(ModalContext)
    const history = useHistory();
    const { t } = useTranslation();

    const copyAddress = target => {
        if (copyToClipboard(target)) {
            setNotificationMessage('Copied!');
        }
    }

    // const handleNavigateAddRequest = () => {
    //     history.push({
    //         pathname: ADD_NEW_REQUEST_URL,
    //         state: vesselSchedule
    //     });
    // }

    return vesselSchedule.length > 0 ? vesselSchedule.map((info, index) => {
        const from = info.legs[0].start;
        const to = info.legs[info.legs.length - 1].end;
        const transits = info.legs.slice(0, info.legs.length - 1);

        return (
            <div className="tr__vessel--search" key={index} >
                <div className="tr__vessel--search__info d-flex justify-content-between">
                    <div className="port d-flex align-items-center justify-content-between" >
                        <div className="port__brand">
                            <img src={info.carrier.logoURL} alt="Logo"/>
                            <span>{info.carrier.name}</span>
                        </div>
                        <div className="port__info d-flex justify-content-center">
                            <div className="port__title text-right">
                                <h4 className="port__title__code f-medium h6 mbx1">
                                    {from.port.name} ({from.port.code})
                                </h4>
                                <p className="f-medium">
                                    {moment(from.date).format('DD MMM YYYY')}
                                </p>
                            </div>
                            <div className="port__transhipping text-center">
                                <span className='port__transhipping__dashed-line'></span>
                                <div className="port__transhipping__label d-flex align-items-center">
                                    <div className="port__transhipping__item">
                                        {transits.length > 0 && transits.map((leg, legIndex) => (
                                            <React.Fragment key={legIndex}>
                                                <i className="icon-chevron-right" />
                                                <p className="f-medium" >
                                                    {leg.end.port.code}
                                                </p>
                                                {legIndex === transits.length - 1 && (
                                                    <i className="icon-chevron-right" />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                {info.legs.length > 1 && (
                                    <p className="sub-title mtx2">{t('vesselSchedule.searchResult.tranship')}</p>
                                )}
                            </div>
                            <div className="port__title">
                                <h4 className="port__title__code f-medium h4 mbx1">
                                    {to.port.name} ({to.port.code})
                                </h4>
                                <p className="f-medium">
                                    {moment(to.date).format('DD MMM YYYY')}
                                </p>
                            </div>
                        </div>
                    </div>
                    
                    <div className="right__port text-right d-flex justify-content-between" >
                        <div className="block-column right__port__date d-flex flex-column align-items-center justify-content-center">
                            <h4 className="right__port__date__number f-medium mbx1">
                                {info.duration} {info.duration > 1 ? t('vesselSchedule.searchResult.days') : t('vesselSchedule.searchResult.day')}
                            </h4>
                            {info.legs.length > 1 && (
                                <p className="f-medium">{info.legs.length - 1} T/S</p>
                            )}
                        </div>
                        <div className="block-column d-flex align-items-center justify-content-center">
                            <Tooltip
                                className="mrx2"
                                content={info.carrier.url || 'Website is not available at the moment.'}
                                position="right"
                            >
                                <a
                                    className="tr__button info icon"
                                    href={info.carrier.url ? `http://${info.carrier.url}` : ''}
                                    target="_blank"
                                >
                                    <i className="icon-web" />
                                </a>
                            </Tooltip>
                            <Tooltip
                                content={info.carrier.headquarters || 'Address is not available at the moment.'}
                                position="right"
                                className="mrx2"
                            >
                                <input
                                    type="text"
                                    id={`companyAddress${index}`}
                                    defaultValue={info.carrier.headquarters}
                                    className="hidden"
                                />
                                <Button
                                    className="icon"
                                    type="info"
                                    icon="icon-pin"
                                    onClick={() => copyAddress(`companyAddress${index}`)}
                                />
                            </Tooltip>

                            {linkedVessel ? <Button 
                                type="primary" 
                                className="block-button secondary outline "
                                onClick={() => {
                                setSelectLinkedVessel(true);
                                setChooseLinkedVessel(info);
                            }}
                            >
                                {t('vesselSchedule.searchResult.select')}
                            </Button> : <Button 
                                type="primary" 
                                className="block-button"
                                onClick={() => {
                                    history.push({
                                        pathname: ADD_NEW_REQUEST_URL,
                                        // state: info
                                    });
                                    setChooseLinkedVessel(info);
                                }}
                            >
                                {t('vesselSchedule.searchResult.book')}
                            </Button>}
                        </div>
                    </div>
                </div>
                <div className="tr__vessel--search__footer">
                    <Collapse collapseTitle={t('vesselSchedule.searchResult.vessels')} className="blue-bg">
                        <div className="content__view">
                            <div className="content__view__header d-flex align-center justify-content-between">
                                <div className="content__view__header__column">{t('vesselSchedule.searchResult.vessel')}</div>
                                <div className="content__view__header__column ">{t('vesselSchedule.searchResult.voyage')}</div>
                                <div className="content__view__header__column">{t('vesselSchedule.searchResult.service')}</div>
                                <div className="content__view__header__column">{t('vesselSchedule.searchResult.departure')}</div>
                                <div className="content__view__header__column">{t('vesselSchedule.searchResult.arrival')}</div>
                            </div>
                            {info.legs.map((dataRoute, routeIndex) => (
                                <div className="content__view__row d-flex align-center justify-content-between" key={routeIndex}> 
                                    <div className="content__view__row__column">{dataRoute.vessel.name} </div>
                                    <div  className="content__view__row__column">{dataRoute.voyage} </div>
                                    <div  className="content__view__row__column">{dataRoute.route.name} </div>
                                    <div  className="content__view__row__column">
                                        <div className="mbx05">{dataRoute.start.port.name}  ({dataRoute.start.port.code})</div>  
                                        <div className="date">{moment(dataRoute.start.date).format('DD MMM YYYY')} </div>
                                    </div>
                                    <div  className="content__view__row__column">
                                        <div className="mbx1">{dataRoute.end.port.name}  ({dataRoute.end.port.code})
                                        </div>
                                        <div className="date">{moment(dataRoute.end.date).format('DD MMM YYYY')} </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Collapse>
                </div>
            </div> 
        )
    }) : (totalItems > 0 ? (
        <div className="tr__vessel--search no-result text-center">
            <h3 className="h3 f-medium">{t('vesselSchedule.searchResult.noResult')}</h3>
        </div>
    ) : <Spinner type="transparent" />)
};

ResultCard.propTypes = {
    schedules: PropTypes.array,
};
