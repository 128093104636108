import React, { useContext } from 'react';

import {
    Modal,
} from 'components';
import { HomeContext, TemplateContext } from 'contexts';
import { useHistory } from 'react-router-dom';
import { MINE, TEMPLATES_URL, TEMPLATE_PAGE_SIZE } from 'actions';
import { useTranslation } from 'react-i18next';

export const DeleteTemplate = ({ open = false, onCancel }) => {
    const { setNotificationMessage } = useContext(HomeContext);
    const { templatesDetails, doDeleteTemplate, doGetTemplates }  = useContext(TemplateContext);
    const history = useHistory();
    const [t] = useTranslation();

    const handleDeleteTemplate = () => {
        doDeleteTemplate(templatesDetails && templatesDetails.id, () => {
            onCancel();
            setNotificationMessage(`
                <p> Template has been deleted successfully!</p>
            `);
            doGetTemplates({ 
                tab: MINE, 
                limit: TEMPLATE_PAGE_SIZE, 
                page: 0,
            });
            history.push(TEMPLATES_URL);

        })
    }
    
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title={t("template.popupDelete.title")}
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel={t("template.popupDelete.yes")}
            onSubmitClick={handleDeleteTemplate}
            cancelButton={true}
            cancelButtonLabel={t("template.popupDelete.no")}
            cancelButtonType=""
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">{t("template.popupDelete.content")}</h6>
            </div>
        </Modal>
    )
};