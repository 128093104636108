import React, { useContext } from 'react';

import {
    Modal,
} from 'components';
import { HomeContext } from 'contexts';
import {useTranslation} from "react-i18next";

export const DeleteLinkedVessel = ({ open = false, onCancel }) => {
    const { setUnLinkVessel } = useContext(HomeContext);
    const { t } = useTranslation();
    return (
        <Modal
            open={open}
            className="tr__edit-section"
            onCancel={onCancel}
            title={t('home.BookingRequest.deleteLinkedVessel.title')}
            btnClasses="justify-content-center"
            submitButton={true}
            submitButtonLabel="Yes"
            onSubmitClick={() => {
                setUnLinkVessel(true);
                onCancel();
            }}
            cancelButton={true}
            cancelButtonLabel="No"
            cancelButtonType=""
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">{t('home.BookingRequest.deleteLinkedVessel.content')}</h6>
            </div>
        </Modal>
    )
};
