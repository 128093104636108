import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components';
import { HomeContext, ManagementContext } from 'contexts';
import { useHistory, useLocation } from 'react-router-dom';
import { TEMPLATES_URL } from 'actions';
import { useTranslation } from 'react-i18next';

export const ConfirmSaveAsTemplate = ({
    open = false,
    onCancel,
    ...rest
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { setNotificationMessage } = useContext(HomeContext);
    const { doSaveAsTemplate } = useContext(ManagementContext);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    const handleSubmit = () => {
        doSaveAsTemplate(shipmentId, {
            id: shipmentId,
        }, () => {
            history.push(TEMPLATES_URL);
            setNotificationMessage(`
                <p> Save to My template successfully!</p>
            `);
        })
        onCancel();
    }

    return (
        <Modal
            open={open}
            className="tr__delete-confirmation"
            onCancel={onCancel}
            title={t("home.save-template.title")}
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel={t("home.save-template.yes")}
            onSubmitClick={handleSubmit}
            cancelButton={true}
            cancelButtonLabel={t("home.save-template.no")}
            cancelButtonClassNames="outline secondary"
            onCancelClick={onCancel}
            isBackDropClickable={false}
            {...rest}
        >
            <div className="text-center delete-message">
                <h6 className="h6 mtbx2 f-medium">{t("home.save-template.notification")}</h6>
            </div>
        </Modal>
    )
};

ConfirmSaveAsTemplate.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
};
