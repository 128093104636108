import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    Modal,
} from 'components';
import { HomeContext, NotificationContext, OrganizationContext } from 'contexts';
import { Dropdown } from 'components/common';
import { MINE } from 'actions';
import { useTranslation } from 'react-i18next';

export const CouponNotication = ({
    open = false,
    onCancel,
    onSubmit = () => {},
    title = 'Enjoy your extra free month',
    message = '',
    ...rest
}) => {
    const { couponMessage, notificationId } = useContext(HomeContext);
    const { organizations, doGetOrganizations, doExtraCoupon } = useContext(OrganizationContext);
    const { doDisableNotification, setRawNotifications, rawNotifications } = useContext(NotificationContext);
    const [orgLoading, setOrgLoading] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [t] = useTranslation();

    const onSearchOrganization = (value) => {
        setOrgLoading(true);
        doGetOrganizations({
			page: 0,
            name: value.trim(),
			isSearching: true,
            tab: MINE.toUpperCase(),
            unexpired: true,
		}, () => {
			setOrgLoading(false);
		}, false);
    }
    const handleExtraCoupon = () => {
        let payload = couponMessage.split(" ")
        doExtraCoupon(selectedOrg.key, {invitedEmail: payload[3], orgInviteName: payload[5]}, () => {
            doDisableNotification(notificationId, () => {
                let newNotifications = rawNotifications.map( e => {
                    if (e.id === notificationId) {
                        e.enableClick = false;
                    }
                    return e;
                })
                setRawNotifications(newNotifications);
            })
            setSelectedOrg(null);
            onCancel();
        })
    }
    return (
        <Modal
            open={open}
            className="tr__coupon-notification"
            onCancel={() => {
                setSelectedOrg(null);
                onCancel();
            }}
            title={t("template.title-couponNotification")}
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel={t("template.popupDelete.yes")}
            onSubmitClick={handleExtraCoupon}
            cancelButton={true}
            cancelButtonLabel={t("template.popupDelete.no")}
            cancelButtonClassNames="outline secondary"
            submitButtonDisabled={selectedOrg !== null ? false : true}
            onCancelClick={() => {
                setSelectedOrg(null);
                onCancel();
            }}
            isBackDropClickable={false}
            {...rest}
        >
            <div className="text-center coupon-message">
                <h6 className="h6 mtbx2 f-medium">{couponMessage}</h6>
                <Dropdown
                    className="select big-label"
                    icon="icon-chevron-down"
                    iconPosition="right"
                    mode="input"
                    name="organization"
                    label={t("template.choose-organization")}
                    options={organizations && organizations.length > 0 ? organizations.map(org => {
                        return {
                            key: org._id,
                            value: org.name
                        }
                    }) : []}
                    placeholder={t("home.active-shipment.select-organization")}
                    dropdownPosition="center"
                    onInputChange={onSearchOrganization}
                    searchLoading={orgLoading}
                    onChange={values => {
                        setSelectedOrg(values);
                    }}
                />
            </div>
        </Modal>
    )
};

CouponNotication.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string
};