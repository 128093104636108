import React, { useContext } from 'react';
import {
    Modal,
    FileUploader,
    RadioGroup
} from 'components';
import {
    AIR,
    MAX_FILE_SHIPMENT_DETAIL_DOCS,
    MAX_SIZE_SHIPMENT_DETAIL_DOC,
} from 'actions';
import { reuploadAirDocument, reuploadDocument } from 'utils';
import { useTranslation } from 'react-i18next';
import { ActiveShipmentsContext } from 'contexts';

export const ReuploadDocument = ({
    open = false,
    onCancel = () => { },
    onSubmit = () => { },
    documents = [],
    setDocuments = () => { },
    typeDocument = {},
    setTypeDocument = () => { } }) => {

    const handleFileUpload = uploadFiles => {
        let fileList = [];
        for (var i = 0; i < uploadFiles.length; i++) {
            if (!uploadFiles[i].name) return
            fileList.push(uploadFiles[i])
        }
        setDocuments(oldFiles => [...oldFiles, ...fileList])
    }

    const handleFileRemove = file => {
        if (!documents) return;
        setDocuments(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }
    const [t] = useTranslation();
    const { shipmentDetails } = useContext(ActiveShipmentsContext);

    return (
        <Modal
            open={open}
            className="tr__reupload-document"
            onCancel={onCancel}
            title={t("home.reupload-document.title")}
            btnClasses="justify-content-between"
            submitButton
            submitButtonLabel={t("home.reupload-document.button")}
            submitButtonDisabled={!documents.length}
            onSubmitClick={onSubmit}
            footerLabel={t("home.reupload-document.footerLabel")}
            isBackDropClickable={false}
        >
            <div className="tr__add-shipment-details-form--group mbx2">
                <RadioGroup
                    name="documentType"
                    buttonMode={false}
                    type="default"
                    horizontal
                    items={shipmentDetails?.shippingDetails?.shipmentMode === AIR ? reuploadAirDocument : reuploadDocument}
                    value={typeDocument}
                    onChange={doc => setTypeDocument(doc)}
                />
            </div>
            <div className="tr__add-shipment-details-form--group mbx3">
                <FileUploader
                    name="reuploadDocuments"
                    className="big-label"
                    handleDrop={handleFileUpload}
                    handleRemove={handleFileRemove}
                    uploadedFiles={documents}
                    mode="list"
                    multiple={false}
                    // error={!!errors.shipmentDetailsDocs}
                    // errorMessage={getErrorMessage(errors.shipmentDetailsDocs, "ShipmentDetails Docs Type")}
                    // refs={register}
                    outputFormat='base64'
                    limitNumberUploadFile={MAX_FILE_SHIPMENT_DETAIL_DOCS}
                    limitSizePerFile={MAX_SIZE_SHIPMENT_DETAIL_DOC}
                    fileTypeApproved={['image', 'pdf']}
                    ocrRequired={true}
                />
            </div>
        </Modal>
    )
};
