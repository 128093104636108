import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    Input,
    EditShipmentInfoDropdown,
    EditShipmentInfoInput
} from 'components';
import { ActiveShipmentsContext, TrackShipmentContext } from 'contexts';
import { AIR, DESTINATION, LOCODE_PAGE_SIZE, ORIGIN } from 'actions';
import { useTranslation } from 'react-i18next';

export const AddressDetails = ({ 
    register = () => {}, 
    routes, 
    editShipmentInfo,
    setShipmentDetail = () => {},
    setPrePolLocation = () => {},
    setPostPodLocation = () => {},
    setPostPodAir = () => {},
    setPrePolAir = () => {},
    isArchive = false,
    typeOfShipment,
    tabTypeShipment,
    shippingDetails = {},
}) => {
    const { origins, setOrigins, destinations, setDestinations, firstLocode, doGetLocodes } = useContext(TrackShipmentContext);
    const [t] = useTranslation();
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [shipperAddress, setShipperAddress] = useState('');
    const [consigneeAddress, setConsigneeAddress] = useState('');
    const [originalAddresses, setOriginalAddresses] = useState();
    const [originLoading, setOriginLoading] = useState(false);
    const [destinationLoading, setDestinationLoading] = useState(false);
    const [newPrepol, setNewPrepol] = useState();
    const [newPostpod, setNewPostpod] = useState();
    const [originalPrePol, setOriginalPrePol] = useState();
    const [originalPostPod, setOriginalPostPod] = useState();
    const [originPrePolAir, setOriginPrePolAir] = useState();
    const [originPostPodAir, setOriginPostPodAir] = useState()
    const [newPrePolAir, setNewPrePolAir] = useState();
    const [newPostPodAir, setNewPostPodAir] = useState();
    const { doGetAirPort, originAirPorts, destinationAirPorts } = useContext(ActiveShipmentsContext);

    /*eslint-disable */
    useEffect(() => {
        if(routes){
            const { prepol, postpod } = routes;
            if (routes.shipperPickupAdd) {
                const {shipperPickupAdd, consigneeDeliveryAdd } = routes
                setOriginalAddresses({...routes});
                setShipperAddress(shipperPickupAdd);
                setConsigneeAddress(consigneeDeliveryAdd);
            }

            setNewPrepol(getLocationValue(prepol));
            setNewPostpod(getLocationValue(postpod));
            setOriginalPrePol(getLocationValue(prepol));
            setOriginalPostPod(getLocationValue(postpod));

            setNewPrePolAir(getLocationValueAir(prepol));
            setNewPostPodAir(getLocationValueAir(postpod));
            setOriginPrePolAir(getLocationValueAir(prepol));
            setOriginPostPodAir(getLocationValueAir(postpod));
        }
        const scripts = document.scripts;
        const MAPBOX_GEOCODER_URL = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.min.js';
        let isInjected = false;

        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src === MAPBOX_GEOCODER_URL) {
                isInjected = true;
                break;
            }
        }

        if (!isInjected) {
            let mapGeocoderScriptElement = document.createElement('script');
            mapGeocoderScriptElement.src = MAPBOX_GEOCODER_URL;

            document.head.appendChild(mapGeocoderScriptElement);
        } else {
            setScriptLoaded(true);
        }

        if (firstLocode && firstLocode.length > 0) {
            setOrigins(firstLocode);
            setDestinations(firstLocode);
        }
    }, [])

    useEffect(() => {
        doGetAirPort({
            limit: 9999,
            page: 0
        });
    }, [])

    useEffect(() => {
        if (window.MapboxGeocoder) setScriptLoaded(true);
    }, [window.MapboxGeocoder])

    useEffect(() => {
        if (scriptLoaded) {
            addGeocoder('sa', 'shipperAddressId', 'Shipper Pick-up Address', setShipperAddress);
            addGeocoder('ca', 'consigneeAddressId', 'Consignee Delivery Address', setConsigneeAddress);
        }
    }, [scriptLoaded])
    /*eslint-enable */

    const onSearchOriginAirport = value => {
        setOriginLoading(true);
        doGetAirPort({
            limit: LOCODE_PAGE_SIZE,
            search: value.trim(),
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    }

    const onSearchDestAirport = value => {
        setDestinationLoading(true);
        doGetAirPort({
            limit: LOCODE_PAGE_SIZE,
            search: value.trim(),
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    }


    const getLocationValue = data => {
        if (!data || (data && !data.location)) return '';
        const { name, country, locode } = data.location;
        return name + (country ? `, ${country}` : '') + (locode ? ` (${locode})` : '');
    }

    const getLocationValueAir = data => {
        if (!data) return '';
        const { city, country, countryCode } = data;
        return city + (country ? `, ${country}` : '') + (countryCode ? ` (${countryCode})` : '');
    }

    const addGeocoder = (key, selector, placeholder, setPlace) => {
        window[key] = null;
        if (window.MapboxGeocoder) {
            window[key] = new window.MapboxGeocoder({
                accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
                types: 'country,region,place,postcode,locality,neighborhood',
                placeholder
            });

            window[key].addTo(`#${selector}`);

            window[key].on('result', result => {
                setPlace(result.result.place_name);
            })
        }
    }

    const handleSearch = (value, key, setValue) => {
        setValue(value);
        if (window[key]) {
            if (value) window[key].setInput(value)._geocode(value);
            else window[key].setInput(value);
        }
    }

    const onSearchOriginLocode = value => {
        setOriginLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
            setOrigins([]);
        }, ORIGIN);
    }

    const onSearchDestLocode = (value) => {
        setDestinationLoading(true);

        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
            setDestinations([]);
        }, DESTINATION);
    }

    return (
        <div className="tr__verify-details-form--group mbx4">
            <div className="tr__verify-details-form--group--content address-frame">
            <h3 className="h3 f-medium tr__info-block--label mtx2 mlx2 d-flex align-items-center"> 
                {t("home.active-shipment.details.tabs.shipment-tab.address")}
            </h3>
                <div className="tr__verify-details-form--group--content-frame">
                    <div className="tr__verify-details-form--group__input">
                        <div className="row">
                            {(tabTypeShipment === AIR || typeOfShipment?.key === AIR || shippingDetails?.shipmentMode === AIR) ? '' : <div className="col-12 col-sm-6 color-icon">
                                <EditShipmentInfoDropdown
                                    label={t("home.active-shipment.details.tabs.shipment-tab.pre-pol")}
                                    name="prePol"
                                    className="mtx2"
                                    placeholder={t("home.active-shipment.AddressDetails.prePol.placeholder")}
                                    icon="icon-chevron-down"
                                    iconPosition="right"
                                    mode="input"
                                    refs={register()}
                                    disabled={isArchive}
                                    options={origins && origins.length > 0 ? origins.map(locode => {
                                        return {
                                            key: locode.locode,
                                            value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                            item: locode
                                        }
                                    }) : []}
                                    dropdownPosition="center"
                                    onInputChange={onSearchOriginLocode}
                                    searchLoading={originLoading}
                                    onChange={option => {
                                        const { item } = option;
                                        if(editShipmentInfo){
                                            setNewPrepol(option ? option.key : '');
                                            setPrePolLocation({
                                                name: item.city,
                                                country: item.country,
                                                locode: item.locode
                                            });
                                        }
                                        setShipmentDetail(old => {
                                            return {
                                                ...old,
                                                routes: {
                                                    ...old?.routes,
                                                    prepol: {
                                                        ...old?.routes?.prepol,
                                                        location: {
                                                            name: item.city,
                                                            country: item.country,
                                                            locode: item.locode
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                    }}
                                    defaultValue={routes && routes.prepol && routes.prepol.location ? {
                                        key: routes.prepol.location.locode,
                                        value: `${routes.prepol.location.name}, ${routes.prepol.location.country} (${routes.prepol.location.locode})`,
                                        item: routes.prepol.location,
                                    } : null}
                                    originalvalue={originalPrePol}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={newPrepol}
                                />
                            </div> }
                           {(typeOfShipment?.key === AIR || tabTypeShipment === AIR || shippingDetails?.shipmentMode === AIR) && <div className="col-12 col-sm-6 color-icon">
                                <EditShipmentInfoDropdown
                                    label={'Origin Address'}
                                    name="originAdress"
                                    className="mtx2"
                                    placeholder={'Origin Address'}
                                    icon="icon-chevron-down"
                                    iconPosition="right"
                                    mode="input"
                                    refs={register()}
                                    disabled={isArchive}
                                    options={originAirPorts && originAirPorts.length > 0 ? originAirPorts.map(originPort => {
                                        return {
                                            key: originPort.countryCode,
                                            value: `${originPort.city}, ${originPort.country} (${originPort.countryCode})`,
                                            item: originPort
                                        }
                                    }) : []}
                                    onInputChange={onSearchOriginAirport}
                                    dropdownPosition="center"
                                    searchLoading={originLoading}
                                    onChange={option => {
                                        // const { item } = option;
                                        if(editShipmentInfo){
                                            setNewPrePolAir(option ? option.key : '');
                                            setPrePolAir(option.item);
                                        }
                                        setShipmentDetail(old => {
                                            return {
                                                ...old,
                                                airRoutes: {
                                                    ...old.airRoutes,
                                                    prepol: option.item
                                                }
                                            }
                                        });
                                    }}
                                    defaultValue={routes && routes.prepol && routes.prepol.location ? {
                                        key: routes.prepol.location.locode,
                                        value: `${routes.prepol.location.name}, ${routes.prepol.location.country} (${routes.prepol.location.locode})`,
                                        item: routes.prepol.location,
                                    } : (routes && routes.prepol ? {
                                        key: routes.prepol.city,
                                        value: `${routes.prepol.city}, ${routes.prepol.country} (${routes.prepol.countryCode})`,
                                        item: routes.prepol,
                                    } : null)}
                                    originalvalue={originPrePolAir}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={newPrePolAir}
                                />
                            </div>}
                            {(typeOfShipment?.key === AIR || tabTypeShipment === AIR || shippingDetails?.shipmentMode === AIR) && <div className="col-12 col-sm-6 color-icon">
                                <EditShipmentInfoDropdown
                                    label={'Destination Address'}
                                    name="destAddress"
                                    className="mtx2"
                                    placeholder={'Destination Address'}
                                    icon="icon-chevron-down"
                                    iconPosition="right"
                                    mode="input"
                                    refs={register()}
                                    disabled={isArchive}
                                    options={destinationAirPorts && destinationAirPorts.length > 0 ? destinationAirPorts.map(destAirPort => {
                                        return {
                                            key: destAirPort.countryCode,
                                            value: `${destAirPort.city}, ${destAirPort.country} (${destAirPort.countryCode})`,
                                            item: destAirPort
                                        }
                                    }) : []}
                                    onInputChange={onSearchDestAirport}
                                    dropdownPosition="center"
                                    searchLoading={destinationLoading}
                                    onChange={option => {
                                        // const { item } = option;
                                        if(editShipmentInfo){
                                            setNewPostPodAir(option ? option.key : '');
                                            setPostPodAir(option.item);
                                        }
                                        setShipmentDetail(old => {
                                            return {
                                                ...old,
                                                airRoutes: {
                                                    ...old.airRoutes,
                                                    postpod: option.item
                                                }
                                            }
                                        });
                                    }}
                                    defaultValue={routes && routes.postpod && routes.postpod.location ? {
                                        key: routes.postpod.location.locode,
                                        value: `${routes.postpod.location.name}, ${routes.postpod.location.country} (${routes.postpod.location.locode})`,
                                        item: routes.postpod.location,
                                    } :  (routes && routes.postpod ? {
                                        key: routes.postpod.city,
                                        value: `${routes.postpod.city}, ${routes.postpod.country} (${routes.postpod.countryCode})`,
                                        item: routes.postpod,
                                    } : null)}
                                    originalvalue={originPostPodAir}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={newPostPodAir}
                                />
                            </div>}
                           
                            {(tabTypeShipment === AIR || typeOfShipment?.key === AIR || shippingDetails?.shipmentMode === AIR) ? '' : <div className="col-12 col-sm-6">
                                <Input
                                    className="mtx2"
                                    name="pol"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.pol")}
                                    placeholder="POL Address"
                                    defaultValue={routes && routes.pol && routes.pol.location
                                        ? `${routes.pol.location.name}, ${routes.pol.location.country} (${routes.pol.location.locode})`
                                        : routes && routes.pol 
                                        ? getLocationValue(routes.pol) 
                                        : ''
                                    }
                                    viewOnly={true}
                                />
                            </div>}
                            
                            {(tabTypeShipment === AIR || typeOfShipment?.key === AIR || shippingDetails?.shipmentMode === AIR) ? '' : <div className="col-12 col-sm-6">
                                <Input
                                    label={t("home.active-shipment.details.tabs.shipment-tab.pod")}
                                    name="pod"
                                    className="mtx2"
                                    placeholder="POD Address"
                                    defaultValue={routes && routes.pod && routes.pod.location
                                        ? `${routes.pod.location.name}, ${routes.pod.location.country} (${routes.pod.location.locode})`
                                        : routes && routes.pod 
                                        ? getLocationValue(routes.pod) 
                                        : ''
                                    }
                                    viewOnly={true}
                                />
                            </div>}
                            {(tabTypeShipment === AIR || typeOfShipment?.key === AIR || shippingDetails?.shipmentMode === AIR) ? '' : <div className="col-12 col-sm-6 color-icon">
                                <EditShipmentInfoDropdown
                                    className="mtx2"
                                    name="postPod"
                                    label={t("home.active-shipment.details.tabs.shipment-tab.post-pod")}
                                    placeholder={t("home.active-shipment.AddressDetails.postPod.placeholder")}
                                    icon="icon-chevron-down"
                                    iconPosition="right"
                                    mode="input"
                                    disabled={isArchive}
                                    refs={register()}
                                    options={destinations && destinations.length > 0 ? destinations.map(locode => {
                                        return {
                                            key: locode.locode,
                                            value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                            item: locode
                                        }
                                    }) : []}
                                    dropdownPosition="center"
                                    onInputChange={onSearchDestLocode}
                                    searchLoading={destinationLoading}
                                    onChange={option => {
                                        const { item } = option;
                                        if(editShipmentInfo){
                                            setNewPostpod(option ? option.key : '');
                                            setPostPodLocation({
                                                name: item.city,
                                                country: item.country,
                                                locode: item.locode
                                            });
                                        }
                                        setShipmentDetail(old => {
                                            return {
                                                ...old,
                                                routes: {
                                                    ...old?.routes,
                                                    postpod: {
                                                        ...old?.routes?.postpod,
                                                        location: {
                                                            name: item.city,
                                                            country: item.country,
                                                            locode: item.locode
                                                        }
                                                    }
                                                }
                                            }
                                        })
                                    }}
                                    defaultValue={routes && routes.postpod && routes.postpod.location ? {
                                            key: routes.postpod.location.locode,
                                            value: `${routes.postpod.location.name}, ${routes.postpod.location.country} (${routes.postpod.location.locode})`,
                                            item: routes.postpod.location,
                                        } : null}
                                        originalvalue={originalPostPod}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={newPostpod}
                                    />
                                </div>}
                                <div className="col-12 col-sm-12 color-icon">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="shipperAddress"
                                        label={t("home.active-shipment.details.tabs.shipment-tab.shipper-pickup")}
                                        placeholder={t("home.active-shipment.AddressDetails.shipperAddress.placeholder")}
                                        errorMessage="Please Shipper Pick-up Address"
                                        iconPosition="right"
                                        autoComplete="new-password"
                                        value={shipperAddress}
                                        disabled={isArchive}
                                        refs={register()}
                                        onChange={e => handleSearch(e.target.value, 'sa', setShipperAddress)}
                                        icon="icon-location"
                                        originalvalue={originalAddresses ? originalAddresses.shipperPickupAdd : ''}
                                        newValue={shipperAddress}
                                        tooltipOldlvalue={editShipmentInfo}
                                    />
                                    <div id="shipperAddressId" className={shipperAddress ? '' : 'no-result'} />
                                </div>
                                <div className="col-12 col-sm-12 color-icon">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name="consigneeDeliveryAdress"
                                        label={t("home.active-shipment.details.tabs.shipment-tab.consignee-delivery")}
                                        placeholder={t("home.active-shipment.AddressDetails.consigneeDeliveryAdress.placeholder")}
                                        iconPosition="right"
                                        autoComplete="new-password"
                                        value={consigneeAddress}
                                        disabled={isArchive}
                                        refs={register()}
                                        onChange={e => handleSearch(e.target.value, 'ca', setConsigneeAddress)}
                                        icon="icon-location"
                                        originalvalue={originalAddresses ? originalAddresses.consigneeDeliveryAdd : ''}
                                        newValue={consigneeAddress}
                                        tooltipOldlvalue={editShipmentInfo}
                                    />
                                    <div id="consigneeAddressId" className={consigneeAddress ? '' : 'no-result'} />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
};

AddressDetails.propTypes = {
    error: PropTypes.bool,
    routes: PropTypes.object,
    editShipmentInfo: PropTypes.bool,
    errors: PropTypes.any,
    getValues: PropTypes.func,
    register: PropTypes.func
};
