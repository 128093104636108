import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export const IssuesHeader = ({ className = '', children }) => {
    return (
        <div className={`tr__issues-header ${className} `}>
            <div className="row">
                <div className="tr__issues-header--action">
                    <div className="right">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
};

IssuesHeader.propTypes = {
    className: PropTypes.string
};