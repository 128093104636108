import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import {
    Modal,
    MultiSelect,
    AssigneeList,
    ItemCard
} from 'components';
import avatar from 'assets/images/avatar.png';
import { CollaboratorsContext, DocumentsContext, HomeContext, ManagementContext, ModalContext, TemplateContext } from 'contexts';
import { ALL, COLLABORATORS_PAGE_SIZE, DOCUMENT_PAGE_SIZE, GET_FILE, TEAM } from 'actions';
import { useLocation } from 'react-router-dom';
import { difference } from 'lodash';
import { useTranslation } from 'react-i18next';

export const AssignModal = ({ open = false, onCancel }) => {
    const { t } = useTranslation();
    const { 
        taskId, 
        taskName, 
        taskRoles, 
        isTemplate, 
        isDocument, 
        isArchive, 
        isRemovePartners 
    } = useContext(HomeContext);
    const { taskCollaborators, doGetCollaborators } = useContext(CollaboratorsContext);
    const { taskPartners, doAssignTaskPartner, doGetTaskManagements, doRemoveTaskPartner, isDone } = useContext(ManagementContext);
    const { setAssignCollaborators } = useContext(ModalContext);
    const { 
        partnersTaskTemplate,
        templatesDetails, 
        doGetTemplatesDetails, 
        collaboratorsTemp, 
        doGetCollaboratorsTemplate, 
        doAssignPartnerTemplate,
        doRemovePartnerTemplate 
    } = useContext(TemplateContext);
    const {
        doGetDocuments,
    } = useContext(DocumentsContext);
    const [assignees, setAssignees] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [members, setMembers] = useState([]);
    const [searchValueMember, setSearchValueMember] = useState("");
    const [partners, setPartners] = useState([]);
    const [removePartner, setRemovedPartner] = useState([]);
    const location = useLocation();
    const pathnameArr = location.pathname.split('/');
    const shipmentId = pathnameArr[pathnameArr.length - 1];
    const shipmentIdforDocument = pathnameArr[pathnameArr.length - 2];

    /*eslint-disable */
    useEffect(() => {
        if(isTemplate) {
            setPartners(collaboratorsTemp.map(taskPartner => {
                if (taskPartner.base?.delete) {
                    taskPartner.name = "deleted_" + taskPartner.id
                }
                return taskPartner}));
        } else {
            setPartners(taskCollaborators.map(taskPartner => {
                if (taskPartner.base?.delete) {
                    taskPartner.name = "deleted_" + taskPartner.id
                }
                return taskPartner}));
        }
    },[collaboratorsTemp,taskCollaborators ]);
    
    useEffect(() => {
        setMembers((isTemplate 
            ? difference(partnersTaskTemplate, removePartner) 
            : taskPartners).filter(partner => partner.name.includes(searchValueMember) || (partner.email ? partner.email.includes(searchValueMember) : (
            partner.orgName && partner.orgName.includes(searchValueMember)))));
    }, [taskPartners, searchValueMember, partnersTaskTemplate])
    /*eslint-enable */

    const handleAddPartners = () => {
        let selectedPartners = [];
        assignees.forEach(item => {
            selectedPartners = [
                ...selectedPartners,
                {
                    id: item.id,
                    type: item.type,
                    roles: item.roles
                }
            ]
        });
        const payload = {
            partners: selectedPartners
        }
        let selectedPartnersTemplate = [];
        assignees.forEach(item => {
            selectedPartnersTemplate = [
                ...selectedPartnersTemplate,
                {
                    id: item.id,
                    type: item.type,
                }
            ]
        })
        const payloadTemplate = selectedPartnersTemplate;
        setAssignees([]);
        if (isTemplate) {
            doAssignPartnerTemplate(taskId, payloadTemplate, () => {
                doGetTemplatesDetails(templatesDetails.id);
                setAssignCollaborators(false);
            })
        } else if (isDocument) {
            doAssignTaskPartner(shipmentIdforDocument, taskId, payload, () => {
                doGetDocuments(shipmentIdforDocument, {
                    page: 0,
                    limit: DOCUMENT_PAGE_SIZE
                }, () => {
                });
                setAssignCollaborators(false);

            });
           
        } else {
            doAssignTaskPartner(shipmentId, taskId, payload);
            setAssignCollaborators(false);
        }
        
    }

    const handleSearchCollaborators = (value) => {
        const params = {
            query: value.trim(),
            limit: COLLABORATORS_PAGE_SIZE,
            page: 0,
            role: taskRoles.map(role => { return role.role })
        };
        const paramsTemplate = {
            query: value.trim(),
            limit: 9999,
            page: 0,
            role: taskRoles.map(role => { return role.role })
        }

        if (isTemplate) {
            setSearchLoading(true);
            doGetCollaboratorsTemplate(templatesDetails.id, paramsTemplate, () => {
                setSearchLoading(false);
            }, false);
        } else {
            setSearchLoading(true);
            doGetCollaborators(isDocument ? shipmentIdforDocument : shipmentId, params, () => {
                setSearchLoading(false);
            });
        }

    }

    const handleRemovePartner = (member) => {
        if(isTemplate) {
            doRemovePartnerTemplate(taskId, {
                id: member.id
            }, () => {
                // doGetTemplatesDetails(templatesDetails.id);
                setMembers(members.filter(partner => partner.id !== member.id));
                setRemovedPartner(old => [...old, member])
            })
        } else {
            doRemoveTaskPartner(taskId, {
                id: member.id
            })
        }
    }

    const handleChange = items => {
        setAssignees(oldValue => [...oldValue, ...items.map(item => {
            return {
                ...item,
            }
        })]);
    }

    const handleRemove = item => {
        setAssignees(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
    }

    return (
        <Modal
            open={open}
            className="tr__assign-modal no-padding"
            onCancel={() => { 
                onCancel();
                if(isTemplate) {
                    doGetTemplatesDetails(templatesDetails && templatesDetails.id)
                } else if (isDocument) {
                    doGetDocuments(shipmentIdforDocument, {
                        page: 0,
                        limit: DOCUMENT_PAGE_SIZE
                    }, () => {
                    })
                } else {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: ALL
                    });
                }
                setAssignees([]);
                setSearchValueMember("");
            }}
            title={`${t("home.assign.title")} "${taskName}"`}
            submitButton
            submitButtonLabel={t("home.assign.add-btn")}
            isBackDropClickable={false}
            submitButtonDisabled={!(assignees && assignees.length > 0)}
            onSubmitClick={handleAddPartners}
        >
            <div className="tr__assign-modal--form">
                <MultiSelect
                    className="mbx2"
                    options={partners && partners.length > 0 ? partners
                        .filter(collaborator => (isTemplate ? partnersTaskTemplate : taskPartners).every(m => m.id !== collaborator._id))
                        .filter(collaborator => assignees.every(m => m.id !== collaborator._id) && !collaborator.base?.delete)
                        .map(item => {
                        return {
                            id: item._id,
                            icon: item.avatar ? GET_FILE + item.avatar : (item.type === TEAM ? 'icon-users' : avatar),
                            title: item.name,
                            description: item.email,
                            type: item.type,
                            roles: item.roles
                        }
                    }) : []}
                    value={assignees}
                    // addButton={true}
                    // addButtonLabel={t("home.assign.select-btn")}
                    label={`${t("home.assign.title")} "${taskName}"`}
                    placeholder={t('home.BookingRequest.addBrCollaborators.placeholder')}
                    onChange={handleChange}
                    onRemove={handleRemove}
                    onInputChange={handleSearchCollaborators}
                    searchLoading={searchLoading}
                    disabled={isDone || isArchive}
                    renderList={members => members.length > 0 && (
                        <div className='tr__partners d-flex flex-wrap mtx1'>
                            {members && members.length > 0 && members.map((member, memberIndex) => (
                                <ItemCard
                                    key={memberIndex}
                                    item={member}
                                    onRemove={handleRemove}
                                    cardOnly={true}
                                />
                            ))}
                        </div>
                    )}
                />
            </div>
            <div className="tr__assign-modal--list">
                <AssigneeList
                    title={t("home.assign.assigned")}
                    assignees={members}
                    onRemove={handleRemovePartner}
                    setSearchValue={setSearchValueMember}
                    searchValue={searchValueMember}
                    isClosed={isDone}
                    minimal={!isRemovePartners}
                />
            </div>
        </Modal>
    )
};
