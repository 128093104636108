import React, { useContext, useEffect, useState } from 'react';
import { getErrorMessage } from 'utils';

import './index.scss';
import { useForm } from "react-hook-form";
import { Input, Dropdown, Button } from 'components';
import { 
    TAB_BOOKING_NO, 
    // TAB_BOOKING_NO, 
    // TRACKER_BOOKING_BK 
} from 'actions';
import { TrackShipmentContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const CourierNumberForm = ({ onSubmit, trackerParams }) => {

    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const { handleSubmit, register, errors, reset } = useForm();
    const { doGetTrackAndTraceV2, doGetCouriers, couriers } = useContext(TrackShipmentContext);
    const { t } = useTranslation();

    /*eslint-disable */
    useEffect(() => {
        doGetCouriers();
    }, [])
    /*eslint-enable */

    const handleSearch = (data) => {
        setLoading(true);
        // const { carrier, bookingNumber } = data;
        doGetTrackAndTraceV2({
            type: 'courier',
            // typeTracker: 'ocean',
            ...data, 
            format: 'new'
        }, () => {
            reset();
            setLoading(false);
            onSubmit(TAB_BOOKING_NO);
        }, () => {
            setLoading(false);
            onSubmit(TAB_BOOKING_NO);
        }, false);
    }

    const onSearchCarrier = (value) => {
        setSearchLoading(true);
        doGetCouriers({
            search : value.trim(),
        }, () => {
            setSearchLoading(false);
        }, () => {
            setSearchLoading(false);
        });
    }
    return (
        <form className="tr__container-form" onSubmit={handleSubmit(handleSearch)}>
            <div className="form-group">
                <div className="tr__container-form--group">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="carrierCode"
                        label={t('Select Courier')}
                        required
                        error={!!errors.carrierCode}
                        errorMessage={getErrorMessage(errors.carrierCode, "Courier")}
                        refs={register({ required: true })}
                        options={couriers && couriers.length > 0 ? couriers.map(courier => {
                            return {
                                key: courier.code,
                                value: `${courier.name} (${courier.code})`
                            }
                        }) : []}
                        placeholder={t('trackshipment.placeHolderSelectCourier')}
                        dropdownPosition="center"
                        onInputChange={onSearchCarrier}
                        searchLoading={searchLoading}
                        // defaultValue={trackerParams && trackerParams.scac ? trackerParams.scac : ""}
                    />
                </div>
                <div className="tr__container-form--group">
                    <Input
                        label={t('Enter Tracking Number')}
                        required
                        name="trackingNo"
                        placeholder="e.g. BC2473530"
                        refs={register({ required: true })}
                        error={!!errors.trackingNo}
                        errorMessage={getErrorMessage(errors.trackingNo, "BK Number")}
                        // defaultValue={trackerParams && trackerParams.carrierBookingNo ? trackerParams.carrierBookingNo : ""}
                    />
                </div>
            </div>
            <div className="form-btns">
                <Button
                    isSubmitBtn={true}
                    loading={loading}
                >
                    {t('trackshipment.searchForm')}
                </Button>
            </div>
        </form>
    )
};
