import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Modal,
    ExtractedInfoTable,
    Button
} from 'components';
import { ModalContext } from 'contexts';
import taxImage from 'assets/images/tax.pdf';
import { extractedInfo } from 'utils';

export const EditExtractedInfo = ({ open = false, onCancel }) => {
    const { setEditExtractedInfo } = useContext(ModalContext);
    const handleCloseModal = () => {
        onCancel();
    }

    const handleCancel = () => {
        setEditExtractedInfo(false);
    }
    return (
        <Modal
            open={open}
            className="tr__edit-extracted-info no-padding"
            onCancel={handleCloseModal}
            title='View And Edit Extracted Info'
            isBackDropClickable={false}
            footerLabel=""
        >
            <div className="page-box">
                <div className="tr__edit-extracted-info--title mtx2 mbx3 d-flex f-medium align-items-center">
                    <h4 className="h4">Invoice No..:</h4>
                    <h2 className="h2 mlx1">9340019971</h2>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="tax-image">
                        <embed src={taxImage} width="800px" height="1000px" />
                    </div>
                    <div className="tax-info">
                        <ExtractedInfoTable 
                        extractedInfo={extractedInfo} 
                        />
                    </div>
                </div>
            </div>
            <div className="tr__add-link-task__footer d-flex align-items-center justify-content-between">
                <Button
                    className="icon"
                    type="primary"
                    icon="icon-plus"
                >
                    Add New Section
                </Button>
                <div>
                    <p className="tr__link secondary f-medium" onClick={handleCancel}>Cancel</p>
                    <Button>
                        Continue
                    </Button>
                </div>
            </div>
        </Modal>
    )
};

EditExtractedInfo.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func
}