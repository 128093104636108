import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Button
} from 'components';
import {
    HomeContext,
    ModalContext,
    ManagementContext,
    // CollaboratorsContext,
    // AuthContext
} from 'contexts';
import { CANCELED, COMPLETE, PERMISSIONS_LIST } from 'actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const TaskManagementButtons = ({ className = '', currentTab }) => {
    // const { userInfo } = useContext(AuthContext);
    const { isArchive, setSectionName, setCurrentTabManagement, permission } = useContext(HomeContext);
    const { setEditSection, setConfirmStatusShipment, setConfirmSaveTemplate } = useContext(ModalContext);
    const { setStatusShipment } = useContext(ManagementContext);
    // const { collaborators } = useContext(CollaboratorsContext);
    const { shipmentId } = useParams();
    const {t} =  useTranslation();

    const changeStatus = (status) => {
        setConfirmStatusShipment(true);
        setStatusShipment({
            shipmentId,
            status
        })
    }

    const handleSaveAsTemplate = () => {
        setConfirmSaveTemplate(true);
    }

    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }

    return (
        <div className={`tr__task-management--buttons d-flex align-items-center justify-content-between ${className}`}>
            <div className="left d-flex align-items-center">
                {!isArchive && checkPermission(PERMISSIONS_LIST.SECTION_CREATE) && (
                    <Button
                        className="icon"
                        type="primary"
                        icon="icon-plus"
                        onClick={() => {
                            setEditSection(true);
                            setSectionName('');
                            setCurrentTabManagement(currentTab);
                        }}
                        // disabled={!collaborators.some(collaborator => collaborator._id === userInfo._id)}
                    >
                        {t("home.active-shipment.details.tabs.management-tab.add-section")}
                    </Button>
                )}
                {!isArchive && checkPermission(PERMISSIONS_LIST.SHIPMENT_SAVE_AS_TEMPLATE) && (
                    <Button
                        className="outline"
                        type="secondary"
                        onClick={handleSaveAsTemplate}
                        // disabled={!collaborators.some(collaborator => collaborator._id === userInfo._id)}
                    >
                       {t("home.active-shipment.details.tabs.management-tab.save-as-template")}
                    </Button>
                )}
               
            </div>
                <div className="right d-flex align-items-center">
                {!isArchive && checkPermission(PERMISSIONS_LIST.SHIPMENT_CANCEL) && (
                    <Button
                        className="outline"
                        type="secondary"
                        onClick={() => changeStatus(CANCELED)}
                        // disabled={!collaborators.some(collaborator => collaborator._id === userInfo._id)}
                    >
                        {t("home.active-shipment.details.tabs.management-tab.cancel-shipment")}
                    </Button>
                )}
                {!isArchive && checkPermission(PERMISSIONS_LIST.SHIPMENT_MARK_AS_COMPLETE) && (
                    <Button
                        type="primary"
                        onClick={() => changeStatus(COMPLETE)}
                        // disabled={!collaborators.some(collaborator => collaborator._id === userInfo._id)}
                    >
                        {t("home.active-shipment.details.tabs.management-tab.mark-as-complete")}
                    </Button>
                )}
            </div>
        </div>
    )
};

TaskManagementButtons.propTypes = {
    className: PropTypes.string
};