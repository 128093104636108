import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { Button } from 'components';

export const ActionButton = ({ btnClasses = 'justify-content-between', buttonLeftLabel, buttonRightLabel, buttonEditLabel, onDelete, onClick, onEdit }) => {

    return (
        <div className={`tr__action-button d-flex align-items-center ${btnClasses}`}>
             <div>
                {buttonLeftLabel && <Button
                    className="w-100"
                    onClick={onClick}
                >
                    {buttonLeftLabel}
                </Button> }
            </div>
            

            <div>
                {buttonEditLabel && (
                    <Button
                            className="w-100 tr__button secondary outline mrx2"
                            onClick={onEdit}
                        > 
                        {buttonEditLabel}    
                    </Button>
                )}
                {buttonRightLabel && (
                    <Button
                            className="w-100"
                            type="danger"
                            onClick={onDelete}
                        >
                        {buttonRightLabel}
                    </Button>
                    )
                }
            </div>
               
        </div>
    )
};

ActionButton.propTypes = {
    btnClasses: PropTypes.string,
    buttonLeftLabel: PropTypes.any,
    buttonRightLabel: PropTypes.any,
    onDelete: PropTypes.func,
    onClick: PropTypes.func
};