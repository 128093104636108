import React, { useContext, useEffect, useState } from 'react';
import { getErrorMessage } from 'utils';

import './index.scss';
import { useForm } from "react-hook-form";
import { Input, Dropdown, Button } from 'components';
import { CARRIER_PAGE_SIZE, TAB_BOOKING_NO } from 'actions';
import { TrackShipmentContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const BookingNoForm = ({ onSubmit, trackerParams }) => {

    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const { handleSubmit, register, errors, reset } = useForm();
    const { carriers, doGetCarriers, doGetTrackAndTraceV2 } = useContext(TrackShipmentContext);
    const { t } = useTranslation();

    /*eslint-disable */
    useEffect(() => {
        doGetCarriers({ limit: CARRIER_PAGE_SIZE });
    }, [])
    /*eslint-enable */

    const handleSearch = (data) => {
        setLoading(true);
        // const { carrier, bookingNumber } = data;
        doGetTrackAndTraceV2({
            // type: TRACKER_BOOKING_BK,
            type: 'ocean',
            typeTracker: 'BK',
            ...data
        }, () => {
            reset();
            setLoading(false);
            onSubmit(TAB_BOOKING_NO);
        }, () => {
            setLoading(false);
            onSubmit(TAB_BOOKING_NO);
        }, false);
    }

    const onSearchCarrier = (value) => {
        setSearchLoading(true);
        doGetCarriers({
            limit: CARRIER_PAGE_SIZE,
            nameOrCode: value.trim(),
        }, () => {
            setSearchLoading(false);
        }, () => {
            setSearchLoading(false);
        });
    }
    return (
        <form className="tr__container-form" onSubmit={handleSubmit(handleSearch)}>
            <div className="form-group">
                <div className="tr__container-form--group">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="scac"
                        label={t('trackshipment.selectCarrier')}
                        required
                        error={!!errors.scac}
                        errorMessage={getErrorMessage(errors.scac, "Carrier")}
                        refs={register({ required: true })}
                        options={carriers && carriers.length > 0 ? carriers.map(carrier => {
                            return {
                                key: carrier.scacCode,
                                value: `${carrier.carrierName} (${carrier.scacCode})`
                            }
                        }) : []}
                        placeholder={t('trackshipment.placeHolderSelect')}
                        dropdownPosition="center"
                        onInputChange={onSearchCarrier}
                        searchLoading={searchLoading}
                        defaultValue={trackerParams && trackerParams.carrier ? trackerParams.carrier : ""}
                    />
                </div>
                <div className="tr__container-form--group">
                    <Input
                        label={t('trackshipment.labelBookingConfirmNum')}
                        required
                        name="carrierBookingNo"
                        placeholder="e.g. BC2473530"
                        refs={register({ required: true })}
                        error={!!errors.carrierBookingNo}
                        errorMessage={getErrorMessage(errors.carrierBookingNo, "BK Number")}
                        defaultValue={trackerParams && trackerParams.bookingConfirmNumber ? trackerParams.bookingConfirmNumber : ""}
                    />
                </div>
            </div>
            <div className="form-btns">
                <Button
                    isSubmitBtn={true}
                    loading={loading}
                >
                    {t('trackshipment.searchForm')}
                </Button>
            </div>
        </form>
    )
};
