import React, { useContext, useEffect, useState } from 'react';

import './index.scss';
import {
    Button,
    UploadInvoicesForm,
    VerifyInvoices,
    FTADetails
} from 'components';
import { useForm } from 'react-hook-form';
import { DocumentsContext, ExtractedContext, FtaAdvisorContext, HomeContext, ManagementContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { ALL, DOCUMENT_DEFAULT_FTA, INVOICE, PERMISSIONS_LIST } from 'actions';
import { useTranslation } from 'react-i18next';

export const FTAAdvisor = ({ isArchive = false }) => {
    const [t] = useTranslation();
    const [step, setStep] = useState(0);
    const [ftaAdvisor, setFtaAdvisor] = useState();
    const [files, setFiles] = useState([]);
    const [taskDocument, setTaskDocument] = useState();
    const { permission } = useContext(HomeContext);
    const { setExtractedFtaData } = useContext(ExtractedContext);
    const { doGetFtaAdvisorResult } = useContext(FtaAdvisorContext);
    const { shipmentId } = useParams();
    const { doGetTaskManagements } = useContext(ManagementContext);
    const {
        doUploadDocumentsTask,
        doGetDocuments
    } = useContext(DocumentsContext);
    const { handleSubmit, register, errors, reset, getValues, setValue, watch, trigger, clearErrors } = useForm();

    /*eslint-disable */
    useEffect(() => {
        doGetTaskManagements(shipmentId, {
            page: 0,
            tab: ALL
        });
        doGetDocuments(shipmentId, {
            page: 0,
            limit: 2
        }, (data) => {
            const taskFTA = data?.items.find(doc => doc?.taskName === DOCUMENT_DEFAULT_FTA)
            setTaskDocument(taskFTA);
        })
    },[])
    /*eslint-enable */

    const handleChangeSection = stepValue => setStep(oldStep => oldStep + stepValue);

    const markAsFtaAdvisorSearch = async (data) => {
        const {
            ftaOrigin,
            ftaDestination,
            invoices
        } = data;
        let params = null;
        if(step === 0){
            if(ftaOrigin && ftaDestination) params = {ftaOrigin, ftaDestination};
            // if (files.length > 0){
                let payload = [];
                files.forEach(file => {
                    payload = [
                        ...payload,
                        {
                            awsId: file.id,
                            fileName: file.name,
                            documentLink: file.url,
                            typeOfDocument: INVOICE,
                        }
                    ]
                })
                // console.log('payload'+ taskDocument._id)
                await doUploadDocumentsTask(taskDocument && taskDocument._id, payload, () => {
                    // setFiles([]);  
                })
                setFtaAdvisor(params);
                handleChangeSection(1);
                reset();
            // }
            
            
        } if(step === 1){
            const invoice = [];
            invoices.forEach(dt => {
                const items = [];
                dt.items.forEach(item => {
                    if(item)
                        items.push({
                            currency: item.currency,
                            hsCode: item.hsCode,
                            netPrice: item.netPrice.replace(",",""),
                            goodsType: item.description ? item.description : ''
                        });
                });
                invoice.push({invoiceNumber: dt.invoiceNumber,items});
            })
            params = {...ftaAdvisor, invoice};
            setFtaAdvisor(params);
            const param = {
                origin: params.ftaOrigin,
                destination: params.ftaDestination
            };
            await doGetFtaAdvisorResult(shipmentId,param, params.invoice);
            handleChangeSection(1);
            reset();
        }
    }
    
    const removeFile = invoice => {
        setFiles(oldFiles => [...oldFiles.filter(f => !(f.id === invoice.id))])
    }

    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action)
    }

    return (
        <form className="tr__fta-advisor" onSubmit={handleSubmit(markAsFtaAdvisorSearch)}>
            <div className="tr__fta-advisor--header d-flex align-items-center">
                {step > 0 && (
                    <p className="tr__link tag mrx2" onClick={() => {
                        reset();
                        handleChangeSection(-1);
                        setExtractedFtaData([]);
                    }}>
                        <i className="icon-chevron-left" />
                        <span>{t("home.active-shipment.details.tabs.fta-tab.back")}</span>
                    </p>
                )}
                {step < 2 && checkPermission(PERMISSIONS_LIST.FTA_UPLOAD_INVOICES) && (
                    <h2 className="h2 f-medium">{t("home.active-shipment.details.tabs.fta-tab.search")}</h2>
                )}
            </div>
            {step < 2 ? (
                <div className={`tr__fta-advisor--content ${step ? 'wide' : ''}`}>
                    {step === 0 && (
                        <>
                        {checkPermission(PERMISSIONS_LIST.FTA_UPLOAD_INVOICES) ? (
                            <UploadInvoicesForm
                            register={register}
                            errors={errors}
                            ftaAdvisor={ftaAdvisor}
                            files={files}
                            setFiles={setFiles}
                            setValue={setValue}
                            isArchive={isArchive}
                         />
                        ) :  (
                            <h3 className="tr__fta-advisor--content__permission-message h2 text-center"> {t("home.active-shipment.details.tabs.fta-tab.permission-message")}</h3>
                            )
                        }
                        </>
                    )}
                    {step === 1 && (
                        <VerifyInvoices
                            register={register}
                            errors={errors}
                            ftaAdvisor={ftaAdvisor}
                            getValues={getValues}
                            files={files}
                            removeFile={removeFile}
                            setValue={setValue}
                            watch={watch}
                            clearErrors={clearErrors}
                        />
                    )}
                </div>
            ) : <FTADetails 
                    register={register}
                    errors={errors}
                    ftaAdvisor={ftaAdvisor}
                    setFtaAdvisor={setFtaAdvisor}
                    files={files}
                    setFiles={setFiles}
                    handleChangeSection={handleChangeSection}
                    trigger={trigger}
                    getValues={getValues}
                />
            }
            {step < 2 && (
                <div className="tr__fta-advisor--footer d-flex align-items-center justify-content-end">
                    {step === 0 && checkPermission(PERMISSIONS_LIST.FTA_UPLOAD_INVOICES) && (
                        <>
                            <p>{t("home.active-shipment.details.tabs.fta-tab.all-uploaded-invoices")}</p>
                            <Button isSubmitBtn={true} disabled={isArchive}>{t("home.active-shipment.details.tabs.fta-tab.continue")}</Button>
                        </>
                    )}
                    {step === 1 && (
                        <div className="d-flex align-items-center">
                            <p className="tr__link f-medium secondary mrx2" onClick={() => { 
                                reset(); 
                                handleChangeSection(-1); 
                                setExtractedFtaData([]);
                            }}>
                                {t("home.active-shipment.details.tabs.fta-tab.cancel")}
                            </p>
                            <Button
                                isSubmitBtn={true}
                            >
                                {t("home.active-shipment.details.tabs.fta-tab.save-and-continue")}
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </form>
    )
}
