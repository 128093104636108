import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    EditShipmentInfoInput,
    EditShipmentInfoDatepicker
} from 'components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AIR, FCL } from 'actions';

export const EditCutOff = ({ 
    register = () => {}, 
    cutOff,
    watch = () => {}, 
    editShipmentInfo,
    isArchive = false,
    selectedShipmentType,
    isEditShipmentInfo = false,
    shippingDetails,
    tabTypeShipment,
    isLinkedShipment = false
}) => {
    const [cutOffDate,setCutOffDate] = useState('');
    const [t] = useTranslation();
    useEffect(()=>{
        const date = cutOff && cutOff.date ? moment(+cutOff.date).format('DD MMM YYYY') : null
        setCutOffDate(date);
    },[cutOff])

    return (
        <div className={`tr__verify-details-form--group mbx4 col-12 ${(shippingDetails?.shipmentMode === AIR || tabTypeShipment === AIR ) ? 'col-sm-4' : 'col-sm-3' }`}>
            <div className="tr__verify-details-form--group--content">
                <h3 className="h3 f-medium tr__info-block--label mtx2 mlx2 d-flex align-items-center"> 
                    {t("home.active-shipment.details.tabs.shipment-tab.cutoff")}
                </h3>
                <div className="tr__verify-details-form--group--content-frame">
                    <div className="tr__verify-details-form--group__input">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="vgmSubmissionTimeline"
                                    refs={register()}
                                    viewOnly={isArchive}
                                    label={t("home.active-shipment.details.tabs.shipment-tab.vgm-ubmission-timeline")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    defaultValue={cutOff && cutOff.vgmSubmissionTimeline ? cutOff.vgmSubmissionTimeline : ''}
                                    originalvalue={cutOff && cutOff.vgmSubmissionTimeline ? cutOff.vgmSubmissionTimeline : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={watch('vgmSubmissionTimeline',cutOff && cutOff.vgmSubmissionTimeline ? cutOff.vgmSubmissionTimeline : '')}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="siSubmission"
                                    refs={register()}
                                    viewOnly={isArchive}
                                    label={t("home.active-shipment.details.tabs.shipment-tab.si-submission")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    defaultValue={cutOff && cutOff.siSubmission ? cutOff.siSubmission : ''}
                                    originalvalue={cutOff && cutOff.siSubmission ? cutOff.siSubmission : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={watch('siSubmission',cutOff && cutOff.siSubmission ? cutOff.siSubmission : '')}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoDatepicker
                                    className="mtx2"
                                    name="cutOffDate"
                                    refs={register()}
                                    viewOnly={isArchive}
                                    label={shippingDetails ? (shippingDetails?.shipmentType === FCL ? t("home.active-shipment.details.tabs.shipment-tab.date.fcl") : t("home.active-shipment.details.tabs.shipment-tab.date.lcl")) : (selectedShipmentType && selectedShipmentType.id === FCL ? t("home.active-shipment.details.tabs.shipment-tab.date.fcl") : t("home.active-shipment.details.tabs.shipment-tab.date.lcl"))}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.choose-a-date")}
                                    value={cutOffDate}
                                    onDatesChange={(date) => {setCutOffDate(date)}}
                                    originalvalue={cutOff && cutOff.date ? moment(+cutOff.date).format('DD MMM YYYY') : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={moment(cutOffDate).format('DD MMM YYYY')}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <EditShipmentInfoInput
                                    className="mtx2"
                                    name="cutOffTime"
                                    refs={register()}
                                    viewOnly={isArchive}
                                    label={t("home.active-shipment.details.tabs.shipment-tab.time")}
                                    placeholder={t("home.active-shipment.details.tabs.shipment-tab.please-enter-the-value")}
                                    defaultValue={cutOff && cutOff.time ? cutOff.time : ''}
                                    originalvalue={cutOff && cutOff.time ? cutOff.time : ''}
                                    tooltipOldlvalue={editShipmentInfo}
                                    newValue={watch('cutOffTime',cutOff && cutOff.time ? cutOff.time : '')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

EditCutOff.propTypes = {
    register: PropTypes.func,
    cutOff: PropTypes.object,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool
};
