import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty } from 'lodash';

import './index.scss';
import {
    ShipmentSteps,
    Input,
    Button
} from 'components';
import { 
    ActiveShipmentsContext,
    AuthContext,
    DocumentsContext, 
    HomeContext, 
    ManagementContext, 
    ModalContext, 
    ShipmentInfoContext, 
    TemplateContext, 
    TrackShipmentContext 
} from 'contexts';
import { roles } from 'utils';
import { useHistory } from 'react-router-dom';
import { AIR, ALL, DOCUMENT_PAGE_SIZE, LOCODE_PAGE_SIZE, MINE, PERMISSIONS_LIST, TASK_PAGE_SIZE } from 'actions';
import { useTranslation } from 'react-i18next';

export const ShipmentDetailsCard = ({ className = '', shipment, onShowDetails, isDetails = false, isCardDetails = false, shipmentDetails = {} }) => {
    const { t } = useTranslation();
    const { isArchive, currentTabManagement, permission } = useContext(HomeContext);
    const { doUpdateShipmentNote } = useContext(ShipmentInfoContext);
    const [isEditBC, setIsEditBC] = useState(false);
    const [shipmentNote, setShipmentNote] = useState();
    const { setAddLink } = useContext(ModalContext);
    const { doGetTemplates } = useContext(TemplateContext);
    const { doGetLocodes } = useContext(TrackShipmentContext);
    const { doGetActiveShipmentsDetails } = useContext(ActiveShipmentsContext);
    const { doGetDocuments } = useContext(DocumentsContext);
    const { doGetShipmentInfo } = useContext(ShipmentInfoContext);
    const { doGetTaskManagements } = useContext(ManagementContext);
    const { userInfo } = useContext(AuthContext);
    const history = useHistory();
    const consigneeIdShipment = shipmentDetails?.linkedShipment?.consigneeLinkedShipment?.id;
    const shipperIdShipment = shipmentDetails?.linkedShipment?.shipperLinkedShipment?.id;

    /*eslint-disable */
    useEffect(() => {
        setShipmentNote(shipmentDetails && shipmentDetails.note ? shipmentDetails.note.content : '')
    }, [shipmentDetails])
    /*eslint-enable*/

    const toggleEditBC = () => {
        if (!isArchive) {
            setShipmentNote(shipmentNote);
            setIsEditBC(!isEditBC);
        }
    };

    const canPressButton = () => {
        return shipmentDetails && shipmentDetails?.roleInShipments?.some(role => ['SHIPMENT_ADMIN', 'SHIPPER', 'CONSIGNEE'].includes(role?.roleType) && role?.partners?.some(partner => partner?.id === userInfo?._id));
    }

    const handleRedirectPage = (linkedIdShipment) => {
        doGetActiveShipmentsDetails(linkedIdShipment);
        doGetShipmentInfo(linkedIdShipment);
         const params = {
            page: 0,
            limit: TASK_PAGE_SIZE,
            tab: currentTabManagement
        }
        doGetTaskManagements(linkedIdShipment, params, () => {});
        if(linkedIdShipment) {
            history.push(`/${isArchive ? 'archive' : 'active-shipments'}/${linkedIdShipment}`);
        }
    }

    const handleUpdateShipmentNote = () => {
        shipmentDetails.note = shipmentNote;
        doUpdateShipmentNote(shipment._id, shipmentNote, () => {
            setShipmentNote(shipmentNote);
            setIsEditBC(false);
        });
    }

    const handleCancelUpdateShipmentNote = (e) => {
        setIsEditBC(false);
        setShipmentNote(shipment.note ? shipment.note.content : 'act');
    }

    const getShipmentStep = () => {
        if (!shipmentDetails || !shipmentDetails.addresses) return null;
        const { addresses, _id } = shipmentDetails;
        const { prepol, pol, pod, postpod } = addresses;
        const newPrepol = {
            id: `${_id}-step1`,
            icon: `${shipmentDetails?.shippingDetails?.shipmentMode === AIR ? 'icon-plane' : 'icon-containers'}`,
            title: prepol && prepol.location && pol && pol.location && prepol.location.locode !== pol.location.locode
                ? `${prepol.location.name}, ${prepol.location.country} (${prepol.location.locode})`
                : 'PREPOL',
            label: 'Arr: ',
            date: prepol && prepol.date
                ? moment.utc(prepol.date).format('YYYY-MM-DD HH:mm')
                : '',
            showHours: true,
            actual: prepol ? prepol.actual : (pol ? pol.actual : false),
        };
        const newPol = {
            id: `${_id}-step2`,
            icon: `${shipmentDetails?.shippingDetails?.shipmentMode === AIR ? 'icon-airport' : 'icon-anchor'}`,
            title: `${pol?.city ? pol?.city : pol && pol.location && pol.location.name}, ${pol?.country ? pol?.country : pol && pol.location && pol.location.country} (${pol?.countryCode ? pol?.countryCode : pol && pol.location && pol.location.locode})`,
            label: 'Dept: ',
            date: moment.utc(shipmentDetails.departureDate).format('YYYY-MM-DD HH:mm'),
            showHours: true,
            actual: pol ? pol.actual : false,
        };
        const newPod = {
            id: `${_id}-step4`,
            icon: `${shipmentDetails?.shippingDetails?.shipmentMode === AIR ? 'icon-airport' : 'icon-anchor' }`,
            title: `${ pod?.city ? pod?.city : pod && pod.location && pod.location.name}, ${pod?.country ? pod?.country : pod && pod.location && pod.location.country} (${pod?.countryCode ? pod?.countryCode : pod && pod.location && pod.location.locode})`,
            label: 'Est Arr: ',
            date: moment.utc(shipmentDetails.arrivalDate).format('YYYY-MM-DD HH:mm'),
            showHours: true,
            actual: pod ? pod.actual : false,
        };
        const newPostPod = {
            id: `${_id}-step5`,
            icon: `${shipmentDetails?.shippingDetails?.shipmentMode === AIR ? 'icon-plane' : 'icon-anchor'}`,
            title: postpod && postpod.location && pod && pod.location && postpod.location.locode !== pod.location.locode
                ? `${postpod.location.name}, ${postpod.location.country} (${postpod.location.locode})`
                : 'POSTPOD',
            label: 'Est. Arr: ',
            date: postpod && postpod.date
                ? moment.utc(postpod.date).format('YYYY-MM-DD HH:mm')
                : '',
            showHours: true,
            actual: postpod ? postpod.actual : (pod ? pod.actual : false),
        };

        return [
            newPrepol,
            newPol,
            {
                id: `${shipmentDetails?.permission?.shipmentId}-step3`,
                icon: `${shipmentDetails?.shippingDetails?.shipmentMode === AIR ? 'icon-plane' : 'icon-ship'}`,
                actual: newPol.actual && newPod.actual
            },
            newPod,
            newPostPod,
        ];
    }

    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }


    return shipmentDetails ? (
        <div className={`tr__active-shipment-item ${className}`}>
            <div className="tr__active-shipment-item--body">
                <div className="tr__active-shipment-item--header box-header d-flex align-items-center">
                    {shipmentDetails?.addresses && shipmentDetails.addresses.pol && shipmentDetails.addresses.pod && (
                        <h4 className="h4 f-medium">
                            {isArchive && !isDetails && shipmentDetails?.status && (
                                <span className={`status-tag text-capitalize ${shipmentDetails.status}`}>{shipmentDetails.status}</span>
                            )}
                            {shipmentDetails && shipmentDetails.shipmentName}
                        </h4>
                    )}
                    <div className="note d-flex">
                        {isEditBC ? (
                            <div className="edit-form d-flex align-items-center">
                                <Input
                                    defaultValue={shipmentDetails && shipmentDetails.note && shipmentDetails.note.content}
                                    placeholder={t("home.active-shipment.add-note")}
                                    value={shipmentNote}
                                    onChange={e => setShipmentNote(e.target.value)}
                                />
                                <Button
                                    className="mlx1 outline"
                                    onClick={handleUpdateShipmentNote}
                                >
                                    {t("home.active-shipment.save-note")}
                                </Button>
                                <Button
                                    className="mlx1 outline"
                                    onClick={handleCancelUpdateShipmentNote}
                                >
                                    {t("home.active-shipment.cancel")}
                                </Button>
                            </div>
                        ) : (
                            <>
                                {shipmentDetails.note && (
                                    <span>{shipmentNote || ''}</span>
                                )}
                                <>
                                    <i className="icon-pencil" onClick={toggleEditBC} />
                                </>
                            </>
                        )}
                    </div>
                    <div className="shipment d-flex align-items-center">
                        <div className="info">
                            {!isArchive && (
                                <i className={shipmentDetails && shipmentDetails.shippingDetails && shipmentDetails.shippingDetails.shipmentType === 'FCL' ? 'icon-container-solid' : 'icon-boxes-solid'} />
                            )}
                            <span>{shipmentDetails && shipmentDetails.shippingDetails && shipmentDetails.shippingDetails.shipmentType}</span>

                            {(!!shipmentDetails?.containersNumb || !!shipmentDetails?.packetsNumb) && (
                                <span className='no-of-product'>
                                    {(shipmentDetails?.containersNumb) ? `${shipmentDetails?.containersNumb} Container` : `${shipmentDetails?.packetsNumb} Packet`}{(shipmentDetails?.containersNumb > 1 || shipmentDetails?.packetsNumb > 1) ? 's' : ''}
                                </span>
                            )}
                        </div>
                        {shipmentDetails.openIssuesNumber > 0 && (
                            <span className="task-warning d-flex align-items-center mlx2">
                                <i className="icon-warning mrx1" />
                                <span>{shipmentDetails.openIssuesNumber} open</span>
                            </span>
                        )}
                    </div>
                </div>
                <div className="tr__active-shipment-item--steps d-flex" onClick={() => onShowDetails ? onShowDetails(shipment._id) : null}>
                    {getShipmentStep() && getShipmentStep().length > 0 && getShipmentStep().map((step, stepIndex) => (
                        <ShipmentSteps
                            key={stepIndex}
                            step={step}
                            active={step.actual}
                            align={stepIndex > 2 ? 'right' : 'left'}
                        />
                    ))}
                </div>
            </div>
            <div className="tr__active-shipment-item--footer">
                {isDetails ? (
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="shipment-teams d-flex align-items-center">
                            <i className="icon-shipper active" />
                            <i className="icon-box active" />
                            <i className="icon-truck" />
                            <i className="icon-box-export" />
                            <i className="icon-box-import" />
                            <i className="icon-export" />
                            <i className="icon-import" />
                        </div>
                    </div>
                ) : (
                    <>
                        {isCardDetails && <div className="tr__active-shipment-item--footer--icon d-flex align-items-center justify-content-between">
                                <div className ="d-flex">
                                    {roles && roles.length > 0 && (
                                        <>
                                            {roles.map((role, roleIndex) => (
                                                <>
                                                    {(shipmentDetails && shipmentDetails.currentUserRoleType) && (
                                                        <div key={roleIndex} className={`bg-icon ${shipmentDetails.currentUserRoleType.includes(role.id) ? 'active' : ''}`}>
                                                            <i className={role.icon} />
                                                        </div>
                                                    )

                                                    }
                                                </>
                                            ))}
                                        </>
                                    )}
                                </div>
                            <div>
                            {shipmentDetails?.isLinkedShipment === false && !isEmpty(shipmentDetails?.linkedShipment?.consigneeLinkedShipment) && <Button
                                className="tr__link-shipment-create mrx3"
                                onClick={() => handleRedirectPage(consigneeIdShipment)}
                                disabled={!canPressButton()}
                            >
                                Link Shipment : {shipmentDetails?.linkedShipment?.consigneeLinkedShipment?.shipmentName}
                            </Button>
                            }

                            {shipmentDetails?.isLinkedShipment === false && !isEmpty(shipmentDetails?.linkedShipment?.shipperLinkedShipment) && <Button
                                className="tr__link-shipment-create mrx3"
                                onClick={() => handleRedirectPage(shipperIdShipment)}
                                disabled={!canPressButton() && !checkPermission(PERMISSIONS_LIST.SHIPMENT_VIEW_LINKED_SHIPMENT)}
                            >
                                Link Shipment : {shipmentDetails?.linkedShipment?.shipperLinkedShipment?.shipmentName}
                            </Button>
                            }
                            {(!isArchive && checkPermission(PERMISSIONS_LIST.SHIPMENT_CREATE_LINKED_SHIPMENT)) && (
                                <>
                                {(shipmentDetails?.isLinkedShipment || (!isEmpty(shipmentDetails?.linkedShipment?.consigneeLinkedShipment) && !isEmpty(shipmentDetails?.linkedShipment?.shipperLinkedShipment?.id))) ? '' : <Button
                                className="outline mrx3"
                                onClick={() => {
                                    doGetLocodes({ limit: LOCODE_PAGE_SIZE });
                                    doGetTemplates({
                                        tab: MINE,
                                        limit: 9999,
                                        page: ALL,
                                    });
                                    doGetDocuments(shipmentDetails?.permission?.shipmentId, {
                                        page: 0,
                                        limit: DOCUMENT_PAGE_SIZE
                                    }, () => {
                                    })
                                    // useEffect(() => {
                                    //     doGetOrganizations({ page: 0, limit: ORGANIZATION_PAGE_SIZE });


                                    //     if (firstLocode && firstLocode.length > 0) {
                                    //         setOrigins(firstLocode);
                                    //         setDestinations(firstLocode);
                                    //     }
                                    // }, [])
                                    setAddLink(true);
                                }}
                            >
                                {t("home.active-shipment.create-linked-shipment")}
                            </Button>
                            }
                                </>
                            )}

                            </div>
                        </div>
                        }
                    </>
                )}
            </div>
        </div>
    ) : '';
};

ShipmentDetailsCard.propTypes = {
    isDetails: PropTypes.bool,
    className: PropTypes.string,
    shipment: PropTypes.object,
    onShowDetails: PropTypes.func
};
