import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { CREATED } from 'actions';
import {useTranslation} from "react-i18next";

export const BookingTaskHeader = ({ className = '', typeRequest, onStatusSorting = () => {} }) => {

    const { t } = useTranslation();

    return (
        <div className={`tr__booking-task-header f-medium d-flex align-items-center table-header justify-content-between ${className}`}>
            <div className="column number">
                <p>{t('home.BookingRequest.table.reference')}</p>
            </div>
            <div className="column booking-no">
                <p>{t('home.BookingRequest.table.booking')}</p>
            </div>
            <div className="column port-loading">
                <p>{t('home.BookingRequest.table.portOfLoading')}</p>
            </div>
            <div className="column port-discharge">
                <p>{t('home.BookingRequest.table.portOfDischarge')}</p>
            </div>
            <div className="column date-receipt">
                <p>{t('home.BookingRequest.table.dateOfReceipt')}</p>
            </div>
            <div className="column requester">
                <p>{typeRequest === CREATED ? t('home.BookingRequest.table.freightPartner') : t('home.BookingRequest.table.requester')}</p>
            </div>
            <div className="column created-date">
                <p>{t('home.BookingRequest.table.createdDate')}</p>
            </div>
            <div className="column status">
                <p>{t('home.BookingRequest.table.status')}</p>
                <i className="icon-filter" onClick={onStatusSorting} />
            </div>
        </div>
    )
};

BookingTaskHeader.propTypes = {
    className: PropTypes.string,
    typeRequest: PropTypes.string,
    onStatusSorting: PropTypes.func
};
