import React, { useEffect, useState } from 'react';

import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const TranshipmentInfo = ({ transhipmentInfo = [] }) => {
    const [t] = useTranslation();
    const [transhipmentInfos,setTranshipmentInfos] = useState([0]);

    /*eslint-disable */
    useEffect(() => {
        if(transhipmentInfo && transhipmentInfo.length > 0) setTranshipmentInfos(transhipmentInfo)
    }, [transhipmentInfo])
    /*eslint-enable */

    return transhipmentInfos.length  > 0 ? <InfoBlock
        className="tr__details-box"
        title={t("home.active-shipment.details.tabs.shipment-tab.transhipment-information")}
        highColumn={true}
    >
        <InfoRow>
        <div className="sub-card">
            {transhipmentInfos.map((transhipment, index) =>
                    (
                        <div className={`col-12 col-sm-12 ${index > 0 ? 'mtx3' : ''}`} key={index}>
                             <div className="card-add-new">
                            <div className="row mtx2">
                                <div className="col-6">
                                    <div className='row'>
                                        <InfoCell
                                            className="col-sm-6"
                                            label={t("home.active-shipment.details.tabs.shipment-tab.ts-port")}
                                            value={transhipment.tsPort ? transhipment.tsPort : ''}
                                        />
                                        <InfoCell
                                            className="col-sm-6"
                                            label={t("home.active-shipment.details.tabs.shipment-tab.ts-next-port")}
                                            value={transhipment.tsNextPort ? transhipment.tsNextPort : ''}
                                        />
                                    </div> 
                                </div>
                                <div className="col-6 mtx2">
                                    <div className='row'>
                                        <InfoCell
                                            className="col-sm-6"
                                            label={t("home.active-shipment.details.tabs.shipment-tab.ts-vessel")}
                                            value={transhipment.tsVessel ? transhipment.tsVessel : ''}
                                        />
                                        <InfoCell
                                            className="col-sm-6"
                                            label={t("home.active-shipment.details.tabs.shipment-tab.ts-vessel-imo")}
                                            value={transhipment.tsVesselIMO ? transhipment.tsVesselIMO : ''}
                                        />
                                    </div> 
                                </div>
                                <div className="col-6 mtx2">
                                    <div className='row'>
                                        <InfoCell
                                            className="col-sm-6"
                                            label={t("home.active-shipment.details.tabs.shipment-tab.ts-etd")}
                                            value={transhipment.tsETD ? transhipment.tsETD : ''}
                                        />
                                        <InfoCell
                                            className="col-sm-6"
                                            label={t("home.active-shipment.details.tabs.shipment-tab.ts-eta")}
                                            value={transhipment.tsETA ? transhipment.tsETA : ''}
                                        />
                                    </div> 
                                </div>
                            </div>
                            </div>
                        </div>
                    )
                ) 
            }
        </div>
        </InfoRow>
    </InfoBlock> : null;
};

TranshipmentInfo.propTypes = {
    ContainerTracking: PropTypes.array
};
