import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import avatar from 'assets/images/default-avatar.png';
import { Input, FileUploader, Dropdown } from 'components';
import { countryList, getErrorMessage, isEmail, isPhoneNumber, isPostalCode, isValidRegistrationNumber, isValidTaxNumber } from 'utils';
import { OrganizationContext } from 'contexts';
import { useTranslation } from 'react-i18next';
import { LOCODE_PAGE_SIZE, ORGANIZATION } from 'actions';
import { CountryContext } from 'contexts';

export const OrganizationDetailsForm = ({
    isModalForm = true,
    title ='',
    isEdit = false,
    children,
    register = () => {},
    errors = [],
    logo,
    setValue = () => {},
    setLogo,
    expiredDate
}) => {
	const [t] = useTranslation();
    const [file, setFile] = useState(null);
    const handleFileUpload = uploadFile => setFile(uploadFile);
    const { organization } = useContext(OrganizationContext);
    const { countries, countryLoading, setSelectCountry, doGetCountries, setCountryLoading } = useContext(CountryContext);
   
    useEffect(() => {
        if (organization && logo) {
            organization.logo = logo;
        }
    }, [organization, logo]);

    /*eslint-disable */
    useEffect(() => {
        doGetCountries();
    }, [])

    useEffect(() => {
        if(organization && organization.country){
            const selectOrganizationCountry = countryList.find(item => item.name);
            setSelectCountry({
            key: selectOrganizationCountry.locode,
            value: selectOrganizationCountry.name
        })
        }else{
            setSelectCountry({})
        }
    }, [organization])

    const onSearchCountries = value => {
        setCountryLoading(true);
        setValue('country', '', { shouldValidate: true });
            doGetCountries({limit: LOCODE_PAGE_SIZE, value}, () => {
                setCountryLoading(false);
            }, ORGANIZATION)
    }

    const addressMarkup = (
        <div className="row address-markup">
            <div className="col-12">
                <Input
                    required
                    label={t("organization.formOrg.companyAddress")}
                    className="mbx3"
                    name="cAddress"
                    placeholder={t("organization.formOrg.enterCompanyAddress")}
                    viewOnly={!isEdit}
                    refs={register({ required: true })}
                    defaultValue={organization && organization.companyAddress}
                    error={!!errors.cAddress}
                    errorMessage={getErrorMessage(errors.cAddress, "Company address")}
                />
            </div>
            <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
               {
                isEdit ?  
                <Dropdown
                    required
                    mode="input"
                    label={t("organization.formOrg.country")}
                    name="country"
                    className="select mbx3"
                    placeholder={t("organization.formOrg.enterCountry")}
                    viewOnly={!isEdit}
                    refs={register({ required: true })}
                    dropdownPosition="center"
                    options={countries && countries.length > 0 ? countries.map(country => {
                        return {
                            key: country.name,
                            value: `${country.name}`,
                            item: country
                        }
                    }) : []}
                    error={!!errors.country}
                    errorMessage={getErrorMessage(errors.country, "organization country")}
                    defaultValue={organization && organization.country}
                    searchLoading={countryLoading}
                    onInputChange={onSearchCountries}
                    onChange={value => setSelectCountry(value)}
               />
                : 
                <Input
                   required
                   label={t("organization.formOrg.country")}
                   className="mbx3"
                   name="country"
                   placeholder={t("organization.formOrg.enterCountry")}
                   refs={register({ required: true })}
                   defaultValue={organization && organization.country}
                   error={!!errors.country}
                   errorMessage={getErrorMessage(errors.country, "Country")}
               /> 
               }
            </div>
            <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                <Input
                    required
                    label={t("organization.formOrg.postalCode")}
                    className="mbx3"
                    name="postalCode"
                    placeholder={t("organization.formOrg.enterPostalCode")}
                    viewOnly={!isEdit}
                    refs={register({ required: true, validate: isPostalCode })}
                    defaultValue={organization && organization.postalCode}
                    error={!!errors.postalCode}
                    errorMessage={getErrorMessage(errors.postalCode, "Postal code", "Invalid postal code")}
                />
            </div>
            <div className={!isModalForm ? '' : 'col-12'}>
                <Input
                    label={t("organization.formOrg.companyRegisteredAddress")}
                    className="mbx3"
                    name="companyRegisteredAddress"
                    placeholder={t("organization.formOrg.enterCompanyRegisteredAddress")}
                    viewOnly={!isEdit}
                    refs={register()}
                    defaultValue={organization && organization.registeredAddress}
                />
            </div>
            <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                <Input
                    label={t("organization.formOrg.registrationNumber")}
                    className="mbx3"
                    name="registrationNumber"
                    placeholder={t("organization.formOrg.enterRegistrationNumber")}
                    viewOnly={!isEdit}
                    refs={register({ validate: value => isValidRegistrationNumber(value, false) })}
                    defaultValue={organization && organization.registrationNumber}
                    error={!!errors.registrationNumber}
                    errorMessage={getErrorMessage(errors.registrationNumber, "Registration number", "Invalid registration number")}
                />
            </div>
            <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                <Input
                    label={t("organization.formOrg.companyTaxNumber")}
                    className="mbx3 last"
                    name="companyTaxNumber"
                    placeholder={t("organization.formOrg.enterCompanyTaxNumber")}
                    viewOnly={!isEdit}
                    refs={register({ validate: value => isValidTaxNumber(value, false) })}
                    defaultValue={organization && organization.taxNumber}
                    error={!!errors.companyTaxNumber}
                    errorMessage={getErrorMessage(errors.companyTaxNumber, "Company tax number", "Invalid tax number")}
                />
            </div>
        </div>
    );

    return (
    <>
        {!isModalForm && (
            <div className="h2 tr__organization-title f-medium titleCard d-flex align-items-center">
                {children}
            </div>
        )}
        <div className={`tr__organization-details-form ${isModalForm ? 'modal-form' : ''}`}>
            {title && (
                <h2 className="h2 f-medium details-heading">{title}</h2>
            )}
            <div>
            {expiredDate && (
                <h3 className="h4 mbx2 f-medium text-error"> {expiredDate} </h3>
            )}
            </div>
            <div className="row main--content">
                <div className="col-12 col-sm-4 file-upload">
                    <FileUploader
                        mode="avatar"
                        multiple={false}
                        name="organizationLogo"
                        handleDrop={handleFileUpload}
                        uploadedFiles={file}
                        defaultAvatar={organization && organization.logo ? organization.logo : avatar}
                        disabled={!isEdit && !isModalForm}
                        refs={register()}
                        setAvatar={setLogo}
                        fileTypeApproved={['image']}
                        imageUploadLabel={t('home.BookingRequest.comment.btnUpload')}
                    />
                </div>
                <div className="col-12 col-sm-8 organization-name">
                    <div className='col-12'>
                        <Input
                            required
                            label={t("organization.formOrg.organizationName")}
                            name="organizationName"
                            placeholder={t("organization.formOrg.enterOrgName")}
                            viewOnly={!isEdit}
                            refs={register({ required: true })}
                            defaultValue={organization && organization.name}
                            error={!!errors.organizationName}
                            errorMessage={getErrorMessage(errors.organizationName, "Organization name")}
                        />
                    </div>
                </div>
               <div className="tr__organization-details-form--contact">
                    <div className="row">
                        <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                            <Input
                                required
                                label={t("organization.formOrg.contactEmail")}
                                className="mbx3"
                                name="contactEmail"
                                placeholder={t("organization.formOrg.enterEmail")}
                                viewOnly={!isEdit}
                                refs={register({ required: true, validate: isEmail })}
                                maxLength="128"
                                defaultValue={organization && organization.contactEmail}
                                error={!!errors.contactEmail}
                                errorMessage={getErrorMessage(errors.contactEmail, "Contact email", "Invalid email format")}
                            />
                        </div>
                        <div className={!isModalForm ? '' : 'col-12 col-sm-6'}>
                            <Input
                                required
                                label={t("organization.formOrg.contactPhoneNumber")}
                                className="mbx3"
                                name="organizationPhoneNo"
                                placeholder={t("organization.formOrg.enterPhoneNumber")}
                                viewOnly={!isEdit}
                                refs={register({ required: true, validate: isPhoneNumber })}
                                defaultValue={organization && organization.contactPhoneNumber}
                                error={!!errors.organizationPhoneNo}
                                errorMessage={getErrorMessage(errors.organizationPhoneNo, "Phone number", "Invalid phone number")}
                            />
                        </div>
                    </div>
                    {!isModalForm && (
                        addressMarkup
                    )}
                </div>
                {isModalForm && (
                    <div className="col-12">
                        {addressMarkup}
                    </div>
                )}
            </div>
        </div>
    </>
    )
};

OrganizationDetailsForm.propTypes = {
    isModalForm: PropTypes.bool,
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    isEdit: PropTypes.bool,
    register: PropTypes.any,
    errors: PropTypes.any,
    logo: PropTypes.any,
    setLogo: PropTypes.func,
};
