import React, { useContext, useEffect, useState } from 'react';

import {
    Tab,
    TabPane,
    TaskManagement,
    ShipmentInfo,
    Documents,
    Issues,
    FTAAdvisor,
    ShipmentLog
} from 'components';
import { HomeContext } from 'contexts';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { 
    ACTIVE_SHIPMENT_DETAILS_URL, 
    ISSUES_URL, 
    TASK_MANAGEMENT, 
    DOCUMENTS,
    ISSUES,
    FTA,
    SHIPMENT_INFO,
    SHIPMENT_LOG,
    DOCUMENTS_URL,
    FTA_URL,
    SHIPMENT_INFO_URL,
    SHIPMENT_LOG_URL,
    NOT_FOUND_URL,
    ARCHIVE_DETAILS_URL,
    DOCUMENTS_URL_ARCHIVE,
    ISSUE_URL_ARCHIVE,
    ISSUE_URL_ARCHIVE_DETAILS,
    ARCHIVE_SHIPMENT_INFO_URL,
    ARCHIVE_SHIPMENT_LOG_URL,
    ISSUE_DETAILS_URL,
    ARCHIVE_FTA_URL
} from 'actions';
import { IssueDetails } from '../components';
import { useTranslation } from 'react-i18next';



export const ShipmentDetailsTabs = ({ type, shipmentInfo, shipmentDetails }) => {
    const {t} = useTranslation();
    const { setIsDocument } = useContext(HomeContext);
    const location = useLocation();
    const history = useHistory();
    const { shipmentId } = useParams();
    const [currentTab, setCurrentTab] = useState(0);
    const pathnameArr = location.pathname.split('/');
    const id = pathnameArr[pathnameArr.length - 1];

    /*eslint-disable */
    useEffect(() => {
        switch (location.pathname) {
            case isArchive ? ARCHIVE_DETAILS_URL.replace(':shipmentId', shipmentId) : ACTIVE_SHIPMENT_DETAILS_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(0);
                break;
            case isArchive ? DOCUMENTS_URL_ARCHIVE.replace(':shipmentId', shipmentId) : DOCUMENTS_URL.replace(':shipmentId', shipmentId) :
                setCurrentTab(1);
                break;
            case isArchive ? ISSUE_URL_ARCHIVE.replace(':shipmentId', shipmentId) + '/' + id : ISSUES_URL.replace(':shipmentId', shipmentId) + '/' + id:
                setCurrentTab(2);
                isArchive ? history.push(`${ISSUE_URL_ARCHIVE.replace(':shipmentId', shipmentId)}/${id}`) : history.push(`${ISSUES_URL.replace(':shipmentId', shipmentId)}/${id}`);
                break;
            case isArchive ? ISSUE_URL_ARCHIVE.replace(':shipmentId', shipmentId) : ISSUES_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(2);
                break;
            case isArchive ? ARCHIVE_FTA_URL.replace(':shipmentId', shipmentId) : FTA_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(3);
                break;
            case isArchive ? ARCHIVE_SHIPMENT_INFO_URL.replace(':shipmentId', shipmentId) : SHIPMENT_INFO_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(4);
                break;
            case isArchive ? ARCHIVE_SHIPMENT_LOG_URL.replace(':shipmentId', shipmentId) : SHIPMENT_LOG_URL.replace(':shipmentId', shipmentId):
                setCurrentTab(5);
                break;
            default:
                history.push(NOT_FOUND_URL);
                break;
        }
    }, [location.pathname])
    /*eslint-enable */
    
    const { isArchive } = useContext(HomeContext);
   
    const handleTabChange = (tab) => {
        switch (tab) {
            case TASK_MANAGEMENT:
                isArchive ? history.push(ARCHIVE_DETAILS_URL.replace(':shipmentId',shipmentId)) : history.push(ACTIVE_SHIPMENT_DETAILS_URL.replace(':shipmentId',shipmentId));
                setIsDocument(false);
                break;
            case DOCUMENTS:
                isArchive ? history.push(DOCUMENTS_URL_ARCHIVE.replace(':shipmentId',shipmentId)) : history.push(DOCUMENTS_URL.replace(':shipmentId',shipmentId));
                break;
            case ISSUES:
                isArchive ? history.push(ISSUE_URL_ARCHIVE.replace(':shipmentId',shipmentId)) : history.push(ISSUES_URL.replace(':shipmentId',shipmentId));
                break;
            case FTA:
                isArchive ? history.push(ARCHIVE_FTA_URL.replace(':shipmentId',shipmentId)) : history.push(FTA_URL.replace(':shipmentId',shipmentId));
                break;
            case SHIPMENT_INFO:
                isArchive ? history.push(ARCHIVE_SHIPMENT_INFO_URL.replace(':shipmentId',shipmentId)) : history.push(SHIPMENT_INFO_URL.replace(':shipmentId',shipmentId));
                break;
            case SHIPMENT_LOG:
                isArchive ? history.push(ARCHIVE_SHIPMENT_LOG_URL.replace(':shipmentId',shipmentId)) : history.push(SHIPMENT_LOG_URL.replace(':shipmentId',shipmentId));
                break;
            default:
                history.push(ACTIVE_SHIPMENT_DETAILS_URL.replace(':shipmentId', shipmentId));
                break;
        }
    }
    return (
        <div className="tr__shipment-details-tabs full-height">
            <Tab className="minimal full-height" initialTab={currentTab} onTabClick={handleTabChange}>
                <TabPane
                    tabName={t("home.active-shipment.details.tabs.task-management")}
                    tabSlug={TASK_MANAGEMENT}
                >
                    <Switch>
                        <Route path={isArchive ? ARCHIVE_DETAILS_URL : ACTIVE_SHIPMENT_DETAILS_URL} exact>
                            <TaskManagement 
                                type={type}
                                isArchive={isArchive}
                            />
                        </Route>
                    </Switch>
                   
                </TabPane>
                <TabPane
                    tabName={t("home.active-shipment.details.tabs.documents")}
                    tabSlug={DOCUMENTS}
                >
                    <Switch>
                        <Route path={isArchive ? DOCUMENTS_URL_ARCHIVE : DOCUMENTS_URL} exact>
                            <Documents />
                        </Route>
                    </Switch>
                </TabPane>
                
                <TabPane
                    tabName={t("home.active-shipment.details.tabs.issues")}
                    tabSlug={ISSUES}
                >   
                <Switch>
                     <Route path={isArchive ? ISSUE_URL_ARCHIVE : ISSUES_URL } exact>
                        <Issues />
                    </Route>
                    <Route path={isArchive ? ISSUE_URL_ARCHIVE_DETAILS : ISSUE_DETAILS_URL} exact>
                        <IssueDetails />
                    </Route>
                   
                </Switch>
                   
                </TabPane>
              
                <TabPane
                    tabName={t("home.active-shipment.details.tabs.fta")}
                    tabSlug={FTA}
                >
                    <Switch>
                        <Route path={isArchive ? ARCHIVE_FTA_URL : FTA_URL} exact>
                            <FTAAdvisor isArchive={isArchive} />
                        </Route>
                    </Switch>
                </TabPane>

                <TabPane
                    tabName={t("home.active-shipment.details.tabs.shipment-info")}
                    tabSlug={SHIPMENT_INFO}
                >
                    <Switch>
                        <Route path={isArchive ? ARCHIVE_SHIPMENT_INFO_URL : SHIPMENT_INFO_URL} exact>
                            <ShipmentInfo 
                                shipmentInfo={shipmentInfo}
                                shipmentDetails={shipmentDetails}
                            />
                        </Route>
                    </Switch>
                </TabPane>
                <TabPane
                    tabName={t("home.active-shipment.details.tabs.shipment-log")}
                    tabSlug={SHIPMENT_LOG}
                >
                    <Switch>
                        <Route path={isArchive ? ARCHIVE_SHIPMENT_LOG_URL : SHIPMENT_LOG_URL} exact>
                            <ShipmentLog isArchive={isArchive} />
                        </Route>
                    </Switch>
                </TabPane>
            </Tab>
        </div>
    )
};