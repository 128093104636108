import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

export const InfoRow = ({ className = '', label, children }) => {
    return (
        <div className="col-12">
            <div className={`tr__info-row ${className}`}>
                {label && <p className="f-medium tr__info-row--label mbx2">{label}</p>}
                <div className="tr__info-row--content row">
                    {children}
                </div>
            </div>
        </div>
    )
};

InfoRow.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string
}