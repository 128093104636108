import React, { useEffect, useState } from 'react';

import './index.scss';

import { PropTypes } from 'prop-types';
import { Input } from 'components/common';
import { getErrorMessage } from 'utils';

export const EditExtractedTable = ({
    register = () => {},
    errors = [],
    section,
    extractedInfo = [],
    isArchive = false,
    watch = () => {},
    getValues,
    clearErrors = () => {},
    removeSection = () => {}
}) => {
    const [items, setItems] = useState([0]);
    const [limit, setLimit] = useState(8);
    const [isToggle, setIsToggle] = useState(true);

    /*eslint-disable */
    useEffect(() => {
        if (extractedInfo[section] && extractedInfo[section].details.length > 0) {
            setItems([...Array(extractedInfo[section].details.length).keys()]);
            setLimit(extractedInfo[section].details.length + 8);
        }
        setIsToggle(extractedInfo[section])
    }, [extractedInfo])
    /*eslint-enable */

    const addNewLine = () => {
        let item = 1;
        if (items.length > 0) item = Math.max(...items) + 1;
        setItems(old => [...old, item]);
    }

    const removeLine = (item) => {
        setItems(old => [...old.filter(o => o !== item)]);
    }

    const handleError = (item, field) => {
        return errors.sections && errors.sections[section] && errors.sections[section].items && errors.sections[section].items[item] && errors.sections[section].items[item][field]
            ? errors.sections[section].items[item][field] : null;
    }

    const isValidSectionName = (value, section) => {
        const data = getValues().sections.filter((s, index) => index !== section);
        if (extractedInfo[section] && !extractedInfo[section].isNewSection) {
            return true;
        }
        return !data.some(dt => dt.sectionName === value);
    }

    const isValidKey = (value, section, keyIndex) => {
        let items = [];
        const data = getValues().sections;
        data.forEach((dt, index) => {
            if (dt.items) {
                const itemData = dt.items.filter(item => item);
                if (itemData.length > 0) {
                    if (section === index) {
                        items = [...items, ...itemData.filter((item, i) => i !== keyIndex)];
                    } else {
                        items = [...items, ...itemData];
                    }
                }
            }
        })
        if ((extractedInfo[section] && !extractedInfo[section].isNewSection) || !value) {
            return true;
        }
        return !items.some(item => item.key === value);
    }

    return <div className="tr__extracted-info-table">
        {!extractedInfo[section] || (extractedInfo[section] && extractedInfo[section].isNewSection) ?
            <p className="tr__link remove-card" onClick={() => removeSection(section)} >-</p> : ''
        }
        <div className="tr__extracted-info-list">
            <div className="tr__extracted-info-list--header d-flex justify-content-between align-items-center">
                <div className="d-flex f-medium">
                    <h1 className="mrx1">
                        {isToggle && extractedInfo[section] ? getValues(`sections[${section}].sectionName`) : ''}
                        {extractedInfo[section] && extractedInfo[section].isNewSection && isToggle &&
                            <i className="icon-pencil mlx1"
                                onClick={e => {
                                    setIsToggle(!isToggle);
                                }}
                            />
                        }
                    </h1>
                    <Input
                        hidden={isToggle}
                        refs={register({
                            required: true,
                            validate: {
                                validate: value => isValidSectionName(value, section),
                                space: value => value.trim() !== ''
                            }
                        })}
                        name={`sections[${section}].sectionName`}
                        error={errors.sections && errors.sections[section] && errors.sections[section].sectionName ? errors.sections[section].sectionName : null}
                        errorMessage={!isToggle && getErrorMessage(errors.sections && errors.sections[section] && errors.sections[section].sectionName ? errors.sections[section].sectionName : null
                            , "key", "Section name is existed")
                        }
                        placeholder="Please enter section name"
                        maxLength={50}
                        defaultValue={extractedInfo[section] ? extractedInfo[section].title : ''}
                        onBlur={e => {
                            const value = e.target.value;
                            if (value && (extractedInfo[section] && extractedInfo[section].isNewSection) && isValidSectionName(value, section))
                                setIsToggle(!isToggle);
                        }}
                    />
                    <input hidden ref={register()} name={`sections[${section}].isNewSection`} value={!extractedInfo[section] || (extractedInfo[section] && extractedInfo[section].isNewSection)} />
                </div>
            </div>
            <div className="tr__extracted-info-list--table">
                {items.length > 0 && items.map((item, i) => {
                    const items = `sections[${section}].items[${item}]`;

                    return (
                        <div className="table-content d-flex justify-content-between" key={item}>
                            <div className="col-6 column name d-flex align-items-center">
                                {!isArchive && (!extractedInfo[section] || (extractedInfo[section] && extractedInfo[section].isNewSection)) && <i className="icon-times mrx2" onClick={() => removeLine(item)} />}
                                <Input
                                    className="full"
                                    name={`${items}.key`}
                                    maxLength={50}
                                    refs={register({ 
                                        validate: {
                                            validate: value => isValidKey(value, section, i),
                                            space: value =>  watch(`${items}.value`).trim() === '' || (extractedInfo[section] && !extractedInfo[section].isNewSection) ? true :
                                            (!extractedInfo[section] || (extractedInfo[section] && extractedInfo[section].isNewSection)) && value.trim() !== '' ? true : false
                                        }
                                    })}
                                    error={handleError(item, 'key')}
                                    viewOnly={isArchive}
                                    errorMessage={getErrorMessage(handleError(item, 'key'), "key", "Key is existed")}
                                    defaultValue={extractedInfo[section] && extractedInfo[section].details[item] ?
                                        extractedInfo[section].details[item].name : ""
                                    }
                                    readOnly={extractedInfo[section] && !extractedInfo[section].isNewSection}
                                    onChange={e => {
                                        if (!e.target.value) clearErrors(`${items}.value`);
                                    }}
                                />
                            </div>
                            <div className="col-6 column">
                                <Input
                                    name={`${items}.value`}
                                    refs={register({ 
                                        validate: {
                                            space: value => watch(`${items}.key`).trim() === '' || (extractedInfo[section] && !extractedInfo[section].isNewSection) ? true :
                                            (!extractedInfo[section] || (extractedInfo[section] && extractedInfo[section].isNewSection)) && value.trim() !== '' ? true : false
                                        } 
                                    })}
                                    error={handleError(item, 'value')}
                                    errorMessage={getErrorMessage(handleError(item, 'value'))}
                                    viewOnly={isArchive}
                                    defaultValue={extractedInfo[section] && extractedInfo[section].details[item] ?
                                        extractedInfo[section].details[item].value : ""
                                    }
                                    onChange={e => {
                                        if (!e.target.value) clearErrors(`${items}.key`);
                                    }}
                                />
                            </div>
                        </div>
                    )
                })}
                {items.length < limit && !isArchive && (!extractedInfo[section] || (extractedInfo[section] && extractedInfo[section].isNewSection)) &&
                    <div className="d-flex align-items-center add-new-line" onClick={() => addNewLine()}>
                        <i className="icon-plus mrx1" />
                        <h4 className="f-medium">Add New Line</h4>
                    </div>
                }
            </div>
        </div>
    </div>
};

EditExtractedTable.propTypes = {
    extractedInfo: PropTypes.array
}