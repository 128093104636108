import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

import {
    Dropdown,
    EditShipmentInfoInput,
} from 'components';
import { getErrorMessage } from 'utils';
import { ActiveShipmentsContext, TrackShipmentContext } from 'contexts';
import { AIR, DESTINATION, LOCODE_PAGE_SIZE, ORIGIN } from 'actions';
import { useTranslation } from 'react-i18next';
import { Button, Input, InputTags } from 'components/common';
import { EditShipmentInfoDropdown } from '../EditShipmentInfoDropdown';

export const EditAISTracking = ({
    register = () => {},
    errors = [],
    getValues = () => {},
    vessels = [],
    setVessels = () => {},
    trackings,
    watch = () => {},
    editShipmentInfo,
    setError= () => {}, 
    clearErrors= () =>{},
    setDepartureLocation=() =>{},
    setArrivalLocation=() =>{},
    departureLocation,
    arrivalLocation,
    isArchive = false,
    bookingRequest={},
    setTranshipments,
    tabTypeShipment,
    typeOfShipment,
    bookingDetails, 
    shippingDetails,
    airTracking,
    routes
}) => {
    const [t] = useTranslation();
    const [originLoading, setOriginLoading] = useState(Array(vessels.length).fill(false));
    const [destinationLoading, setDestinationLoading] = useState(Array(vessels.length).fill(false));
    const { doGetLocodes, origins, destinations } = useContext(TrackShipmentContext);
    const { doGetAirPort, doGetAirLine, airLines } = useContext(ActiveShipmentsContext);
    // const [carrierName, setCarrierName] = useState(bookingDetails && bookingDetails.carrierName ? bookingDetails.carrierName : '');

     /*eslint-disable */
     useEffect(() => {
    }, [trackings, airTracking])

    useEffect(() => {
        doGetAirPort({
            limit: 9999,
            page: 0
        });

        doGetAirLine({
            limit: 9999,
            page: 0
        })
    }, [])
    /*eslint-enable */
    

    // const isVesselDirty = vessel => {
    //     const name = getValues(`vesselName${vessel}`);
    //     const imo = getValues(`vesselImo${vessel}`);
    //     const voyage = getValues(`vesselVoyage${vessel}`);
    //     const departure = getValues(`vesselDeparture${vessel}`);
    //     const arrival = getValues(`vesselArrival${vessel}`);
        
    //     return name || imo || voyage || departure || arrival;
    // }

    // const getLocationValue = data => {
    //     if (!data || (data && !data.location)) return '';
    //     const { name, country, locode } = data.location;
    //     return name + (country ? `, ${country}` : '') + (locode ? ` (${locode})` : '');
    // }

    const onSearchOriginLocode = (index, value) => {
        handleValidVessel(index);
        setOriginLoading(old => [...old.map((item, itemIndex) => itemIndex === index)]);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setOriginLoading(Array(vessels.length).fill(false));
        }, () => {
            setOriginLoading(Array(vessels.length).fill(false));
        }, ORIGIN);
    }

    const onSearchDestAirport = (value) => {
        setDestinationLoading(true);
        doGetAirLine({
            limit: LOCODE_PAGE_SIZE,
            search: value.trim(),
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    } 

    const onSearchDestLocode = (index, value) => {
        handleValidVessel(index);
        setDestinationLoading(old => [...old.map((item, itemIndex) => itemIndex === index)]);

        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value.trim(),
            isSearching: true
        }, () => {
            setDestinationLoading(Array(vessels.length).fill(false));
        }, () => {
            setDestinationLoading(Array(vessels.length).fill(false));
        }, DESTINATION);
    }
    
    const getLocationItem = code => {
        if (code) {
            const { name, country, locode } = code;
            return {
                key: locode,
                value: name + (country ? `, ${country}` : '') + (locode ? ` (${locode})` : ''),
                item: locode,
            }
        }
        return null;
    }

    const handleValidVessel = (index) =>{
        // const name = getValues(`vesselName${index}`);
        // const imo = getValues(`vesselImo${index}`);
        // const voyage = getValues(`vesselVoyage${index}`);
        // (isVesselDirty(index) && name) || !isVesselDirty(index) ? clearErrors(`vesselName${index}`) : setError(`vesselName${index}`,{type: 'required'});
        // (isVesselDirty(index) && imo) || !isVesselDirty(index) ? clearErrors(`vesselImo${index}`) : setError(`vesselImo${index}`,{type: 'required'});
        // (isVesselDirty(index) && voyage) || !isVesselDirty(index) ? clearErrors(`vesselVoyage${index}`) : setError(`vesselVoyage${index}`,{type: 'required'});
    }
    
    return (
        <div className="tr__verify-details-form--group mbx4"> 
            <div className="tr__verify-details-form--group--content ship-plane-frame">
                <h3 className="h3 f-medium tr__info-block--label mtx2 mlx2 d-flex align-items-center"> 
                    {t("home.active-shipment.details.tabs.shipment-tab.ship-tracking")}
                </h3>
                <div className="tr__verify-details-form--group--content-frame">
                    <div className="tr__verify-details-form--group__input">
                        {vessels.length > 0 && vessels.map(vessel => (
                            
                            <div className="row" key={vessel}>
                                {(tabTypeShipment === AIR || shippingDetails?.shipmentMode === AIR) &&
                                    <div className="col-12 col-sm-6 color-icon">
                                        <EditShipmentInfoDropdown
                                            label={'Airline name'}
                                            name="airline"
                                            className="mtx2"
                                            placeholder={'You can select any of airline'}
                                            icon="icon-chevron-down"
                                            iconPosition="right"
                                            mode="input"
                                            refs={register()}
                                            disabled={isArchive}
                                            options={airLines && airLines.length > 0 ? airLines.map(airLine => {
                                                return {
                                                    key: airLine.code,
                                                    value: `${airLine.name}, ${airLine.location} (${airLine.code})`,
                                                    item: airLine
                                                }
                                            }) : []}
                                            onInputChange={onSearchDestAirport}
                                            dropdownPosition="center"
                                            // onInputChange={onSearchOriginLocode}
                                            // searchLoading={originLoading}
                                            // onChange={option => {
                                            //     const { item } = option;
                                            //     if(editShipmentInfo){
                                            //         setNewPrepol(option ? option.key : '');
                                            //         setPrePolLocation({
                                            //             name: item.city,
                                            //             country: item.country,
                                            //             locode: item.locode
                                            //         });
                                            //     }
                                            //     setShipmentDetails(old => {
                                            //         return {
                                            //             ...old,
                                            //             routes: {
                                            //                 ...old.routes,
                                            //                 prepol: {
                                            //                     ...old.routes.prepol,
                                            //                     location: {
                                            //                         name: item.city,
                                            //                         country: item.country,
                                            //                         locode: item.locode
                                            //                     }
                                            //                 }
                                            //             }
                                            //         }
                                            //     });
                                            // }}
                                            defaultValue={trackings && trackings[vessel] && trackings[vessel].airline ? {
                                                key: trackings[vessel].airline?.code,
                                                value: `${trackings[vessel].airline?.name}, ${trackings[vessel].airline?.location} (${trackings[vessel].airline?.code})`,
                                                item: trackings[vessel],
                                            } : ''}
                                            // originalvalue={originalPrePol}
                                            // tooltipOldlvalue={editShipmentInfo}
                                            // newValue={newPrepol}
                                        />
                                    </div>}
    
                                <div className="col-12 col-sm-6">
                                    <EditShipmentInfoInput
                                        className="mtx2"
                                        name={`vesselName${vessel}`}
                                        label={'Vessel name'}
                                        placeholder="MSC DOMITILLE"
                                        refs={register()}
                                        viewOnly={isArchive}
                                        maxLength={50}
                                        error={!!errors[`vesselName${vessel}`]}
                                        errorMessage={getErrorMessage(errors[`vesselName${vessel}`], 'Airline Name')}
                                        defaultValue={trackings && trackings[vessel] ? trackings[vessel].name : ''}
                                        originalvalue={trackings && trackings[vessel] ? trackings[vessel].name : ''}
                                        tooltipOldlvalue={editShipmentInfo}
                                        newValue={watch(`vesselName${vessel}`,trackings && trackings[vessel] ? trackings[vessel].name : '')}
                                        onChange={val => handleValidVessel(vessel)}
                                    />
                                </div>

                                {(tabTypeShipment === AIR || shippingDetails?.shipmentMode === AIR) && <div className="col-12 col-sm-6">
                                    <Input
                                    className="mtx2"
                                    name="pol"
                                    label={"Origin Airports"}
                                    placeholder="POL Address"
                                    defaultValue={trackings && trackings[vessel] ? `${trackings[vessel].originAirport.city}, ${trackings[vessel].originAirport.country} (${trackings[vessel].originAirport.countryCode})` : '' || (routes && routes.pol
                                        ? `${routes.pol.city}, ${routes.pol.country} (${routes.pol.countryCode})`
                                        : ''
                                    )}
                                    viewOnly={true}
                                />
                            </div>}
                            {(tabTypeShipment === AIR || shippingDetails?.shipmentMode === AIR) && <div className="col-12 col-sm-6">
                                <Input
                                    label={"Destination Airports"}
                                    name="pod"
                                    className="mtx2"
                                    placeholder="POD Address"
                                    defaultValue={trackings && trackings[vessel] ? `${trackings[vessel].destinationAirport.city}, ${trackings[vessel].destinationAirport.country} (${trackings[vessel].destinationAirport.countryCode})` : '' || (routes && routes.pod
                                        ? `${routes.pod.city}, ${routes.pod.country} (${routes.pod.countryCode})`
                                        : ''
                                    )}
                                    viewOnly={true}
                                />
                            </div>}
                               
                                {(tabTypeShipment === AIR || shippingDetails?.shipmentMode === AIR) && <> 
                                    <div className="col-12 col-sm-6">
                                        <EditShipmentInfoInput
                                            className="mtx2"
                                            name={`masterAirwayBill${vessel}`}
                                            refs={register()}
                                            label={"Master airway bill"}
                                            placeholder="BN823592"
                                            maxLength={50}
                                            viewOnly={isArchive}
                                            defaultValue={trackings && trackings[vessel] ? trackings[vessel].masterAirwayBill : ''}
                                            originalvalue={trackings && trackings[vessel] ? trackings[vessel].masterAirwayBill : ''}
                                            tooltipOldlvalue={editShipmentInfo}
                                            newValue={watch(`masterAirwayBill${vessel}`,trackings && trackings[vessel] ? `${trackings[vessel].masterAirwayBill}` : '')}
                                            onChange={val => handleValidVessel(vessel)}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <InputTags
                                            className="mtx2"
                                            name="houseAirBill"
                                            refs={register()}
                                            label={"House airway bill"}
                                            placeholder="BN823592, BN823592, BN823592"
                                            maxLength={50}
                                            viewOnly={isArchive}
                                            defaultValue={trackings && trackings[vessel] ? trackings[vessel].houseAirwayBill : []}
                                            isTooltip={editShipmentInfo}
                                        />
                                    </div>
                                </>}
                               
                               {(tabTypeShipment === AIR || shippingDetails?.shipmentMode === AIR) ? '' : <> 
                                    <div className="col-12 col-sm-6">
                                        <EditShipmentInfoInput
                                            className="mtx2"
                                            name={`vesselImo${vessel}`}
                                            label={t("home.active-shipment.details.tabs.shipment-tab.imo")}
                                            placeholder="BN9720201"
                                            refs={register()}
                                            viewOnly={isArchive}
                                            maxLength={50}
                                            error={!!errors[`vesselImo${vessel}`]}
                                            errorMessage={getErrorMessage(errors[`vesselImo${vessel}`], 'Vessel IMO')}
                                            defaultValue={trackings && trackings[vessel] ? trackings[vessel].imo : ''}
                                            originalvalue={trackings && trackings[vessel] ? `${trackings[vessel].imo}` : ''}
                                            tooltipOldlvalue={editShipmentInfo}
                                            newValue={watch(`vesselImo${vessel}`,trackings && trackings[vessel] ? `${trackings[vessel].imo}` : '')}
                                            onChange={val => handleValidVessel(vessel)}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <EditShipmentInfoInput
                                            className="mtx2"
                                            name={`vesselVoyage${vessel}`}
                                            label={t("home.active-shipment.details.tabs.shipment-tab.voyage")}
                                            placeholder="0230"
                                            refs={register()}
                                            viewOnly={isArchive}
                                            maxLength={50}
                                            error={!!errors[`vesselVoyage${vessel}`]}
                                            errorMessage={getErrorMessage(errors[`vesselVoyage${vessel}`], 'Vessel Voyage')}
                                            defaultValue={trackings && trackings[vessel] ? trackings[vessel].voyage : ''}
                                            originalvalue={trackings && trackings[vessel] ? trackings[vessel].voyage : ''}
                                            tooltipOldlvalue={editShipmentInfo}
                                            newValue={watch(`vesselVoyage${vessel}`,trackings && trackings[vessel] ? trackings[vessel].voyage : '')}
                                            onChange={val => handleValidVessel(vessel)}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <Dropdown
                                            className="select mtx2"
                                            icon="icon-chevron-down"
                                            iconPosition="right"
                                            mode="input"
                                            name={`vesselDeparture${vessel}`}
                                            label={t("home.active-shipment.details.tabs.shipment-tab.departure")}
                                            error={!!errors[`vesselDeparture${vessel}`]}
                                            comparable={true}
                                            errorMessage={getErrorMessage(errors[`vesselDeparture${vessel}`], "Departure", "Departure port and Arrival port can't be the same")}
                                            refs={register({ 
                                                // required: isVesselDirty(vessel), 
                                                validate: value => !value || value !== getValues(`vesselArrival${vessel}`) 
                                            })}
                                            disabled={isArchive}
                                            options={origins && origins.length > 0 ? origins.map(locode => {
                                                return {
                                                    key: locode.locode,
                                                    value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                                    item: locode
                                                }
                                            }) : []}
                                            placeholder={t("home.active-shipment.EditAISTracking.vesselDeparture.placeholder")}
                                            dropdownPosition="center"
                                            onInputChange={value => onSearchOriginLocode(vessel, value)}
                                            searchLoading={originLoading[vessel]}
                                            defaultValue={trackings && trackings[vessel] ? getLocationItem(trackings[vessel].departure) : null}
                                            onChange={data => {
                                                let departure = {...departureLocation};
                                                departure[`vesselDeparture${vessel}`] = {
                                                    name: data.item.city,
                                                    country: data.item.country,
                                                    locode: data.item.locode
                                                };
                                                setDepartureLocation(departure);
                                                handleValidVessel(vessel);
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <Dropdown
                                            className="select mtx2"
                                            icon="icon-chevron-down"
                                            iconPosition="right"
                                            mode="input"
                                            name={`vesselArrival${vessel}`}
                                            label={t("home.active-shipment.details.tabs.shipment-tab.arrival")}
                                            error={!!errors[`vesselArrival${vessel}`]}
                                            comparable={true}
                                            errorMessage={getErrorMessage(errors[`vesselArrival${vessel}`], "Arrival", "Departure port and Arrival port can't be the same")}
                                            refs={register({ 
                                                // required: isVesselDirty(vessel), 
                                                validate: value => !value || value !== getValues(`vesselDeparture${vessel}`) 
                                            })}
                                            disabled={isArchive}
                                            options={destinations && destinations.length > 0 ? destinations.map(locode => {
                                                return {
                                                    key: locode.locode,
                                                    value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                                    item: locode
                                                }
                                            }) : []}
                                            placeholder={t("home.active-shipment.EditAISTracking.vesselArrival.placeholder")}
                                            dropdownPosition="center"
                                            onInputChange={value => onSearchDestLocode(vessel, value)}
                                            searchLoading={destinationLoading[vessel]}
                                            defaultValue={trackings && trackings[vessel] ? getLocationItem(trackings[vessel].arrival) : null}
                                            onChange={data => {
                                                let arrival = {...arrivalLocation};
                                                arrival[`vesselArrival${vessel}`] = {
                                                    name: data.item.city,
                                                    country: data.item.country,
                                                    locode: data.item.locode
                                                };
                                                setArrivalLocation(arrival);
                                                handleValidVessel(vessel);
                                            }}
                                        />
                                    </div>
                               </> }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {!isArchive && <div className="h4 mbx4 f-medium add-vessel mtbx4 align-items-center">
                <Button 
                onClick={() => setTranshipments(old => [...old, old.length])}
                className="outline"
                >
                <i className="icon-plus mrx1" />
                 <span>{t("home.active-shipment.details.tabs.shipment-tab.add-new-vessel")}</span>
                </Button>
            </div>}
        </div>
    )
};

EditAISTracking.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.any,
    getValues: PropTypes.func,
    vessels: PropTypes.array,
    setVessels: PropTypes.func,
    trackings: PropTypes.any,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
};
