import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    ShipmentTag
} from 'components';

export const ShipmentTags = ({ tags = [] }) => {
    return tags.length > 0 ? (
        <div className="tr__shipment-tags d-flex">
            {tags.map((tag, tagIndex) => (
                <ShipmentTag
                    key={tagIndex}
                    tag={tag}
                />
            ))}
        </div>
    ) : ''
};

ShipmentTags.propTypes = {
    tags: PropTypes.array
}