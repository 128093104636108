import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import './index.scss';
import {
    Modal,
    OrganizationDetailsForm,
    // BillingDetailsForm
} from 'components';
import { AuthContext, HomeContext, ModalContext, OrganizationContext, UploadFileContext } from 'contexts';
import { useHistory } from 'react-router';
import { 
    getBase64Mime, 
    getMimeFileExtension, 
    // getTimestamp, 
    getUnixTimestamp 
} from 'utils';
import { FILE_TYPE_ORG_FILES, FILE_SUBTYPE_ORG_LOGO, ORGANIZATIONS_DETAILS_URL } from "actions";
import { useTranslation } from 'react-i18next';

export const AddOrganization = ({ open = false, onCancel }) => {
	const [t] = useTranslation();
    const { doCreateOrganization } = useContext(OrganizationContext);
    const { setAddOrganization, setCouponPopup } = useContext(ModalContext);
    const { setNotificationMessage, setCouponMessage } = useContext(HomeContext);
    const { doUploadFile } = useContext(UploadFileContext);
    const { loggedInUser } = useContext(AuthContext);

    const { handleSubmit, register, errors, reset } = useForm();
    // const [billingEmails, setBillingEmails] = useState(['']);
    // const [paymentCards, setPaymentCards] = useState([]);
    const [logo, setLogo] = useState(null);
    const history = useHistory();

    const doAddOrganization = async data => {
        const {
            organizationName,
            contactEmail,
            organizationPhoneNo,
            cAddress,
            country,
            postalCode,
            companyRegisteredAddress,
            registrationNumber,
            companyTaxNumber,
        } = data;

        let logoId = null;

        if (logo) {
            const uploadedLogo = await doUploadFile({
                type: FILE_TYPE_ORG_FILES,
                subType: FILE_SUBTYPE_ORG_LOGO,
                fileName: `logo_organizations_${getUnixTimestamp()}`,
                fileExtension: getMimeFileExtension(getBase64Mime(logo)),
            }, logo, loggedInUser);

            logoId = uploadedLogo.id;
        }

        const payload = {
            logo: logoId,
            name: organizationName,
            contactEmail,
            contactPhoneNumber: organizationPhoneNo,
            companyAddress: cAddress,
            country,
            postalCode,
            registeredAddress: companyRegisteredAddress,
            registrationNumber,
            taxNumber: companyTaxNumber,
            // billingEmails: billingEmails.map((email, emailIndex) => {
            //     return data[`billingEmail${emailIndex}`];
            // }).filter(email => email),
            // paymentCards: paymentCards.map((card, cardIndex) => {
            //     return {
            //         cardName: data[`cardName${cardIndex}`],
            //         cardNumber: data[`cardNumber${cardIndex}`],
            //         expiredDate: getTimestamp(data[`expiredDate${cardIndex}`]),
            //         cvv: data[`cvv${cardIndex}`],
            //         primary: card.primary
            //     }
            // })
        }

        doCreateOrganization(payload, (data) => {
            history.push(ORGANIZATIONS_DETAILS_URL.replace(':orgId', (data[0]._id)));
            setCouponMessage(data[2]);
            reset();
            // setBillingEmails(['']);
            // setPaymentCards([]);
            setAddOrganization(false);
            setNotificationMessage(`
                <p><strong>${payload.name}</strong> organization has been created successfully!</p>
            `);
            setCouponPopup(true);
        });
    }

    const handelCloseModal = () => {
        onCancel();
        // setBillingEmails(['']);
    }

    return (
        <Modal
            open={open}
            className="tr__add-organization no-padding"
            onCancel={handelCloseModal}
            title={t("organization.addNewOrg")}
            submitButton
            submitButtonLabel={t("organization.add")}
            isBackDropClickable={false}
            renderForm={children => (
                <form onSubmit={handleSubmit(doAddOrganization)}>
                    {children}
                </form>
            )}
        >
            <OrganizationDetailsForm
                title={t("organization.orgDetails")}
                register={register}
                errors={errors}
                isEdit={true}
                logo={logo}
                setLogo={setLogo}
            />
            {/* <BillingDetailsForm
                title={t("organization.formOrg.billingDetails")}
                register={register}
                errors={errors}
                paymentCards={paymentCards}
                setPaymentCards={setPaymentCards}
                billingEmails={billingEmails}
                setBillingEmails={setBillingEmails}
                getValues={watch}
            /> */}
        </Modal>
    )
};

AddOrganization.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func
};
