import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    Modal
} from 'components';
import { SUCCESS } from 'actions';
import { ModalContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const ToastMessage = ({
    open = false,
    type = SUCCESS,
    notificationMessage = '',
    duration,
    onCancel
}) => {
    let timeout;
    const { setToastMessage } = useContext(ModalContext);
    const [t] = useTranslation();
    /*eslint-disable */
    useEffect(() => {
        if (open && duration && duration > 0) {
            timeout = setTimeout(() => {
                onCancel();
            }, duration);
        }

        return () => {
            clearTimeout(timeout);
        }
    }, [duration, open]);
    /*eslint-enable */
  
    return (
        <Modal
            open={open}
            className={`tr__toast-message`}
            onCancel={onCancel}
            isBackDropClickable={false}
            isNotification={true}
            // onSubmitClick={onCancel}
        >
            <div className="error-toast-message">
                 <div dangerouslySetInnerHTML={{ __html: t("team.toastmessage") }} className="text-center content h3" onClick={() => {setToastMessage(false)}}></div>
                 <div className="line-toast-message"></div>
                 <div onClick={() => {
                     setToastMessage(false);
                 }}><i className="icon-times"></i></div>
            </div>
        </Modal>
    )
};

ToastMessage.propTypes = {
    open: PropTypes.bool,
    type: PropTypes.string,
    notificationMessage: PropTypes.string,
    onCancel: PropTypes.func,
    duration: PropTypes.number
};