import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import {
    Modal,
} from 'components';
import { HomeContext, ModalContext } from 'contexts';
import { useTranslation } from 'react-i18next';
export const CouponOrganization = ({
    open = false,
    onCancel,
    onSubmit = () => {},
}) => {
    const { couponMessage } = useContext(HomeContext);
    const { setToastMessage } = useContext(ModalContext);
    const [t] = useTranslation();
    return (
        <Modal
            open={open}
            className="tr__issue-confirmation"
            onCancel={() => {
                onCancel();
                setToastMessage(true);
            }}
            title={t("organization.congratulations")}
            btnClasses="justify-content-center"
            submitButton
            submitButtonLabel={t("organization.yes")}
            isBackDropClickable={false}
            onSubmitClick={() => {
                onCancel();
                setToastMessage(true);
            }}
        >
            <div className="text-center change-status-issue">
                <h6 className="h6 mtbx2 f-medium">{couponMessage}</h6>
            </div>
        </Modal>
    )
}
CouponOrganization.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string
};
