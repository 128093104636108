import React, { createContext, useContext, useState } from 'react';
import * as _ from 'lodash';

import { 
    getIssue, 
    getIssues,
    OPERATION_FAILED_MESSAGE, 
    createAnIssue,
    updateIssue,
    removePartnerIssue,
    addPartnerIssue,
    getComments,
    createComment,
    getTasksIssue,
    FILE_TYPE_ISSUE_CMT_DOCS,
    getAllIssues,
    changeCloseStatus,
    changeOpenStatus,
    // getUploadedSignedFileUrl,
} from 'actions';
import { GET_FILE } from 'actions/constants';
import { HomeContext, AuthContext, UploadFileContext } from 'contexts';

export const IssueContext = createContext();

export const IssueContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const { setIssuePermission } = useContext(HomeContext);
    const {
        setLoading,
        setNotificationMessage,
        setNotificationType,
    } = useContext(HomeContext);
    const { doUploadFile } = useContext(UploadFileContext);

    const [issues, setIssues] = useState([]);
    const [taskIssue, setTaskIssue] = useState([]);
    const [issue, setIssue] = useState();
    const [comments, setComments] = useState([]);
    const [statusIssue, setStatusIssue] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalIssues, setTotalIssues] = useState(0);
    const [currentCommentPage, setCurrentCommentPage] = useState(0);
    const [totalCommentPages, setTotalCommentPages] = useState(1);
    const [allIssues, setAllIssues] = useState([]);
    const [currentAllIssue, setCurrentAllIssue] = useState(0);
    const [allIssuePages, setAllIssuePage] = useState(1);
    const [isSearchIssues, setIsSearchIssues] = useState(false);

    const handleException = error => {
        const { data } = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetIssues = async (shipmentId, params, callback) => {
        if(shipmentId) {
            try {
                setLoading(true);
               
                const response = await getIssues(shipmentId, params, loggedInUser);
                const { data, status } = response;
                if(status === 200) {
                    if(params.page !== currentPage) {
                        setIssues(oldIssues => [...oldIssues, ...(data.items || [])]);
                    } else {
                        setIssues(data.items || []);
                    }
                    setTotalPages(data.totalPage);
                    setCurrentPage(data.currentPage);
                    setTotalIssues(data.totalItems);

                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetIssue = async (shipmentId, issueId, callback) => {
        if(issueId) {
            try {
                setLoading(true);
                const response = await getIssue(shipmentId, issueId, loggedInUser);
                const { data, status } = response;
                if(status === 200) {
                    setIssue(data || []);
                    setIssuePermission(data.permission);
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doChangeCloseStatus = async (shipmentId, issueId, payload, callback) => {
        if(issueId) {         
            try {
                setLoading(true);
                const response = await changeCloseStatus(shipmentId, issueId, payload, loggedInUser);
                const { status } = response;
                if( status === 200) {
                    doGetIssue(shipmentId, issueId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
    const doChangeOpenStatus = async (shipmentId, issueId, payload, callback) => {
        if(issueId) {         
            try {
                setLoading(true);
                const response = await changeOpenStatus(shipmentId, issueId, payload, loggedInUser);
                const { status } = response;
                if( status === 200) {
                    doGetIssue(shipmentId, issueId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doCreateIssues = async (shipmentId, payload, callback) => {
        if(shipmentId) { 
            try {
                setLoading(true);
                const response = await createAnIssue(shipmentId, payload, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    if (callback) callback(data);
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doUpdateIssue = async (shipmentId, issueId, payload, callback) => {
        if(issueId) {
            try {
                setLoading(true);
                const response = await updateIssue(shipmentId,issueId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    doGetIssue(shipmentId, issueId);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            } 
        }
    }

    const doRemovePartnerIssue = async (shipmentId, issueId, payload, callback) => {
        if(issueId) {
            try {
                setLoading(true);
                const response = await removePartnerIssue(shipmentId, issueId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    doGetIssue(shipmentId, issueId);
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }

    const doAddPartnerIssue = async (shipmentId, issueId, payload, callback) => {
        if(issueId) {
            try {
                setLoading(true);
                const response = await addPartnerIssue(shipmentId, issueId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    doGetIssue(shipmentId, issueId);
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }

    const doGetComments = async (shipmentId, issueId, params, callback) => {
        if(issueId) {
            try {
                setLoading(true);
                const newParams = { ...params };
                delete newParams.isSearching;
                const response = await getComments(shipmentId, issueId, params, loggedInUser);
                const { data, status } = response;
                if(status === 200) {
                    let avatarUrls = [];
                    for (let comment of data.items) {
                        let avatarUrl = (comment.base.createdBy.avatar) ? _.find(avatarUrls, item => item.id === comment.base.createdBy.avatar) : null;
                        if (avatarUrl) avatarUrl = avatarUrl.url;

                        if (comment.base.createdBy.avatar && !avatarUrl) {
                            // let signedAvatar = await getUploadedSignedFileUrl(comment.base.createdBy.avatar, loggedInUser);
                            // avatarUrls.push({ id: comment.base.createdBy.avatar, url: signedAvatar.data.ret.downloadSignedURI.itemURI });
                            // avatarUrl = signedAvatar.data.ret.downloadSignedURI.itemURI;
                            
                            avatarUrls.push({ id: comment.base.createdBy.avatar, url: GET_FILE + comment.base.createdBy.avatar });
                            avatarUrl = GET_FILE + comment.base.createdBy.avatar;
                        }

                        if (comment.base.createdBy.avatar) comment.base.createdBy.avatar = avatarUrl;

                        if (comment.attachments) {
                            for (let file of comment.attachments) {
                                // let singedFile = await getUploadedSignedFileUrl(file.fileId, loggedInUser);
                                // file.url = singedFile.data.ret.downloadSignedURI.itemURI;
                                file.url = GET_FILE + file.fileId;
                            }
                        }
                    }

                    if (params.page !== currentCommentPage) {
                        setComments(oldComments => [...oldComments, ...(data.items || [])]);
                    } else {
                        setComments(data.items || []);
                    }
                    setTotalCommentPages(data.totalPage);
                    setCurrentCommentPage(data.currentPage);
                    // setTotalIssues(data.totalItems);

                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doCreateComment = async (shipmentId, issueId, payload, callback) => {
        if (issueId) {
            try {
                setLoading(true);
                let tmpAttachments = [];
                // let avatarUrl = '';

                if (payload.attachments) {
                    let promises = [];

                    for (let file of payload.attachments) {
                        promises.push(doUploadFile({
                            fileName: file.name.split('.').slice(0, -1).join('.'),
                            type: FILE_TYPE_ISSUE_CMT_DOCS,
                            subType: FILE_TYPE_ISSUE_CMT_DOCS,
                            fileExtension: file.name.split('.').pop(),
                        }, file.data, loggedInUser));
                    }

                    payload.attachments = await Promise.all(promises);
                    tmpAttachments = payload.attachments;
                    payload.attachments = payload.attachments.map(item => {
                        return {
                            fileName: item.name,
                            fileId: item.id,
                        };
                    });
                }

                const response = await createComment(shipmentId, issueId, payload, loggedInUser);
                const { data, status } = response;
               
                if(status === 200) {
                    if (data.base.createdBy.avatar) {
                        // let signedAvatar = await getUploadedSignedFileUrl(data.base.createdBy.avatar, loggedInUser);
                        // avatarUrl = signedAvatar.data.ret.downloadSignedURI.itemURI;
                        // data.base.createdBy.avatar = avatarUrl;
                        data.base.createdBy.avatar = GET_FILE + data.base.createdBy.avatar
                    }

                    data.attachments = tmpAttachments.map(item => {
                        return {
                            fileName: item.name,
                            url: item.url,
                        };
                    });
                    setComments(old => [...old, data]);
                    if(callback) callback()
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetTasksIssue = async (shipmentId, callback) => {
        if(shipmentId) {
            try {
                setLoading(true);
                const response = await getTasksIssue(shipmentId, loggedInUser);
                
                const { data, status } = response;
               
                if(status === 200) {
                    setTaskIssue(data || [])
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetAllIssues = async (params, callback) => {
        try {
            setLoading(true);
            setIsSearchIssues(params.term !== undefined);
            const response = await getAllIssues(params, loggedInUser);
                
            const { data, status } = response;
            
            if(status === 200) {
                setAllIssues(data.items || []);
                setAllIssuePage(data.totalPage);
                setCurrentAllIssue(data.currentPage);
                if(callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    return (
        <IssueContext.Provider 
            value={{
                currentPage,
                totalPages,
                totalIssues,
                issues,
                issue,
                statusIssue,
                comments,
                currentCommentPage,
                totalCommentPages,
                taskIssue,
                allIssues,
                allIssuePages,
                currentAllIssue,
                isSearchIssues,
                setTaskIssue,
                setComments,
                setStatusIssue,
                setIssue,
                setIssues,
                doGetIssues,
                doCreateIssues,
                doGetTasksIssue,
                doGetIssue,
                doUpdateIssue,
                setCurrentPage,
                doChangeCloseStatus,
                doChangeOpenStatus,
                doRemovePartnerIssue,
                doGetComments,
                doCreateComment,
                doAddPartnerIssue,
                doGetAllIssues
            }}
        >
            { children }
        </IssueContext.Provider>
    )
}
