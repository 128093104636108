import React, { createContext, useContext, useState } from 'react'

import { AuthContext } from './AuthContext';
import { HomeContext } from './HomeContext';
import { OPERATION_FAILED_MESSAGE, createCollabrator, getCollaborators, removeCollaborator } from 'actions';

export const CollaboratorsContext = createContext();

export const CollaboratorsContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const [collaborators, setCollaborators] = useState([]); 
    const [taskCollaborators, setTaskCollaborators] = useState([]);
    const {
        setLoading,
        setNotificationMessage,
        setNotificationType,
    } = useContext(HomeContext);

    const handleException = error => {
        const { data } = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doCreateCollaborators = async (activeShipmentId, payload, callback) => {
        if(activeShipmentId && payload.rolesInShipment.length > 0) { 
            try {
                setLoading(true);
                const response = await createCollabrator(activeShipmentId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetCollaborators = async (shipmentId, params, callback ) => {
        if(shipmentId) {
            try {
                const response = await getCollaborators(shipmentId, params, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    if (params.hasOwnProperty('role')) {
                        if (data && data.items.length > 0) {
                            // for (let member of data.items) {
                            //     if (member.avatar) {
                            //         member.avatar = GET_FILE + member.avatar;
                            //     }
                            // }
                            setTaskCollaborators(data.items || []);
                        } else {
                            setTaskCollaborators([]);
                        }
                        
                    } else {
                        if (data && data.items.length > 0) {
                            
                            setCollaborators(data.items || []);
                        } else {
                            setCollaborators([]);
                        }
                        
                    }
                    if(callback) callback();
                }
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doRemoveCollaborator = async (shipmentId, payload, callback) => {
        if (shipmentId) {
            try {
                setLoading(true);
                const response = await removeCollaborator(shipmentId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if(callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
    return (
        <CollaboratorsContext.Provider 
            value={{
                collaborators,
                taskCollaborators,
                setCollaborators,
                doGetCollaborators,
                doCreateCollaborators,
                doRemoveCollaborator,
                setTaskCollaborators
            }}
        >
            { children }
        </CollaboratorsContext.Provider>
    )
}