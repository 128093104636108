import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import './index.scss';
import {
    OrganizationDetailsForm,
    // BillingDetailsForm,
    DeleteBlock,
    DetailsTableHeader
} from 'components';
import { DATE_FORMAT, FILE_SUBTYPE_ORG_LOGO, FILE_TYPE_ORG_FILES, GET_FILE, ORGANIZATIONS_LIST_URL } from 'actions';
import { AuthContext, HomeContext, ModalContext, OrganizationContext, UploadFileContext } from 'contexts';
import { 
    convertDate, 
    getBase64Mime, 
    getMimeFileExtension, 
    // getTimestamp, 
    getUnixTimestamp
} from 'utils';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const DetailsForm = () => {
	const [t] = useTranslation();
    const history = useHistory();
    const { handleSubmit, register, errors, reset } = useForm();
    const { setNotificationMessage } = useContext(HomeContext);
    const { setDeleteOrganization } = useContext(ModalContext);
    const { organization, doUpdateOrganization, doGetOrganization } = useContext(OrganizationContext);
    const { loggedInUser } = useContext(AuthContext);
    const { doUploadFile } = useContext(UploadFileContext);

    const [isEdit, setIsEdit] = useState(false);
    // const [billingEmails, setBillingEmails] = useState(['']);
    // const [paymentCards, setPaymentCards] = useState([]);
    const [logo, setLogo] = useState(null);

    /*eslint-disable */
    useEffect(() => {
        if (!isEdit) reset();
    }, [isEdit])

    useEffect(() => {
        // setBillingEmails(organization && organization.billingEmails ? organization.billingEmails : [""])
    }, [organization])

    /*eslint-enable */

    const doEditOrganization = async data => {
        if (organization) {
            const {
                organizationName,
                contactEmail,
                organizationPhoneNo,
                cAddress,
                country,
                postalCode,
                companyRegisteredAddress,
                registrationNumber,
                companyTaxNumber
            } = data;
    
            const { _id } = organization;

            let logoId = null;

            if (logo) {
                const uploadedLogo = await doUploadFile({
                    type: FILE_TYPE_ORG_FILES,
                    subType: FILE_SUBTYPE_ORG_LOGO,
                    fileName: `logo_organizations_${getUnixTimestamp()}`,
                    fileExtension: getMimeFileExtension(getBase64Mime(logo)),
                }, logo, loggedInUser);

                logoId = uploadedLogo.id;
            }

            const payload = {
                _id,
                name: organizationName,
                logo: logoId ? logoId : (organization?.logo ? organization?.logo.slice(GET_FILE.length, organization?.logo.length) : null),
                contactEmail,
                contactPhoneNumber: organizationPhoneNo,
                companyAddress: cAddress,
                country,
                postalCode,
                registeredAddress: companyRegisteredAddress,
                registrationNumber,
                taxNumber: companyTaxNumber,
                // billingEmails: billingEmails.map((email, emailIndex) => {
                //     return data[`billingEmail${emailIndex}`];
                // }).filter(email => email),
                // paymentCards: paymentCards.map((card, cardIndex) => {
                //     return {
                //         cardName: data[`cardName${cardIndex}`],
                //         cardNumber: data[`cardNumber${cardIndex}`],
                //         expiredDate: getTimestamp(data[`expiredDate${cardIndex}`]),
                //         cvv: data[`cvv${cardIndex}`],
                //         primary: card.primary
                //     }
                // })
            }
            doUpdateOrganization(payload, () => {
                setIsEdit(false);
                setNotificationMessage(`
                    <p><strong>${payload.name}</strong> organization has been updated successfully!</p>
                `);
                doGetOrganization(_id);
            });
            // console.log(payload);
        }
    }

    return (
        <div className={`tr__details-form ${isEdit ? '' : 'view-only'}`}>
            <form onSubmit={handleSubmit(doEditOrganization)}>
                <div className="mbx2">
                    <OrganizationDetailsForm
                        isModalForm={false}
                        title={t("organization.information")}
                        isEdit={isEdit}
                        register={register}
                        errors={errors}
                        logo={logo}
                        setLogo={setLogo}
                        expiredDate={organization?.expiredDate ? `${t("organization.promotionExpire")} ${moment(organization?.expiredDate).format('DD MMM YYYY')}` : ''}
                    >
                        <DetailsTableHeader
                            title={`${isEdit ? t("organization.editOrganizationDetails") : t("organization.orgDetails")}`}
                            actionButton={false}
                            rightLabel={
                                organization && organization.base
                                    ? `${t("organization.createdBy")} ${organization.base.createByName} ${t("organization.on")} ${convertDate(organization.base.createdDate, DATE_FORMAT)}`
                                    : ''
                            }
                            backUrl={() => history.push(ORGANIZATIONS_LIST_URL)}
                        />
                    </OrganizationDetailsForm>

                    {/* <BillingDetailsForm
                        isModalForm={false}
                        isEdit={isEdit}
                        title={t("organization.formOrg.billingDetails")}
                        register={register}
                        errors={errors}
                        billingEmails={billingEmails}
                        setBillingEmails={setBillingEmails}
                        paymentCards={paymentCards}
                        setPaymentCards={setPaymentCards}
                        getValues={watch}
                    /> */}
                    
                </div>
                
                <DeleteBlock
                    isEdit={isEdit}
                    buttonRightLabel={t("organization.edit")}
                    buttonLeftLabel={organization && organization.showButtonDelete ? t("organization.deleteOrg") : ""}
                    showMessage={organization && organization.showMessage}
                    onLeftBtnClick={() => setDeleteOrganization(true)}
                    className="outline icon"
                    icon="icon-pencil"
                    typeBtn="secondary"
                    onRightBtnClick={() => setIsEdit(true)}
                    setIsEdit={edit => setIsEdit(edit)}
                />
            </form>
        </div>
    )
};
