import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { AuthContext, HomeContext, OrganizationContext, TeamContext } from 'contexts';
import { GET_FILE, OWNER } from 'actions/constants';
import avatar from 'assets/images/avatar.png';
import { MultiSelect, ItemCard } from 'components';
import { getRoleList } from 'utils';
import { ADMIN } from 'actions';
import { useTranslation } from 'react-i18next';

export const InviteMembers = ({
    title,
    isMember,
    name = 'teamRole',
    selectedMembers = [],
    members = [],
    onInputChange = () => {},
    onAddItem = () => {},
    setSelectedMembers = () => {},
    register = () => {},
    errors = [],
    searchLoading = false,
    formState,
    handleAddTeam = () => {},
    getValues = () => {},
    isOrgTeam = false,
    isNew = false
}) => {
    const [t] = useTranslation();
    const { userInfo } = useContext(AuthContext);
    const { teamMember } = useContext(HomeContext);
    const { organization, userOrgRole } = useContext(OrganizationContext);
    const { team, teams, doGetOrgTeams, userTeamRole } = useContext(TeamContext);
    
    /*eslint-disable */
	useEffect(() => {
		if (organization && isMember && !team) {
            doGetOrgTeams({
                orgId: organization._id,
                isSearching: true
            });
        }
	}, [organization, isMember])
	/*eslint-enable */

    const handleChange = items => {
        setSelectedMembers(oldValue => [...oldValue, ...items.map(item => {
            return {
                ...item,
                teams: [],
                teamOptions: teams
            }
        })]);
    }

    const handleRemove = item => {
        setSelectedMembers(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
    }

    return (
        <div className="tr__invite-members">
            {title && (
                <h4 className="h2 f-medium text-black">{title}</h4>
            )}
            <MultiSelect
                className="mbx2"
                options={members.filter(mem => !selectedMembers.some(m => m.id === mem._id) && (userInfo._id !== mem._id)).map(member => {
                    return {
                        id: member._id || new Date().getTime().toString(),
                        icon: member._id ? (member.avatar ? GET_FILE + member.avatar : avatar) : 'icon-envelop',
                        title: member._id ? member.name : member.email,
                        description: member._id ? member.email : member.email,
                        isInviteEmail: member._id ? false : true
                    }
                })}
                name="orgMembers"
                value={selectedMembers}
                placeholder={t("team.placeholderInvite")}
                onChange={handleChange}
                onInputChange={onInputChange}
                onAddItem={onAddItem}
                onRemove={handleRemove}
                register={register}
                errors={errors}
                formState={formState}
                addButton={true}
                addButtonLabel={t("organization.add")}
                searchLoading={searchLoading}
                renderList={members => members.length > 0 && (
                    <div className='tr__dropdown--list'>
                        {members && members.length > 0 && members.map((member, memberIndex) => (
                            <ItemCard
                                key={memberIndex}
                                item={member}
                                name={`${name}${member.id}`}
                                onRemove={handleRemove}
                                dropdownOptions={isOrgTeam && !isNew ? getRoleList(teamMember ? userTeamRole : userOrgRole, false).map(type => {
                                    return {
                                        key: type.key,
                                        value: t(type.value)
                                    }
                                }) : getRoleList(ADMIN).map(type => {
                                    return {
                                        key: type.key,
                                        value: t(type.value)
                                    }
                                })}
                                orgDropdownOptions={userOrgRole === OWNER.toUpperCase() && member.isInviteEmail ? getRoleList(ADMIN).map(type => {
                                    return {
                                        key: type.key,
                                        value: t(type.value)
                                    }
                                }) : getRoleList(userOrgRole).map(type => {
                                    return {
                                        key: type.key,
                                        value: t(type.value)
                                    }
                                })}
                                teamOptions={member.teamOptions}
                                getValues={getValues}
                                isMember={isMember}
                                register={register}
                                errors={errors}
                                teams={member.teams}
                                handleAddTeam={() => handleAddTeam(member.id)}
                            />
                        ))}
                    </div>
                )}
            />
        </div>
    )
};

InviteMembers.propTypes = {
    title: PropTypes.string,
    isMember: PropTypes.bool,
    register: PropTypes.func,
    errors: PropTypes.any,
    selectedMembers: PropTypes.array,
    setSelectedMembers: PropTypes.func,
    onInputChange: PropTypes.func,
    onAddItem: PropTypes.func,
    searchLoading: PropTypes.bool,
    formState: PropTypes.any,
    members: PropTypes.array,
    handleAddTeam: PropTypes.func,
    getValues: PropTypes.func,
    isOrgTeam: PropTypes.bool,
    isNew: PropTypes.bool
};
