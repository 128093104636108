import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import {
    FileUploader,
    Dropdown,
    Button
} from 'components';
import { documentTypeOptions, getErrorMessage } from 'utils';
import { DOCUMENT_PAGE_SIZE, FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS, FILE_TYPE_SHIPMENT_DETAIL_DOCS, MAX_SIZE_SHIPMENT_DETAIL_DOC,PERMISSIONS_LIST } from 'actions';
import { DocumentsContext,HomeContext } from 'contexts';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const UploadDocuments = ({ 
    className = '',
    title, 
    label, 
    name, 
    mode, 
    type, 
    onFilesUpload, 
    onCancel, 
    documents = [],
}) => {
    const [t] = useTranslation();
    const { shipmentId } = useParams();
    const { handleSubmit, errors, register, reset } = useForm();
    const {
        doUploadDocumentsTask,
        setDocuments,
        doGetDocuments,
        doUploadDocumentsTaskDefault
    } = useContext(DocumentsContext);
    const { permission } = useContext(HomeContext);
    const [files, setFiles] = useState([]);
    const [documentType, setDocumentType] = useState();
    const [documentTask, setDocumentTask] = useState();

    const handleFileUpload = uploadFiles => {
        let fileList = [];
        for (var i = 0; i < uploadFiles.length; i++) {
          if (!uploadFiles[i].name) return
          fileList.push(uploadFiles[i])
        }
        setFiles(oldFiles => [...oldFiles, ...fileList]);
    }

    const handleFileRemove = file => {
        if (!file) return;
        setFiles(oldFiles => [...oldFiles.filter(f => !(f.name === file.name && f.size === file.size))])
    }

    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }

    const handleUploadDocuments = () => {
        onFilesUpload(files);
        let payload = [];
        files.forEach(file => {
            payload = [
                ...payload,
                {
                    awsId: file.id,
                    fileName: file.name,
                    documentLink: file.url,
                    typeOfDocument: documentType?.key
                }
            ]
        })
        
        
        if(documentTask.value === "Upload Shipment Information" || documentTask.value ===  "Upload Invoices for FTA"){
            doUploadDocumentsTaskDefault(documentTask?.key, payload, () => {
                setDocuments([]);
                setFiles([]);
                reset();
                setDocumentType(null);
                setDocumentTask(null)
                doGetDocuments(shipmentId, {
                    page: 0,
                    limit: DOCUMENT_PAGE_SIZE
                }, () => {
                })
            })
        }else{
            doUploadDocumentsTask(documentTask?.key, payload, () => {
                setDocuments([]);
                setFiles([]);
                reset();
                setDocumentType(null);
                setDocumentTask(null)
                doGetDocuments(shipmentId, {
                    page: 0,
                    limit: DOCUMENT_PAGE_SIZE
                }, () => {
                })
            })
        }
    }

    const handleUploadTaskDashBoard = () => {
        onFilesUpload(files);
    }

    const isListMode = () => mode === 'list';
    const isDocMode = () => mode === 'doc';

    return (
        <div className={`tr__upload-documents ${mode} ${className}`}>
            {title && (
                <h5 className="h5 mbx3 mtx1 f-medium">{title}</h5>
            )}
            <form onSubmit={handleSubmit(handleUploadDocuments)}>
                <FileUploader
                    name={name}
                    handleDrop={handleFileUpload}
                    handleRemove={handleFileRemove}
                    error={!!errors[name]}
                    errorMessage={getErrorMessage(errors[name], "ShipmentDetails Docs Type")}
                    refs={register}
                    // handleDrop={handleFileUpload}
                    // handleRemove={handleFileRemove}
                    uploadedFiles={files}
                    // uploadedFiles={documents}
                    mode={isDocMode() ? 'list' : mode}
                    label={label}
                    type={type}
                    limitNumberUploadFile={9}
                    outputFormat='base64'
                    // limitNumberUploadFile={MAX_FILE_SHIPMENT_DETAIL_DOCS}
                    limitSizePerFile={MAX_SIZE_SHIPMENT_DETAIL_DOC}
                    fileTypeApproved={['image', 'pdf']}
                    uploadInner={true}
                    uploadFileType={FILE_TYPE_SHIPMENT_DETAIL_DOCS}
                    uploadFileSubType={FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS}
                    ocrRequired={true}
                />
                {isListMode() && (
                    <div className="tr__upload-documents--footer d-flex align-items-center justify-content-between">
                        <span className="tr__link" onClick={onCancel}>Cancel</span>
                        <Button
                            type="primary"
                            disabled={files.length === 0}
                            onClick={handleUploadTaskDashBoard}
                        >
                            Upload
                    </Button>
                    </div>
                )}

                {isDocMode() && (
                    <>
                        <Dropdown
                            className="select mtx3"
                            icon="icon-chevron-down"
                            iconPosition="right"
                            mode="select"
                            name="documentTask"
                            label={t("home.active-shipment.details.tabs.document-tab.select-document-task")}
                            required
                            options={documents.map(doc => {
                                return {
                                    key: doc._id,
                                    value: doc.taskName
                                }
                            })}
                            placeholder={t("home.active-shipment.details.tabs.document-tab.any-document-task")}
                            dropdownPosition="center"
                            onChange={value => setDocumentTask(value)}
                            refs={register({ required: true })}
                            value={documentTask}
                            error={!!errors.documentTask}
                            errorMessage={getErrorMessage(errors.documentTask, "Document Task")}
                        />
                        <Dropdown
                            className="select mtx3"
                            icon="icon-chevron-down"
                            iconPosition="right"
                            mode="select"
                            name="documentTypes"
                            label={t("home.active-shipment.details.tabs.document-tab.select-document-type")}
                            required
                            options={documentTypeOptions}
                            placeholder={t("home.active-shipment.details.tabs.document-tab.any-document-type")}
                            dropdownPosition="center"
                            onChange={value => setDocumentType(value)}
                            refs={register({ required: true })}
                            error={!!errors.documentTypes}
                            value={documentType}
                            errorMessage={getErrorMessage(errors.documentTypes, "Document Types")}
                        />

                        <div className="tr__upload-documents--footer mtx3 d-flex align-items-center">
                            <div className="note d-flex align-items-center">
                                <i className="icon-shield" />
                                <p>{t("home.active-shipment.details.tabs.document-tab.highly-secured-here")}</p>
                            </div>
                            {checkPermission(PERMISSIONS_LIST.DOCUMENT_UPLOAD_TASK_DOCUMENT) && (
                            <Button
                                className="big-btn-size"
                                type="primary"
                                // onClick={handleUploadDocuments}
                                disabled={files.length > 0 ? false : true}
                                isSubmitBtn={true}
                            >
                                {t("home.active-shipment.details.tabs.document-tab.upload-document")}
                        </Button>
                        )}
                        </div>
                    </>
                )}
            </form>
            
        </div>
    )
};

UploadDocuments.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    mode: PropTypes.string,
    onFilesUpload: PropTypes.func,
    onCancel: PropTypes.func
};