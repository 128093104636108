import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { useTranslation } from 'react-i18next';

export const ShipmentTaskHeader = ({ className = '' }) => {
    const {t} = useTranslation();
    return (
        <div className={`tr__shipment-task-header d-flex align-items-center table-header justify-content-between ${className}`}>
            <div className="column name">
                <p>{t("home.active-shipment.details.tabs.management-tab.table-header.task-name")}</p>
            </div>
            <div className="column partner">
                <p>{t("home.active-shipment.details.tabs.management-tab.table-header.partner")}</p>
                <i className="icon-filter" />
            </div>
            <div className="column assignee">
                <p>{t("home.active-shipment.details.tabs.management-tab.table-header.operator")}</p>
                <i className="icon-filter" />
            </div>
            <div className="column status">
                <p>{t("home.active-shipment.details.tabs.management-tab.table-header.task-status")}</p>
                <i className="icon-filter" />
            </div>
            <div className="column date">
                <p>{t("home.active-shipment.details.tabs.management-tab.table-header.due-date")}</p>
                <i className="icon-filter" />
            </div>
            <div className="column actions">
                <p>{t("home.active-shipment.details.tabs.management-tab.table-header.actions")}</p>
                <i className="icon-filter" />
            </div>
        </div>
    )
};

ShipmentTaskHeader.propTypes = {
    className: PropTypes.string
};