import React from 'react';
import PropTypes from 'prop-types';

import {
} from 'components';
import { InputTags } from 'components/common';
import { useTranslation } from 'react-i18next';
import { AIR } from 'actions';

export const EditInvoice = ({ register, shipmentDetails, editShipmentInfo, isArchive = false, isEditShipmentInfo = false, tabTypeShipment, shippingDetails = {},  isLinkedShipment = false }) => {
    const [t] = useTranslation();
    return (
        <div className={`tr__verify-details-form--group mbx4 col-12 ${shippingDetails?.shipmentMode === AIR || tabTypeShipment === AIR ? 'col-sm-4' : 'col-sm-3'}`}>
            <div className="tr__verify-details-form--group--content">
                <h3 className="h3 f-medium tr__info-block--label mtx2 mlx2 d-flex align-items-center"> 
                    {t("home.active-shipment.details.tabs.shipment-tab.invoice")}
                </h3>
                <div className="tr__verify-details-form--group--content-frame">
                    <div className="tr__verify-details-form--group__input">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <InputTags 
                                    className="mtx2"
                                    name="invoiceNumber"
                                    refs={register()}
                                    maxLength={50}
                                    label={t("home.active-shipment.details.tabs.shipment-tab.invoice-number")}
                                    placeholder="Eg: BN94500331575, BN94500331575"
                                    defaultValue={shipmentDetails && shipmentDetails.invoices ? shipmentDetails.invoices : []}
                                    isTooltip={editShipmentInfo}
                                    viewOnly={isArchive}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

EditInvoice.propTypes = {
    register: PropTypes.func,
    shipmentDetails: PropTypes.object,
    watch: PropTypes.func,
    editShipmentInfo: PropTypes.bool
};
