import React from 'react';

import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AIR, FCL } from 'actions';

export const CutOff = ({ cutOff = {}, shippingDetails}) => {
    const [t] = useTranslation();
    return <InfoBlock
        className={`tr__details-box ${shippingDetails?.shipmentMode === AIR ? 'col-sm-4' : 'col-sm-3'}`}
        title={t("home.active-shipment.details.tabs.shipment-tab.cutoff")}
    >
        <InfoRow >
            <div className="col-12">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.vgm-ubmission-timeline")}
                        value={cutOff.vgmSubmissionTimeline ? cutOff.vgmSubmissionTimeline : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.si-submission")}
                        value={cutOff.siSubmission ? cutOff.siSubmission : ''}
                    />
                </div>
            </div>
            <div className="col-12 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={shippingDetails && shippingDetails.shipmentType === FCL ? t("home.active-shipment.details.tabs.shipment-tab.date.fcl") : t("home.active-shipment.details.tabs.shipment-tab.date.lcl")}
                        value={cutOff.date ? moment(+cutOff.date).format('DD MMM YYYY') : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.time")}
                        value={cutOff.time ? cutOff.time : ''}
                    />
                </div>
            </div>
        </InfoRow>
    </InfoBlock>
};

CutOff.propTypes = {
    cutOff: PropTypes.object
};
