import React, {useContext, useEffect, useState} from "react";
import {
    ActiveShipmentsContext,
    HomeContext,
    OrganizationContext
} from "contexts";
import {Modal} from "components";
import {useForm} from "react-hook-form";
import {
    USER_ROLES,
    PERMISSIONS_LABEL_LIST,
    PERMISSIONS_LIST,
} from 'actions';
import "./index.scss";
import { useTranslation } from "react-i18next";

export const EditAccessPermission = ({open = false, onCancel}) => {
    const [t] = useTranslation();
    const {handleSubmit, register} = useForm();
    const {setNotificationMessage} = useContext(HomeContext);
    const { doSetPermission, permissionShipment, unableToEdit } = useContext(OrganizationContext);
    const {shipmentDetails, setShipmentDetails} = useContext(ActiveShipmentsContext);
    const [arrayPageCheckAll, setArrayPageCheckAll] = useState([]);
    const [dataUTEdit, setDataUTEdit] = useState([]);
    const [role] = useState([{col: USER_ROLES.MANAGER},
        {col: USER_ROLES.EDITOR},
        {col: USER_ROLES.VIEWER},
        {col: USER_ROLES.OPERATOR},
        {col: USER_ROLES.CREATOR},
        {col: USER_ROLES.ASSIGNEE},
        {col: USER_ROLES.ORG_OWNER},
        {col: USER_ROLES.ORG_ADMIN},
        {col: USER_ROLES.SHIPMENT_ADMIN},
        {col: USER_ROLES.SHIPPER},
        {col: USER_ROLES.CONSIGNEE},
        {col: USER_ROLES.FREIGHT},
        {col: USER_ROLES.EXPORT_LOGISTICS},
        {col: USER_ROLES.IMPORT_LOGISTICS},
        {col: USER_ROLES.EXPORT_CUSTOMS},
        {col: USER_ROLES.IMPORT_CUSTOMS}]);

    /*eslint-disable*/
    useEffect(() => {
        setArrayPageCheckAll(permissionShipment.filter(x => x.col !== "SHIPMENT_CREATOR" && x.col !== "TASK_ASSIGNEE"));
        setDataUTEdit(unableToEdit.filter(x => x.COL !== "SHIPMENT_CREATOR" && x.COL !== "TASK_ASSIGNEE"));
    }, [shipmentDetails]);
    /*eslint-enable */

    const handleEditTemplate = async data => {
        const {
            MANAGER,
            EDITOR,
            VIEWER,
            OPERATOR,
            CREATOR,
            ASSIGNEE,
            ORG_OWNER,
            ORG_ADMIN,
            SHIPMENT_ADMIN,
            SHIPPER,
            CONSIGNEE,
            FREIGHT,
            EXPORT_LOGISTICS,
            IMPORT_LOGISTICS,
            EXPORT_CUSTOMS,
            IMPORT_CUSTOMS
        } = USER_ROLES;
        const dataKeys = Object.keys(data);
        const manager = dataKeys.filter(k => k.indexOf(MANAGER) > -1 && data[k]).map(m => m.replace(`${MANAGER}-`, ''));
        const editor = dataKeys.filter(k => k.indexOf(EDITOR) > -1 && data[k]).map(m => m.replace(`${EDITOR}-`, ''));
        const viewer = dataKeys.filter(k => k.indexOf(VIEWER) > -1 && data[k]).map(m => m.replace(`${VIEWER}-`, ''));
        const assignee = dataKeys.filter(k => k.indexOf(ASSIGNEE) > -1 && data[k]).map(m => m.replace(`${ASSIGNEE}-`, ''));
        const orgOwner = dataKeys.filter(k => k.indexOf(ORG_OWNER) > -1 && data[k]).map(m => m.replace(`${ORG_OWNER}-`, ''));
        const orgAdmin = dataKeys.filter(k => k.indexOf(ORG_ADMIN) > -1 && data[k]).map(m => m.replace(`${ORG_ADMIN}-`, ''));
        const operator = dataKeys.filter(k => k.indexOf(OPERATOR) > -1 && data[k]).map(m => m.replace(`${OPERATOR}-`, ''));
        const creator = dataKeys.filter(k => k.indexOf(CREATOR) > -1 && data[k]).map(m => m.replace(`${CREATOR}-`, ''));
        const shipmentAdmin = dataKeys.filter(k => k.indexOf(SHIPMENT_ADMIN) > -1 && data[k]).map(m => m.replace(`${SHIPMENT_ADMIN}-`, ''));
        const shipper = dataKeys.filter(k => k.indexOf(SHIPPER) > -1 && data[k]).map(m => m.replace(`${SHIPPER}-`, ''));
        const consignee = dataKeys.filter(k => k.indexOf(CONSIGNEE) > -1 && data[k]).map(m => m.replace(`${CONSIGNEE}-`, ''));
        const freight = dataKeys.filter(k => k.indexOf(FREIGHT) > -1 && data[k]).map(m => m.replace(`${FREIGHT}-`, ''));
        const export_Logistics = dataKeys.filter(k => k.indexOf(EXPORT_LOGISTICS) > -1 && data[k]).map(m => m.replace(`${EXPORT_LOGISTICS}-`, ''));
        const import_Logistics = dataKeys.filter(k => k.indexOf(IMPORT_LOGISTICS) > -1 && data[k]).map(m => m.replace(`${IMPORT_LOGISTICS}-`, ''));
        const export_Customs = dataKeys.filter(k => k.indexOf(EXPORT_CUSTOMS) > -1 && data[k]).map(m => m.replace(`${EXPORT_CUSTOMS}-`, ''));
        const import_Customs = dataKeys.filter(k => k.indexOf(IMPORT_CUSTOMS) > -1 && data[k]).map(m => m.replace(`${IMPORT_CUSTOMS}-`, ''));

        const payload = {authorities: []};

         payload.authorities.push({
            authority: MANAGER,
            functions: manager,
        })
        payload.authorities.push({
            authority: EDITOR,
            functions: editor,
        })
        payload.authorities.push({
            authority: VIEWER,
            functions: viewer,
        })
        payload.authorities.push({
            authority: ASSIGNEE,
            functions: assignee,
        })
        payload.authorities.push({
            authority: ORG_OWNER,
            functions: orgOwner,
        })
        payload.authorities.push({
            authority: ORG_ADMIN,
            functions: orgAdmin,
        })

        payload.authorities.push({
            authority: OPERATOR,
            functions: operator,
        })
        payload.authorities.push({
            authority: CREATOR,
            functions: creator,
        })
        payload.authorities.push({
            authority: SHIPMENT_ADMIN,
            functions: shipmentAdmin,
        })
        payload.authorities.push({
            authority: SHIPPER,
            functions: shipper,
        })
        payload.authorities.push({
            authority: CONSIGNEE,
            functions: consignee,
        })
        payload.authorities.push({
            authority: FREIGHT,
            functions: freight,
        })
        payload.authorities.push({
            authority: EXPORT_LOGISTICS,
            functions: export_Logistics,
        })
        payload.authorities.push({
            authority: IMPORT_LOGISTICS,
            functions: import_Logistics,
        })
        payload.authorities.push({
            authority: EXPORT_CUSTOMS,
            functions: export_Customs,
        })
        payload.authorities.push({
            authority: IMPORT_CUSTOMS,
            functions: import_Customs,
        })
        if (shipmentDetails.id) {
            doSetPermission(shipmentDetails.id, payload, () => {
                setNotificationMessage(`
                <p>Update successfully!</p>
            `);
                onCancel();
                setArrayPageCheckAll([]);
                setShipmentDetails(null);
            })
        }
    }

    const handleSelectAll = (event, value) => {
        const arrayUTEditWithCol = dataUTEdit.filter(x => x.ROW === value);
        let dataAfterFilter = [];
        if (arrayUTEditWithCol.length > 0){
            dataAfterFilter = role.filter(({col}) => !arrayUTEditWithCol.some(x => x.COL === col))
        }
        const oldArrData = [...arrayPageCheckAll];
        if (event.target.checked === true && oldArrData.filter(x => x.row === value).length === 0) {
            for (let i = 0; i < dataAfterFilter.length; i++) {
                const data = {row: value, col: dataAfterFilter[i].col}
                oldArrData.push(data)
            }
            setArrayPageCheckAll(oldArrData);
        } else if (event.target.checked === true && oldArrData.filter(x => x.row === value).length > 0) {
            const setUpData = oldArrData.filter(x => x.row !== value);
            for (let i = 0; i < dataAfterFilter.length; i++) {
                const data = {row: value, col: dataAfterFilter[i].col}
                setUpData.push(data);
            }
            setArrayPageCheckAll(setUpData);
        } else {
        const dataAfterDeSelectAll = [...arrayPageCheckAll].filter(({col, row}) => ! dataAfterFilter.some(x => x.col === col && value === row));
        // setArrayPageCheckAll([...arrayPageCheckAll].filter(x => !(x.row === value)));
        setArrayPageCheckAll(dataAfterDeSelectAll);
        }
    }

    const handleSelect = (event, valueCol, valueRow) => {
        if (event.target.checked === true && arrayPageCheckAll.findIndex(x => (x.row === valueRow && x.col === valueCol)) === -1) {
            setArrayPageCheckAll([...arrayPageCheckAll , {row: valueRow, col: valueCol}]);
        } else {
            let arr = [...arrayPageCheckAll];
            let data = arr.filter(x => !(x.col === valueCol && x.row === valueRow));
            setArrayPageCheckAll(data);
        }
    }

    const cancelPopup = () => {
        setShipmentDetails(null);
        onCancel();
    }

    return (
        <Modal
            open={open}
            className="tr__edit-access-permission"
            onCancel={cancelPopup}
            title={t("organization.shipments.edit-access-permission.title")}
            btnClasses="justify-content-end"
            submitButton
            cancelButton
            cancelButtonClassNames = 'label'
            onCancelClick={cancelPopup}
            cancelButtonLabel={t("organization.shipments.edit-access-permission.cancel")}
            submitButtonLabel={t("organization.shipments.edit-access-permission.save-change")}
            renderForm={children => (
                <form onSubmit={handleSubmit(handleEditTemplate)}>
                    <div className="mtx2"/>
                    {children}
                </form>
            )}
        >
            <div className="tr__edit-access-permission__modal--form">
                <table id="table" className={"tr__tab"}>
                    <thead className={'table-header'}>
                        <tr className='table-header-1'>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th colSpan="4" className={'colSpan'}>
                            {t("organization.shipments.edit-access-permission.task")}
                            </th>
                            <th colSpan="2" className={'colSpan'}>
                            {t("organization.shipments.edit-access-permission.issue")}
                            </th>
                            <th>
                            {t("organization.shipments.edit-access-permission.org-owner")}
                            </th>
                            <th>
                            {t("organization.shipments.edit-access-permission.org-admin")}
                            </th>
                            <th colSpan="8" className={'colSpan'}>
                            {t("organization.shipments.edit-access-permission.shipment-collaborators")}
                            </th>
                        </tr>
                        <tr className={'table-header-2'}>
                            <th></th>
                            <th></th>
                            <th>
                            {t("organization.shipments.edit-access-permission.select_all")}
                            </th>
                            <th>
                            {t("organization.shipments.edit-access-permission.manager")}
                            </th>
                            <th>
                            {t("organization.shipments.edit-access-permission.editor")}
                            </th>
                            <th>
                            {t("organization.shipments.edit-access-permission.viewer")}
                            </th>
                            <th>
                            {t("organization.shipments.edit-access-permission.operator")}
                            </th>
                            <th>
                            {t("organization.shipments.edit-access-permission.creator")}
                            </th>
                            <th>
                            {t("organization.shipments.edit-access-permission.assignee")}
                            </th>
                            <th></th>
                            <th></th>
                            <th>
                                {t("organization.shipments.edit-access-permission.shipment-admin")}
                            </th>
                            <th>Shipper</th>
                            <th>Consignee</th>
                            <th>Freight</th>
                            <th>Export Logistics</th>
                            <th>Import Logistics</th>
                            <th>Export Customs</th>
                            <th>Import Customs</th>
                        </tr>
                    </thead>
                    <tbody className={'table-body'}>
                    {Object.keys(PERMISSIONS_LIST).map((p, pIndex) => (
                        <tr key={pIndex}>
                            {pIndex === 0 && <td rowSpan={6}>{t("organization.shipments.edit-access-permission.shipment-detail")}</td>}
                            {pIndex === 6 && <td rowSpan={22}>{t("organization.shipments.edit-access-permission.task-dashboard")}</td>}
                            {pIndex === 28 && <td rowSpan={10}>{t("organization.shipments.edit-access-permission.issue")}</td>}
                            {pIndex === 38 && <td rowSpan={11}>{t("organization.shipments.edit-access-permission.documents")}</td>}
                            {pIndex === 49 && <td rowSpan={2}>{t("organization.shipments.edit-access-permission.shipment-log")}</td>}
                            {pIndex === 51 && <td rowSpan={3}>{t("organization.shipments.edit-access-permission.shipment-information")}</td>}
                            {pIndex === 54 && <td rowSpan={7}>{t("organization.shipments.edit-access-permission.fta-advisor")}</td>}
                            <td>{t(PERMISSIONS_LABEL_LIST[p])}</td>
                            <td>
                                <input type="checkbox" onChange={(event) => handleSelectAll(event, PERMISSIONS_LIST[p])}
                                       checked={arrayPageCheckAll.filter(x => x.row === PERMISSIONS_LIST[p]).length > 15 - dataUTEdit.filter(x => x.ROW === PERMISSIONS_LIST[p]).length}
                                       name={'selectAll'}/>
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.MANAGER) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.MANAGER) !== -1}
                                        name={`${USER_ROLES.MANAGER}-${p}`}
                                        ref={register()}
                                        onChange={(event) => handleSelect(event, USER_ROLES.MANAGER, p)}
                                        value={USER_ROLES.MANAGER} type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.EDITOR) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.EDITOR) !== -1}
                                        name={`${USER_ROLES.EDITOR}-${p}`}
                                        ref={register()} value={USER_ROLES.EDITOR}
                                        onChange={(event) => handleSelect(event, USER_ROLES.EDITOR, p)}
                                        type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.VIEWER) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.VIEWER) !== -1}
                                        name={`${USER_ROLES.VIEWER}-${p}`}
                                        ref={register()} value={USER_ROLES.VIEWER}
                                        onChange={(event) => handleSelect(event, USER_ROLES.VIEWER, p)}
                                        type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.OPERATOR) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.OPERATOR) !== -1}
                                        name={`${USER_ROLES.OPERATOR}-${p}`}
                                        ref={register()} value={USER_ROLES.OPERATOR}
                                        onChange={(event) => handleSelect(event, USER_ROLES.OPERATOR, p)}
                                        type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.CREATOR) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.CREATOR) !== -1}
                                        name={`${USER_ROLES.CREATOR}-${p}`}
                                        ref={register()} value={USER_ROLES.CREATOR}
                                        onChange={(event) => handleSelect(event, USER_ROLES.CREATOR, p)}
                                        type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.ASSIGNEE) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.ASSIGNEE) !== -1}
                                        name={`${USER_ROLES.ASSIGNEE}-${p}`}
                                        ref={register()} value={USER_ROLES.ASSIGNEE}
                                        onChange={(event) => handleSelect(event, USER_ROLES.ASSIGNEE, p)}
                                        type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.ORG_OWNER) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.ORG_OWNER) !== -1}
                                        name={`${USER_ROLES.ORG_OWNER}-${p}`}
                                        ref={register()} value={USER_ROLES.ORG_OWNER}
                                        onChange={(event) => handleSelect(event, USER_ROLES.ORG_OWNER, p)}
                                        type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.ORG_ADMIN) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.ORG_ADMIN) !== -1}
                                        name={`${USER_ROLES.ORG_ADMIN}-${p}`}
                                        ref={register()}
                                        onChange={(event) => handleSelect(event, USER_ROLES.ORG_ADMIN, p)}
                                        value={USER_ROLES.ORG_ADMIN} type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.SHIPMENT_ADMIN) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.SHIPMENT_ADMIN) !== -1}
                                        name={`${USER_ROLES.SHIPMENT_ADMIN}-${p}`} ref={register()}
                                        onChange={(event) => handleSelect(event, USER_ROLES.SHIPMENT_ADMIN, p)}
                                        value={USER_ROLES.SHIPMENT_ADMIN}
                                        type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.SHIPPER) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.SHIPPER) !== -1}
                                        name={`${USER_ROLES.SHIPPER}-${p}`} ref={register()}
                                        onChange={(event) => handleSelect(event, USER_ROLES.SHIPPER, p)}
                                        value={USER_ROLES.SHIPPER} type="checkbox"/> : ''
                                }

                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.CONSIGNEE) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.CONSIGNEE) !== -1}
                                        name={`${USER_ROLES.CONSIGNEE}-${p}`} ref={register()}
                                        onChange={(event) => handleSelect(event, USER_ROLES.CONSIGNEE, p)}
                                        value={USER_ROLES.CONSIGNEE} type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.FREIGHT) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.FREIGHT) !== -1}
                                        name={`${USER_ROLES.FREIGHT}-${p}`} ref={register()}
                                        onChange={(event) => handleSelect(event, USER_ROLES.FREIGHT, p)}
                                        value={USER_ROLES.FREIGHT} type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.EXPORT_LOGISTICS) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.EXPORT_LOGISTICS) !== -1}
                                        name={`${USER_ROLES.EXPORT_LOGISTICS}-${p}`} ref={register()}
                                        onChange={(event) => handleSelect(event, USER_ROLES.EXPORT_LOGISTICS, p)}
                                        value={USER_ROLES.EXPORT_LOGISTICS} type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.IMPORT_LOGISTICS) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.IMPORT_LOGISTICS) !== -1}
                                        name={`${USER_ROLES.IMPORT_LOGISTICS}-${p}`} ref={register()}
                                        onChange={(event) => handleSelect(event, USER_ROLES.IMPORT_LOGISTICS, p)}
                                        value={USER_ROLES.IMPORT_LOGISTICS} type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.EXPORT_CUSTOMS) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.EXPORT_CUSTOMS) !== -1}
                                        name={`${USER_ROLES.EXPORT_CUSTOMS}-${p}`} ref={register()}
                                        onChange={(event) => handleSelect(event, USER_ROLES.EXPORT_CUSTOMS, p)}
                                        value={USER_ROLES.EXPORT_CUSTOMS} type="checkbox"/> : ''
                                }
                            </td>
                            <td>
                                {dataUTEdit.findIndex(x => x.ROW === p && x.COL === USER_ROLES.IMPORT_CUSTOMS) === -1 ?
                                    <input
                                        checked={arrayPageCheckAll.findIndex(x => x.row === p && x.col === USER_ROLES.IMPORT_CUSTOMS) !== -1}
                                        name={`${USER_ROLES.IMPORT_CUSTOMS}-${p}`} ref={register()}
                                        onChange={(event) => handleSelect(event, USER_ROLES.IMPORT_CUSTOMS, p)}
                                        value={USER_ROLES.IMPORT_CUSTOMS} type="checkbox"/> : ''
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </Modal>
    )
}
