import React, { useContext } from 'react';
import PropsTypes from 'prop-types';

import './index.scss';
import {
    Button,
    ShipmentFilter
} from 'components';
import {ActiveShipmentsContext, HomeContext, ModalContext, OrganizationContext} from 'contexts';
import { useHistory } from 'react-router-dom';
import { MARK_NEW, SHIPMENT_DETAILS, ACTIVE, ACTIVE_SHIPMENT_PAGE_SIZE, NEED_ATTENTION, STAR, OTHER_ACTIVE } from 'actions';
import { useTranslation } from 'react-i18next';

export const ActiveShipmentsHeader = ({ title, noOfActiveShipments, currentTab }) => {
    const [t] = useTranslation();
    const history = useHistory();

    const { setMarkAsShipmentType, setFilterParamsShipment } = useContext(HomeContext);
    const { shipmentFilter, setShipmentFilter, doGetActiveShipments } = useContext(ActiveShipmentsContext);
    const { activeShipmentFilter, setActiveShipmentFilter } = useContext(ModalContext);
    const { isArchive, setSearchTerm } = useContext(HomeContext);
    const { doGetOrganizations } = useContext(OrganizationContext);

    return (
        <>
            <div className="tr__active-shipments-header mtx1 mbx2">
                <div className="row">
                    <div className={`${isArchive ? 'col-12 flex-column' : 'col-6 align-items-center'} d-flex`}>
                        {title && (
                            <h1 className="h1 f-medium">{title}</h1>
                        )}
                        {noOfActiveShipments > 0 && (
                            <p className={`${isArchive ? '' : 'mlx2'} f-medium`}>
                                {noOfActiveShipments} {isArchive ? t("home.active-shipment.archive") : t("home.active-shipment.active")} {noOfActiveShipments > 1 ? t("home.active-shipment.shipments") : t("home.active-shipment.shipment")}
                            </p>
                        )}
                    </div>
                    {!isArchive && (
                        <div className="col-6 d-flex align-items-center justify-content-end">
                            <Button
                                onClick={() => {
                                    setShipmentFilter(false);
                                    let payload = {
                                        page: 0,
                                        limit: ACTIVE_SHIPMENT_PAGE_SIZE,
                                        tab: currentTab,
                                        status: ACTIVE,
                                    }
                                    setFilterParamsShipment(payload);
                                    doGetActiveShipments({...payload,tabType:STAR}, () => {
                                        setActiveShipmentFilter(false);
                                    });
                                    doGetActiveShipments({...payload,tabType:NEED_ATTENTION}, () => {
                                        setActiveShipmentFilter(false);
                                    });
                                    doGetActiveShipments({...payload,tabType:OTHER_ACTIVE}, () => {
                                        setActiveShipmentFilter(false);
                                    });
                                }}
                                className="icon outline reload"
                                icon="icon-refresh"
                                type="secondary"
                            />

                            <h4 className = {`f-medium text-right ${shipmentFilter ? "activeReload" : "deActiveReload"}`} onClick={() => {
                                setActiveShipmentFilter(true);
                                doGetOrganizations({page: 0, limit: 9999, tab: 'MINE', isSearching: true});
                            }}>
                                <i className="icon-filter" />
                                <span>{t("home.active-shipment.filter")}</span>
                            </h4>
                            <Button
                                className="btn_create_shipment"
                                type="primary"
                                onClick={() => {
                                    setMarkAsShipmentType(MARK_NEW);
                                    history.push(SHIPMENT_DETAILS);
                                    setSearchTerm("");
                                }}
                            >
                                {t("home.active-shipment.create-shipment")}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <ShipmentFilter
                open={activeShipmentFilter}
                onCancel={() => setActiveShipmentFilter(false)}
                currentTab={currentTab}
            />
        </>
    )
};

ActiveShipmentsHeader.propTypes = {
    title: PropsTypes.string,
    noOfActiveShipments: PropsTypes.number,
    currentTab: PropsTypes.string
};
