import React, { useContext, useState, useRef } from 'react';
import moment from 'moment';
import {
    Datepicker,
    Input,
    Collaborators,
    Dropdown,
    Pie,
    Tooltip,
} from 'components';
import { CollaboratorsContext, HomeContext, ManagementContext, ModalContext, ShipmentInfoContext } from 'contexts';
import { statusOptions } from 'utils';
import { CARD, ALL, COLLABORATORS_PAGE_SIZE, DONE, MYTASK, VDL } from 'actions';
import { CONSIGNEE, EXPORT_CUSTOMS, EXPORT_LOGISTICS, FREIGHT, GET_FILE, IMPORT_CUSTOMS, IMPORT_LOGISTICS, PERMISSIONS_LIST, SHIPMENT_ADMIN, SHIPMENT_STATUS } from 'actions/constants';
import avatar from 'assets/images/avatar.png';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd';

export const CardEvent = ({ task, index, moveCard, currentTab, type, section, tasks }) => {
    // console.log(task)
    // console.log(section)
    const { t } = useTranslation();
    const {
        setAddTask,
        setAssignCollaborators,
        setLinkIssueList
    } = useContext(ModalContext);
    const {
        setIsEditTask,
        isArchive,
        setTaskId,
        setSectionId,
        setEventTask,
        setSelectTask,
        setTaskName,
        setTaskRoles,
        setCurrentTabManagement,
        setIsTemplate,
        permission,
        setPermissionEditTask,
        setIsRemovePartners
    } = useContext(HomeContext);
    const { doGetCollaborators } = useContext(CollaboratorsContext);

    const {
        doGetTaskPartners,
        doChangeStatusTask,
        doGetTaskManagements,
        doAddEventTask,
        setIsDone,
        setLinkIssues,
        permissionTask,
        doEditOrderTaskActiveShipment
    } = useContext(ManagementContext);
    const { shipmentInfo } = useContext(ShipmentInfoContext);
    const [limit] = useState(4);
    const { shipmentId } = useParams();

    const handleAssignTask = (taskId, roles, name, status) => {
        setIsRemovePartners(checkPermissionTasks(PERMISSIONS_LIST.TASK_REMOVE_PARTNERS, taskId))
        doGetTaskPartners(taskId, () => {
            setAssignCollaborators(true);
        });
        setTaskId(taskId);
        setTaskName(name);
        setTaskRoles(roles);
        setIsDone(status && status.toLowerCase() === DONE);
        doGetCollaborators(shipmentId, {
            page: 0,
            limit: COLLABORATORS_PAGE_SIZE,
            role: roles.map(role => role.role)
        });

    }
    const handleChangeStatus = (option, taskId) => {
        // debugger
        const payload = {
            status: option.value.toUpperCase()
        }
        if (currentTab === ALL) {
            doChangeStatusTask(taskId, payload, () => {
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: ALL
                })
            });
        } else {
            doChangeStatusTask(taskId, payload, () => {
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: MYTASK
                })
            });
        }

    }

    const handleAddEvent = (date, taskId, event, sectionId, reminderEnable, schedules) => {
        let partnersUpdate = [];
        task.partners.forEach(partner => {
            partnersUpdate = [
                ...partnersUpdate,
                {
                    id: partner.collaboratorId,
                    type: partner.type
                }
            ];
        })

        let selectedDueDate = {}
        if (task.dueDate && task.dueDate.specificDate) {
            selectedDueDate = {
                specificDate: task.dueDate.specificDate,
            }
        } else {
            selectedDueDate = {
                eventBasedDate: {
                    typeOfEvent: (task.dueDate.eventBasedDate && task.dueDate.eventBasedDate.typeOfEvent !== null) ? task.dueDate.eventBasedDate.typeOfEvent : null,
                    dateOfEvent: (task.dueDate.eventBasedDate && task.dueDate.eventBasedDate.dateOfEvent !== null) ? task.dueDate.eventBasedDate.dateOfEvent : 0,
                    adjustDays: (task.dueDate.eventBasedDate && task.dueDate.eventBasedDate.adjustDays !== null) ? task.dueDate.eventBasedDate.adjustDays : null
                }
            }
        }
        // let currentDuedate = task.dueDate;
        // currentDuedate.specificDate = date.getTime();

        let remindSchedule = [];
        schedules && schedules.length > 0 && schedules.forEach(schedules => {
            remindSchedule = [
                ...remindSchedule,
                {
                    daysBeforeDueDate: schedules.daysBeforeDueDate,
                    remindMail: schedules.remindMail,
                    remindNotification: schedules.remindNotification,
                    timeAsMillisecond: schedules.timeAsMillisecond
                }
            ];
        })

        const payload = {
            sectionId: sectionId,
            taskName: task.name,
            dueDate: selectedDueDate,
            roles: task.roles.map(role => {
                return {
                    permission: role.permission,
                    role: role.role,
                }
            }),
            partners: partnersUpdate,
            type: task.type,
            action: date.getTime(),
            reminderEnable,
            remindSchedule
            // remindSchedule: remindSchedule,

        }

        doAddEventTask(shipmentId, taskId, payload, () => {
            doChangeStatusTask(taskId, {
                status: DONE.toUpperCase()
            }, () => {
                if (currentTab === ALL) {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: ALL
                    })
                } else {
                    doGetTaskManagements(shipmentId, {
                        page: 0,
                        tab: MYTASK
                    })
                }

            });
        });

    }

    const checkPermissionTasks = (action, taskId) => {
        return permission?.shipmentFunctions.includes(action) || permissionTask.find(task => task.id === taskId)?.functions.includes(action);
    }

    const checkRolesPartner = (role) => {
        let rolePartner = "";
        switch (role) {
            case EXPORT_CUSTOMS:
                rolePartner = "home.role.export-customs"
                break;
            case IMPORT_CUSTOMS:
                rolePartner = "home.role.import-customs"
                break;
            case FREIGHT:
                rolePartner = "home.role.freight"
                break;
            case IMPORT_LOGISTICS:
                rolePartner = "home.role.import-logistics"
                break;
            case EXPORT_LOGISTICS:
                rolePartner = "home.role.export-logistics"
                break;
            case CONSIGNEE:
                rolePartner = "home.role.consignee"
                break;
            case SHIPMENT_ADMIN:
                rolePartner = "home.role.shipment-admin"
                break;
            default:
                rolePartner = "home.role.shipper"
                break;
        }
        return rolePartner;
    }
    const checkStatus = (status) => {
        let taskStatus = "";
        switch (status) {
            case SHIPMENT_STATUS.PENDING:
                taskStatus = "home.active-shipment.details.tabs.management-tab.status.pending"
                break;
            case SHIPMENT_STATUS.INPROGRESS:
                taskStatus = "home.active-shipment.details.tabs.management-tab.status.inprogress"
                    break;
            case SHIPMENT_STATUS.STUCK:
                taskStatus = "home.active-shipment.details.tabs.management-tab.status.stuck"
                break;
            case SHIPMENT_STATUS.DONE:
                taskStatus = "home.active-shipment.details.tabs.management-tab.status.done"
                break;
            default:
                taskStatus = ""
                break;
        }
        return taskStatus;
    }
    const ref = useRef(null)

    const [{ isOver }, drop] = useDrop({
        accept: CARD,
        collect: (monitor) => ({
            // handlerId: monitor.getHandlerId(),
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver()
        }),
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            // console.log("handlerId", handlerId)
            // debugger
            const dragIndex = item.index;
            const hoverIndex = index;
            const dragType = item.typeEvent;
            const hoverType = type;
            // console.log("item", item);
            // console.log("item task", item.task)

            // console.log("dragType", dragType)
            // console.log("hoverType", hoverType);
            if (dragIndex === hoverIndex) {
                return;
            }
            if (dragType !== hoverType) {
                return;
            }
            // if((dragIndex !== hoverIndex) && (item.typeEvent !== ){
            //     return;
            // }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        drop: () => doEditOrderTaskActiveShipment(shipmentId, tasks.map((task, taskIndex) => {
                return {
                    id: task.id,
                    order: taskIndex
                }
            }), () => {
                doGetTaskManagements(shipmentId, {
                    page: 0,
                    tab: currentTab === ALL ? ALL : MYTASK
                })
            })
    });

    const [{ isDragging }, drag] = useDrag({
        item: {
            type: CARD,
            id: task.id,
            index,
            typeEvent: task.type
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    let backgroundColor = "";
    if(isOver){
        backgroundColor =  "#bbf";
    }
    if(isDragging){
        backgroundColor = '#fbb';
    }

    return (
        <div ref={ref} style={{ backgroundColor }} className={`table--body d-flex table-body justify-content-between ${task.status.taskStatus.toLowerCase() === DONE ? 'done' : ''}`} >
            <div className="column name d-flex align-items-center justify-content-between">
                <h4 className="h4 f-medium">
                    <i className="icon-check" />
                    {/* <span>{task.name}</span> */}
                    <span className="short-name" title={task.name}>{task.name}</span>
                </h4>
                {task.issuesLink.length > 0 && (
                    <i className="tr__link-task-pin icon-chain" onClick={() => {
                        setLinkIssueList(true);
                        setLinkIssues(task.issuesLink);
                    }
                    } />
                )}

            </div>
            <div className="column partner">
                <div className="partners">
                    {task.roles && task.roles.length > 0 && (
                        <>
                            {task.roles && task.roles.length > 0 && (
                                <>
                                    {task.roles.slice(0, limit).map((role, roleIndex) => (
                                        <Tooltip
                                            content={role && role.role
                                                ? t(checkRolesPartner(role.role))
                                                : role}
                                            key={roleIndex}
                                        >
                                            {role && role.role && role.role === EXPORT_CUSTOMS ? <i className={`icon-export`} /> : ''}
                                            {role && role.role && role.role === IMPORT_CUSTOMS ? <i className={`icon-import`} /> : ''}
                                            {role && role.role && role.role === FREIGHT ? <i className={`icon-truck`} /> : ''}
                                            {role && role.role && role.role === IMPORT_LOGISTICS ? <i className={`icon-box-import`} /> : ''}
                                            {role && role.role && role.role === EXPORT_LOGISTICS ? <i className={`icon-box-export`} /> : ''}
                                            {role && role.role && role.role === CONSIGNEE ? <i className={`icon-box`} /> : ''}
                                            {role && role.role && role.role === SHIPMENT_ADMIN ? <i className={`icon-admin`} /> : ''}
                                            <i className={`icon-${role && role.role ? role.role.toLowerCase() : ''}`} />
                                        </Tooltip>
                                    ))}

                                    {limit < task.roles.length && (
                                        <Tooltip
                                            renderContent={() => (
                                                <div className="tags d-flex flex-column">
                                                    {task.roles.slice(limit, task.roles.length).map((partner, partnerIndex) => (
                                                        <span key={partnerIndex}>{partner && partner.role ? partner.role.replace('_', ' ').toLowerCase() : ''}</span>
                                                    ))}
                                                </div>
                                            )}
                                        >
                                            <span className="f-medium">+{task.roles.length - limit}</span>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="column assignee">
                <Collaborators
                    members={task.partners}
                    onAdd={() => handleAssignTask(task.id, task.roles, task.name)}
                    onAvatarsClick={() => handleAssignTask(task.id, task.roles, task.name, task.status.taskStatus)}
                    done={task.status.taskStatus.toLowerCase() === DONE ? true : false}
                    noneButton={!checkPermissionTasks(PERMISSIONS_LIST.TASK_ASSIGN_PARTNERS, task.id)}
                    skip={5}
                    label={t("template.my-template.detail.assign")}
                />
            </div>
            {/* cho nay  */}
            <div className="column status">
                <Dropdown
                    className="select"
                    icon="icon-chevron-down"
                    iconPosition="right"
                    mode="select"
                    placeholder="Choose status"
                    // disabled={!task.partners.some(partner => partner.collaboratorId === userInfo._id)}
                    dropdownPosition="center"
                    options={statusOptions.map(type => {
                        return {
                            key: type.key,
                            value: t(type.value)
                        }
                    })}
                    value={statusOptions[Math.floor(Math.random() * statusOptions.length)]}
                    renderLabel={selectedValue => (
                        <div className="status-option">
                            <p className={`task-status text-capitalize ${task.status.taskStatus.toLowerCase()}`}>
                                {t(checkStatus(task.status.taskStatus))}
                            </p>
                            <Tooltip
                                renderContent={() => (
                                    <div className="tags d-flex flex-column">
                                        <span>{task.status.userUpdateName}</span>
                                        <small>{task.status.userRole ? task.status.userRole.replace('_', ' ').toLowerCase() : ''}</small>
                                    </div>
                                )}
                            >
                                <img src={task.status.userUpdateAvatar === '' ? avatar : GET_FILE + task.status.userUpdateAvatar} alt={task.status.userUpdateName} />
                            </Tooltip>
                        </div>
                    )}
                    render={(options, handleChange, selectedValue) => (
                        <>
                            {(task.status.taskStatus.toLowerCase() !== DONE && !isArchive) ? (
                                <div className={`tr__status-dropdown  ${checkPermissionTasks(PERMISSIONS_LIST.TASK_UPDATE_STATUS_DATE, task.id) && !isArchive ? '' : 'disabled'}`}>
                                    {options.map((option, optionIndex) => (
                                        <div
                                            className={`status-option ${task.status.taskStatus === option.key ? 'active' : ''}`}
                                            key={optionIndex}
                                            onClick={() => {
                                                handleChangeStatus(option, task.id)
                                                handleChange(option)
                                            }}
                                        >
                                            <p className={`task-status text-capitalize ${option.key.toLowerCase()}`}>{t(option.value)}</p>
                                            <i className="icon-check" />
                                        </div>
                                    ))}
                                </div>
                            ) : ''}
                        </>
                    )}
                />
            </div>
            <div className="column date d-flex align-items-center justify-content-between">
                {task.status.taskStatus.toLowerCase() !== DONE && (
                    <>
                        {task.dueDate.specificDate ? (
                            <>
                                {new Date().getTime() - task.dueDate.specificDate > 0 ? (
                                    <Tooltip
                                        content={`${Math.abs(Math.floor((new Date().getTime() - task.dueDate.specificDate) / 86400000)) + t("home.active-shipment.shipmenttaskitem.days-overdue")}`}
                                    >
                                        <i className="icon-warning" />
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        content={`${Math.abs(Math.floor((new Date().getTime() - task.dueDate.specificDate) / 86400000)) + t("home.active-shipment.shipmenttaskitem.notification-left")}`}
                                    >
                                        <Pie
                                            width={18}
                                            height={18}
                                            percentage={80}
                                        />
                                    </Tooltip>
                                )}

                            </>
                        ) : (task.dueDate.eventBasedDate.typeOfEvent !== null ? (
                            <>
                                {task.dueDate.eventBasedDate && new Date().getTime() - ((task.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + task.dueDate.eventBasedDate.adjustDays) > 0 ? (
                                    <Tooltip
                                        content={`${Math.abs(Math.floor((new Date().getTime() - ((task.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + task.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000)) + t("home.active-shipment.shipmenttaskitem.days-overdue")}`}
                                    >
                                        <i className="icon-warning" />
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        content={`${Math.abs(Math.floor((new Date().getTime() - ((task.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + task.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000)) + t("home.active-shipment.shipmenttaskitem.notification-left")}`}
                                    >
                                        <Pie
                                            width={18}
                                            height={18}
                                            percentage={80}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        ) : (''))}


                    </>
                )}
                {task.status.taskStatus.toLowerCase() === DONE && (
                    <>
                        {task.dueDate.specificDate ? (
                            <Tooltip
                                content={`${(task.action - task.dueDate.specificDate) < 0
                                    ? t("home.active-shipment.shipmenttaskitem.done-on-time")
                                    : Math.abs(Math.floor((task.action - task.dueDate.specificDate) / 86400000)) + t("home.active-shipment.shipmenttaskitem.notification-completed")}`}
                            >
                                <i className="icon-check"></i>
                            </Tooltip>
                        ) : (
                            <Tooltip
                                content={`${(task.action - ((task.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + task.dueDate.eventBasedDate.adjustDays * 86400000)) < 0
                                    ? t("home.active-shipment.shipmenttaskitem.done-on-time")
                                    : Math.abs(Math.floor((task.action - ((task.dueDate.eventBasedDate.typeOfEvent === VDL ? shipmentInfo.departureDate : shipmentInfo.arrivalDate) + task.dueDate.eventBasedDate.adjustDays * 86400000)) / 86400000)) + t("home.active-shipment.shipmenttaskitem.notification-completed")}`}
                            >
                                <i className="icon-check"></i>
                            </Tooltip>
                        )}
                    </>
                )}
                <Datepicker
                    start={new Date()}
                    render={(value, onClick) => (
                        <div className="tr__booking-date">
                            <Input
                                iconPosition="right"
                                placeholder="Booking Date"
                                value={task.dueDate.specificDate
                                    ? moment(task.dueDate.specificDate).format('DD MMM YYYY')
                                    // : moment(task.dueDate.eventBasedDate.dateOfEvent + task.dueDate.eventBasedDate.adjustDays * 86400000).format('DD MMM YYYY')}
                                    : (task.dueDate.eventBasedDate.typeOfEvent !== null
                                        ? (task.dueDate.eventBasedDate.typeOfEvent === VDL ? moment(shipmentInfo.departureDate + (task.dueDate.eventBasedDate.adjustDays * 86400000)).format('DD MMM YYYY') : moment(shipmentInfo.arrivalDate + (task.dueDate.eventBasedDate.adjustDays * 86400000)).format('DD MMM YYYY'))
                                        : ''
                                    )}
                                disabled={true}
                            />
                            {task.dueDate.eventBasedDate && task.dueDate.eventBasedDate.typeOfEvent !== null ? (
                                <p className="tr__badge">{`${task.dueDate.eventBasedDate.typeOfEvent}  ${task.dueDate.eventBasedDate.adjustDays > 0 ? '+' : ''} ${task.dueDate.eventBasedDate.adjustDays}`}</p>
                            ) : ''}
                        </div>
                    )}
                />
            </div>
            <div className="column actions d-flex align-items-center justify-content-between">
                {isArchive ? (
                    <p className="mlx2"></p>
                ) : (
                    <>
                        {/* {checkPermissionTasks(PERMISSIONS_LIST.TASK_ADD_EVENT, task.id) && ( */}
                        <Datepicker
                            onDatesChange={date => handleAddEvent(date, task.id, task, section.id, task.reminderEnable, task.remindSchedule)}
                            disabled={task.status.taskStatus.toLowerCase() === DONE || !checkPermissionTasks(PERMISSIONS_LIST.TASK_ADD_EVENT, task.id)}
                            placeholder={`+ ${t("home.active-shipment.details.tabs.management-tab.add-event")}`}
                            value={task.status.taskStatus.toLowerCase() === DONE &&
                                (task.action !== null ? moment(task.action).format('DD MMM YYYY') : '')
                            }
                            minDate={new Date()}
                        />

                        {/* } */}

                        {checkPermissionTasks(PERMISSIONS_LIST.TASK_VIEW_DETAILS, task.id) && (
                            <p
                                className={`tr__link info ${task.status.taskStatus.toLowerCase() === DONE ? 'disabled' : ''
                                    }`}
                                onClick={() => {
                                    setAddTask(true);
                                    setEventTask(task);
                                    setIsEditTask(true);
                                    setSectionId(task.id);
                                    setSelectTask(section);
                                    setIsTemplate(false);
                                    setCurrentTabManagement(currentTab);
                                    setPermissionEditTask(checkPermissionTasks(PERMISSIONS_LIST.TASK_UPDATE_DETAILS, task.id));
                                }}
                            >
                                <i className="icon-pencil" />
                            </p>
                        )

                        }

                    </>
                )}
            </div>
        </div>
   

    )
}
