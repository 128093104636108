import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import './index.scss';
import { Dropdown, Input, Datepicker, InfoCell } from 'components';
import { getErrorMessage } from 'utils';
import { BookingRequestContext, HomeContext, ModalContext, TrackShipmentContext } from 'contexts';
import { DESTINATION, LOCODE_PAGE_SIZE, ORIGIN, VESSEL_SCHEDULE_URL } from 'actions';
import { Button, Collapse } from 'components/common';
import { isEmpty } from 'lodash'
import {useTranslation} from "react-i18next";

export const ShipmentForm = ({
    register = () => {},
    errors = [],
    containers = [],
    setContainers = () => {},
    typeOfShipment,
    getValues = () => {},
    shipmentDetails,
    bookVesselSchedule = {},
    isEditBooking = false,
    noSelectLinkedVessel = false,
    draft
}) => {

    const { brId } = useParams();
    const { origins, destinations, doGetLocodes } = useContext(TrackShipmentContext);
    const { bookingRequest } = useContext(BookingRequestContext);
    const { setDeleteLinkedVessel } = useContext(ModalContext);
    const { setLinkedVessel, setBookingid, unlinkVessel } = useContext(HomeContext);
    setBookingid(brId);
    const history = useHistory();
    const { t } = useTranslation();

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [loadingPortLoading, setLoadingPortLoading] = useState(false);
    const [dischargePortLoading, setDischargePortLoading] = useState(false);
    const [placeReceipt, setPlaceReceipt] = useState('');
    const [placeDelivery, setPlaceDelivery] = useState('');
    const from = bookingRequest?.linkedVesselSchedule?.legs[0].start;
    const to = bookingRequest?.linkedVesselSchedule?.legs[bookingRequest.linkedVesselSchedule.legs.length - 1].end;
    const transits = bookingRequest?.linkedVesselSchedule?.legs?.slice(0, bookingRequest.linkedVesselSchedule.legs.length - 1);

    const fromBookingVessel = bookVesselSchedule && bookVesselSchedule.legs && bookVesselSchedule.legs.length > 0 && bookVesselSchedule.legs[0].start;
    const toBookingVessel = bookVesselSchedule && bookVesselSchedule.legs && bookVesselSchedule.legs.length > 0 && bookVesselSchedule.legs[bookVesselSchedule.legs.length - 1].end;
    const transitsBookingVessel = bookVesselSchedule && bookVesselSchedule.legs && bookVesselSchedule.legs.length > 0 && bookVesselSchedule.legs.slice(0, bookVesselSchedule.legs.length - 1);
    console.log(bookVesselSchedule, 'data')
    /*eslint-disable */
    useEffect(() => {
        doGetLocodes({ limit: LOCODE_PAGE_SIZE });
        const scripts = document.scripts;
        const MAPBOX_GEOCODER_URL = 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.5.1/mapbox-gl-geocoder.min.js';
        let isInjected = false;

        for (let i = 0; i < scripts.length; i++) {
            if (scripts[i].src === MAPBOX_GEOCODER_URL) {
                isInjected = true;
                break;
            }
        }

        if (!isInjected) {
            let mapGeocoderScriptElement = document.createElement('script');
            mapGeocoderScriptElement.src = MAPBOX_GEOCODER_URL;

            document.head.appendChild(mapGeocoderScriptElement);
        } else {
            setScriptLoaded(true);
        }
    }, [])

    useEffect(() => {
        if (window.MapboxGeocoder) setScriptLoaded(true);
    }, [window.MapboxGeocoder])

    useEffect(() => {
        if (scriptLoaded) {
            addGeocoder('pr', 'placeOfReceipt', 'Place of receipt', setPlaceReceipt);
            addGeocoder('pd', 'placeOfDelivery', 'Place of Delivery', setPlaceDelivery);
        }
    }, [scriptLoaded])

    useEffect(() => {
        if (bookingRequest) {
            setPlaceReceipt(bookingRequest.placeOfReceipt);
            setPlaceDelivery(bookingRequest.placeOfDelivery);
        } else if (shipmentDetails){
            setPlaceDelivery(shipmentDetails.placeOfDelivery ? shipmentDetails.placeOfDelivery : '');
            setPlaceReceipt(shipmentDetails.placeOfReceipt ? shipmentDetails.placeOfReceipt : '');
        }
        else {
            setPlaceReceipt('');
            setPlaceDelivery('');
        }
    }, [bookingRequest])

    const addGeocoder = (key, selector, placeholder, setPlace) => {
        window[key] = null;
        if (window.MapboxGeocoder) {
            window[key] = new window.MapboxGeocoder({
                accessToken: process.env.REACT_APP_MAPBOX_API_KEY,
                types: 'country,region,place,postcode,locality,neighborhood',
                placeholder
            });

            window[key].addTo(`#${selector}`);

            window[key].on('result', result => {
                setPlace(result.result.place_name);
            })
        }
    }

    const handleSearch = (value, key, setValue) => {
        setValue(value);
        if (window[key]) {
            if (value) window[key].setInput(value)._geocode(value);
            else window[key].setInput(value);
        }
    }

    const onSearchLoadingPort = value => {
        setLoadingPortLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setLoadingPortLoading(false);
        }, () => {
            setLoadingPortLoading(false);
        });
    }

    const onSearchDischargePort = value => {
        setDischargePortLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setDischargePortLoading(false);
        }, () => {
            setDischargePortLoading(false);
        }, DESTINATION);
    }

    const getLocodeName = (locodes, locode, type) => {
        let selectedLocode = locodes.find(l => l.locode === locode);
        if (!selectedLocode) {
            doGetLocodes({
                limit: LOCODE_PAGE_SIZE,
                cop: locode,
                isSearching: true
            }, data => {
                selectedLocode = data.find(l => l.locode === locode);
                return `${selectedLocode.city}, ${selectedLocode.country} (${selectedLocode.locode})`
            }, () => {
                return '';
            }, type);
        } else {
            return `${selectedLocode.city}, ${selectedLocode.country} (${selectedLocode.locode})`
        }
    }

    return (
        <div className="tr__shipment-form">
            <div className="row">
                <div id="demo" />
                {typeOfShipment ? (
                    <>
                        <div className="col-12 col-sm-6">
                            <Input
                                className="mtx3"
                                name="numberPackage"
                                placeholder={t('home.BookingRequest.addNewRequest.form.placeholderNumberOfPackage')}
                                defaultValue={shipmentDetails ? shipmentDetails.numberOfPackage : (bookingRequest ? bookingRequest.numberOfPackage : '')}
                                refs={register({ required: true, pattern: /[0-9]+/ })}
                                error={!!errors.numberPackage}
                                errorMessage={getErrorMessage(errors.numberPackage, "Number of package")}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <Input
                                className="mtx3"
                                name="numberPallet"
                                placeholder={t('home.BookingRequest.addNewRequest.form.placeholderNumberOfPallet')}
                                refs={register({ pattern: /[0-9]+/ })}
                                defaultValue={shipmentDetails ? shipmentDetails.numberOfPallet : (bookingRequest ? bookingRequest.numberOfPallet : '')}
                                error={!!errors.numberPallet}
                                errorMessage={getErrorMessage(errors.numberPallet, "Numnber of pallet")}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <Input
                                className="mtx3"
                                name="totalVolume"
                                placeholder={t('home.BookingRequest.addNewRequest.form.placeholderTotalOfVolume')}
                                defaultValue={shipmentDetails ? shipmentDetails.totalOfVolume : (bookingRequest ? bookingRequest.totalOfVolume : '')}
                                refs={register({ pattern: /[0-9]+/ })}
                                error={!!errors.totalVolume}
                                errorMessage={getErrorMessage(errors.totalVolume, "Total of volume")}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        {(shipmentDetails && shipmentDetails.containers && shipmentDetails.containers.length === containers.length ? shipmentDetails.containers : containers).map((container, containerIndex) => (
                            <React.Fragment key={containerIndex}>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        className="mtx3"
                                        name={`numberContainer-${containerIndex}`}
                                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderNumberOfContainer')}
                                        defaultValue={container ? container.numberOfContainer : ''}
                                        refs={register({ required: true, pattern: /[0-9]+/ })}
                                        error={!!errors[`numberContainer-${containerIndex}`]}
                                        errorMessage={getErrorMessage(errors[`numberContainer-${containerIndex}`], "Number of containers")}
                                    />
                                </div>
                                <div className="col-12 col-sm-6">
                                    <Input
                                        className="mtx3"
                                        name={`typeContainer-${containerIndex}`}
                                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderTypeOfContainer')}
                                        defaultValue={container ? container.typeOfContainer : ''}
                                        refs={register({ required: true })}
                                        error={!!errors[`typeContainer-${containerIndex}`]}
                                        errorMessage={getErrorMessage(errors[`typeContainer-${containerIndex}`], "Type of container")}
                                    />
                                </div>
                            </React.Fragment>
                        ))}
                        <p
                            className="tr__link mtx3"
                            onClick={() => setContainers(oldContainers => [...oldContainers, oldContainers.length])}
                        >
                            <i className="icon-plus mrx1" />
                            <span>{t('home.BookingRequest.addNewRequest.form.addMoreContainer')}</span>
                        </p>
                    </>
                )}
                <div className="col-12 col-sm-6 with-label">
                    <Input
                        className="mtx3"
                        name="totalWeight"
                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderTotalWeight')}
                        defaultValue={(shipmentDetails && shipmentDetails.totalWeight) ? shipmentDetails.totalWeight.value : (bookingRequest ? bookingRequest?.totalWeight?.value : '')}
                        refs={register({ pattern: /[0-9]+/ })}
                        error={!!errors.totalWeight}
                        errorMessage={getErrorMessage(errors.totalWeight, "Total weight")}
                    />
                    <Dropdown
                        className="select mtx3 no-icon"
                        mode="select"
                        name="weightUnit"
                        defaultValue={{
                            key: 'KG',
                            value: 'Kg'
                        }}
                        refs={register({ required: true })}
                        dropdownPosition="center"
                    />
                </div>
                <div className={`col-12 ${typeOfShipment ? '' : 'col-sm-6'} with-label`}>
                    <Input
                        className="mtx3"
                        name="temperature"
                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderTemperature')}
                        defaultValue={(shipmentDetails && shipmentDetails.temperature) ? shipmentDetails.temperature.value : (bookingRequest ? bookingRequest?.temperature?.value : '')}
                        refs={register({ pattern: /[0-9]+/ })}
                        error={!!errors.temperature}
                        errorMessage={getErrorMessage(errors.temperature, "Temperature")}
                    />
                    <Dropdown
                        className="select mtx3"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="select"
                        name="unitOfMeasure"
                        defaultValue={{
                            key: 'CELSIUS',
                            value: 'Celsius'
                        }}
                        refs={register({ required: true })}
                        options={[{
                            key: 'CELSIUS',
                            value: 'Celsius'
                        }, {
                            key: 'FAHRENHEIT',
                            value: 'Fahrenheit'
                        }]}
                        dropdownPosition="center"
                    />
                </div>
                <div className="col-12">
                    <Input
                        className="mtx3"
                        name="remark"
                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderRemark')}
                        defaultValue={(shipmentDetails && shipmentDetails.remark) ? shipmentDetails.remark : (bookingRequest ? bookingRequest.remark : '')}
                        refs={register}
                        error={!!errors.remark}
                        errorMessage={getErrorMessage(errors.remark, "Remark")}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Dropdown
                        className="select mtx3"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="loadingPort"
                        error={!!errors.loadingPort}
                        comparable={true}
                        defaultValue={shipmentDetails ? {
                            key: shipmentDetails.loadingPort,
                            value: getLocodeName(origins, shipmentDetails.loadingPort, ORIGIN)
                        } : bookingRequest ? {
                            key: bookingRequest.loadingPort.locode,
                            value: `${bookingRequest.loadingPort.city}, ${bookingRequest.loadingPort.country} (${bookingRequest.loadingPort.locode})`
                        } : !isEmpty(bookVesselSchedule) ? {
                            key: fromBookingVessel?.port?.code,
                            value: `${fromBookingVessel?.port?.name}, ${fromBookingVessel?.port?.name} (${fromBookingVessel?.port?.code})`
                        } : null}
                        errorMessage={getErrorMessage(errors.loadingPort, "Loading port", "Loading port and Discharge port can't be the same")}
                        refs={register({ required: true, validate: value => value !== getValues('dischargePort') })}
                        options={origins && origins.length > 0 ? origins.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderLoadingPort')}
                        disabled={bookVesselSchedule?.legs || bookingRequest?.linkedVesselSchedule}
                        dropdownPosition="center"
                        onInputChange={onSearchLoadingPort}
                        searchLoading={loadingPortLoading}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Input
                        className="mtx3"
                        name="placeReceipt"
                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderPlaceOfReceipt')}
                        refs={register}
                        onChange={e => handleSearch(e.target.value, 'pr', setPlaceReceipt)}
                        value={placeReceipt}
                        error={!!errors.placeReceipt}
                        errorMessage={getErrorMessage(errors.placeReceipt, "Place of receipt")}
                    />
                    <div id="placeOfReceipt" className={placeReceipt ? '' : 'no-result'} />
                </div>
                <div className="col-12">
                    <Datepicker
                        className="mtx3"
                        name="dateReceipt"
                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderDateOfReceipt')}
                        placement="top-start"
                        refs={register}
                        start={(shipmentDetails && shipmentDetails.dateOfReceipt && shipmentDetails.dateOfReceipt.fromDate) ? new Date(shipmentDetails.dateOfReceipt.fromDate) : ((bookingRequest && bookingRequest.dateOfReceipt && bookingRequest.dateOfReceipt.fromDate) ? new Date(bookingRequest.dateOfReceipt.fromDate) : null)}
                        end={(shipmentDetails && shipmentDetails.dateOfReceipt && shipmentDetails.dateOfReceipt.toDate) ? new Date(shipmentDetails.dateOfReceipt.toDate) : ((bookingRequest && bookingRequest.dateOfReceipt && bookingRequest.dateOfReceipt.toDate) ? new Date(bookingRequest.dateOfReceipt.toDate) : null)}
                        error={!!errors.dateReceipt}
                        errorMessage={getErrorMessage(errors.dateReceipt, "Date of receipt")}
                        range={true}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Dropdown
                        className="select mtx3"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="dischargePort"
                        comparable={true}
                        error={!!errors.dischargePort}
                        errorMessage={getErrorMessage(errors.dischargePort, "Discharge port", "Loading port and Discharge port can't be the same")}
                        refs={register({ required: true, validate: value => value !== getValues('loadingPort') })}
                        defaultValue={shipmentDetails ? {
                            key: shipmentDetails.dischargePort,
                            value: getLocodeName(destinations, shipmentDetails.dischargePort, DESTINATION)
                        } : bookingRequest ? {
                            key: bookingRequest.dischargePort.locode,
                            value: `${bookingRequest.dischargePort.city}, ${bookingRequest.dischargePort.country} (${bookingRequest.dischargePort.locode})`
                        } : !isEmpty(bookVesselSchedule) ? {
                            key: toBookingVessel?.port?.code,
                            value: `${toBookingVessel?.port?.name}, ${toBookingVessel?.port?.name} (${toBookingVessel?.port?.code})`
                        } : null}
                        options={destinations && destinations.length > 0 ? destinations.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderDischargePort')}
                        disabled={bookVesselSchedule?.legs || bookingRequest?.linkedVesselSchedule}
                        dropdownPosition="center"
                        onInputChange={onSearchDischargePort}
                        searchLoading={dischargePortLoading}
                    />
                </div>
                <div className="col-12 col-sm-6">
                    <Input
                        className="mtx3"
                        name="placeDelivery"
                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderPlaceOfDelivery')}
                        refs={register}
                        onChange={e => handleSearch(e.target.value, 'pd', setPlaceDelivery)}
                        value={placeDelivery}
                        error={!!errors.placeDelivery}
                        errorMessage={getErrorMessage(errors.placeDelivery, "Place of Delivery")}
                    />
                    <div id="placeOfDelivery" className={placeDelivery ? '' : 'no-result'} />
                </div>
                {typeOfShipment ? (
                    <>
                        <div className="col-12">
                            <Input
                                className="mtx3"
                                name="freightExport"
                                placeholder={t('home.BookingRequest.addNewRequest.form.placeholderExportContainer')}
                                defaultValue={shipmentDetails ? shipmentDetails.exportAddress : (bookingRequest ? bookingRequest.exportAddress : '')}
                                refs={register}
                                error={!!errors.freightExport}
                                errorMessage={getErrorMessage(errors.freightExport, "Freight Export Address")}
                                maxLength={500}
                            />
                        </div>
                        <div className="col-12">
                            <Input
                                className="mtx3"
                                name="freightImport"
                                placeholder={t('home.BookingRequest.addNewRequest.form.placeholderImportContainer')}
                                defaultValue={shipmentDetails ? shipmentDetails.importAddress : (bookingRequest ? bookingRequest.importAddress : '')}
                                refs={register}
                                error={!!errors.remark}
                                errorMessage={getErrorMessage(errors.freightImport, "Freight Import Address")}
                                maxLength={500}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-12">
                            <Input
                                className="mtx3"
                                name="emptyAddress"
                                placeholder={t('home.BookingRequest.addNewRequest.form.placeholderEmptyP/U_Address')}
                                defaultValue={(shipmentDetails && shipmentDetails.emptyAddress) ? shipmentDetails.emptyAddress : (bookingRequest ? bookingRequest.emptyAddress : '')}
                                refs={register}
                                error={!!errors.emptyAddress}
                                errorMessage={getErrorMessage(errors.emptyAddress, "Empty P/U Address")}
                            />
                        </div>
                        <div className="col-12">
                            <Input
                                className="mtx3"
                                name="fullAddress"
                                placeholder={t('home.BookingRequest.addNewRequest.form.placeholderDropFullAddress')}
                                defaultValue={(shipmentDetails && shipmentDetails.dropAddress) ? shipmentDetails.dropAddress : (bookingRequest ? bookingRequest.dropAddress : '')}
                                refs={register}
                                error={!!errors.fullAddress}
                                errorMessage={getErrorMessage(errors.fullAddress, "Drop Full Address")}
                            />
                        </div>
                    </>
                )}
                {brId && (
                    <div className="col-12 single-row">
                        <label className="big-label f-medium mtx5 ptx4">{t('home.BookingRequest.addNewRequest.detail.reference')}</label>
                        <Input
                            className="mtx1"
                            name="bookingRequestNumber"
                            placeholder={t('home.BookingRequest.addNewRequest.detail.BookingNumber')}
                            defaultValue={shipmentDetails ? shipmentDetails.bookingRequestNumber : (bookingRequest ? bookingRequest.bookingRequestNumber : '')}
                            refs={register()}
                        />
                    </div>
                )}

                {bookingRequest?.linkedVesselSchedule && !unlinkVessel && <div>
                    <h2 className="h2 f-medium mtx3 mbx3">Link Vessel Schedule Details</h2>
                    <div className="tr__vessel--search__info">
                        <div className="d-flex justify-content-between border-column">
                            <div className="px2">
                                <div className="port__brand">
                                    <img src={!noSelectLinkedVessel && bookVesselSchedule?.carrier?.logoURL || (bookingRequest?.linkedVesselSchedule?.carrier?.logoURL)} alt="Logo" />
                                    <span>{!noSelectLinkedVessel && bookVesselSchedule?.carrier?.name || bookingRequest?.linkedVesselSchedule?.carrier?.name}</span>
                                </div>
                            </div>
                            <div className="right__port">
                                <div className="block-column right__port__date d-flex flex-column align-items-center justify-content-center">
                                    <h4 className="right__port__date__number f-medium mbx1">
                                        {!noSelectLinkedVessel && bookVesselSchedule?.duration || bookingRequest?.linkedVesselSchedule?.duration} day{bookVesselSchedule?.duration > 1 ? 's' : '' || bookingRequest?.linkedVesselSchedule?.duration > 1 ? 's' : ''}
                                    </h4>
                                    {(bookingRequest?.linkedVesselSchedule?.legs.length > 1 && (
                                        <p className="f-medium">{bookingRequest?.linkedVesselSchedule?.legs.length - 1} T/S</p>
                                    )) || (!noSelectLinkedVessel && bookVesselSchedule?.legs?.length > 1 && (
                                        <p className="f-medium">{bookVesselSchedule && bookVesselSchedule.legs && bookVesselSchedule.legs.length > 0 &&  bookVesselSchedule.legs.length -1 } T/S</p>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="port__info d-flex justify-content-center">
                            <div className="port__title text-right">
                                <h4 className="port__title__code f-medium h6 mbx1">
                                    {!noSelectLinkedVessel && fromBookingVessel?.port?.name || from?.port?.name} ({!noSelectLinkedVessel && fromBookingVessel?.port?.code || from?.port?.code})
                                    </h4>
                                <p className="f-medium">
                                    {moment(!noSelectLinkedVessel && fromBookingVessel?.date || from?.date).format('DD MMM YYYY')}
                                </p>
                            </div>
                            <div className="port__transhipping text-center">
                                <span className='port__transhipping__dashed-line'></span>
                                <div className="port__transhipping__label d-flex align-items-center">
                                    <div className="port__transhipping__item">
                                        {!noSelectLinkedVessel && transitsBookingVessel?.length > 0 && transitsBookingVessel.map((leg, legIndex) => (
                                            <React.Fragment >
                                                <i className="icon-chevron-right" />
                                                <p className="f-medium" >
                                                    {leg.end.port.code}
                                                </p>
                                                {legIndex === transitsBookingVessel.length - 1 && (
                                                    <i className="icon-chevron-right" />
                                                )}
                                            </React.Fragment>
                                        )) || transits.length > 0 && transits.map((leg, legIndex) => (
                                            <React.Fragment >
                                                <i className="icon-chevron-right" />
                                                <p className="f-medium" >
                                                    {leg.end.port.code}
                                                </p>
                                                {legIndex === transits.length - 1 && (
                                                    <i className="icon-chevron-right" />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                {!noSelectLinkedVessel && bookVesselSchedule && bookVesselSchedule.legs && bookVesselSchedule.legs.length > 1 || bookingRequest?.linkedVesselSchedule?.legs.length > 1 && (
                                    <p className="sub-title mtx2">Transhipped</p>
                                )}
                            </div>
                            <div className="port__title">
                                <h4 className="port__title__code f-medium h4 mbx1">
                                    { !noSelectLinkedVessel && toBookingVessel?.port?.name || to?.port?.name} ({!noSelectLinkedVessel && toBookingVessel?.port?.code || to?.port?.code})
                                    </h4>
                                <p className="f-medium">
                                    {moment(!noSelectLinkedVessel && toBookingVessel?.date || to?.date).format('DD MMM YYYY')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="tr__vessel--search__footer">
                        <Collapse collapseTitle="vessels" className="blue-bg">
                            <div className="content__view">
                                {!noSelectLinkedVessel && bookVesselSchedule && bookVesselSchedule.legs && bookVesselSchedule.legs.map((dataRoute, routeIndex) => (
                                    <div className="content__view__row" key={routeIndex}>
                                        <div className="d-flex title">
                                            <InfoCell title="Vessel" label={dataRoute.vessel.name} className="a" />
                                            <InfoCell title="Voyage" label={dataRoute.voyage} />
                                            <InfoCell title="Service" label={dataRoute.route.name} />
                                        </div>
                                        <div className="d-flex title">
                                            <InfoCell title="Departure" label={`${dataRoute.start.port.name} (${dataRoute.start.port.code})`} >
                                                <div className="date">{moment(dataRoute.start.date).format('DD MMM YYYY')} </div>
                                            </InfoCell>
                                            <InfoCell title="Arrival" label={`${dataRoute.end.port.name} (${dataRoute.end.port.code})`}>
                                                <div className="date">{moment(dataRoute.end.date).format('DD MMM YYYY')} </div>
                                            </InfoCell>
                                        </div>
                                    </div>
                                )) || bookingRequest?.linkedVesselSchedule?.legs.map((dataRoute, routeIndex) => (
                                    <div className="content__view__row" key={routeIndex}>
                                        <div className="d-flex title">
                                            <InfoCell title="Vessel" label={dataRoute.vessel.name} className="a" />
                                            <InfoCell title="Voyage" label={dataRoute.voyage} />
                                            <InfoCell title="Service" label={dataRoute.route.name} />
                                        </div>
                                        <div className="d-flex title">
                                            <InfoCell title="Departure" label={`${dataRoute.start.port.name} (${dataRoute.start.port.code})`} >
                                                <div className="date">{moment(dataRoute.start.date).format('DD MMM YYYY')} </div>
                                            </InfoCell>
                                            <InfoCell title="Arrival" label={`${dataRoute.end.port.name} (${dataRoute.end.port.code})`}>
                                                <div className="date">{moment(dataRoute.end.date).format('DD MMM YYYY')} </div>
                                            </InfoCell>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Collapse>
                    </div>
                    <div className="d-flex align-center justify-content-between mtx2">
                        <Button
                            type="primary"
                            onClick={() => {
                                history.push({
                                    pathname: VESSEL_SCHEDULE_URL,
                                    state: brId
                                });
                                setLinkedVessel(true);
                            }}
                        >
                            {t('home.BookingRequest.addNewRequest.detail.btnLinkAnother')}
                        </Button>
                        <Button type="danger" onClick={() => setDeleteLinkedVessel(true)}>{t('home.BookingRequest.addNewRequest.detail.btnUnlink')}</Button>
                    </div>
                </div>}

                <div className="col-12 single-row">
                    {brId && (<label className="big-label f-medium mtx5 ptx4">{t('home.BookingRequest.addNewRequest.detail.addNote')}</label>)}
                    <Input
                        className={brId ? 'mtx1' : 'mtx3'}
                        name="note"
                        placeholder={t('home.BookingRequest.addNewRequest.form.placeholderNote')}
                        defaultValue={shipmentDetails ? shipmentDetails.note : (bookingRequest ? bookingRequest.note : '')}
                        refs={register()}
                    />
                </div>
            </div>
        </div>
    )
};

ShipmentForm.propTypes = {
    register: PropTypes.func,
    errors: PropTypes.any,
    containers: PropTypes.array,
    setContainers: PropTypes.func,
    typeOfShipment: PropTypes.bool,
    getValues: PropTypes.func,
    shipmentDetails: PropTypes.any
};
