import React, { createContext, useContext, useState } from 'react'

import { AuthContext } from './AuthContext';
import { HomeContext } from './HomeContext';
import { OPERATION_FAILED_MESSAGE } from 'actions';
import {
    getDocuments,
    removeDocumentsTask,
    uploadDocumentsTask,
    uploadTaskDashboard,
    generateTradeTrust,
    getTradeTrust,
    getDocumentTaskDefault,
    uploadDocumentTaskDefault
} from 'actions/documents';

export const DocumentsContext = createContext();

export const DocumentsContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const [documents, setDocuments] = useState([]);
    const [documentsOfTask, setDocumentsOfTask] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [documentPermission, setDocumentPermission] = useState(false);
    const [permissionTask, setPermissionTask] = useState([]);
    const [permissionDefaultTask, setPermissionDefaultTask] = useState([]);
    const [functionsTask, setFunctionsTask] = useState([]);
    const {
        setLoading,
        setNotificationMessage,
        setNotificationType,
    } = useContext(HomeContext);

    const handleException = error => {
        const { data } = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetDocuments = async (shipmentId, params, callback) => {
        // if (documents && documents.length === 0) {
        try {
            setLoading(true);
            const response = await getDocuments(shipmentId, params, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                // if (params.page !== currentPage) {
                    setDocuments(data.items || []);
                // } else {
                //     setDocuments(data.items || []);
                // }
                // // setDocuments(data.items);
                // setTotalPages(data.totalPage);
                // setCurrentPage(data.currentPage);
                // setTotalDocuments(data.totalItems);
                doGetDocumentTaskDefault(shipmentId);
                setPermissionTask(data.permission.taskFunctions);
                if (callback) callback(data);
            }
            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }
    // }

    const doRemoveDocumentsTask = async (taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await removeDocumentsTask(taskId, payload, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    setDocumentsOfTask(data);
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }

    }

    const doUploadDocumentsTask = async (taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await uploadDocumentsTask(taskId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }
    const doUploadTaskDashboard = async (taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await uploadTaskDashboard(taskId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }

    const doGenerateTradeTrust = async (taskId, fileName, shipmentId, isLoading, callback) => {
        try {
            if (isLoading)
                setLoading(true);
            const response = await generateTradeTrust(taskId, fileName, shipmentId, loggedInUser);
            const { data, status } = response;
            if (isLoading)
                setLoading(false);
            return callback(status === 200 && data.success);
        } catch (error) {
            handleException(error)
        }
        if (isLoading)
            setLoading(false);
        return callback(false);
    }

    const doGetTradeTrust = async (taskId, ids, isLoading, callback) => {
        try {
            if (isLoading)
                setLoading(true);
            const response = await getTradeTrust(taskId, ids, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                callback(data);
            }
        } catch (error) {
            handleException(error)
        }
        if (isLoading)
            setLoading(false);
    }

    const doGetDocumentTaskDefault = async (shipmentId) => {
        try {
            const response = await getDocumentTaskDefault(shipmentId, loggedInUser);
            const { data, status } = response;
            if (status === 200) {
                setDocuments(old => [...data.items,...old]);
                setPermissionDefaultTask(data.permission.taskFunctions);
            }
        } catch (error) {
            handleException(error)
        }
    }

    const doUploadDocumentsTaskDefault = async (taskId, payload, callback) => {
        if (taskId) {
            try {
                setLoading(true);
                const response = await uploadDocumentTaskDefault(taskId, payload, loggedInUser);
                const { status } = response;
                if (status === 200) {
                    if (callback) callback();
                }
                setLoading(false);
            } catch (error) {
                handleException(error)
            }
        }
    }

    return (
        <DocumentsContext.Provider
            value={{
                documents,
                documentsOfTask,
                currentPage,
                totalPages,
                totalDocuments,
                documentPermission,
                permissionTask,
                permissionDefaultTask,
                functionsTask, 
                setFunctionsTask,
                setTotalDocuments,
                setDocumentPermission,
                setDocumentsOfTask,
                setDocuments,
                setCurrentPage,
                setTotalPages,
                doGetDocuments,
                doRemoveDocumentsTask,
                doUploadDocumentsTask,
                doUploadTaskDashboard,
                doGenerateTradeTrust,
                doGetTradeTrust,
                doGetDocumentTaskDefault,
                doUploadDocumentsTaskDefault
            }}
        >
            { children}
        </DocumentsContext.Provider>
    )
}
