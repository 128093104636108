import React, { useEffect, useState } from 'react';

import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ContainerTracking = ({ containerTracking = {} }) => {
    const [t] = useTranslation();
    const [containers,setContainers] = useState([0]);

    /*eslint-disable */
    useEffect(() => {
        if(containerTracking && containerTracking.containers && containerTracking.containers.length > 0) setContainers(containerTracking.containers)
    }, [containerTracking])
    /*eslint-enable */

    return <InfoBlock
        className="tr__details-box"
        title={t("home.active-shipment.details.tabs.shipment-tab.container-tracking")}
        highColumn={true}
    >
        <InfoRow>
            <div className="col-6 mbx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.measurement")}
                        value={containerTracking.measurement ? containerTracking.measurement : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.container-yard")}
                        value={containerTracking.containerYard ? containerTracking.containerYard : ''}
                    />
                </div>
            </div>
            <div className="col-6 mbx2">
                <div className='row'>
                <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.commodity-description")}
                        value={containerTracking.commodityDescription ? containerTracking.commodityDescription : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.service-type")}
                        value={containerTracking.serviceType ? containerTracking.serviceType : ''}
                    />
                </div>
            </div>
            <div className="col-6 mbx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.gross-weight-unit")}
                        value={containerTracking.grossWeight && containerTracking.grossWeight.weightUnit ? containerTracking.grossWeight.weightUnit : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.gross-weight-value")}
                        value={containerTracking.grossWeight && containerTracking.grossWeight.value ? containerTracking.grossWeight.value : ''}
                    />
                </div>
            </div>
            <div className="col-6 mbx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.hs-code")}
                        value={containerTracking.hsCode ? containerTracking.hsCode : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.number-of-containers")}
                        value={containerTracking.numberOfContainers ? containerTracking.numberOfContainers : ''}
                    />
                </div>
            </div>
            <div className="sub-card">
                <div className="tr__info-block--label mbx2">
                    <span>{t("home.active-shipment.details.tabs.shipment-tab.containers")}</span>
                </div>
                {containers.length  > 0 
                    ? containers.map((container, index) =>
                        (
                            <div className="card-add-new">
                                <div className="col-12 col-sm-12 " key={index}>
                                    <div className="row">
                                        <div className="col-6 mtx2">
                                            <div className='row'>
                                                <InfoCell
                                                    className="col-sm-6"
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.container-number")}
                                                    value={container.containerNumber ? container.containerNumber : ''}
                                                />
                                                <InfoCell
                                                    className="col-sm-6"
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.carrier-seal-number")}
                                                    value={container.carrierSealNumber ? container.carrierSealNumber : ''}
                                                />
                                            </div> 
                                        </div>
                                        <div className="col-6 mtx2">
                                            <div className='row'>
                                                <InfoCell
                                                    className="col-sm-6"
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.container-type")}
                                                    value={container.containerType ? container.containerType : ''}
                                                />
                                                <InfoCell
                                                    className="col-sm-6"
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.weight-unit")}
                                                    value={container.weight && container.weight.weightUnit ? container.weight.weightUnit : ''}
                                                />
                                            </div> 
                                        </div>
                                        <div className="col-6 mtx2">
                                            <div className='row'>
                                                <InfoCell
                                                    className="col-sm-6"
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.weight-value")}
                                                    value={container.weight && container.weight.value ? container.weight.value : ''}
                                                />
                                                <InfoCell
                                                    className="col-sm-6"
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.volume-unit")}
                                                    value={container.volume && container.volume.unit ? container.volume.unit : ''}
                                                />
                                            </div> 
                                        </div>
                                        <div className="col-6 mtx2">
                                            <div className='row'>
                                                <InfoCell
                                                    className="col-sm-6"
                                                    label={t("home.active-shipment.details.tabs.shipment-tab.volume-value")}
                                                    value={container.volume && container.volume.value ? container.volume.value : ''}
                                                />
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    ) : null
                }
            </div>
            
        </InfoRow>
    </InfoBlock>;
};

ContainerTracking.propTypes = {
    ContainerTracking: PropTypes.object
};
