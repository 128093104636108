import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { MultiSelect, Member } from 'components';
import { multiselectValues, organizationMembersList } from 'utils';

export const AssignMembers = ({ title }) => {
    const [consignee, setConsignee] = useState(multiselectValues.slice(0, multiselectValues.length - 1));
    const [filteredOptions, setFilteredOptions] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        const newOptions = multiselectValues.filter(option => {
            return !(consignee.length > 0 && consignee.includes(option))
        });

        setFilteredOptions([...newOptions]);
    }, [consignee]);
    /*eslint-enable */

    const handleChange = items => {
        setConsignee(oldValue => [...oldValue, ...items])
    }

    const handleRemove = item => {
        setConsignee(oldValue => [...oldValue.filter(val => val.id !== item.id)]);
    }

    return (
        <div className="tr__assign-members">
            {title && (
                <h3 className="h3 f-medium mbx3 text-blue-gray">{title}</h3>
            )}
            <MultiSelect
                className="mbx2"
                options={filteredOptions}
                value={consignee}
                addButton={true}
                addButtonLabel="Add"
                placeholder='You can search by name, email, team name or can invite new people...'
                onChange={handleChange}
                renderList={members => (
                    <div className='tr__dropdown--list'>
                        {members && members.length > 0 && members.map((member, memberIndex) => (
                            <Member
                                key={memberIndex}
                                member={organizationMembersList.find(mem => mem.id === member.id)}
                                onRemove={handleRemove}
                            />
                        ))}
                    </div>
                )}
            />
        </div>
    )
};

AssignMembers.propTypes = {
    title: PropTypes.string
};