import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';

import './index.scss';
import { MemberContext } from 'contexts';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';

export const OganizationsInfo = ({
    className='',
    handleLeave = () => {},
    isEdit = false
}) => {
    const { member } = useContext(MemberContext);
    const [t] = useTranslation();
    const getMemberOrgs = () => member && member.orgTeams ? (member.orgTeams) : [];

    return (
        <div className="tr__organization-information">
            <h4 className="h4 f-medium">{t("home.personal-settings.organizations-information")}</h4>
            {getMemberOrgs().length > 0 ? (
                <div className={`tr__task-header-organization mtx2 pbx2 f-medium d-flex align-items-center justify-content-between ${className}`}>
                    <div className="column name">
                        <p>{t("home.personal-settings.organization-name")}</p>
                    </div>
                    <div className="column partner">
                        <p>{t("home.personal-settings.role")}</p>
                    </div>
                    <div className="column action">
                        <p>{t("home.personal-settings.action")}</p>
                    </div>
                </div>
            ) : (
                <p className="mtx2">{t("home.personal-settings.message-organization")}</p>
            )}

            {getMemberOrgs() && getMemberOrgs().map((org, orgIndex) => (
                <React.Fragment key={orgIndex}>
                    <div className="table--body d-flex justify-content-between">
                        <div className="table--body__column name f-medium text-uppercase">
                            {org.orgName}
                        </div>
                        <div className="table--body__column role text-capitalize">
                            {(org.roleName || '').toLowerCase()}
                        </div>
                        <div className="table--body__column action">
                            <Button
                                className="outline icon"
                                type="secondary"
                                onClick={() => !isEdit && handleLeave({
                                    orgIds: [org.orgId],
                                    orgNames: [org.orgName]
                                })}
                                disabled={isEdit}
                            >
                               {t("home.personal-settings.leave")}
                            </Button>
                        </div>
                    </div>
                    {org.teams && org.teams.length > 0 && (
                        <p className="title mtx2 pbx2 f-medium">{t("home.personal-settings.team-name")}</p>
                    )}
        
                    {org.teams && org.teams.length > 0 && org.teams.map((team, teamIndex) => (
                        <div className="table--body sub d-flex justify-content-between" key={teamIndex} >
                            <div className="table--body__column name">
                                {team.teamName}
                            </div>
                            <div className="table--body__column role text-capitalize">
                                {(team.roleName || '').toLowerCase()}
                            </div>
                            <div className="table--body__column action">
                                <Button
                                    className="outline icon"
                                    type="secondary"
                                    onClick={() => !isEdit && handleLeave({
                                        teamIds: [team.teamId],
                                        teamNames: [team.teamName]
                                    })}
                                    disabled={isEdit}
                                >
                                   {t("home.personal-settings.leave")}
                                </Button>
                            </div>
                        </div>
                    ))}
                </React.Fragment>
            ))}
        </div>
    )
};

OganizationsInfo.propTypes = {
    userOrganization: PropTypes.array,
    handleLeave: PropTypes.func,
    isEdit: PropTypes.bool
};
