import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AIR } from 'actions';

export const TabBookingDetails = ({ shippingDetails = {} }) => {
    const [t] = useTranslation();
    return <InfoBlock
        className={`tr__details-box ${shippingDetails?.shipmentMode === AIR ? 'col-sm-4' : 'col-sm-3'}`}
        title={t("home.active-shipment.details.tabs.shipment-tab.shipping-details")}
    >
        <InfoRow>
            <div className="col-12">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.shipment-mode")}
                        value={shippingDetails.shipmentMode ? shippingDetails.shipmentMode : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.shipment-type")}
                        value={shippingDetails.shipmentType ? shippingDetails.shipmentType : ''}
                    />
                </div>
            </div>
            <div className="col-12 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.booking-date")}
                        value={shippingDetails.bookingDate ?  moment(shippingDetails.bookingDate).format('DD MMM YYYY') : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.shipper")}
                        value={shippingDetails.shipper ? shippingDetails.shipper : ''}
                    />
                </div>
            </div>
            <div className="col-12 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.eta")}
                        value={shippingDetails.eta ? moment(+shippingDetails.eta).format('DD MMM YYYY') : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.etd")}
                        value={shippingDetails.etd ? moment(+shippingDetails.etd).format('DD MMM YYYY') : ''}
                    />
                </div>
            </div>
        </InfoRow>
    </InfoBlock>
};

TabBookingDetails.propTypes = {
    shippingDetails: PropTypes.object,
};
