import React, { useContext } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

import {
    Modal,
} from 'components';
import { AuthContext, HomeContext, OrganizationContext } from 'contexts';

export const ConfirmCancelRequest = ({ open = false, onCancel, onSubmit }) => {
    const { userInfo } = useContext(AuthContext);
    const { setNotificationMessage } = useContext(HomeContext);
    const { organization, doCancelJoinRequest, doGetOrganization } = useContext(OrganizationContext);
    const handleCancelRequest = () => {
        doCancelJoinRequest(organization._id, userInfo._id, () => {
            
            onCancel();
            doGetOrganization(organization._id);
            setNotificationMessage(`<p> Request has been cancelled successfully!</p>`)
        }, false)
    }
    return (
        <Modal
            open={open}
            className="tr__cancel-request__organization"
            onCancel={onCancel}
            title="Cancel Join Request"
            btnClasses="justify-content-between"
            submitButton
            submitButtonLabel="Yes"
            submitButtonClassNames="w-100"
            onSubmitClick={handleCancelRequest}
            cancelButton={true}
            cancelButtonLabel="No"
            cancelButtonType="outline"
            cancelButtonClassNames="outline secondary w-100"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="text-center">
                <h6 className="h6 mtbx4 f-medium">Are you sure you want to cancel the request?</h6>
            </div>
        </Modal>
    )
};

ConfirmCancelRequest.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func
};