import React, { useContext, useEffect, useState } from 'react';
import { getErrorMessage } from 'utils';

import './index.scss';
import { useForm } from "react-hook-form";
import { Input, Button } from 'components';
import { 
    CARRIER_PAGE_SIZE, TAB_BOOKING_NO, 
    // TAB_BOOKING_NO, 
    // TRACKER_BOOKING_BK
} from 'actions';
import { TrackShipmentContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const AirwayBillForm = ({ onSubmit, trackerParams }) => {

    const [loading, setLoading] = useState(false);

    const { handleSubmit, register, errors, reset } = useForm();
    const { doGetCarriers, doGetTrackAndTraceV2 } = useContext(TrackShipmentContext);
    const { t } = useTranslation();

    /*eslint-disable */
    useEffect(() => {
        doGetCarriers({ limit: CARRIER_PAGE_SIZE });
    }, [])
    /*eslint-enable */

    const handleSearch = (data) => {
        setLoading(true);
        // const { carrier, bookingNumber } = data;
        doGetTrackAndTraceV2({
            type: 'air',
            // typeTracker: 'air',
            ...data,
            format: 'new'
        }, () => {
            reset();
            setLoading(false);
            onSubmit(TAB_BOOKING_NO);
        }, () => {
            setLoading(false);
            onSubmit(TAB_BOOKING_NO);
        }, false);
    }
    return (
        <form className="tr__container-form" onSubmit={handleSubmit(handleSearch)}>
            <div className="form-group">
                <div className="tr__container-form--group">
                    <Input
                        label={t('Enter Airway Bill Number')}
                        required
                        name="mblNo"
                        placeholder="e.g. BC2473530"
                        refs={register({ required: true })}
                        error={!!errors.mblNo}
                        errorMessage={getErrorMessage(errors.mblNo, "Airway Bill Number")}
                        // defaultValue={trackerParams && trackerParams.carrierBookingNo ? trackerParams.carrierBookingNo : ""}
                    />
                </div>
            </div>
            <div className="form-btns">
                <Button
                    isSubmitBtn={true}
                    loading={loading}
                >
                    {t('trackshipment.searchForm')}
                </Button>
            </div>
        </form>
    )
};
