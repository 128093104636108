import React, {useContext} from "react";
import {ShipmentHeader} from "components";
import "./index.scss";
import PropTypes from 'prop-types';
import {ORGANIZATIONS_LIST_URL} from "actions";
import {ActiveShipmentsContext, HomeContext, ModalContext, OrganizationContext} from "contexts";

export const ShipmentItem = ({shipments = [], backUrl = ORGANIZATIONS_LIST_URL}) => {
    const { setEditAccessPermission } = useContext(ModalContext);
    const { setIdShipment } = useContext(HomeContext);
    const { setShipmentDetails } = useContext(ActiveShipmentsContext);
    const { doGetPermissionShipment, setPermissionShipment, setUnableToEdit } = useContext(OrganizationContext);
    const arrayData = [];
    const arrayUnableToEdit = [];

    const handleEditAccessPermission = async (event, shipment) =>{
        if (shipment){
            setIdShipment(shipment.id);
            await doGetPermissionShipment(shipment.id, (values) => {
                for (let i = 0; i < values.length; i++) {
                    for (let j = 0; j < values[i].functions.length; j++) {
                        const data = {
                            row: values[i].functions[j],
                            col: values[i].authority,
                        }
                        arrayData.push(data);
                    }
                    for (let z = 0; z < values[i].unableToEdit.length; z++) {
                        const obUnableToEdit = {
                            ROW: values[i].unableToEdit[z],
                            COL: values[i].authority
                        }
                        arrayUnableToEdit.push(obUnableToEdit);
                    }
                }
                setUnableToEdit(arrayUnableToEdit);
                setPermissionShipment(arrayData);
                setShipmentDetails(shipment);
            });
            setEditAccessPermission(true);
        }
    }

    return (
        shipments && (
            <div className="tr__activities-shipment-item">
                <ShipmentHeader className="pbx2"/>
                {shipments.map((shipment, shipmentIndex) => (
                    <div
                        className="table--body d-flex justify-content-between"
                        key={shipmentIndex}
                    >
                        <div className="table--body__column name">
                            {shipment.shipmentName}
                        </div>
                        <div className="table--body__column email">
                            {shipment.creator.name}
                        </div>
                        <div className="table--body__column activity">
                            <i onClick={(event) => handleEditAccessPermission(event,shipment)} className={"icon-pencil"}/>
                        </div>
                    </div>
                ))}
            </div>
        )
    );
};
ShipmentItem.prototype = {
    shipments: PropTypes.array,
};
