import React, { useContext, useState, useEffect } from 'react';

import './index.scss';
import {
    FILE_TYPE_SHIPMENT_DETAIL_DOCS,
    FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS,
    BC,
    MBL,
    HBL,
    MARK_NEW,
    PERMISSIONS_LIST,
    AIR
} from 'actions';
import {
    Button,
    TabBookingDetails,
    TabAddress,
    TabVesselRouteInfo,
    ContainerTracking,
    AISTracking,
    Invoice,
    EditShipmentInfo,
    CutOff,
    ContactDetails,
    TranshipmentInfo,
    ReuploadDocument,
    SectionInfo
} from 'components';
import { HomeContext, ModalContext, UploadFileContext, ExtractedContext, DocumentsContext } from 'contexts';
import _ from 'lodash';
import { getToken, convertStringtoDDMMYYY } from 'utils';
import { useTranslation } from 'react-i18next';

export const ShipmentInfo = ({ shipmentInfo, shipmentDetails }) => {
    const [t] = useTranslation();
    const [token, setToken] = useState();
    const { reuploadDoc, setReuploadDoc, setViewExtractedInfo, viewExtractedInfo } = useContext(ModalContext);
    const { isArchive, setFileId, setLoading, setMarkAsShipmentType, permission } = useContext(HomeContext);
    const { doUploadFile } = useContext(UploadFileContext);
    const { ocrData, setOcrData } = useContext(ExtractedContext);
    const { setDocumentPermission } = useContext(DocumentsContext);
    const [editShipmentInfo, setEditShipmentInfo] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [typeDocument, setTypeDocument] = useState({
        id: 'BC',
        name: 'BC'
    });
    const [dataShipment, setDataShipment] = useState({});

    /*eslint-disable */
    useEffect(() => {
        setToken(getToken())
    })

    useEffect(() => {
        setTypeDocument(shipmentDetails?.shippingDetails?.shipmentMode === AIR ? {
            id: 'MAB',
            name: 'Master AB'
        } : {
            id: 'BC',
            name: 'BC'
        } )
    }, [shipmentDetails])

    useEffect(() => {
        setMarkAsShipmentType(MARK_NEW);
    },[])

    useEffect(() => {
        setDataShipment(shipmentInfo);
    },[shipmentInfo])

    useEffect(() => {
        if (!viewExtractedInfo && !_.isEmpty(ocrData)){
            const data = _.isEmpty(dataShipment) ? shipmentInfo : {...dataShipment};
            if(ocrData.Shipper !== undefined){
                data.shippingDetails = {
                    ...data.shippingDetails,
                    shipper: ocrData.Shipper
                }
            }
            if(ocrData.ETD !== undefined){
                data.shippingDetails = {
                    ...data.shippingDetails,
                    etd: new Date(convertStringtoDDMMYYY(ocrData.ETD)).getTime()
                }
            }
            if(ocrData.ETA !== undefined){
                data.shippingDetails = {
                    ...data.shippingDetails,
                    eta: new Date(convertStringtoDDMMYYY(ocrData.ETA)).getTime()
                }
            }
            if(ocrData.BookingDate !== undefined){
                data.shippingDetails = {
                    ...data.shippingDetails,
                    bookingDate: new Date(convertStringtoDDMMYYY(ocrData.BookingDate)).getTime() 
                }
            }
            if(ocrData.CarrierName !== undefined){
                data.bookingDetails = {
                    ...data.bookingDetails,
                    carrierName: ocrData.CarrierName,
                    scacCode: ''
                }
            }

            if(ocrData.Time !== undefined){
                data.cutOff = {
                    ...data.cutOff,
                    time: ocrData.Time
                }
            }
            if(ocrData.SISubmission !== undefined){
                data.cutOff = {
                    ...data.cutOff,
                    siSubmission: ocrData.SISubmission
                }
            }
            if(ocrData.Date !== undefined){
                data.cutOff = {
                    ...data.cutOff,
                    date: new Date(convertStringtoDDMMYYY(ocrData.Date)).getTime()
                }
            }
            if(ocrData.VGMSubmissionTimeline !== undefined){
                data.cutOff = {
                    ...data.cutOff,
                    vgmSubmissionTimeline: ocrData.VGMSubmissionTimeline
                }
            }

            if(ocrData.ShipperAddress !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    shipperAddress: ocrData.ShipperAddress
                }
            }
            if(ocrData.ShipperContact !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    shipperContact: ocrData.ShipperContact
                }
            }
            if(ocrData.ShipperFax !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    shipperFax: ocrData.ShipperFax
                }
            }
            if(ocrData.Consignee !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    consignee: ocrData.Consignee
                }
            }
            if(ocrData.ConsigneeAddress !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    consigneeAddress: ocrData.ConsigneeAddress
                }
            }
            if(ocrData.ConsigneeContact !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    consigneeContact: ocrData.ConsigneeContact
                }
            }
            if(ocrData.ConsigneeFax !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    consigneeFax: ocrData.ConsigneeFax
                }
            }
            if(ocrData.NotifyParty !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    notifyParty: ocrData.NotifyParty
                }
            }
            if(ocrData.NotifyPartyAddress !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    notifyPartyAddress: ocrData.NotifyPartyAddress
                }
            }
            if(ocrData.NotifyContact !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    notifyContact: ocrData.NotifyContact
                }
            }
            if(ocrData.NotifyFax !== undefined){
                data.contactDetail = {
                    ...data.contactDetail,
                    notifyFax: ocrData.NotifyFax
                }
            }
            if(ocrData.ServiceContractNumber){
                data.contactDetail = {
                    ...data.contactDetail,
                    serviceContractNumber: ocrData.ServiceContractNumber
                }
            }

            let tranInfo = {};
            if(ocrData.TSPort !== undefined){
                tranInfo = {
                    ...tranInfo,
                    tsPort: ocrData.TSPort
                }
            }
            if(ocrData.TSNextPort !== undefined){
                tranInfo = {
                    ...tranInfo,
                    tsNextPort: ocrData.TSNextPort
                }
            }
            if(ocrData.TSVessel !== undefined){
                tranInfo = {
                    ...tranInfo,
                    tsVessel: ocrData.TSVessel
                }
            }
            if(ocrData.TSVesselIMO !== undefined){
                tranInfo = {
                    ...tranInfo,
                    tsVesselIMO: ocrData.TSVesselIMO
                }
            }
            if(ocrData.TSETD !== undefined){
                tranInfo = {
                    ...tranInfo,
                    tsETD: ocrData.TSETD
                }
            }
            if(ocrData.TSETA !== undefined){
                tranInfo = {
                    ...tranInfo,
                    tsETA: ocrData.TSETA
                }
            }
            if(!_.isEmpty(tranInfo)){
                data.transhipmentInfo = [tranInfo];
            }

            let tracking = {};
            if(ocrData.VesselName !== undefined){
                tracking = {
                    ...tracking,
                    name: ocrData.VesselName
                }
            } 
            if(ocrData.VesselName !== undefined){
                tracking = {
                    ...tracking,
                    imo: ocrData.IMO
                }
            } 
            if(ocrData.VesselName !== undefined){
                tracking = {
                    ...tracking,
                    voyage: ocrData.Voyage
                }
            }
            if(ocrData.Arrival !== undefined){
                tracking = {
                    ...tracking,
                    arrival: {name : ocrData.Arrival}
                }
            } 
            if(ocrData.Departure !== undefined){
                tracking = {
                    ...tracking,
                    departure: {name : ocrData.Departure}
                }
            }
            if(!_.isEmpty(tracking)){
                data.aisTracking = [tracking];
            }

            if(ocrData.InvoiceNumber !== undefined){
                data.invoices = ocrData.InvoiceNumber;
            }
            
            if(ocrData.Measurement !== undefined){
                data.containerTracking = {
                    ...data.containerTracking,
                    measurement: ocrData.Measurement
                }
            }
            if(ocrData.ContainerYard !== undefined){
                data.containerTracking = {
                    ...data.containerTracking,
                    containerYard: ocrData.ContainerYard
                }
            }
            if(ocrData.CommodityDescription !== undefined){
                data.containerTracking = {
                    ...data.containerTracking,
                    commodityDescription: ocrData.CommodityDescription
                }
            }
            if(ocrData.NumberOfContainers !== undefined){
                data.containerTracking = {
                    ...data.containerTracking,
                    numberOfContainers: ocrData.NumberOfContainers
                }
            }
            if(ocrData.ServiceType !== undefined){
                data.containerTracking = {
                    ...data.containerTracking,
                    serviceType: ocrData.ServiceType
                }
            }
            if(ocrData.HSCode !== undefined){
                data.containerTracking = {
                    ...data.containerTracking,
                    hsCode: ocrData.HSCode
                }
            }
            if(ocrData.GrossWeightValue !== undefined){
                data.containerTracking = {
                    ...data.containerTracking,
                    grossWeight:{
                        ...data.containerTracking.grossWeight,
                        weightUnit: ocrData.GrossWeightUnit
                    } 
                }
            }
            if(ocrData.GrossWeightValue !== undefined){
                data.containerTracking = {
                    ...data.containerTracking,
                    grossWeight:{
                        ...data.containerTracking.grossWeight,
                        value: ocrData.GrossWeightValue
                    } 
                }
            }

            let container = {};
            if(ocrData.ContainerNumber){
                container = {
                    ...container,
                    containerNumber: ocrData.ContainerNumber
                }
            }
            if(ocrData.ContainerType){
                container = {
                    ...container,
                    containerType: ocrData.ContainerType
                }
            } 
            if(ocrData.CarrierSealNumber){
                container = {
                    ...container,
                    carrierSealNumber: ocrData.CarrierSealNumber
                }
            }
            if(ocrData.WeightUnit ){
                container = {
                    ...container,
                    weight:{
                        ...container.weight,
                        weightUnit: ocrData.WeightUnit
                    } 
                }
            }
            if(ocrData.WeightValue){
                container = {
                    ...container,
                    weight:{
                        ...container.weight,
                        value: ocrData.WeightValue
                    } 
                }
            }
            if(ocrData.VolumeUnit){
                container = {
                    ...container,
                    volume:{
                        ...container.volume,
                        unit: ocrData.VolumeUnit
                    } 
                }
            }
            if(ocrData.VolumeValue){
                container = {
                    ...container,
                    volume:{
                        ...container.volume,
                        value: ocrData.VolumeValue
                    } 
                }
            }
            if(!_.isEmpty(container)){
                data.containerTracking = {
                    ...data.containerTracking,
                    containers: [container]
                };
            }
           
            data.bookingDetails = {
                ...data.bookingDetails,
                ...{
                    confirmationNo: [],
                    masterBoL: [],
                    houseBoL: []
                }
            };
            if(data.document){
                switch (data.document.typeOfDocument) {
                    case BC:
                        data.bookingDetails.confirmationNo = [ocrData.BookingNumber];
                        break;
                    case MBL:
                        data.bookingDetails.masterBoL = [ocrData.BookingNumber];
                        break;
                    case HBL:
                        data.bookingDetails.houseBoL = [ocrData.BookingNumber];
                        break;
                    default:
                        break;
                }
            }
            data.addedInfoSections = ocrData.newSections && ocrData.newSections.length > 0 ? ocrData.newSections : [];
            setDataShipment(data);
            setOcrData({});
            setEditShipmentInfo(true);
        }
    }, [ocrData, viewExtractedInfo])

    /*eslint-enable */

    const handleViewExtracted = async () => {
        // Upload documents to S3
        setDocumentPermission(true);
        if (documents && documents.length) {
            const document = await Promise.all(documents.map(doc => doUploadFile({
                type: FILE_TYPE_SHIPMENT_DETAIL_DOCS,
                subType: FILE_SUB_TYPE_SHIPMENT_DETAIL_DOCS,
                ocrRequired: true,
                fileName: doc.name.split('.').slice(0, -1).join('.'),
                fileExtension: doc.name.split('.').pop(),
            }, doc.data, token)))
                .then(res => {
                    setLoading(false);
                    return res.map(f => ({
                        fileName: f.name,
                        typeOfDocument: typeDocument.id,
                        awsId: f.id
                    }))[0]
                });
            if (document) {
                const data = {...dataShipment,document}
                setDataShipment(data)
                setReuploadDoc(false);
                setFileId(document.awsId);
                setViewExtractedInfo(true);
            }
        }
        // !!! Upload documents to S3
    }
    
    const checkPermission = (action) => {
        return permission?.shipmentFunctions.includes(action);
    }

    return editShipmentInfo ? (
        <EditShipmentInfo
            shipmentInfo={dataShipment}
            goBack={() => {
                setEditShipmentInfo(false);
                setDataShipment(shipmentInfo);
            }}
        />
    ) : (
            <div className="tr__shipment-info">
                {checkPermission(PERMISSIONS_LIST.SHIPMENT_VIEW_INFO) ? (
                    <>
                        <div className="tr__shipment-info--content">
                            <div className="row">
                                {shipmentInfo?.shippingDetails?.shipmentMode !== AIR && <TabVesselRouteInfo bookingDetails={shipmentInfo.bookingDetails || {}} />}
                                <TabBookingDetails shippingDetails={shipmentInfo.shippingDetails} />
                                <CutOff 
                                    cutOff={shipmentInfo.cutOff || {}} 
                                    shippingDetails={shipmentInfo.shippingDetails}
                                />
                                <Invoice 
                                    invoices={shipmentInfo.invoices}
                                    shippingDetails={shipmentInfo.shippingDetails}
                                />
                                <div>
                                    <div className="row">
                                        <div className="col-12 col-sm-8">
                                            <ContainerTracking containerTracking={shipmentInfo.containerTracking || {}} />
                                            <ContactDetails contactDetails={shipmentInfo.contactDetail || {}} />
                                            <TranshipmentInfo transhipmentInfo={shipmentInfo.transhipmentInfo || []} />
                                        </div>
                                        <div className="col-12 col-sm-4">
                                            <AISTracking aisTracking={shipmentInfo?.aisTracking} aisAirTracking={shipmentDetails?.aisTracking} shippingDetails={shipmentInfo.shippingDetails}/>
                                            <TabAddress addresses={shipmentInfo.addresses} shippingDetails={shipmentInfo.shippingDetails}/>
                                            <SectionInfo sections={shipmentInfo.addedInfoSections} />
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className="tr__shipment-info--footer d-flex align-items-center justify-content-between">
                            {!isArchive && !_.isEmpty(shipmentInfo) && checkPermission(PERMISSIONS_LIST.SHIPMENT_EDIT_INFO) && (
                                <Button
                                    className="icon outline mlx2"
                                    icon="icon-pencil"
                                    type="secondary"
                                    onClick={() => setEditShipmentInfo(true)}
                                >
                                {t("home.active-shipment.details.tabs.shipment-tab.edit")}
                                </Button>
                            )}
                            {!isArchive && !_.isEmpty(shipmentInfo) && checkPermission(PERMISSIONS_LIST.SHIPMENT_RE_UPLOAD_BC_BL) && (
                                <div className="btns d-flex align-items-center">
                                    <Button
                                        className="icon outline"
                                        icon="icon-upload"
                                        type="secondary"
                                        onClick={() => setReuploadDoc(true)}
                                    >
                                    {t("home.active-shipment.details.tabs.shipment-tab.reupload")}
                                </Button>
                                </div>
                            )}
                            </div>
                        <ReuploadDocument
                            open={reuploadDoc}
                            onCancel={() => setReuploadDoc(false)}
                            onSubmit={handleViewExtracted}
                            documents={documents}
                            setDocuments={setDocuments}
                            typeDocument={typeDocument}
                            setTypeDocument={setTypeDocument}
                        />
                    </>
                ) : (
                    <h3 className="tr__fta-advisor--content__permission-message h2 text-center mtx4"> {t("home.active-shipment.details.tabs.fta-tab.permission-message")}</h3>
                )}
            </div>
        )
};
