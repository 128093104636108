import React, { useContext, useState, useEffect } from 'react';

import {
    Tab,
    TabPane,
    ShipmentList
} from 'components';
import { useHistory } from 'react-router-dom';
import { HomeContext, ActiveShipmentsContext, TrackShipmentContext } from 'contexts';
import {
    ACTIVE_SHIPMENT_PAGE_SIZE,
    ALL,
    ARCHIVE_SHIPMENT_TABS,
    CANCELED,
    COMPLETE,
} from 'actions';
import { useTranslation } from 'react-i18next';

export const ArchiveTabs = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { isArchive, setShipment, seachParamActive } = useContext(HomeContext);
    const { setTrackers, setSelectedTracker, setRoutes } = useContext(TrackShipmentContext);
    const { 
        doGetActiveShipments, 
        activeShipments, 
        setNeedAttentionActiveShipments,
        setActiveShipments,
        totalPages,
        currentPage
    } = useContext(ActiveShipmentsContext);
    const [tabs,setTabs] = useState(ARCHIVE_SHIPMENT_TABS);
    const [currentTab, setCurrentTab] = useState([COMPLETE,CANCELED]);
        
    /*eslint-disable */
    useEffect(() => {
        setNeedAttentionActiveShipments([]);
        setActiveShipments([]);
        doGetActiveShipments({
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            status: currentTab,
        });
    }, [seachParamActive, currentTab])
    
    useEffect(() => {
        setTabs(ARCHIVE_SHIPMENT_TABS);
    }, [isArchive])
    /*eslint-enable */

    const handleShowDetails = shipmentId => {
        if (shipmentId) {
            setTrackers([]);
            setSelectedTracker(null);
            setRoutes(null);
            history.push(`/${'archive'}/${shipmentId._id}`);
            setShipment(shipmentId);
        }
    }

    const handleTabChange = tab => {
        switch(tab) {
            case ALL:
                setCurrentTab([COMPLETE,CANCELED]);
                break;
            case COMPLETE:
                setCurrentTab(COMPLETE);
                break;
            case CANCELED:
                setCurrentTab(CANCELED);
                break;
            default:
        }
    }
    const handlePageChange = (page) => {
        doGetActiveShipments({
            page: page,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            // tab: ACTIVE_SHIPMENT_TABS.ALL,
            status: currentTab,
            name: seachParamActive
        });
    }

    return (
        <div className="tr__shipment-tabs mtx2">
            <Tab initialTab={0} onTabClick={handleTabChange}>
                {tabs.map((tab, tabIndex) => (
                    <TabPane
                        key={tabIndex}
                        tabName={t(tab.name).toLowerCase().replace('_', ' ')}
                        tabSlug={tab.id}
                    >
                        <>
                            <ShipmentList
                                className='mbx4'
                                title="Other Shipments"
                                shipments={activeShipments}
                                onShowDetails={handleShowDetails}
                                isArchive={isArchive}
                                seachParamActive={seachParamActive}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </>
                    </TabPane>
                ))}
            </Tab>
        </div>
    )
};
