import React, { useCallback, useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import {
    Collaborators,
    Button,
    Tooltip,
    Collapse
} from 'components';
import { HomeContext, ModalContext, TemplateContext } from 'contexts';
import { roles } from 'utils';
import { useDrag, useDrop } from 'react-dnd';
import { cloneDeep, get, find, isEmpty } from 'lodash';
import { CARD, COLLABORATORS_PAGE_SIZE, MEMBER } from 'actions';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CardEvent = ({ task, index, isMyTemplate, section, moveCard, type, tasksEvent }) => {
    const [t] = useTranslation();
    // const dropRef = React.useRef(null)
    // const dragRef = React.useRef(null)
    const ref= useRef(null);
    const { setAddTask, setAssignCollaborators } = useContext(ModalContext);
    const {
        setIsTemplate,
        setSelectTask,
        setEventTask,
        setIsEditTask,
        setTaskName,
        setTaskId,
        setTaskRoles,
        setIsRemovePartners
    } = useContext(HomeContext);
    const [limit] = useState(4);
    const {
        setPartnersTaskTemplate,
        doGetCollaboratorsTemplate,
    } = useContext(TemplateContext);
    const {
        doEditOrderTaskTemplate,
        doGetTemplatesDetails,
        templatesDetails
    } = useContext(TemplateContext);
    const { templateId } = useParams();
    const handleAssignTask = (task) => {
        setAssignCollaborators(true)
        setIsTemplate(true);
        setTaskId(task.id);
        setTaskName(task.name);
        setTaskRoles(task.roles);
        setIsRemovePartners(true);
        setPartnersTaskTemplate(task.partners.map(collaborator => {
            return {
                id: collaborator.collaboratorId,
                avatar: collaborator.avatar,
                type: collaborator.type,
                roles: collaborator.roles,
                name: collaborator.type === MEMBER.toUpperCase() ? collaborator.username : collaborator.teamName,
                orgName: collaborator.organization ? collaborator.organization.name : '',
                email: collaborator.type === MEMBER.toUpperCase() ? collaborator.email : null,
            }
        }))
        doGetCollaboratorsTemplate(templateId, {
            page: 0,
            limit: COLLABORATORS_PAGE_SIZE,
            role: task.roles
        });
    }

    const [{ isOver }, drop] = useDrop({
        accept: CARD,
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
        }),
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            // console.log("handlerId", handlerId)
            // debugger
            const dragIndex = item.index;
            const hoverIndex = index;
            const dragType = item.typeEvent;
            const hoverType = type;
            // console.log("item", item);
            // console.log("item task", item.task)
            // console.log("dragType", dragType)
            // console.log("hoverType", hoverType);
            if (dragIndex === hoverIndex) {
                return;
            }
            if (dragType !== hoverType) {
                return;
            }
            // if((dragIndex !== hoverIndex) && (item.typeEvent !== ){
            //     return;
            // }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
        drop: (item) => doEditOrderTaskTemplate(templateId, tasksEvent.map((task, taskIndex) => {
            return {
                id: task.id,
                order: taskIndex
            }
        }), () => {
            doGetTemplatesDetails(templatesDetails && templatesDetails.id)
        })
    });
    const [{ isDragging }, drag] = useDrag({
        item: {
            type: CARD,
            id: task.id,
            index,
            typeEvent: task.type
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    let backgroundColor = "";
    if(isOver){
        backgroundColor =  "#bbf";
    }
    if(isDragging){
        backgroundColor = '#fbb';
    }
   

    return (
        <>
            <div ref={ref} style={{backgroundColor}} className="table--body d-flex table-body justify-content-between" >
                <div className="column name">
                    <h4 className="h4 f-medium">
                        <i className="icon-check" />
                        <span className="has-tooltip" title={task.name}>{task.name}</span>
                    </h4>
                </div>
                <div className="column partner">
                    <div className="partners">
                        {task.roles && task.roles.length > 0 && (
                            <>
                                {task.roles.slice(0, limit).map((role, roleIndex) => (
                                    <Tooltip
                                        content={role && role.role ? role.role.replace('_', ' ').toLowerCase() : ''}
                                        key={roleIndex}
                                    >
                                        <i className={get(find(roles, ['id', role && role.role ? role.role : '']), 'icon')} />
                                    </Tooltip>
                                ))}

                                {limit < task.roles.length && (
                                    <Tooltip
                                        renderContent={() => (
                                            <div className="tags d-flex flex-column">
                                                {task.roles.slice(limit, task.roles.length).map((partner, partnerIndex) => (
                                                    <span key={partnerIndex}>{partner && partner.role ? partner.role.replace('_', ' ').toLowerCase() : ''}</span>
                                                ))}
                                            </div>
                                        )}
                                    >
                                        <span className="f-medium more">+{task.roles.length - limit}</span>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="column assignee">
                    <Collaborators
                        label={t("template.my-template.detail.assign")}
                        members={task.partners}
                        skip={3}
                        onAdd={() => handleAssignTask(task)}
                        noneButton={!isMyTemplate}
                    />
                </div>
                <div className="column date d-flex align-items-center justify-content-between">
                    {(task && task.dueDate.eventBasedDate.typeOfEvent !== null && !isEmpty(task.dueDate.eventBasedDate)) ? (
                        <p className="tr__badge mlx2">{`${task.dueDate.eventBasedDate.typeOfEvent} ${(task.dueDate.eventBasedDate.adjustDays > 0) ? "+" + task.dueDate.eventBasedDate.adjustDays : task.dueDate.eventBasedDate.adjustDays} `}</p>
                    ) : (<p></p>)}
                    {isMyTemplate && (
                        <p className="tr__link info" onClick={() => {
                            setAddTask(true);
                            setIsTemplate(true);
                            setIsEditTask(true);
                            setEventTask(task);
                            setSelectTask(section);
                        }}>
                            <i className="icon-pencil" />
                        </p>
                    )}
                </div>
            </div>
        </>
    )
}

const Card =
    ({ section,
        tasks,
        isMyTemplate,
        partnerLimit,
    }) => {
        const [tasksEvent, setTasksEvent] = useState(tasks);
        // const {
        //     doEditOrderTaskTemplate,
        //     doGetTemplatesDetails,
        //     templatesDetails
        // } = useContext(TemplateContext);
        // const { setNotificationMessage } = useContext(HomeContext);
        // const { templateId } = useParams();
        useEffect(() => {
            setTasksEvent(tasks);
        }, [tasks])

        const moveCardEvent = useCallback((dragIndex, hoverIndex,) => {
            const dragCard = tasksEvent[dragIndex];
            let newTasks = cloneDeep(tasksEvent)
            const temp = cloneDeep(dragCard)
            newTasks[dragIndex] = cloneDeep(newTasks[hoverIndex])
            newTasks[hoverIndex] = temp
            // let payload = [...newTasks]
            // console.log(payload, "payload");
            // setTasksEvent(payload);
            setTasksEvent(newTasks);
        }, [tasksEvent ]);

        return <>
            {
                tasksEvent.map((task, taskIndex) => (
                    <CardEvent tasksEvent={tasksEvent} moveCard={moveCardEvent} index={taskIndex} task={task} isMyTemplate={isMyTemplate} key={taskIndex} section={section} type={task.type} />
                ))
            }
        </>
    }


export const TemplateTaskItem = ({ sections, partnerLimit, isMyTemplate = true }) => {
    const [t] = useTranslation();
    const { setAddTask, setEditSection, setIsEditSection } = useContext(ModalContext);
    const {
        setIsTemplate,
        setSectionName,
        setSectionId,
        setSectionOrder,
        setSelectTask,
        setIsEditTask,
    } = useContext(HomeContext);

    const handleEditSection = (section) => {
        setEditSection(true);
        setIsTemplate(true);
        setIsEditSection(true);
        setSectionName(section.name);
        setSectionId(section.id);
        setSectionOrder(section.order);

    }

    const handleAddTask = (section) => {
        setAddTask(true);
        setIsTemplate(true);
        setSectionId(section.id);
        setSectionName(section.name);
        setSelectTask(section);
        setIsEditTask(false);
    }
    return sections.length > 0 ? sections.map((task, taskIndex) => {
        return (
            <div className="tr__template-task-item__wrapper">
                <Collapse
                    className="tr__template-task-item mtx3"
                    title={task?.name}
                    initialOpen={true}
                    renderHeader={setOpen => (
                        <>
                            <span onClick={setOpen}>{task?.name}</span>
                            <div className="toggle-btn d-flex align-items-center">
                                <i className="icon-chevron-down" onClick={setOpen} />
                                {isMyTemplate && (
                                    <>
                                        <Button
                                            className="icon mlx2"
                                            icon="icon-arrow-up"
                                            type="secondary"
                                        // onClick={() => { index > 0 && moveCard(index, index - 1) }}
                                        />
                                        <Button
                                            className="icon mlx1"
                                            icon="icon-arrow-down"
                                            type="secondary"
                                        // onClick={() => { index < tasksLength - 1 && moveCard(index, index + 1) }}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                >
                    <>
                        <div className='tr__template-task-item--table'>
                            {task.tasks.events && task.tasks.events.length > 0 && (
                                <>
                                    <div className='table--header'>
                                        <h4 className="h4 f-medium">{t("home.active-shipment.shipmenttaskitem.Events")}</h4>
                                    </div>
                                    <Card tasks={task.tasks.events} partnerLimit={partnerLimit} isMyTemplate={isMyTemplate} section={task} />
                                </>
                            )}
                        </div>
                        <div className='tr__template-task-item--table mtx3'>
                            {task.tasks.documents && task.tasks.documents.length > 0 && (
                                <>
                                    <div className='table--header'>
                                        <h4 className="h4 f-medium">{t("home.active-shipment.shipmenttaskitem.Documents")}</h4>
                                    </div>
                                    <Card tasks={task.tasks.documents} partnerLimit={partnerLimit} isMyTemplate={isMyTemplate} section={task} />
                                </>
                            )}
                        </div>
                    </>
                </Collapse>
                {isMyTemplate && (
                    <>
                        <div className="tr__template-task-item-btns d-flex align-items-center">
                            <Button
                                className="outline icon mrx2"
                                icon="icon-plus"
                                type="primary"
                                onClick={() => handleAddTask(task)}
                            >
                                {t("home.active-shipment.details.tabs.management-tab.add-task")}
                            </Button>
                            <Button
                                className="outline icon"
                                icon="icon-pencil"
                                type="secondary"
                                onClick={() => handleEditSection(task)}
                            >
                                {t("home.active-shipment.details.tabs.management-tab.edit-section")}
                            </Button>
                        </div>
                    </>
                )}

            </div>
        )
    }) : '';
};

TemplateTaskItem.propTypes = {
    isMyTemplate: PropTypes.bool,
    partnerLimit: PropTypes.number,
    sections: PropTypes.array
};
