import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { Tooltip } from 'components';

export const InfoCell = ({ className = '', title, label, value, children }) => {
    return (
        <div className={`col-12 tr__info-cell ${className}`}>
            {title && <p className="f-medium tr__info-cell--title mbx2">{title}</p>}
            {label && <p className="f-medium tr__info-cell--label">{label}</p>}
            {value && value.length >= 30 ? (
                <Tooltip content= {children ? children : value}>
                    <div className={`tr__info-cell--content mrx1 has-tooltip`}>
                        {children ? children : value}
                    </div>
                </Tooltip>
            ) : (
                    <div className={`tr__info-cell--content mrx1`}>
                        {children ? children : value}
                    </div>
            )}
            
        </div>
    )
};

InfoCell.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string
}