import React from 'react';

import './index.scss';
import {
    InfoBlock,
    InfoRow,
    InfoCell
} from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ContactDetails = ({ contactDetails = {} }) => {
    const [t] = useTranslation();
    return <InfoBlock
        className="tr__details-box"
        title={t("home.active-shipment.details.tabs.shipment-tab.contact-details")}
        highColumn={true}
    >
        <InfoRow>
            <div className="col-6 mbx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.shipper-address")}
                        value={contactDetails.shipperAddress ? contactDetails.shipperAddress : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.consignee")}
                        value={contactDetails.consignee ? contactDetails.consignee : ''}
                    />
                </div>
            </div>
            <div className="col-6 mbx2">
                <div className='row'>
                <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.consignee-address")}
                        value={contactDetails.consigneeAddress ? contactDetails.consigneeAddress : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.notify-party")}
                        value={contactDetails.notifyParty ? contactDetails.notifyParty : ''}
                    />
                </div>
            </div>
            <div className="col-6 mbx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.notify-party-address")}
                        value={contactDetails.notifyPartyAddress ? contactDetails.notifyPartyAddress : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.service-contract-number")}
                        value={contactDetails.serviceContractNumber ? contactDetails.serviceContractNumber : ''}
                    />
                </div>
            </div>
            <h4 className="h4 f-medium tr__info-block--label">
                <span>{t("home.active-shipment.details.tabs.shipment-tab.contact-information")}</span>
            </h4>
            <div className="col-6 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.shipper-contact")}
                        value={contactDetails && contactDetails.shipperContact ? contactDetails.shipperContact : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.shipper-fax")}
                        value={contactDetails && contactDetails.shipperFax ? contactDetails.shipperFax : ''}
                    />
                </div> 
            </div>
            <div className="col-6 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.consignee-contact")}
                        value={contactDetails && contactDetails.consigneeContact ? contactDetails.consigneeContact : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.consignee-fax")}
                        value={contactDetails && contactDetails.consigneeFax ? contactDetails.consigneeFax : ''}
                    />
                </div> 
            </div>
            <div className="col-6 mtx2">
                <div className='row'>
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.notify-contact")}
                        value={contactDetails && contactDetails.notifyContact ? contactDetails.notifyContact : ''}
                    />
                    <InfoCell
                        className="col-sm-6"
                        label={t("home.active-shipment.details.tabs.shipment-tab.notify-fax")}
                        value={contactDetails && contactDetails.notifyFax ? contactDetails.notifyFax : ''}
                    />
                </div> 
            </div>
        </InfoRow>
    </InfoBlock>;
};

ContactDetails.propTypes = {
    contactDetails: PropTypes.object
};
