import React, {useState, useContext, useEffect} from 'react';
import { useForm } from "react-hook-form";
import PropTypes from 'prop-types';
import './index.scss';
import {
    Modal,
    CheckboxGroup,
    Dropdown,
    Datepicker,
    Button,
    ShipmentInformation
} from 'components';
import {
    taskRoles,
    shipmentTypes,
} from 'utils';
import {
    ActiveShipmentsContext,
    HomeContext,
    ModalContext,
    ShipmentInfoContext,
    TrackShipmentContext,
    OrganizationContext,
} from 'contexts';
import { ACTIVE, ACTIVE_SHIPMENT_PAGE_SIZE, CARRIER_PAGE_SIZE, DESTINATION, EMPTY, LOCODE_PAGE_SIZE, NEED_ATTENTION, ORIGIN, OTHER_ACTIVE, STAR } from 'actions';
import { useTranslation } from 'react-i18next';

export const ShipmentFilter = ({ open = false, onCancel, currentTab }) => {
    const { t } = useTranslation();
    const { handleSubmit, register } = useForm();

    const {
        doGetActiveShipments,
        doGetBookingDetails,
        setShipmentFilter,
        shipmentFilter
    } = useContext(ActiveShipmentsContext);
    const { setActiveShipmentFilter } = useContext(ModalContext);
    const { shipmentFields, doGetShipmentFiels } = useContext(ShipmentInfoContext);
    const { doGetCarriers, origins, destinations, doGetLocodes } = useContext(TrackShipmentContext);
    const { setFilterParamsShipment } = useContext(HomeContext);
    const { organizations } = useContext(OrganizationContext);

    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedOrigin, setSelectedOrigin] = useState(null);
    const [selectedDestination, setSelectedDestination] = useState(null);
    const [originLoading, setOriginLoading] = useState(false);
    const [destinationLoading, setDestinationLoading] = useState(false);
    const [VDLDates, setVDLDates] = useState(null);
    const [VADDates, setVADDates] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [shipmentValue, setShipmentValue] = useState(null);
    const [selectShipmentKey, setSelectShipmentKey] = useState(null);
    const [shipmentKeys, setShipmentKeys] = useState([]);
    const [count, setCount] = useState([{information:null, value:'', items:[]}]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    /*eslint-disable*/
    useEffect(() => {
        doGetCarriers({ limit: CARRIER_PAGE_SIZE });
        doGetLocodes({ limit: LOCODE_PAGE_SIZE });
        doGetBookingDetails();
        doGetShipmentFiels();
    }, [])
    useEffect(() => {
        setShipmentKeys(shipmentFields);
    }, [shipmentFields])
    useEffect(() => {
        if (!shipmentFilter) handleClearFilterShipment();
    },[shipmentFilter])
    /*eslint-enable*/

    const handleSubmitFilter = data => {
        const {
            destination,
            origin,
            shipmentRole,
            shipmentType,
        } = data;

        let payload = {
            page: 0,
            limit: ACTIVE_SHIPMENT_PAGE_SIZE,
            tab: currentTab,
            status: ACTIVE,
        }
        if (destination)  payload.destination = destination;
        if (origin)       payload.origin = origin;
        if (shipmentRole) payload.role = shipmentRole;
        if (shipmentType) payload.type = shipmentType;
        if (selectedOrg)  payload.orgId = selectedOrg.key;
        if (VDLDates){
            payload.vdlStart = VDLDates.fromTime;
            payload.vdlEnd   = VDLDates.toTime;
        }
        if (VADDates) {
            payload.vadStart = VADDates.fromTime;
            payload.vadEnd   = VADDates.toTime;
        }

        const payloadCriteria = [];
        for (let i = 0; i < count.length; i++) {
            if (count[i].information && count[i].value){
                payloadCriteria.push(count[i].information.key + '_spl_' + count[i].value);
            }
        }
        payload.criteria = payloadCriteria;

        setFilterParamsShipment(payload);

        doGetActiveShipments({...payload,tabType:STAR}, () => {
            setActiveShipmentFilter(false);
        });
        doGetActiveShipments({...payload,tabType:NEED_ATTENTION}, () => {
            setActiveShipmentFilter(false);
        });
        doGetActiveShipments({...payload,tabType:OTHER_ACTIVE}, () => {
            setActiveShipmentFilter(false);
        });
        setShipmentFilter(true);
    }

    const onSearchOriginLocode = value => {
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setOriginLoading(false);
        }, () => {
            setOriginLoading(false);
        }, ORIGIN);
    }

    const onSearchDestLocode = (value) => {
        setDestinationLoading(true);
        doGetLocodes({
            limit: LOCODE_PAGE_SIZE,
            cop: value,
            isSearching: true
        }, () => {
            setDestinationLoading(false);
        }, () => {
            setDestinationLoading(false);
        }, DESTINATION);
    }

    const handleClearFilterShipment = () => {
        setSelectedRoles([]);
        setSelectedOrigin(null);
        setSelectedDestination(null);
        setSelectedType(null);
        setVDLDates(null);
        setVADDates(null);
        setSelectShipmentKey("");
        setShipmentValue("");
        setCount([{information : null, value : '', items:[]}]);
        setSelectedOrg(null);
        setShipmentFilter(false);
    }

    const filterCountByIndex = (index) => {
        const array = [...count];
        if (count.length > 1){
            array.splice(index, 1);
        }
        setCount(array);
    }

    const handleVDLEvent = (dates) => {
        if (!dates) {
            const newVDLDates = {...VDLDates};
            delete newVDLDates.fromTime;
            delete newVDLDates.toTime;

            setVDLDates(newVDLDates);
            return;
        }
        const [from, to] = dates;

        if (from && to) {
            const fromTime = new Date(from).getTime() - (new Date().getTimezoneOffset() * 60000);
            const toTime = new Date(to).getTime() - (new Date().getTimezoneOffset() * 60000);
            setVDLDates({fromTime: fromTime, toTime: toTime});
            // console.log(fromTime, toTime)
        }

    }

    const handleVADEvent = (dates) => {
        if (!dates) {
            const newVADDates = {...VADDates};
            delete newVADDates.fromTime;
            delete newVADDates.toTime;

            setVADDates(newVADDates);
            return;
        }
        const [from, to] = dates;

        if (from && to) {
            const fromTime = new Date(from).getTime() - (new Date().getTimezoneOffset() * 60000);
            const toTime = new Date(to).getTime() - (new Date().getTimezoneOffset() * 60000);
            // console.log(from, to)
            setVADDates({fromTime: fromTime, toTime: toTime});
        }

    }

    const handleSearchShipmentKey = value => {
        if (value) {
            setShipmentKeys(shipmentFields.filter(item => item.label.toLowerCase().includes(value.toLowerCase())));
        } else {
            setShipmentKeys(shipmentFields);
        }
    }

    const addMoreShipmentInfo = () => {
        const oldArray = [...count]
        const value = {
            information: null, value: ''
        }
        oldArray.push(value);
        setCount(oldArray);
    }

    const onChangeInput = (arrayValue, index) => {
        const data = [...count];
        data[index].items = arrayValue;
        setCount(data);
    }

    const onChangeInputValue = (value, index) => {
        const data = [...count];
        data[index].value = value;
        setCount(data);
    }

    return (
        <Modal
            open={open}
            className="tr__shipment-filter"
            onCancel={onCancel}
            title={t("home.active-shipment.filter")}
            type="panel"
        >
            <form
                onSubmit={handleSubmit(handleSubmitFilter)}
            >
                <div className="tr__shipment-filter--group mbx2 pbx1">
                    <CheckboxGroup
                        label={t("home.active-shipment.filter-panel.role")}
                        name="shipmentRole"
                        className="big-label"
                        vertical
                        items={taskRoles}
                        labelClear={true}
                        values={selectedRoles}
                        onChange={roles => setSelectedRoles(roles)}
                        buttonMode={true}
                        refs={register}
                    >
                        <Button
                            className="icon outline mbx2"
                            icon="icon-reload"
                            type="secondary"
                            onClick={() => setSelectedRoles([])}
                        />
                    </CheckboxGroup>
                </div>
                <div className="tr__shipment-filter--group route mbx3 pbx3">
                    <span className="route-icon">
                        <i className="icon-location" />
                        <i className="icon-location" />
                    </span>
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="origin"
                        label={t("home.active-shipment.filter-panel.port")}
                        horizontalLabel={`${t("home.origin.label")}:`}
                        value={selectedOrigin}
                         options={origins && origins.length > 0 ? origins.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        placeholder={`${t("home.origin.placeholder")}:`}
                        dropdownPosition="center"
                        refs={register}
                        onChange={value => setSelectedOrigin(value)}
                        onInputChange={onSearchOriginLocode}
                        searchLoading={originLoading}
                    />
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="input"
                        name="destination"
                        horizontalLabel={`${t("home.destination.label")}:`}
                        value={selectedDestination}
                        options={destinations && destinations.length > 0 ? destinations.map(locode => {
                            return {
                                key: locode.locode,
                                value: `${locode.city}, ${locode.country} (${locode.locode})`,
                                item: locode
                            }
                        }) : []}
                        placeholder={`${t("home.destination.placeholder")}:`}
                        dropdownPosition="center"
                        refs={register}
                        onChange={value => setSelectedDestination(value)}
                        onInputChange={onSearchDestLocode}
                        searchLoading={destinationLoading}
                    />
                </div>
                <Datepicker
                    name="VDL"
                    label={t("home.active-shipment.filter-panel.departure-date.label")}
                    className="mbx4"
                    placeholder={t("home.active-shipment.filter-panel.departure-date.placeholder")}
                    range={true}
                    refs={register}
                    minDate={null}
                    start={VDLDates ? VDLDates?.fromTime : null}
                    end={VDLDates ? VDLDates?.toTime : null}
                    onDatesChange={handleVDLEvent}
                    defaultValue={`${VDLDates ? VDLDates?.fromTime + '-' + VDLDates?.toTime : EMPTY}`}
                />
                <Datepicker
                    name="VAD"
                    label={t("home.active-shipment.filter-panel.arrival-date.label")}
                    className="mbx4"
                    placeholder={t("home.active-shipment.filter-panel.departure-date.placeholder")}
                    range={true}
                    refs={register}
                    minDate={null}
                    start={VADDates ? VADDates?.fromTime : null}
                    end={VADDates ? VADDates?.toTime : null}
                    onDatesChange={handleVADEvent}
                    defaultValue={`${VADDates ? VADDates?.fromTime + '-' + VADDates?.toTime : EMPTY}`}
                />
                <div className="tr__shipment-filter--group mbx3 pbx3">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="select"
                        name="shipmentType"
                        label={t("home.active-shipment.filter-panel.type.label")}
                        options={shipmentTypes.map(item => {
                            return {
                                key: item.id,
                                value: item.name,
                            };
                        })}
                        placeholder={t("home.active-shipment.filter-panel.type.placeholder")}
                        dropdownPosition="center"
                        refs={register}
                        onChange={value => setSelectedType(value)}
                        value={selectedType}
                    />
                </div>
                <div className="tr__shipment-filter--group mbx3 pbx3">
                    <Dropdown
                        className="select"
                        icon="icon-chevron-down"
                        iconPosition="right"
                        mode="select"
                        name="organization"
                        label={"Organization"}
                        options={organizations && organizations.length > 0 ? organizations.sort((a, b) => (a.name > b.name) * 2 -1).map(item => {
                            return {
                                key: item._id,
                                value: item.name,
                            };
                        }):[]}
                        placeholder={"Search Organization"}
                        dropdownPosition="center"
                        refs={register}
                        defaultValue={null}
                        onChange={value => setSelectedOrg(value)}
                        value={selectedOrg}
                    />
                </div>
                <ShipmentInformation
                    handleSearchShipmentKey={handleSearchShipmentKey}
                    register={register()}
                    selectShipmentKey={selectShipmentKey}
                    setSelectShipmentKey={setSelectShipmentKey}
                    setShipmentValue={setShipmentValue}
                    shipmentKeys={shipmentKeys}
                    shipmentValue={shipmentValue}
                    count={count}
                    onChangeInput={onChangeInput}
                    onChangeInputValue={onChangeInputValue}
                    filterCountByIndex={filterCountByIndex}
                />
                <p style={count.length > 0 ? {marginBottom:'20px'} : {marginBottom:'20px'}} onClick={() => addMoreShipmentInfo()}
                   className=" tr__link">
                    <i className={'icon-plus'}/> {count.length > 0 ? <span>Add more shipment info</span> : <span>Add shipment info</span>}
                </p>
                <div className="tr__shipment-filter--btns d-flex align-items-center">
                    <Button
                        className="icon outline"
                        icon="icon-reload"
                        type="secondary"
                        onClick={() => {
                            handleClearFilterShipment();
                        }}
                    >
                        {t("home.active-shipment.filter-panel.button.clear")}
                    </Button>
                    <Button
                        type="primary"
                        isSubmitBtn={true}
                    >
                        {t("home.active-shipment.filter-panel.button.apply")}
                    </Button>
                </div>
            </form>
        </Modal>
    )
};

ShipmentFilter.propTypes = {
    open: PropTypes.bool,
    onCancel: PropTypes.func
};
