import React, { createContext, useState } from 'react';
import { countryList } from 'utils';
import _ from 'lodash';
import {ORGANIZATION, LOCODE_PAGE_SIZE, ORIGIN, DESTINATION} from 'actions';

export const CountryContext = createContext();

export const CountryContextProvider = ({children}) => {
    const [originCountries, setOriginCountries] = useState([]);
    const [destinationCountries, setDestinationCountries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectCountry, setSelectCountry] = useState({
        country: ''
    });
    const [countryLoading, setCountryLoading] = useState(false);
  
    const doGetCountries = async (params, callback, type) => {
        const limit = params && params.limit ? params.limit : LOCODE_PAGE_SIZE;
        let value =  params && params.value;
        let result = [];
        if(value){
            value = value.toUpperCase();
            result = _.filter(countryList, function(country) { 
                return country.name.toUpperCase().indexOf(value) > -1 || country.locode.toUpperCase().indexOf(value) > -1
            }).slice(0,limit);
        }else{
            result = countryList.slice(0,limit);
        }
        switch (type) {
            case ORGANIZATION:
                if (callback) callback();
                setCountries(result);
                break;
            case ORIGIN:
                if (callback) callback();
                setOriginCountries(result);
                break;
            case DESTINATION:
                if (callback) callback();
                setDestinationCountries(result);
                break;
            default:
                setCountries(result);
                break;
        }
    }
    

    return (
        <CountryContext.Provider
        value={{
            countries,
            selectCountry,
            countryLoading,
            destinationCountries,
            originCountries,
            setSelectCountry,
            doGetCountries,
            setCountryLoading,
            setOriginCountries,
            setDestinationCountries
        }}
        >
            {children}
        </CountryContext.Provider>
    )
}
