import React, { useContext } from 'react';

import './index.scss';
import {
    Modal
} from 'components';
import { FtaAdvisorContext } from 'contexts';
import { useTranslation } from 'react-i18next';

export const DocumentModal = ({ open = false, onCancel }) => {
    const [t] = useTranslation();
    const {documentFta} = useContext(FtaAdvisorContext);

    return (
        <Modal
            open={open}
            className="tr__document-modal no-padding"
            onCancel={onCancel}
            title={t("home.active-shipment.details.tabs.document-tab.document")}
            isBackDropClickable={false}
        >
            <div className="tr__document-modal--item mbx1">
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">{t("home.active-shipment.details.tabs.fta-tab.category")}</p>
                    <p className="value">{documentFta.docCategory ? documentFta.docCategory : ''}</p>
                </div>
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">{t("home.active-shipment.details.tabs.fta-tab.code")}</p>
                    <p className="value">{documentFta.docCode ? documentFta.docCode : ''}</p>
                </div>
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">{t("home.active-shipment.details.tabs.fta-tab.notes")}</p>
                    <p className="value">{documentFta.docExpNotes ? documentFta.docExpNotes : ''}</p>
                </div>
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">{t("home.active-shipment.details.tabs.fta-tab.name")}</p>
                    <p className="value">{documentFta.docName ? documentFta.docName : ''}</p>
                </div>
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">{t("home.active-shipment.details.tabs.fta-tab.responsible-party")}</p>
                    <p className="value">{documentFta.docResponsibleParty ? documentFta.docResponsibleParty : ''}</p>
                </div>
                <div className="tr__document-modal--field d-flex">
                    <p className="label f-medium">{t("home.active-shipment.details.tabs.fta-tab.source")}</p>
                    <p className="value">{documentFta.docSource ? documentFta.docSource : ''}</p>
                </div>
            </div>
        </Modal>
    )
};