import React, {createContext, useContext, useState} from 'react';
import {
    getVesselSchedules,
    OPERATION_FAILED_MESSAGE
} from 'actions'

import { AuthContext, HomeContext } from 'contexts';

export const VesselScheduleContext = createContext();

export const VesselScheduleContextProvider = ({ children }) => {
    const { loggedInUser } = useContext(AuthContext);
    const { setLoading, setNotificationType, setNotificationMessage } = useContext(HomeContext);
    
    const [totalItems, setTotalItems] = useState(0);
    const [minDuration, setMinDuration] = useState(0);
    const [maxDuration, setMaxDuration] = useState(1);
    const [allVesselSchedules, setAllVesselSchedules] = useState([]);
    const [vesselSchedule, setVesselSchedule] = useState([]);
    const [clearFilters, setClearFilters] = useState(false);

    const handleException = error => {
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((error && error.message) || OPERATION_FAILED_MESSAGE);
    }

    const doGetVesselSchedule = async (params, callback, errorCallback, hasLoading = false, reset = false) => {
        try {
            if (hasLoading) setLoading(true);
            const response = await getVesselSchedules(params, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                const { items, summary } = data;
                if (!items || !summary) {
                    setVesselSchedule([]);
                    setAllVesselSchedules([]);
                    setTotalItems(0);
                    setMinDuration(0);
                    setMaxDuration(1);
                } else {
                    setVesselSchedule(items);
                    if (!allVesselSchedules.length || reset) {
                        setAllVesselSchedules(items);

                    }
                    if ((!allVesselSchedules.length || reset) || !totalItems) {
                        setTotalItems(summary.count);
                        setMinDuration(summary.min_duration);
                        setMaxDuration(summary.max_duration);
                    }
                }
                if (callback) callback();
            }
            setLoading(false);
        } catch (error) {
            handleException(error);
            if (errorCallback) errorCallback();
        }
    }

    return (
        <VesselScheduleContext.Provider
            value={{
                clearFilters,
                vesselSchedule,
                allVesselSchedules,
                totalItems,
                minDuration,
                maxDuration,
                doGetVesselSchedule,
                setVesselSchedule,
                setClearFilters
            }}
        >
            { children }
        </VesselScheduleContext.Provider>
    );
};
