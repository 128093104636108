import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    getOrganizations,
    createOrganization,
    getOrganization,
    updateOrganization,
    removeOrganization,
    getShipmentByOrganizations,
    // getUploadedSignedFileUrl,
    OPERATION_FAILED_MESSAGE,
    ORGANIZATION_PAGE_SIZE,
    MINE,
    joinOrganization,
    cancelJoinRequest,
    getJoinRequest,
    approveJoinRequest,
    organizationCoupon,
    updatePermission,
    getPermissionShipment,
} from 'actions';
import { GET_FILE } from 'actions/constants';
import { HomeContext } from './HomeContext';
import { AuthContext } from './AuthContext';

export const OrganizationContext = createContext();

export const OrganizationContextProvider = ({ children }) => {
    const { setLoading, setNotificationMessage, setSearchMessage, setNotificationType } = useContext(HomeContext);
    const { loggedInUser, userInfo, setUserOrg, doGetUserInfo } = useContext(AuthContext);
    const [organizations, setOrganizations] = useState([]);
    const [organization, setOrganization] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalOrgs, setTotalOrgs] = useState(0);
    const [userOrgRole, setUserOrgRole] = useState('');
    const [requesterInfo, setRequesterInfo] = useState();
    const [notificationInvited,setNotificationInvited] = useState([]);
    const [shipments, setShipments] = useState([]);
    const [totalPageShipments, setTotalPageShipments] = useState(1);
    const [currentPageShipments, setCurrentPageShipments] = useState(0);
    const [totalShipment, setTotalShipment] = useState(0);
    const [permissionShipment, setPermissionShipment] = useState([]);
    const [arrayPermissionChecked, setArrayPermissionChecked] = useState([]);
    const [unableToEdit, setUnableToEdit] = useState([]);
    const [primitiveData, setPrimitiveData] = useState([]);

    /*eslint-disable */
    useEffect(() => {
        if (organization) {
            if (userInfo) {
                const memOrg = userInfo.orgTeams ? userInfo.orgTeams.find(org => org.orgId === organization._id) : null;
                setUserOrgRole(memOrg ? memOrg.roleName : '')

                setUserOrg(userInfo.orgTeams ? userInfo.orgTeams.find(org => org.orgId === organization._id) : null);
            } else {
                setUserOrgRole('');
            }
        }
    }, [userInfo, organization])

    // Clear all state after logout
    // useEffect(() => {
    //     if (!loggedInUser) {
    //         setOrganizations([]);
    //         setCurrentPage(0);
    //         setTotalPages(1);
    //         setTotalOrgs(0);
    //         setOrganization(null);
    //     }
    // }, [loggedInUser])
    /*eslint-enable */

    const handleException = error => {
        const { data } = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetShipments = async (orgId, params, callback, hasLoading = true) =>{
        if (!orgId) setShipments(null);
            try {
                if (hasLoading) setLoading(true);
                const response = await getShipmentByOrganizations(orgId, params, loggedInUser);
                const { data, status } = response;
                if (status === 200) {
                    setShipments(data.items || []);
                    setTotalPageShipments(data.totalPage);
                    setCurrentPageShipments( data.currentPage);
                    if (callback) callback(data.items);
                    else {
                        setTotalShipment(data.totalItems);
                    }
                }
                if (hasLoading) setLoading(false);
            } catch (e) {
                handleException(e);
            }
    }

    const doGetPermissionShipment = async (shipmentId, callback) => {
        try {
            setLoading(true);
            const response = await getPermissionShipment(shipmentId, loggedInUser);
            const { data, status } = response;
            console.log(data);
            if(status === 200){
                setPrimitiveData(data);
                if(callback) callback(data.authorities);
            }
            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doSetPermission = async (id, payload, callback) =>{
        try {
            setLoading(true);
            const response = await updatePermission(id, payload);
            const { status } = response;
            if (status === 200) {
                if (callback) callback();
            }
            setLoading(false)
        }catch (e) {
            handleException(e);
        }
    }

    const doGetOrganizations = async (params, callback, hasLoading = true, keepOrgDetails = false) => {
        if (!keepOrgDetails) setOrganization(null);

        if (currentPage !== params.page || params.isSearching) {
            try {
                if (hasLoading) setLoading(true);
                delete params.isSearching;

                const response = await getOrganizations(params, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    for (let org of data.items) {
                        if (org.logo) {
                            // let signedLogo = await getUploadedSignedFileUrl(org.logo, loggedInUser);
                            // org.logo = signedLogo.data.ret.downloadSignedURI.itemURI;

                            org.logo = GET_FILE + org.logo;
                        }
                    }

                    setOrganizations(data.items || []);
                    setTotalPages(data.totalPage);
                    setCurrentPage(data.currentPage);
                    if (callback) callback(data.items);
                    if (params.name) setSearchMessage('No search result');
                    else {
                        setTotalOrgs(data.totalItems);
                        setSearchMessage('');
                    }
                }

                if (hasLoading) setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doCreateOrganization = async (payload, callback) => {
        try {
            setLoading(true);

            const response = await createOrganization(payload, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                if (data.length > 0) {
                    doGetOrganizations({
                        page: currentPage,
                        limit: ORGANIZATION_PAGE_SIZE,
                        tab: MINE.toUpperCase(),
                        isSearching: true
                    });
                    doGetUserInfo();

                    if (callback) callback(data);
                }
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doGetOrganization = async (orgId, callback, isSearching = false) => {
        if (orgId) {
            // if (organizations && organizations.length > 0 && !isSearching) {
            //     setOrganization(organizations.find(o => o._id === orgId));
            // } else {
                try {
                    setLoading(true);

                    const response = await getOrganization(orgId, loggedInUser);
                    const { data, status } = response;

                    if (status === 200) {
                        if (data.logo) {
                            // let signedLogo = await getUploadedSignedFileUrl(data.logo, loggedInUser);
                            // data.logo = signedLogo.data.ret.downloadSignedURI.itemURI;

                            data.logo = GET_FILE + data.logo;
                        }

                        setOrganization(data);

                        if (callback) callback();
                    }

                    setLoading(false);
                } catch (error) {
                    handleException(error);
                }
            // }
        }
    }

    const doUpdateOrganization = async (payload, callback) => {
        try {
            setLoading(true);

            const response = await updateOrganization(payload, loggedInUser);
            const { data, status } = response;

            if (status === 200) {
                setOrganization(data);
                setOrganizations(oldOrgs => [...oldOrgs.map(org => {
                    if (org._id === data._id) return {
                        ...org,
                        ...data
                    };
                    return org;
                })])
                if (callback) callback();
            }

            setLoading(false);
        } catch (error) {
            handleException(error);
        }
    }

    const doDeleteOrganization = async (orgId, callback) => {
        if (orgId) {
            try {
                setLoading(true);

                const response = await removeOrganization(orgId, loggedInUser);
                const { status } = response;

                if (status === 200) {
                    setOrganizations(oldOrgs => [...oldOrgs.filter(org => org._id !== orgId)])
                    doGetUserInfo(() => {
                        if (callback) callback();
                    });
                }

                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doJoinOrganization = async (orgId, payload, callback, hasLoading = false) => {
        if (orgId) {
            try {
                if (hasLoading) setLoading(true);

                const response = await joinOrganization(orgId, payload, loggedInUser);
                const { status } = response;

                if (status === 200) {
                    if (callback) callback();
                }
                if (hasLoading) setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doCancelJoinRequest = async (orgId, memberId, callback, hasLoading = false) => {
        if (orgId) {
            try {
                if (hasLoading) setLoading(true);
                const response = await cancelJoinRequest(orgId, memberId, loggedInUser);
                const { status } = response;

                if (status === 200) {
                    if (callback) callback();
                }

                if(hasLoading) setLoading(false)
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetJoinRequest = async (orgId, memberId, callback, hasLoading = false) => {
        if (orgId) {
            try {
                if (hasLoading) setLoading(true);
                const response = await getJoinRequest(orgId, memberId, loggedInUser);
                const { data, status } = response;

                if (status === 200) {
                    setRequesterInfo(data)
                    if (callback) callback();
                }

                if(hasLoading) setLoading(false)
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doApproveJoinRequest = async (orgId, memberId, payload, callback, hasLoading = false) => {
        if (orgId && memberId) {
            try {
                if (hasLoading) setLoading(true);
                const response = await approveJoinRequest(orgId, memberId, payload, loggedInUser);
                const { status } = response;

                if (status === 200) {
                    if (callback) callback();
                }

                if(hasLoading) setLoading(false)
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doExtraCoupon = async (orgId, payload, callback) => {
        if (orgId) {
            try {
                setLoading(true);
                const response = await organizationCoupon(orgId, payload, loggedInUser);
                const { status } = response;

                if (status === 200) {
                    if (callback) callback();
                }

                setLoading(false)
            } catch (error) {
                handleException(error);
            }
        }
    }

    return(
        <OrganizationContext.Provider
            value={{
                notificationInvited,
                currentPage,
                organizations,
                organization,
                totalPages,
                permissionShipment,
                totalOrgs,
                unableToEdit,
                arrayPermissionChecked,
                shipments,
                totalPageShipments,
                currentPageShipments,
                totalShipment,
                primitiveData,
                userOrgRole,
                requesterInfo,
                setCurrentPage,
                setArrayPermissionChecked,
                setOrganizations,
                setPermissionShipment,
                setUnableToEdit,
                doGetOrganizations,
                doSetPermission,
                doCreateOrganization,
                doGetOrganization,
                doUpdateOrganization,
                doDeleteOrganization,
                doGetShipments,
                doGetPermissionShipment,
                setUserOrgRole,
                setPrimitiveData,
                doJoinOrganization,
                doCancelJoinRequest,
                doGetJoinRequest,
                doApproveJoinRequest,
                doExtraCoupon,
                setNotificationInvited
            }}
        >
            { children }
        </OrganizationContext.Provider>
    );
};
