import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'styles/index.scss';

import React, { Suspense, useEffect, useState, useContext } from "react";
import ReactDOM from 'react-dom';
import { Router, withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import 'i18n';

import { assignToken, delayImport, getCookie, removeAll } from 'utils';
// import moment from 'moment';

import * as serviceWorker from './serviceWorker';

import { Spinner, Modals } from 'components';
import {
    HomeContextProvider,
    AuthContext,
	// HomeContext
} from 'contexts';
import { 
	ACCESS_TOKEN, 
	ACCESS_TOKEN_EXPIRY, 
	CHANGE_PASSWORD_URL, 
	PATH_NAME_AFTER_LOGIN, 
	REFRESH_TOKEN
} from 'actions';

import Amplify, { Auth, Hub } from 'aws-amplify'
import awsconfig from 'aws-config/awsconfig.json';
import awsauth from 'aws-config/awsauth.json';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

declare var window: any;

const HomeLayout = delayImport(import('layouts/HomeLayout'));
const NotFound = delayImport(import('pages/NotFound'));
const AuthenticationLayout = delayImport(import('layouts/AuthenticationLayout'));

const customHistory = createBrowserHistory();

const PrivateRoute = ({ component: Component, ...rest} : any) => (
	<Route {...rest} render={(props) => {	
		const accessToken = getCookie(ACCESS_TOKEN);
		const ssAccessToken = sessionStorage.getItem(ACCESS_TOKEN);
		localStorage.setItem(PATH_NAME_AFTER_LOGIN,{...props}.location.pathname);

		return (
			accessToken || ssAccessToken ? <Component {...props} /> : <Redirect to="/authentication" />
		)
	}} />
)

const LoggedInRoute = ({ component: Component, ...rest} : any) => (
	<Route {...rest} render={(props) => {
		
			
		
		const accessToken = getCookie(ACCESS_TOKEN);
		const ssAccessToken = sessionStorage.getItem(ACCESS_TOKEN);
		const changePasswordUrl = props.location.pathname.indexOf(CHANGE_PASSWORD_URL) > -1;
		return (
			(accessToken || ssAccessToken) && !changePasswordUrl ? <Redirect to="/" /> : <Component {...props} />
		)
	}} />
)

const App = () => {
    const { userInfo, setLoggedInUser, doCheckExistUser } = useContext(AuthContext);
	// const history = useHistory();
	// const { isLogout } = useContext(HomeContext);
	// const [accessToken, setAccessToken] = useState(null);
	const [isToken, setIsToken] = useState(false);
	
	/*eslint-disable */
	useEffect(() => {
				Auth.currentAuthenticatedUser().then(user => {
					// console.log('currentAuthenticatedUser', user)
					Auth.currentSession().then(res=>{
						let accessToken = res.getAccessToken();
						let jwt = accessToken.getJwtToken()
						let refreshToken = res.getRefreshToken().getToken();
						// console.log(refreshToken)
						// console.log(res)
						// console.log(`myAccessToken: ${JSON.stringify(accessToken)}`)
						// console.log(`myJwt: ${jwt}`)
						sessionStorage.setItem(ACCESS_TOKEN_EXPIRY, (accessToken.payload.exp * 1000).toString());
						sessionStorage.setItem(ACCESS_TOKEN, jwt);
						sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
						setLoggedInUser(jwt);
						doCheckExistUser(jwt, () => {
							setIsToken(true);
						})
					})
				  }).catch(() => {
					  console.log('Not signed in')
					  setIsToken(true);
					})
	},[])
	/*eslint-enable */
    
    useEffect(() => {
        // Initializes Intercom Messenger for logged-in user
        if (userInfo) {
            window.Intercom("boot", {
                app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                name: userInfo.name,
                email: userInfo.email
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo]);

	

    // Update Intercom whenever the view or URL changes in the app
    customHistory.listen(() => {
        window.Intercom("update");
    });

	return isToken ? (
		<>
			<Switch>
				<Route path="/not-found" component={NotFound} />
				<LoggedInRoute path="/authentication" component={AuthenticationLayout} />
				<PrivateRoute path="/" component={HomeLayout} />
			</Switch>
			<Modals />
		</>
	) : <></>;
};

const AppRootWithRouter = withRouter(App);

const Root = () => {
	const [isAssigningToken, setIsAssigningToken] = useState(false);
	// const [loggedInUser, setLoggedInUser] = useState();
	
	useEffect(() => {
		Amplify.configure(awsconfig)
		Auth.configure({ oauth: awsauth })  
		Hub.listen('auth', ({ payload: { event, data } }) => {
			switch (event) {
			case 'signIn':
				console.log('sign in', event, data)
				break
			case 'signOut':
				console.log('sign out')

				removeAll();
				break
			}
		})
	}, [])


	/*eslint-disable */
	useEffect(() => {
		assignToken(() => {
			setIsAssigningToken(true);
		});


	}, [])

	/*eslint-enable */
	
	return isAssigningToken ? (
		<Suspense fallback={<Spinner />}>
			<HomeContextProvider>
				<Router history={customHistory}>
					<AppRootWithRouter />
				</Router>
			</HomeContextProvider>
		</Suspense>
	) : <></>;
};

ReactDOM.render(
	<React.StrictMode>
		 <DndProvider backend={HTML5Backend}>
           <Root />
         </DndProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorker.unregister();
