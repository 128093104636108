import React, { createContext, useContext, useEffect, useState } from 'react';
import { HomeContext } from './HomeContext';

import { getToken } from 'utils';
import {
    getUploadedSignedFileUrl,
    getOcrData,
    getBase64FromUrl,
    OPERATION_FAILED_MESSAGE,
    updateOcrData
    // updateOcrData
} from 'actions';
import { isEmpty } from 'lodash';

export const ExtractedContext = createContext();


export const ExtractedContextProvider = ({ children }) => {
    const { setLoading, setNotificationMessage, setNotificationType } = useContext(HomeContext);
    const [token, setToken] = useState();
    const [ocrData, setOcrData] = useState({});
    const [extractedData, setExtractedData] = useState([]);
    const [ocrFtaData, setOcrFtaData] = useState({});
    const [extractedFtaData, setExtractedFtaData] = useState([]);

    // for emebed
    const [fileData, setFileData] = useState({});

    /*eslint-disable */
    useEffect(() => {
        setToken(getToken())
    })

    useEffect(() => {
        let temp = [];
        let commonGroup = {
            title: '',
            details: []
        };
        for (let gr in ocrData) {
            if (typeof (ocrData[gr]) === 'string') {
                commonGroup.details.push({
                    name: gr,
                    value: typeof (ocrData[gr]) === 'string' ? ocrData[gr] : JSON.stringify(ocrData[gr])
                });
            } else if (typeof (ocrData[gr]) === 'object') {
                let group = { title: gr, details: [] };
                if(ocrData[gr].isNewSection){
                    group.isNewSection = ocrData[gr].isNewSection;
                    delete ocrData[gr].isNewSection;
                }
                for (let item in ocrData[gr]) {
                    group.details.push({
                        name: item,
                        value: typeof (ocrData[gr][item]) === 'string' ? ocrData[gr][item] : JSON.stringify(ocrData[gr][item])
                    });
                }
                temp.push(group);
            }
        }
        if (commonGroup.details.length > 0) temp.unshift(commonGroup);
        if (ocrData && Object.keys(ocrData).length) {
            setExtractedData(temp);
        } else { setExtractedData([]) }
    }, [ocrData])
    /*eslint-enable */

    const convertOcrData = (data) => {
        if(isEmpty(data)) return data;
        let temp = {};
        const BookingDetails = isEmpty(data.BookingDetails) ? {
            CarrierName: data.CarrierName,
            BookingNumber: data.BookingNumber
        } : data.BookingDetails;
        const ContainerTracking = isEmpty(data.ContainerTracking) ? {
            Measurement: data.Measurement,
            ContainerYard: data.ContainerYard,
            CommodityDescription: data.CommodityDescription,
            ServiceType: data.ServiceType,
            GrossWeightUnit: data.GrossWeight.Unit,
            GrossWeightValue: data.GrossWeight.Value,
            HSCode: data.HSCode,
            NumberOfContainers: data.NumberOfContainers,
        } : data.ContainerTracking;
        
        const container =  data.Container && data.Container.length > 0 ? data.Container[0] : data.Containers && data.Containers.length > 0 ? data.Containers[0] : {};
        let Containers = {};
        if(!isEmpty(container)){
            Containers = {
                ...container,
                WeightUnit: container.Weight.Unit,
                WeightValue: container.Weight.Value,
                VolumeUnit: container.Volume.Unit,
                VolumeValue: container.Volume.Value,
            }
            if(Containers.Weight)
                delete Containers.Weight;
            if(Containers.Volume)
                delete Containers.Volume;
        }

        const AISTracking = isEmpty(data.AISTracking) ? {
            VesselName: data.Vessel,
            IMO: data.IMO,
            Voyage: data.VoyageNumber,
        } : data.AISTracking;
        const ContactDetails = isEmpty(data.ContactDetails) ?  {
            ShipperAddress: data.ShipperAddress,
            Consignee: data.Consignee,
            ConsigneeAddress: data.ConsigneeAddress,
            NotifyParty: data.NotifyParty,
            NotifyPartyAddress: data.NotifyPartyAddress,
            ServiceContractNumber: data.ServiceContractNumber,
        } : data.ContactDetails;
        if(data.ShippingInformation && data.ShippingInformation.OriginPort)
            delete data.ShippingInformation.OriginPort;
        if(data.ShippingInformation && data.ShippingInformation.DestinationPort)
            delete data.ShippingInformation.DestinationPort;
        const ShippingDetails = isEmpty(data.ShippingDetails) ? {
            ...data.ShippingInformation,
            Shipper: data.Shipper,
            BookingDate: data.BookingDate
        } : data.ShippingDetails;
        const ContactInformation = {...data.ContactInformation}
        const transhipmentInfo = data.TranshipmentInformation && data.TranshipmentInformation instanceof Array && data.TranshipmentInformation.length > 0 
                                    ? data.TranshipmentInformation[0] : !isEmpty(data.TranshipmentInformation) ? data.TranshipmentInformation : {};
        let TranshipmentInformation = {};
        if(!isEmpty(transhipmentInfo)){
            TranshipmentInformation = {...transhipmentInfo}
        }
        const CutOff = {...data.CutOff}
        if(data.newSections){
            const sections = data.newSections;
            for (let name in sections) {
                temp[name] = sections[name];
                temp[name].isNewSection = true;
            }
        }
        temp = {
            ShippingDetails,
            BookingDetails,
            ContainerTracking,
            Container: Containers,
            AISTracking,
            ContactDetails,
            ContactInformation,
            TranshipmentInformation,
            CutOff,
            ...temp
        }
        return temp;
    }

    const doGetOcrData = async (docId, callback) => {
        try {
            setLoading(true);

            const [responseOcr, responseUrl] = await Promise.all([
                getOcrData(docId, token),
                getUploadedSignedFileUrl(docId, token)
            ])
            if (responseOcr.status === 200 && responseUrl.status === 200) {
                setOcrData(convertOcrData(responseOcr.data));
                const base64 = await getBase64FromUrl(responseUrl.data?.ret?.downloadSignedURI?.itemURI);
                if (responseUrl.data?.ret?.fileExtension === 'pdf') {
                    setFileData({
                        contentType: 'application/pdf',
                        data: `data:application/pdf;base64,${base64}`
                    });
                } else {
                    setFileData({
                        contentType: `image/${responseUrl.data?.ret?.fileExtension}`,
                        data: `data:image/${responseUrl.data?.ret?.fileExtension};base64,${base64}`
                    });
                }

                if (callback)
                    callback();
            }
        } catch (error) {
        }
        setLoading(false);
    }

    const doGetOcrFtaData = async (docId,callback) =>{
        try {
            setLoading(true);
            let extractedData = {};
            const [responseOcr, responseUrl] = await Promise.all([
                getOcrData(docId, token),
                getUploadedSignedFileUrl(docId, token)
            ])
            if (responseOcr.status === 200 && responseUrl.status === 200) {
                if(responseOcr.data){
                    extractedData = {
                        id: responseUrl.data?.ret?.fileRecordId ? responseUrl.data?.ret?.fileRecordId : responseUrl.data?.ret?._id,
                        hsCode: responseOcr.data.HSCode ? responseOcr.data.HSCode : "", 
                        description: responseOcr.data.CommodityDescription ? responseOcr.data.CommodityDescription : ""
                    }
                }

                const base64 = await getBase64FromUrl(responseUrl.data?.ret?.downloadSignedURI?.itemURI);
                if (responseUrl.data?.ret?.fileExtension === 'pdf') {
                    setFileData({
                        contentType: 'application/pdf',
                        data: `data:application/pdf;base64,${base64}`
                    });
                } else {
                    setFileData({
                        contentType: `image/${responseUrl.data?.ret?.fileExtension}`,
                        data: `data:image/${responseUrl.data?.ret?.fileExtension};base64,${base64}`
                    });
                }
                setOcrFtaData(extractedData);
            }
            if(callback) callback();
            setLoading(false);
        } catch (error) {
        }
        setLoading(false);
    }

    const doUpdateOcr = (payload, docId) => {
        try {
            setLoading(true);
            updateOcrData(docId, payload, token);
        } catch (error) {
            handleException(error);
        }
        setLoading(false);
    }

    const handleException = error => {
        const { data } = error.response;
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    return (
        <ExtractedContext.Provider
            value={{
                ocrData,
                fileData,
                extractedData,
                extractedFtaData,
                ocrFtaData,
                doUpdateOcr,
                setOcrFtaData,
                setOcrData,
                doGetOcrData,
                doGetOcrFtaData,
                setExtractedFtaData
            }}
        >
            { children}
        </ExtractedContext.Provider>
    );
};
