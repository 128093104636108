import { getLogsOrg, getLogsShipment, getLogsTeam, OPERATION_FAILED_MESSAGE } from 'actions';
import React, { createContext, useContext, useState } from 'react'
import { AuthContext } from './AuthContext';
import { HomeContext } from './HomeContext';

export const ActivitiesLogContext = createContext();

export const ActivitiesLogContextProvider = ({ children }) => {
    const { setLoading, setNotificationMessage, setNotificationType } = useContext(HomeContext);
    const { loggedInUser} = useContext(AuthContext);
    const [logs, setLogs] = useState([]);
    // const [logsOrg, setLogsOrg] = useState([]);
    const [currentPageLog, setCurrentPageLog] = useState(0);
    const [totalPagesLog, setTotalPagesLog] = useState(1)

    const handleException = error => {
        const { data } = (error.response || {});
        setLoading(false);
        setNotificationType('error');
        setNotificationMessage((data && (data.message || data.error)) || OPERATION_FAILED_MESSAGE);
    }

    const doGetLogsTeam = async (teamId, params, callback, isList = false) => {
        if (teamId) {
            try {
                setLoading(true);

                const response = await getLogsTeam(teamId, params, loggedInUser);
                const { status, data } = response;

                if (status === 200) {
                    if (isList) {
                        setLogs(data.items);
                        setTotalPagesLog(data.totalPage);
                        setCurrentPageLog(data.currentPage);
                    } else {
                        if (callback) callback(data);
                    }
                    
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }

    const doGetLogsOrg = async (orgId, params, callback, isList = false) => {
        if (orgId) {
            try {
                setLoading(true);

                const response = await getLogsOrg(orgId, params, loggedInUser);
                const { status, data } = response;

                if (status === 200) {
                    if (isList) {
                        setLogs(data.items);
                        setTotalPagesLog(data.totalPage);
                        setCurrentPageLog(data.currentPage);
                    } else {
                        if (callback) callback(data);
                    }
                    
                    
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
    
    const doGetLogsShipment = async (shipmentId, params, callback, isList = false) => {
        if (shipmentId) {
            try {
                setLoading(true);

                const response = await getLogsShipment(shipmentId, params, loggedInUser);
                const { status, data } = response;

                if (status === 200) {
                    if (isList) {
                        setLogs(data.items);
                        setTotalPagesLog(data.totalPage);
                        setCurrentPageLog(data.currentPage);
                    } else {
                        if (callback) callback(data);
                    }
                    
                    
                }
                setLoading(false);
            } catch (error) {
                handleException(error);
            }
        }
    }
    return (
        <ActivitiesLogContext.Provider
            value={{
                setLogs,
                logs,
                currentPageLog,
                totalPagesLog,
                setCurrentPageLog,
                doGetLogsTeam,
                doGetLogsOrg,
                doGetLogsShipment
            }}
        >
            { children }
        </ActivitiesLogContext.Provider>
    )
}
