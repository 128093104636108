import React, { useContext } from 'react';
import './index.scss';
import {
    Modal,
} from 'components';
import { ManagementContext } from 'contexts';
import { CLOSED } from 'actions';
export const LinkIssueList = ({
    open = false,
    onCancel,
    onSubmit = () => {},
}) => {
    const { linkIssues } = useContext(ManagementContext)
    
    return (
        <Modal
            open={open}
            className="tr__link-issue__modal"
            onCancel={onCancel}
            title="Linked Issue List"
            onCancelClick={onCancel}
            isBackDropClickable={false}
        >
            <div className="d-flex flex-column link-issue__box">
                {linkIssues && linkIssues.length > 0 &&linkIssues.map((issue, issueIndex) => (
                    <div key={issueIndex} className="d-flex align-items-center mtx1">
                        <i className={`icon-chain tr__link-issue mrx1 ${issue.status === CLOSED ? 'tr__link-issue__done' : ''}`} />
                        <p className="mlx2">{issue.title}</p>
                    </div>
                ))

                }
            </div>
        </Modal>
    )
}