import React, { useContext, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { ShipmentCard, TableFooter } from 'components';
import {
    HomeContext
} from 'contexts';
import { useTranslation } from 'react-i18next';

export const ShipmentList = ({
    className = '',
    title,
    shipments,
    onShowDetails,
    isLinkTask = false,
    totalPages,
    currentPage,
    onPageChange = () => {},
    onStarShipment = () => {},
    isStar = false,
    currentTab,
    tabType
}) => {
    const [t] = useTranslation();
    const { isArchive } = useContext(HomeContext);
    const [isPageChange, setIsPageChange] = useState(false);

    /*eslint-disable*/
    useLayoutEffect(() => {
        if(isPageChange) {
            const currentPosition  = window.pageYOffset;
            if(currentPosition > 0){
                window.scroll({
                    top: document.body.offsetHeight,
                    left: 0, 
                    behavior: 'smooth',
                });
            }
            setIsPageChange(false);
        }
    }, [shipments]);
    /*eslint-enable*/

    const handlePageChange = (page) => {
        onPageChange(page);
        setIsPageChange(true);
    }
    return (
        <div className={`tr__active-shipment-list ${className}`}>
            {!isArchive && title && (
                <h2 className="h2 f-medium mbx2">{title}</h2>
            )}
            {shipments.length > 0 ? (
                <div className={`${isLinkTask ? 'tr__link-task-list' : ''}`}>
                    {shipments.map(shipment => (
                        <ShipmentCard
                            key={shipment._id}
                            shipment={shipment}
                            className='mbx3'
                            onShowDetails={() => onShowDetails(shipment)}
                            isArchive={isArchive}
                            onStarShipment={onStarShipment}
                            isStar={isStar}
                            currentTab={currentTab}
                            currentPage={currentPage}
                            tabType={tabType}
                        />
                    ))}
                    {isLinkTask && (
                        <i className='tr__link-task-pin icon-chain' />
                    )}
                    {totalPages > 1 && (
                        <TableFooter
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={handlePageChange}
                        />
                    )}
                </div>
            ) : (
                isArchive ? <p>{t("home.active-shipment.no-archive-shipments-found")}</p> : <p>{t("home.active-shipment.no-active-shipments-found")}</p>
            )}
        </div>
    )
};

ShipmentList.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    onShowDetails: PropTypes.func,
    isLinkTask: PropTypes.bool,
    isStar: PropTypes.bool,
    onStarShipment: PropTypes.func,
    tabType: PropTypes.string,
};
