import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import { 
    SearchForms, 
    MapBox, 
    // ShipmentTypeModal 
} from 'components';
import { HomeContext, TrackShipmentContext } from 'contexts';
import { DONE } from 'actions';
import { typeShipmentOptions } from 'utils';


export const ShipmentsMap = () => {
    const {
        selectedTracker,
        trackers,
        routes,
        setTrackers,
        setSelectedTracker,
        setRoutes
    } = useContext(TrackShipmentContext);

    const shipmentsMapId = 'shipments-map';
    const [step] = useState(1);
    const [searched, setSearched] = useState(false);
    const [markers, setMarkers] = useState([]);
    const [activeRoutes, setActiveRoutes] = useState([]);
    const [markersStatus, setMarkersStatus] = useState([]);
    const [markersInfo, setMarkersInfo] = useState([]);
    const { setTypeOfShipment } = useContext(HomeContext);
    const [
        typeShipment, 
        setTypeShipment
    ] = useState(typeShipmentOptions[0]);

    /*eslint-disable */
    useEffect(() => {
        return () => {
            setTrackers([]);
            setSelectedTracker(null);
            setRoutes(null);
        }
    }, [])
    useEffect(()=> {
        setTypeOfShipment(typeShipment);
    }, [typeShipment])
    
    useEffect(() => {
        if (selectedTracker) {
            const { prePol, postPod } = selectedTracker;
            const isRoutes = routes && routes.routeArray && routes.routeArray.length;
            
            let newActiveRoutes = [];
            let tempMarkers = [];
            let tempMarkerStatus = [];
            let tempMarkerInfo = [];

            if (isRoutes) 
            {
                newActiveRoutes = [
                    [prePol?.location?.lng, prePol?.location?.lat],
                    ...routes.routeArray
                ];
                tempMarkers = [
                    [prePol?.location?.lng, prePol?.location?.lat],
                    ...routes.routeArray,
                    [postPod?.location?.lng, postPod?.location?.lat]
                ];
                tempMarkerStatus = [
                    prePol.status === DONE,
                    ...Array(routes.routeArray.length).fill(true),
                    postPod.status === DONE
                ];
                tempMarkerInfo = [
                    `${prePol?.location?.name} (${prePol?.location?.locode})`,
                    ...Array(routes.routeArray.length).fill(''),
                    `${postPod?.location?.name} (${postPod?.location?.locode})`
                ];

                if (postPod.status === DONE) newActiveRoutes.push([postPod?.location?.lng, postPod?.location?.lat])
            } else {
                tempMarkers = [
                    [prePol?.location?.lng, prePol?.location?.lat],
                    [postPod?.location?.lng, postPod?.location?.lat]
                ];
                tempMarkerStatus = [
                    prePol?.status === DONE,
                    postPod?.status === DONE
                ];
                tempMarkerInfo = [
                    `${prePol?.location?.name} (${prePol?.location?.locode})`,
                    `${postPod?.location?.name} (${postPod?.location?.locode})`
                ];
            }

            setMarkers(tempMarkers);

            setActiveRoutes(newActiveRoutes);

            setMarkersStatus(tempMarkerStatus);

            setMarkersInfo(tempMarkerInfo);
        } else {
            setMarkers([]);
            setMarkersStatus([]);
            setMarkersInfo([]);
            setActiveRoutes([]);
        }
    }, [selectedTracker, routes])

    useEffect(() => {
        if (!searched) {
            setMarkers([]);
            setActiveRoutes([]);
            setMarkersInfo([]);
            setMarkersStatus([]);
        }
    }, [searched, trackers])
    /*eslint-enable */

    // const handleChooseType = (type) => {
    //     if(type) {
    //         setStep(step + 1);
    //     }
    // }
    return (
        <div className="tr__shipments-map">
            {/* {step === 1 && (
                <ShipmentTypeModal
                    chooseType={handleChooseType}
                />
            )} */}

            {step === 1 && (
                <SearchForms
                    searched={searched}
                    setSearched={setSearched}
                    typeShipment={typeShipment}
                    setTypeShipment={setTypeShipment}
                />
            )}
        
            <MapBox
                id={shipmentsMapId}
                markers={markers}
                routes={activeRoutes}
                status={markersStatus}
                infos={markersInfo}
            />
        </div>
    )
};